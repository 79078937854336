import React, { useEffect, useState } from 'react';
import { IPermission } from '../../../../common/types/commonTypes';
import { ColumnType } from 'antd/es/table';
import { IPartialRefundBodyType } from '../../RefundTypes/RefundTypes';
import dayjs from 'dayjs';
import { Button, Popconfirm, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../auth/states/userSlice';
import { useDeletePartialRefundMutation } from '../../Api/Endpoints/PartialRefundEndpoints';

type Props = {
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
  checkButtonState?: Boolean;
};
export const PartialRefundUtils = ({
  permission,
  pagination,
  checkButtonState,
}: Props): ColumnType<IPartialRefundBodyType>[] => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const user = useSelector(selectUser);
  const [deletePartialRefund, { isError, isLoading, isSuccess }] =
    useDeletePartialRefundMutation();

  const handDeleteRefund = async (
    value: IPartialRefundBodyType,
    index: number
  ) => {
    setColumnIndex(index);
    await deletePartialRefund({
      refund_id: value.prfnd_id,
      deleted_by: user?.user_id as number,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);

  return [
    {
      title: 'SL.',
      align: 'center',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Refund Date',
      dataIndex: 'prfnd_date',
      align: 'center',
      key: 'prfnd_date',
      render: (_, date) => <>{dayjs(date.prfnd_date).format('DD-MM-YYYY')}</>,
    },
    {
      title: 'Vouchar No',
      dataIndex: 'prfnd_vouchar_number',
      key: 'prfnd_vouchar_number',
      align: 'center',
    },
    {
      title: 'Ticket No',
      dataIndex: 'ticket_no',
      key: 'ticket_no',
      align: 'center',
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      align: 'center',
      render: (value, record) => {
        const { client_id, combine_id } = record;

        return record?.combine_id || record?.client_id ? (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?client_query=${client_id}`
                : `/reports/combined_ledger?id=${combine_id}`
            }
          >
            <span>{record.client_name}</span>
          </Link>
        ) : (
          value
        );
      },
    },
    {
      title: 'Refund Charge',
      dataIndex: 'prfnd_charge_amount',
      key: 'prfnd_charge_amount',
      align: 'center',
      render: (_, data) => <>{Number(data.prfnd_charge_amount)}</>,
    },
    {
      title: 'Refund Profit',
      dataIndex: 'prfnd_profit_amount',
      key: 'prfnd_profit_amount',
      align: 'center',
      render: (_, data) => <>{Number(data.prfnd_profit_amount)}</>,
    },
    {
      title: 'Created By',
      dataIndex: 'user_full_name',
      key: 'user_full_name',
      align: 'end',
    },
    {
      title: 'Action',
      key: 'operation',
      align: 'center',
      render: (_, data, index) => (
        <Space size='small'>
          {permission?.['read:any'] && !checkButtonState && (
            <Link
              to={`/refund/partial/view/${data.prfnd_id}`}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#fff' }}>View</Typography>
              </Button>
            </Link>
          )}

          {permission?.['delete:any'] && !checkButtonState && (
            <Popconfirm
              title='Sure to delete'
              onConfirm={() => handDeleteRefund(data, index)}
            >
              <Button
                size='small'
                type='primary'
                danger
                // loading={columnIndex === index && true}
                loading={false}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
