import { ColumnsType } from "antd/lib/table";
import { Fixed2, formatAmount } from "../../../../common/utils/common.utils";
import { ISalesManWiseCL } from "../endpoints/SalesManWiseClientDueEndpoints";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const SalesManWiseClientDueColumn = (queryData: {
  isTrash: "0" | "1";
  current: number;
  pageSize: number;
}): ColumnsType<ISalesManWiseCL> => {
  return [
    {
      title: "SL.",
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },

    {
      title: "Sales Man Name",

      render: (record: ISalesManWiseCL) => (
        <Link
          to={`/reports/daily_sales_report?employee_id=${
            record?.employee_id
          }&from_date=${dayjs()
            .startOf("month")
            .format("YYYY-MM-DD")}&to_date=${dayjs()
            .endOf("month")
            .format("YYYY-MM-DD")}`}
        >
          {record?.employee_full_name}
        </Link>
      ),
    },
    {
      title: "Number of Invoice",
      dataIndex: "total",
      key: "total",
      align: "center",
    },

    {
      title: "Total Sales",
      dataIndex: "total_sales",
      key: "total_sales",
      align: "right",
      render: (curr) => (
        <span className="color-sales">{formatAmount(curr)}</span>
      ),
    },
    {
      title: "Client Payment",
      dataIndex: "total_client_payment",
      key: "total_client_payment",
      align: "right",
      render: (curr) => (
        <span className="color-collection">{formatAmount(curr)}</span>
      ),
    },

    {
      title: "Total Discount",
      dataIndex: "total_discount",
      key: "total_discount",
      align: "right",
      render: (curr) => (
        <span className="color-discount">{formatAmount(curr)}</span>
      ),
    },
    {
      title: "Client Due",
      dataIndex: "total_client_due",
      key: "total_client_due",
      align: "right",
      render: (curr) => <span className="color-due">{formatAmount(curr)}</span>,
    },
  ];
};

export default SalesManWiseClientDueColumn;
