import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface IError {
  message: string;
  type: string;
}

interface IState {
  error: IError | null;
  isModal: boolean;
  modalLoading: boolean;
  defaultLoading: boolean;
  tourCount: number;
}

const initialState: IState = {
  error: null,
  isModal: false,
  modalLoading: false,
  defaultLoading: false,
  tourCount: 5,
};

const commonSlice = createSlice({
  name: "common_slice",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<IError>) {
      state.error = action.payload;
    },

    setModal(state, action: PayloadAction<boolean>) {
      state.isModal = action.payload;
    },

    setModalLoading(state, action: PayloadAction<boolean>) {
      state.modalLoading = action.payload;
    },

    setDefaultLoading(state, action: PayloadAction<boolean>) {
      state.defaultLoading = action.payload;
    },

    setTourCount(state, action: PayloadAction<number>) {
      state.tourCount = action.payload;
    },
  },
});

export const {
  setError,
  setModalLoading,
  setDefaultLoading,
  setModal,
  setTourCount,
} = commonSlice.actions;

export const selectCommon = (state: RootState) => state.commonSlice;

export default commonSlice.reducer;
