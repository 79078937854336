import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Form, Space, Table, theme } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetGroupWisePassenerListQuery } from '../endpoints/groupWisePassengerListEndpoints';
import { IGroupWisePassengerFormDataType } from '../types/groupWisePassengerListTypes';
import GroupWisePassengerListColumn from '../utils/GroupWisePassengerListColumn';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';

const GroupWisePassengerList = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  const [fetchGroupWisePassengerList, { data, isLoading }] =
    useLazyGetGroupWisePassenerListQuery();
  const onFinish = async (values: IGroupWisePassengerFormDataType) => {
    await fetchGroupWisePassengerList({
      group_id: values.group_id,
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: values.group_id.toString(),
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IGroupWisePassengerFormDataType = form.getFieldsValue();
    fetchGroupWisePassengerList({
      group_id: values.group_id,
      query: `${query}`,
    });
    setExcelQuery({
      Id: values.group_id.toString(),
      query: `${query}`,
    });
  };
  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Expense_Report`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      size='small'
      bordered
      scroll={{ x: true }}
      columns={GroupWisePassengerListColumn(queryData)}
      dataSource={data?.data}
      pagination={count > 50 ? tableParams.pagination : false}
      loading={{ spinning: isLoading, indicator: loadingIndicator }}
      onChange={(args1, args2, args3) =>
        handleTableChange({
          args: {
            pagination: args1,
            filters: args2,
            sorter: args3,
          },
          states: {
            refetch,
            setQueryData,
            setTableParams,
            tableParams,
          },
        })
      }
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Total Due/Advance (Agents)' }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Group wise Passenger List']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'passenger/groupWisePassengerList',
              excelName: 'groupWisePassengerList',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader
          reports_select
          showSelectGroup
          reports_title='Group wise Passenger List'
        />
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default GroupWisePassengerList;
