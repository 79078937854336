import { EditOutlined } from "@ant-design/icons";
import {
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Table,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../app/hooks";
import {
  IPaxDetails,
  IViewInvoiceDetails,
} from "../../../../common/types/commonInterfaces";
import { Fixed2 } from "../../../../common/utils/common.utils";
import NumToWord from "../../../../components/number_to_words/NumToWord";
import { IFlightData } from "../../../IATA_AIR_TICKET/Components/ManualInvoiceAirTicketEdited/ManualEditInVoiceFlight";
import { IAirticketBillingInfo } from "../../../IATA_AIR_TICKET/types/invoiceAirTicketTypes";

type TableType = {
  key: string;
  name: any;
  value?: any;
};
type IProps = {
  editing: any;
  dataIndex: any;
  title: any;
  inputType: any;
  record: any;
  index: any;
  children: any;
};
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: IProps) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber size="small" />
    ) : (
      <Input size="small" />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <>
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${record?.name}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        </>
      ) : (
        children
      )}
    </td>
  );
};

const InvoiceSubtotalEdit = ({
  invoiceData,
  setAllEditableData,
  allEditableData,
  isShowDiscount,
  isShowPayment,
}: {
  isShowPayment?: boolean;
  isShowDiscount?: boolean;
  invoiceData: IViewInvoiceDetails | undefined;
  setAllEditableData: React.Dispatch<
    React.SetStateAction<{
      flightDetails?: IFlightData[] | undefined;
      paxDetails?: IPaxDetails[] | undefined;
      billingDetails?: IAirticketBillingInfo[] | undefined;
      subtotalDetails?: any;
    }>
  >;
  allEditableData: {
    flightDetails?: IFlightData[] | undefined;
    paxDetails?: IPaxDetails[] | undefined;
    billingDetails?: IAirticketBillingInfo[] | undefined;
    subtotalDetails?: any;
  };
}) => {
  const appConfig = useAppSelector((state) => state.configSlice);

  const totalSubTotal = allEditableData?.billingDetails?.reduce(
    (acc, curr) => acc + Number(curr.airticket_client_price),
    0
  );

  const {
    tac_due_wtr_mark,
    tac_paid_wtr_mark,
    tac_inv_curr_sym: CurrencySymbol,
    tac_invoice_footer_note,
  } = appConfig;
  const [form] = Form.useForm();

  const [data, setData] = useState<TableType[]>([]);

  useEffect(() => {
    const initialData = [
      {
        key: "1",
        name: "Sub Total",
        value: invoiceData?.invoice_sub_total,
      },
      {
        key: "3",
        name: "Net Total",
        value: invoiceData?.invoice_net_total
          ? `${CurrencySymbol || ""}${Number(invoiceData?.invoice_net_total)}`
          : "",
      },
      {
        key: "5",
        name: "Inv Due",
        value:
          Number(invoiceData?.invoice_pay || 0) >=
          Number(invoiceData?.invoice_net_total || 0)
            ? ""
            : `${CurrencySymbol || ""} ${
                Number(invoiceData?.invoice_net_total || 0) -
                  Number(invoiceData?.invoice_pay || 0) || ""
              }`,
      },
    ];

    if (isShowDiscount) {
      initialData.splice(1, 0, {
        key: "2",
        name: "Discount",
        value: `${CurrencySymbol || ""}${
          Fixed2(invoiceData?.invoice_discount) || 0
        }`,
      });
    }

    if (isShowPayment) {
      initialData.splice(initialData.length - 1, 0, {
        key: "4",
        name: "Payment",
        value:
          Number(invoiceData?.invoice_pay || 0) >=
          Number(invoiceData?.invoice_net_total || 0)
            ? "PAID"
            : `${CurrencySymbol || ""}${Number(invoiceData?.invoice_pay)}`,
      });
    }

    setData(initialData);
  }, [isShowDiscount, isShowPayment, invoiceData, CurrencySymbol]);

  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: any, dataIndex: any) =>
    record.key === editingKey && dataIndex === "value";

  const edit = (record: any) => {
    form.setFieldsValue({ value: record?.value });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key: any) => {
    try {
      const row = await form.validateFields();

      const newData = [...data];
      console.log(newData);
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        // Recalculate net total if discount changes
        if (item.name === "Discount") {
          const discount = row?.value || 0;
          const netTotal = Number(totalSubTotal) - discount;
          const netTotalIndex = newData?.findIndex(
            (d) => d.name === "Net Total"
          );
          if (netTotalIndex > -1 && netTotal >= 0) {
            newData[netTotalIndex].value = netTotal.toString();
          }
          const invDueIndex = newData.findIndex((d) => d.name === "Inv Due");
          console.log(invDueIndex);
          // if (invDueIndex > -1 && netTotal >= 0) {
          newData[invDueIndex].value = netTotal.toString();
          // }
        }
        // Recalculate inv due if payment changes
        if (item.name === "Payment") {
          const netTotal: any =
            newData.find((d) => d.name === "Net Total")?.value || 0;
          const payment = row?.value || 0;
          const invDue = Number(totalSubTotal) - payment;
          const invDueIndex = newData.findIndex((d) => d.name === "Inv Due");

          //invDue cant minus value
          if (invDueIndex > -1 && invDue >= 0) {
            newData[invDueIndex].value = invDue.toString();
          }
        }
        setData(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      dataIndex: "name",
      key: "name",
      width: "150px",
    },
    {
      dataIndex: "value",
      key: "value",
      width: "150px",
      align: "right",

      render: (_: any, data: any) => {
        const discountEntry = allEditableData?.subtotalDetails.find(
          (item: any) => item.name === "Discount"
        );
        const paymentEntry = allEditableData?.subtotalDetails.find(
          (item: any) => item.name === "Payment"
        );

        const discount = discountEntry ? discountEntry.value : 0;
        const payment = paymentEntry ? paymentEntry.value : 0;

        if (data.name === "Sub Total") {
          return totalSubTotal;
        } else if (data.name === "Net Total") {
          return Number(totalSubTotal) - Number(discount);
        } else if (data.name === "Inv Due") {
          const netTotal = Number(totalSubTotal) - Number(discount);
          return netTotal - Number(payment);
        } else {
          return data.value;
        }
      },
    },
    {
      dataIndex: "editable",
      key: "editable",
      align: "right",
      render: (text: any, record: any) => {
        const editable = isEditing(record, "value");
        return editable ? (
          <div>
            <span>
              <Typography.Link onClick={() => save(record.key)}>
                ✅
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>❌</a>
              </Popconfirm>
            </span>
          </div>
        ) : (
          <span>
            {(record.name === "Payment" || record.name === "Discount") && (
              <EditOutlined
                style={{
                  color: "blue",
                }}
                onClick={() => edit(record)}
              />
            )}
          </span>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col: any) => ({
    ...col,
    onCell: (record: any) => ({
      record,
      inputType: col.dataIndex === "value" ? "number" : "text",
      dataIndex: col.dataIndex,
      title: col.title,
      editing: isEditing(record, col.dataIndex),
    }),
  }));

  useEffect(() => {
    setAllEditableData((prev: any) => ({
      ...prev,
      subtotalDetails: [...data],
    }));
  }, [data]);

  // Initial load of data to allEditableData
  useEffect(() => {
    setAllEditableData((prev: any) => ({
      ...prev,
      subtotalDetails: [...data],
    }));
  }, []);
  return (
    <div>
      <Row justify="space-between" align={"middle"}>
        <Col style={{ maxWidth: "350px" }}>
          {invoiceData?.invoice_note && (
            <Typography.Text style={{ color: "gray", fontSize: "11px" }}>
              <b>NOTE:</b> <span>{invoiceData?.invoice_note}</span>
            </Typography.Text>
          )}
        </Col>

        <Form form={form} component={false}>
          <Col>
            <Table
              components={{
                header: {
                  row: () => null,
                },
                body: {
                  cell: EditableCell,
                },
              }}
              size="small"
              bordered
              rowKey={(record) => record.key}
              dataSource={data}
              columns={mergedColumns}
              pagination={false}
              style={{ width: "270px", marginTop: "15px" }}
              className="invoiceBillingTable"
              rowClassName={"invoiceBillingTd"}
            />
          </Col>
        </Form>
      </Row>
      <Row style={{ position: "relative" }}>
        <Col span={8} offset={16} style={{ marginTop: "10px" }}>
          <Typography.Text
            style={{
              fontFamily: "'Source Sans Pro', sans-serif",
              fontSize: "12px",
            }}
            strong
          >
            <NumToWord number={Number(totalSubTotal)} />
          </Typography.Text>
        </Col>

        {Number(data[3]?.value || 0) !== Number(data[2]?.value || 0) &&
          tac_due_wtr_mark === 1 && (
            <Row>
              <Col
                style={{
                  marginTop: "10px",
                  position: "absolute",
                  right: 400,
                  bottom: 0,
                  transform: "rotate(-45deg)",
                }}
              >
                <Typography.Text
                  style={{
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontSize: "40px",
                    opacity: "0.2",
                    borderRadius: "4px",
                    border: "1px double red",
                    borderWidth: "4px",
                    padding: "6px 15px",
                    color: "red",
                  }}
                >
                  DUE
                </Typography.Text>
              </Col>
            </Row>
          )}

        {Number(data[3]?.value || 0) === Number(data[2]?.value || 0) &&
          tac_paid_wtr_mark === 1 && (
            <Row>
              <Col
                style={{
                  marginTop: "10px",
                  position: "absolute",
                  right: 400,
                  bottom: 0,
                  transform: "rotate(-45deg)",
                }}
              >
                <Typography.Text
                  style={{
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontSize: "40px",
                    opacity: "0.2",
                    borderRadius: "4px",
                    border: "1px double red",
                    borderWidth: "4px",
                    padding: "6px 15px",
                    color: "red",
                  }}
                >
                  PAID
                </Typography.Text>
              </Col>
            </Row>
          )}
      </Row>

      {tac_invoice_footer_note && <RenderHTML html={tac_invoice_footer_note} />}
    </div>
  );
};

export default InvoiceSubtotalEdit;

const RenderHTML = ({ html }: { html: string | null }) => {
  const rawHtml = html || "";

  const sanitizedHtml = rawHtml;

  return (
    <div
      style={{ marginTop: 5 }}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
};
