import { Col, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAppSelector } from "../../../app/hooks";
import { IViewInvoiceDetails } from "../../../common/types/commonInterfaces";
import { Fixed2, formatNumber } from "../../../common/utils/common.utils";
import NumToWord, { InWord } from "../../number_to_words/NumToWord";

type Props = {
  invoice_info: IViewInvoiceDetails;
};

export type TableType = {
  key: string;
  name: any;
  value?: any;
};

const InvoiceCostSubtotal = ({ invoice_info }: Props) => {
  const appConfig = useAppSelector((state) => state.configSlice);
  const {
    tac_due_wtr_mark,
    tac_paid_wtr_mark,
    tac_inv_curr_sym: CurrencySymbol,
    tac_invoice_footer_note,
  } = appConfig;

  const billingInfoData: TableType[] = [
    {
      key: "1",
      name: "Sub Total",
      value:
        (invoice_info?.invoice_show_discount !== 0 &&
          +invoice_info?.invoice_discount > 0) ||
        +invoice_info?.invoice_service_charge > 0
          ? `${CurrencySymbol || ""}${formatNumber(
              invoice_info?.invoice_sub_total
            )}`
          : "",
    },
    {
      key: "2",
      name: "Service Charge",
      value:
        invoice_info?.invoice_service_charge &&
        `${CurrencySymbol || ""}${formatNumber(
          invoice_info?.invoice_service_charge
        )}`,
    },
    {
      key: "3",
      name: "Discount",
      value:
        invoice_info?.invoice_show_discount !== 0 &&
        +invoice_info?.invoice_discount > 0
          ? `${CurrencySymbol || ""}${
              formatNumber(invoice_info?.invoice_discount) || 0
            }` || ""
          : "",
    },
    {
      key: "4",
      name: "Vat/Tax",
      value:
        (invoice_info?.invoice_vat &&
          `${CurrencySymbol || ""}${formatNumber(
            invoice_info?.invoice_vat
          )}`) ||
        "",
    },
    {
      key: "5",
      name: "Net Total",
      value: invoice_info?.invoice_net_total
        ? `${CurrencySymbol || ""}${formatNumber(
            invoice_info?.invoice_net_total
          )}`
        : "",
    },
    {
      key: "6",
      name: "Payment",
      value:
        Number(invoice_info?.invoice_pay || 0) >=
        Number(invoice_info.invoice_net_total || 0)
          ? "PAID"
          : `${CurrencySymbol || ""}${formatNumber(
              invoice_info?.invoice_pay
            )}` || 0,
    },
    {
      key: "7",
      name: "Inv Due",
      value:
        Number(invoice_info?.invoice_pay || 0) >=
        Number(invoice_info.invoice_net_total || 0)
          ? ""
          : `${CurrencySymbol || ""} ${formatNumber(
              Number(invoice_info.invoice_net_total || 0) -
                Number(invoice_info.invoice_pay || 0) || ""
            )}`,
    },
    {
      key: "8",
      name: "Client Prev Balance",
      value:
        invoice_info?.invoice_show_prev_due === 1
          ? `${CurrencySymbol || ""} ${Fixed2(
              invoice_info?.invoice_client_previous_due
            )}`
          : "",
    },
  ];

  const billingInfoColumn: ColumnsType<any> = [
    {
      dataIndex: "name",
      key: "name",
      width: "150px",
    },
    {
      dataIndex: "value",
      key: "value",
      width: "120px",
      align: "right",
    },
  ];

  return (
    <div>
      <Row justify="space-between" align={"middle"}>
        <Col style={{ maxWidth: "350px" }}>
          {invoice_info?.invoice_note && (
            <Typography.Text style={{ color: "gray", fontSize: 11 }}>
              <b>NOTE: </b> {invoice_info?.invoice_note}
            </Typography.Text>
          )}
        </Col>
        <Col span={24}>
          <Row align={"middle"} justify={"space-between"}>
            <Col>
              {tac_invoice_footer_note && (
                <RenderHTML html={tac_invoice_footer_note} />
              )}
            </Col>
            <Col>
              <Table
                size="small"
                bordered
                rowKey={(e) => e.key}
                className="invoiceBillingTable"
                rowClassName={(rec) => {
                  let className = "invoiceBillingTd";

                  if (rec.name === "Inv Due") {
                    className += " text-red";
                  }

                  return className;
                }}
                dataSource={billingInfoData.filter((item) => item?.value)}
                columns={billingInfoColumn}
                showHeader={false}
                pagination={false}
                style={{ width: "270px" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ position: "relative" }}>
        <Col span={8} offset={16} style={{ marginTop: "10px" }}>
          <Typography.Text>
            In word : {InWord(Number(invoice_info.invoice_net_total || 0))}
          </Typography.Text>
        </Col>

        {Number(invoice_info?.invoice_pay || 0) !==
          Number(invoice_info.invoice_net_total || 0) &&
          tac_due_wtr_mark === 1 && (
            <Row>
              <Col
                style={{
                  marginTop: "10px",
                  position: "absolute",
                  right: 400,
                  bottom: 0,
                  transform: "rotate(-45deg)",
                }}
              >
                <Typography.Text
                  style={{
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontSize: "40px",
                    opacity: "0.2",
                    borderRadius: "4px",
                    border: "1px double red",
                    borderWidth: "4px",
                    padding: "6px 15px",
                    color: "red",
                  }}
                >
                  DUE
                </Typography.Text>
              </Col>
            </Row>
          )}

        {Number(invoice_info?.invoice_pay || 0) ===
          Number(invoice_info.invoice_net_total || 0) &&
          tac_paid_wtr_mark === 1 && (
            <Row>
              <Col
                style={{
                  marginTop: "10px",
                  position: "absolute",
                  right: 400,
                  bottom: 0,
                  transform: "rotate(-45deg)",
                }}
              >
                <Typography.Text
                  style={{
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontSize: "40px",
                    opacity: "0.2",
                    borderRadius: "4px",
                    border: "1px double red",
                    borderWidth: "4px",
                    padding: "6px 15px",
                    color: "red",
                  }}
                >
                  PAID
                </Typography.Text>
              </Col>
            </Row>
          )}
      </Row>
      <Col span={8} offset={16} style={{ marginTop: "10px" }}>
        <Typography.Text
          style={{
            fontFamily: "'Source Sans Pro', sans-serif",
            fontSize: "12px",
          }}
          strong
        >
          {invoice_info.inv_note ? `Note: ${invoice_info.inv_note}` : null}
        </Typography.Text>
      </Col>
    </div>
  );
};

export default InvoiceCostSubtotal;

const RenderHTML = ({ html }: { html: string | null }) => {
  const rawHtml = html || "";

  const sanitizedHtml = rawHtml;

  return (
    <div
      style={{ marginTop: 5, color: "black" }}
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
    />
  );
};
