import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { IAirTicketDetails } from '../Interfaces/AirTicketReport.interfaces';

export const AirTicketDetailsColumn = ({
  pagination,
  paramData,
}: {
  pagination: {
    current: number;
    pageSize: number;
  };
  paramData:
    | {
        date_range: string;
        id: number | string;
      }
    | undefined;
}): ColumnsType<IAirTicketDetails> => {
  return [
    {
      title: 'SL.',
      key: 'invoice_id',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'create_date',
      key: 'create_date',
      render: (_, record) => {
        return `${dayjs(record?.create_date).format('DD MMM YYYY')}`;
      },
    },

    {
      title: 'Airline',
      dataIndex: 'airline_name',
      key: 'airline_name',
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },

    {
      title: 'Ticket No',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
      render: (_, record) => (
        <Link
          to={`/reports/ticket_wise?${paramData?.date_range}&airticket_id=${record.airticket_id}`}
        >
          {' '}
          {record.airticket_ticket_no}
        </Link>
      ),
    },

    {
      title: 'Travellers Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => {
        return (
          <Link
            to={
              record.airticket_client_id
                ? `/reports/client_ledger?client_query=${record.airticket_client_id}`
                : `/reports/combined_ledger?id=${record.airticket_combined_id}`
            }
          >
            <span>{record.client_name}</span>
          </Link>
        );
      },
      width: 150,
    },

    {
      title: 'Dep. Date',
      dataIndex: 'airticket_journey_date',
      key: 'airticket_journey_date',
      render: (_, record) => {
        if (record.airticket_journey_date)
          return `${dayjs(record?.airticket_journey_date).format(
            'DD MMM YYYY'
          )}`;
      },
    },

    {
      title: 'Routes',
      dataIndex: 'airticket_routes',
      key: 'airticket_routes',
    },

    {
      title: 'Client Price',
      dataIndex: 'airticket_client_price',
      key: 'airticket_client_price',
      render: (curr) => {
        return <> {Fixed2(curr)} </>;
      },
    },
    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render: (curr) => {
        return <> {Fixed2(curr)} </>;
      },
    },

    // {
    //   title: 'Commission',
    //   dataIndex: 'commission',
    //   key: 'commission',
    //   render: (commission) => <> {Fixed2(commission)} </>,
    // },

    {
      title: 'Issue By',
      dataIndex: 'issued_by',
      key: 'issued_by',
    },
  ];
};
