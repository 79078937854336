import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  TableParams,
  formatAmountAbs,
  handleTableChange,
} from "../../../../common/utils/common.utils";
import { FormButton } from "../../../../components/common/FormItem/FormItems";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import {
  useLazyGetClientDueQuery,
  useLazyGetExcelQuery,
} from "../../ledgers/endpoints/ledgerEndpoints";
import { useLazyGetAllDueAdvanceVendorsQuery } from "../endpoints/total_due_advance_vendorEndpoints";
import { ITotal_Due_Advance_FormData } from "../types/Total_Due_Advance_VendorTypes";
import { Total_Due_Advance_VendorUtils } from "../utils/Total_Due_Advance_VendorUtils";
type Props = {};

function Total_Due_Advance_Vendor({}: Props) {
  const [form] = Form.useForm();
  const [date_range_value, setDate_range_value] = useState<string>();
  const [getClientDue] = useLazyGetClientDueQuery();
  const [excelQuery, setExcelQuery] = useState<{
    // Id: string;
    query: string;
  }>({
    // Id: "",
    query: "",
  });

  const [fetchTotalDueAdvance, { data, isLoading }] =
    useLazyGetAllDueAdvanceVendorsQuery();

  const todayDate = dayjs().format("YYYY-MM-DD");
  const onFinish = async (values: ITotal_Due_Advance_FormData) => {
    const setFrom_date = dayjs(values.payment_date).format("YYYY-MM-DD");
    const date_range = `payment_date=${setFrom_date}`;
    const body = {
      // vendor_id: values.vendorId,
      date_range: date_range,
    };
    setDate_range_value(date_range);
    fetchTotalDueAdvance({
      // vendor_id: body.vendor_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      // Id: body.vendor_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  useEffect(() => {
    // form.setFieldValue("vendorId", "all");
    const date_range = `payment_date=${todayDate}`;
    const body = {
      // vendor_id: "all",
      date_range: date_range,
    };
    fetchTotalDueAdvance({
      // vendor_id: body.vendor_id,
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      // Id: "all",
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Due_Advance_Report_${date_range_value}`,
  });

  // @PAGINATION--START-------
  const [queryData, setQueryData] = useState<{
    isTrash: "0" | "1";
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: "0", pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ["20", "50", "100", "200", "500"],
      pageSize: 20,
      total: data?.count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: data?.count },
    }));
  }, [data?.count]);

  useEffect(() => {
    form.setFieldValue("payment_date", dayjs());
  }, []);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: ITotal_Due_Advance_FormData = form.getFieldsValue();
    const setTo_date = dayjs(values.payment_date).format("YYYY-MM-DD");
    const date_range = `payment_date=${setTo_date}`;
    const body = {
      // vendor_id:
      //   values.vendorId == "all"
      //     ? values.vendorId
      //     : values.vendorId.split("-")[1],

      payment_date: date_range,
    };

    fetchTotalDueAdvance({
      // vendor_id: body.vendor_id,
      query: `${query}&${body.payment_date}`,
    });
    setExcelQuery({
      // Id: body.vendor_id.toString(),
      query: `${query}&${body.payment_date}`,
    });
  };

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <FormHeaderTitle title="Total Due/Advance (Vendor)" />
      <ConfigProvider>
        <Typography.Text style={{ margin: 10 }}>
          You have total: {data?.count} rows
        </Typography.Text>
        <Table
          rowKey={(e) => e.serial}
          size="small"
          bordered
          pagination={
            data?.count !== undefined && data?.count > 20
              ? tableParams.pagination
              : false
          }
          dataSource={data?.data}
          columns={Total_Due_Advance_VendorUtils(queryData)}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
          onChange={(args1, args2, args3) =>
            handleTableChange({
              args: {
                pagination: args1,
                filters: args2,
                sorter: args3,
              },
              states: {
                refetch,
                setQueryData,
                setTableParams,
                tableParams,
              },
            })
          }
          summary={(tableData) => {
            const totalDebit = tableData.reduce((accumulator, item) => {
              return accumulator + parseFloat(item.total_debit);
            }, 0);
            const totalCredit = tableData.reduce((accumulator, item) => {
              return accumulator + parseFloat(item.total_credit);
            }, 0);
            const totalDue = tableData.reduce((accumulator, item) => {
              const amount =
                parseFloat(item.current_balance) < 0
                  ? Math.abs(+item.current_balance)
                  : 0;
              return accumulator + amount;
            }, 0);
            const totalAdvance = tableData.reduce((accumulator, item) => {
              const amount =
                parseFloat(item.current_balance) > 0
                  ? Math.abs(+item.current_balance)
                  : 0;
              return accumulator + amount;
            }, 0);
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={3} align="right">
                  <Typography.Text strong style={{ textAlign: "right" }}>
                    Total{" "}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <div
                    style={{
                      color: "red",
                      textAlign: "right",
                    }}
                  >
                    {formatAmountAbs(totalDebit)}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>
                    <div
                      style={{
                        color: "green",
                        textAlign: "right",
                      }}
                    >
                      {formatAmountAbs(totalCredit)}
                    </div>
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>
                    <div
                      style={{
                        color: "red",
                        textAlign: "right",
                      }}
                    >
                      {formatAmountAbs(totalDue)}
                    </div>
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>
                    <div
                      style={{
                        color: "green",
                        textAlign: "right",
                      }}
                    >
                      {formatAmountAbs(totalAdvance)}
                    </div>
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </ConfigProvider>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Total Due/Advance (Vendor)" }}
      />
    </div>
  );

  return (
    <div>
      <BreadCrumb arrOfOption={["Report", "Total Due/Advance (Vendor)"]} />

      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            if (data?.data?.length)
              getClientDue({
                // client_id: excelQuery.Id,
                query: excelQuery.query,
                excelApiName: "totalDueAdvance/vendor",
                excelName: "total_due_advance_vendor",
              });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={10}>
          {/* <SelectVendorsOnly
            required
            name="vendorId"
            size={6}
            smSize={10}
            label="Select Vendor"
            placeholder="Select Vendor"
            showAll
            offDropDown
          /> */}
          <Col span={6} xs={24} sm={10} md={10} lg={6} xxl={3}>
            <Form.Item
              label="Select Date"
              name="payment_date"
              rules={[{ required: true, message: ` Date is required!` }]}
            >
              <DatePicker
                format={"DD-MM-YYYY"}
                allowClear={false}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col lg={2} sm={2}>
            <FormButton
              label="Search"
              icon
              textAlign="left"
              loading={isLoading}
            />
          </Col>
        </Row>
      </Form>
      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
}

export default Total_Due_Advance_Vendor;
