import { PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Segmented, Table, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  DateInput,
  FormButton,
} from '../../../../components/common/FormItem/FormItems';
import {
  SelectAirline,
  SelectClients,
  SelectOptionsInput,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';

import { useWatch } from 'antd/es/form/Form';
import Search from 'antd/es/input/Search';
import dayjs from 'dayjs';
import { formatAmountAbs } from '../../../../common/utils/common.utils';
import ClientDueAdvance_Airline_SubTable from '../components/ClientDueAdvance_Airline_SubTable';
import ClientDueAdvance_Client_SubTable from '../components/ClientDueAdvance_Client_SubTable';
import ClientDueAdvance_Details_SubTable from '../components/ClientDueAdvance_Details_SubTable';
import {
  useGetClientAdvanceQuery,
  useGetDueAdvanceDetailsMutation,
} from '../endpoints/DueAdvanceDetailsEndpoints';
import { ClientAdvanceColumn } from '../utils/DueAdvanceDetailsColumn';

interface ISubmit {
  comb_client: string;
  airline_id: string;
  date: [string, string];
}

export interface InfoType {
  comb_client: string;
  data_type: string;
  airline_id: string;
  from_date: string;
  search: string;
  to_date: string;
}

export interface IClickInfo {
  client_id?: string | null;
  airline_id?: string | null;
}

const DueAdvanceDetails = () => {
  const [form] = Form.useForm();
  const [info, setInfo] = useState<InfoType>({
    comb_client: '',
    data_type: 'DUE/ADVANCE',
    airline_id: '',
    from_date: '',
    to_date: '',
    search: '',
  });
  const data_type = info?.data_type;

  const [clickInfo, setClickInfo] = useState<IClickInfo>({
    client_id: null,
    airline_id: '',
  });

  useEffect(() => {
    if (clickInfo.client_id) {
      form.setFieldValue('data_type', 'Details');
      form.setFieldValue('comb_client', `client-${clickInfo.client_id}`);
      setInfo((prev) => ({ ...prev, data_type: 'DETAILS' }));
    }

    if (clickInfo.airline_id) {
      form.setFieldValue('data_type', 'Details');
      form.setFieldValue('airline_id', Number(clickInfo.airline_id));
      setInfo((prev) => ({ ...prev, data_type: 'DETAILS' }));
    }
  }, [clickInfo]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [postInfo, { data, isLoading }] = useGetDueAdvanceDetailsMutation();

  const advance_data_type = useWatch('advance_data_type', form) as
    | 'DUE'
    | 'ADVANCE';

  const { data: clientAdvance, isLoading: advanceLoading } =
    useGetClientAdvanceQuery(
      {
        ...pagination,
        search: info.search,
        data_type: advance_data_type ? advance_data_type : 'DUE',
      },
      { skip: data_type !== 'DUE/ADVANCE' }
    );

  // Reset Pagination
  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [
    info.search,
    info.data_type,
    info.from_date,
    info.to_date,
    info.comb_client,
  ]);

  useEffect(() => {
    form.submit();
    if (data_type === 'CLIENT' || data_type === 'AIRLINE') {
      form.setFieldValue('comb_client', undefined);
      form.setFieldValue('airline_id', undefined);
    }
  }, [pagination]);

  const onFinish = async (item: ISubmit) => {
    if (item.airline_id) {
      setInfo((prev) => ({ ...prev, airline_id: item.airline_id }));
    }

    if (item.comb_client) {
      setInfo((prev) => ({ ...prev, comb_client: item.comb_client }));
    }

    if (item.date) {
      setInfo((prev) => ({
        ...prev,
        from_date: dayjs(item.date[0]).format('YYYY-MM-DD'),
        to_date: dayjs(item.date[1]).format('YYYY-MM-DD'),
      }));
    }

    if (data_type !== 'DUE/ADVANCE') {
      postInfo({
        airline_id: item.airline_id,
        comb_client: item.comb_client,
        from_date: item.date ? dayjs(item.date[0]).format('YYYY-MM-DD') : '',
        to_date: item.date ? dayjs(item.date[1]).format('YYYY-MM-DD') : '',
        search: info.search,
        data_type: info.data_type as 'CLIENT' | 'DETAILS' | 'AIRLINE',
        current: pagination.current,
        pageSize: pagination.pageSize,
      });
    }
  };

  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `SalesMan Collection and Due`,
  });

  // @HANDLE CONTENTS
  const jsx_com =
    data_type !== 'DUE/ADVANCE' ? (
      <>
        {data_type === 'DETAILS' && (
          <ClientDueAdvance_Details_SubTable
            pagination={pagination}
            info={info}
            setClickInfo={setClickInfo}
            isLoading={isLoading}
            data={data}
            handlePaginationChange={handlePaginationChange}
          />
        )}
        {data_type === 'AIRLINE' && (
          <ClientDueAdvance_Airline_SubTable
            pagination={pagination}
            info={info}
            setClickInfo={setClickInfo}
            isLoading={isLoading}
            data={data}
            handlePaginationChange={handlePaginationChange}
          />
        )}
        {data_type === 'CLIENT' && (
          <ClientDueAdvance_Client_SubTable
            pagination={pagination}
            info={info}
            setClickInfo={setClickInfo}
            isLoading={isLoading}
            data={data}
            handlePaginationChange={handlePaginationChange}
          />
        )}
      </>
    ) : (
      <Table
        size='small'
        bordered
        dataSource={clientAdvance?.data?.results}
        columns={ClientAdvanceColumn({
          pagination,
        })}
        pagination={
          clientAdvance?.count !== undefined && clientAdvance?.count < 20
            ? false
            : {
                ...pagination,
                total: clientAdvance?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        summary={(_) => {
          return (
            <Table.Summary.Row
              style={{
                background:
                  advance_data_type === 'ADVANCE' ? '#F5F5F7' : '#F5F5F7',
              }}
            >
              <Table.Summary.Cell index={2}></Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={4}>
                <Typography.Text strong>
                  {advance_data_type === 'ADVANCE'
                    ? 'TOTAL BALANCE'
                    : 'TOTAL DUE'}
                </Typography.Text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={2}>
                <div style={{ textAlign: 'right', marginRight: 5 }}>
                  <Typography.Text
                    strong
                    className={
                      advance_data_type === 'ADVANCE'
                        ? 'color-profit'
                        : 'color-due'
                    }
                  >
                    {formatAmountAbs(clientAdvance?.data?.total_balance || 0)}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'CLIENT DUE ADVANCE DETAILS' }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'CLIENT DUE ADVANCE DETAILS']} />

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify={'space-between'}>
          <Form.Item name={'data_type'}>
            <Segmented<string>
              options={['Due/Advance', 'Airline', 'Details']} // add 'Client', after 'Due/Advance'
              onChange={(value) => {
                setInfo((prev) => ({
                  ...prev,
                  data_type: value.toUpperCase(),
                }));
              }}
            />
          </Form.Item>
          <Button type='primary' onClick={handlePrint}>
            <PrinterOutlined />
            Print
          </Button>
        </Row>

        <Row justify={'space-between'} align={'middle'}>
          <Col xxl={18}>
            <Row gutter={8}>
              <SelectClients
                disabled={!['DETAILS', 'CLIENT'].includes(info.data_type)}
                name='comb_client'
                size={3}
                label='Select Client:'
                placeholder='Select Client'
                showAll
                offDropDown
              />

              <SelectAirline
                disabled={!['DETAILS', 'AIRLINE'].includes(info.data_type)}
                name='airline_id'
                label='Airline Name:'
                size={3}
                placeholder='Select Airline Name'
              />
              <DateInput
                rangePicker
                name='date'
                label='Select Date:'
                size={4}
                disabled={info.data_type !== 'DETAILS'}
              />

              <Col xxl={2}>
                <FormButton
                  disabled={data_type === 'DUE/ADVANCE'}
                  label='Search'
                  icon
                  textAlign='left'
                  loading={isLoading}
                />
              </Col>
            </Row>
          </Col>

          <Col xxl={6}>
            <Row justify={'end'} align={'middle'} gutter={12}>
              {data_type === 'DUE/ADVANCE' && (
                <SelectOptionsInput
                  label='Select Type:'
                  name='advance_data_type'
                  options={['ADVANCE', 'DUE']}
                  defaultValue={'DUE'}
                  placeholder='Advance or Due'
                  size={12}
                  allowClear={false}
                />
              )}
              <Col lg={12}>
                <Search
                  style={{ marginTop: 6 }}
                  allowClear
                  placeholder='Search Here ...'
                  loading={false}
                  enterButton
                  onSearch={(e) => setInfo((prev) => ({ ...prev, search: e }))}
                  onPressEnter={(e: any) =>
                    setInfo((prev) => ({ ...prev, search: e.target.value }))
                  }
                />
              </Col>
            </Row>
            <br />
          </Col>
        </Row>
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      <div>
        <FormHeaderTitle title='CLIENT DUE ADVANCE DETAILS' />
        {jsx_com}
      </div>

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default DueAdvanceDetails;
