import { ColumnsType } from "antd/es/table";
import { IEmd_tkt_info } from "../../Invoice_Re_Issue/Type/InoviceReissue.interface";
import { Fixed2 } from "../../../common/utils/common.utils";

export const EmdTableColumns: ColumnsType<IEmd_tkt_info> = [
  {
    title: <span className="table-column-title"> Sl. </span>,
    align: "center",
    render(value, record, index) {
      return <>{index + 1}</>;
    },
  },
  {
    title: <span className="table-column-title">Ticket No</span>,
    dataIndex: "airticket_ticket_no",
    key: "airticket_ticket_no",
  },
  {
    title: <span className="table-column-title">Ticket Class</span>,
    dataIndex: "airticket_ticket_type",
    key: "airticket_ticket_type",
    render: (text: string) => <span>{text}</span>,
  },

  {
    title: <span className="table-column-title">Base Fare</span>,
    dataIndex: "airticket_base_fare",
    key: "airticket_base_fare",
    render: (curr) => Fixed2(curr),
  },

  {
    title: <span className="table-column-title"> GDS </span>,
    key: "airticket_gds_id",
    dataIndex: "airticket_gds_id",
  },
  {
    title: <span className="table-column-title"> AIT </span>,
    key: "airticket_ait",
    dataIndex: "airticket_ait",
  },
  {
    title: <span className="table-column-title">Commission </span>,
    key: "airticket_commission_percent",
    dataIndex: "airticket_commission_percent",
    render: (curr) => Fixed2(curr),
  },
  {
    title: <span className="table-column-title">C.Total</span>,
    key: "airticket_commission_percent_total",
    dataIndex: "airticket_commission_percent_total",
    render: (curr) => curr,
  },
  {
    title: <span className="table-column-title">Remark </span>,
    key: "airticket_emd_remarks",
    dataIndex: "airticket_emd_remarks",
  },
];
