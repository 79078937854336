import { Button, Popconfirm, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { IPermission, crud } from '../../../../common/types/commonTypes';
import {
  useDeleteOthersRefundClientMutation,
  useRestoreOthersRefundClientMutation,
} from '../../Api/Endpoints/OthersRefundClientEndpoints';
import {
  IOthersRefundClientDataType,
  IRefundDeleteRestoreBody,
  IRefundRestoreBody,
} from '../../RefundTypes/RefundTypes';

export const OthersRefundClientViewUtils = ({
  permission,
  pagination,
}: {
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IOthersRefundClientDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);
  const [deleteRefund, { isSuccess, isError }] =
    useDeleteOthersRefundClientMutation();
  const [restoreRefund, { isLoading }] = useRestoreOthersRefundClientMutation();
  const handleRefundDelete = async (
    values: IOthersRefundClientDataType,
    index: number
  ) => {
    const body: IRefundDeleteRestoreBody = {
      refund_id: values.refund_id,
      deleted_by: user?.user_id as number,
    };
    setColumnIndex(index);
    await deleteRefund(body);
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);

  const handleRefundRestore = async (values: IOthersRefundClientDataType) => {
    const body: IRefundRestoreBody = {
      refund_id: values.crefund_refund_id,
      refund_restored_by: user?.user_id as number,
    };
    await restoreRefund(body);
  };
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Refund Date',
      key: 'refund_date',
      align: 'center',
      render: (_, record) => {
        return `${dayjs(record.crefund_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Voucher',
      dataIndex: 'crefund_vouchar_number',
      key: 'crefund_vouchar_number',
      align: 'center',
    },

    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      align: 'center',
    },

    {
      title: 'Client',
      dataIndex: 'crefund_client_name',
      key: 'crefund_client_name',
      align: 'center',

      render: (value, record) => {
        const { client_id, combine_id } = record;

        return record?.combine_id || record?.client_id ? (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?client_query=${client_id}`
                : `/reports/combined_ledger?id=${combine_id}`
            }
          >
            <span>{value}</span>
          </Link>
        ) : (
          value
        );
      },
    },

    {
      title: 'Amount',
      dataIndex: 'crefund_total_amount',
      key: 'crefund_total_amount',
      align: 'center',
      render: (_, record) => Number(record.crefund_total_amount),
    },
    {
      title: 'Type',
      dataIndex: 'crefund_payment_type',
      key: 'crefund_payment_type',
      align: 'center',
    },
    {
      title: 'Created By',
      dataIndex: 'user_full_name',
      key: 'user_full_name',
      align: 'end',
    },
    {
      title: 'Action',
      key: 'operation',
      align: 'center',
      render: (_, record, index) => {
        return (
          <Space size='small'>
            {permission?.['read:any'] && (
              <Link
                to={`/refund/client/details/${record.refund_id}`}
                state={location.pathname}
              >
                <Button size='small' type='primary'>
                  <Typography style={{ color: '#ffffff' }}>View</Typography>
                </Button>
              </Link>
            )}

            {permission?.[crud.delete] && record.crefund_is_deleted === 0 && (
              <Popconfirm
                title='Sure to delete?'
                onConfirm={() => handleRefundDelete(record, index)}
              >
                <Button
                  size='small'
                  type='primary'
                  danger
                  loading={columnIndex === index && true}
                >
                  Delete
                </Button>
              </Popconfirm>
            )}

            {permission?.[crud.delete] && record.crefund_is_deleted !== 0 && (
              <Popconfirm
                title='Sure to Restore?'
                onConfirm={() => handleRefundRestore(record)}
              >
                <Button
                  size='small'
                  type='primary'
                  style={{ background: '#52C41A', border: 'none' }}
                >
                  Restore
                </Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];
};
