import { api } from "../../../../app/baseQuery";
import { HTTPResponse } from "../../../../common/types/commonTypes";
import { SALESMANCOMMISSIONREPORT } from "../../Api/constants";

export const SalesManWiseClientDueEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    // get all SaleaManCommissionReport
    getSalesManWiseCl: build.query<
      HTTPResponse<ISalesManWiseCL[]>,
      {
        employee_id: string | number;
        query: string;
      }
    >({
      query: ({ employee_id, query }) => ({
        url: `/report/salesman-wise-cl-due/${employee_id}${query}`,
      }),
      providesTags: [
        { type: "SaleaManCommissionReport", id: SALESMANCOMMISSIONREPORT },
      ],
    }),
  }),
});

export const { useLazyGetSalesManWiseClQuery } = SalesManWiseClientDueEndpoints;

export interface ISalesManWiseCL {
  total_sales: number;
  total_discount: string;
  total_client_payment: string;
  total_client_due: number;
  client_name: string;
  client_mobile: string;
  comb_client: string;
  employee_full_name: string;
  employee_id: number;
}
