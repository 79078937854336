import { Button, FormListFieldData, Row } from "antd";
import { FormInstance, FormListOperation } from "antd/lib";
import React from "react";
import {
  SelectProduct,
  SelectVendors,
} from "../../../FormItem/SelectCustomFeilds";
import { NumberInput } from "../../../FormItem/FormItems";
import { CloseOutlined } from "@ant-design/icons";

type Props = {
  subField: FormListFieldData;
  subOpt: FormListOperation;
  form?: FormInstance<any>;
  index: number;
};
const TabVisaVoid = ({ subField, subOpt, index }: Props) => {
  return (
    <>
      {" "}
      <Row
        className="border"
        // style={is_deleted === 1 ? { backgroundColor: "#FFC0CB" } : {}}
        align="middle"
        gutter={[10, 0]}
      >
        <SelectProduct
          label="Product"
          name={[subField.name, "billing_product_id"]}
          size={5}
          placeholder="Select Product"
          required
          disabled
        />

        <NumberInput
          label="Quantity"
          name={[subField.name, "billing_quantity"]}
          size={3}
          required
          min="0"
          maxChar={3}
          readOnly
        />
        <NumberInput
          label="Unit Price"
          name={[subField.name, "billing_unit_price"]}
          size={3}
          required
          min="0"
          readOnly
        />

        <NumberInput
          //   required={conditionalCostVendor ? isVendorSelected : true}
          label="Cost Price"
          name={[subField.name, "billing_cost_price"]}
          size={3}
          min="0"
          readOnly
        />
        <NumberInput
          name={[subField.name, "vendor_charge"]}
          label="Vendor Charge"
          size={4}
        />
        <SelectVendors
          label="Vendor"
          disabled
          name={[subField.name, "billing_vendor_id"]}
          size={5}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value: string) {
                if (value && getFieldValue("invoice_combclient_id") === value) {
                  return Promise.reject();
                } else {
                  return Promise.resolve();
                }
              },
              message: "Client and vendor can't be same!",
            }),
          ]}
        />

        {index !== 0 && (
          <Button
            style={{ marginTop: "8px" }}
            size="small"
            type="dashed"
            danger
            icon={<CloseOutlined />}
            onClick={() => subOpt.remove(subField.name)}
          />
        )}
      </Row>
    </>
  );
};

export default TabVisaVoid;
