import { Tabs } from "antd";
import { useRef, useState } from "react";
import { useParams } from "react-router";
import InvoiceActivityLogs from "../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs";
import CommonViewInvoice from "../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice";
import TabDetailsView from "../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView";
import TabPaymentView from "../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView";
import ViewInvoiceHeader from "../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader";
import ViewInvocieTourDetails from "../Components/Components/ViewInvocieTourDetails";
import ViewInvoiceTourBilling from "../Components/Components/ViewInvoiceTourBilling";
import { useViewInvoiceTourByIdQuery } from "../Endpoints/GenerateInvoiceEndpoints";
import LoadingIndicator from "../../../components/common/spinner/LoadingIndicator";
import ViewFakeInvoice from "../../../components/common/Invoice/ViewInvoiceInfo/edited_invoice/view/ViewFakeInvoice";
import FakeFlightViewTourBilling from "../../../components/common/Invoice/ViewInvoiceInfo/edited_invoice/view/Billing/FakeFlightViewTourBilling";
import { Tab } from "../../Invoice_Re_Issue/pages/ViewInvoiceInfo/ViewInvoiceReissue";

const ViewInvoiceTour = () => {
  // ================= DATA FOR VIEW =====================
  const invoice_id = Number(useParams().id);
  // GET INVOICE AIRTICKET DETAILS
  const {
    data: invoices,
    isFetching,
    isLoading,
  } = useViewInvoiceTourByIdQuery(invoice_id);
  const invoiceData = invoices?.data;
  const invoice_category_id = invoiceData?.invoice_category_id;

  const viewInvoicePrintableRef = useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = useRef<HTMLDivElement>(null);
  const viewEditedInvoiceRef = useRef<HTMLDivElement>(null);

  const header_ref = useRef<HTMLDivElement>(null);
  const signiture_ref = useRef<HTMLDivElement>(null);
  const subtotal_ref = useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = useState<
    "viewInvoice" | "costDetails" | "payments" | "activity" | "edited"
  >("viewInvoice");

  let selectedPrintableRef = viewInvoicePrintableRef;

  switch (selectedForPrint) {
    case "viewInvoice":
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case "costDetails":
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case "payments":
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case "activity":
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;
    case "edited":
      selectedPrintableRef = viewEditedInvoiceRef;
      break;
    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  const items: Tab[] = [
    {
      label: "Invoice",
      key: "1",
      children: isLoading ? (
        <LoadingIndicator />
      ) : (
        invoiceData && (
          <CommonViewInvoice
            invoiceData={invoiceData}
            viewInvoicePrintableRef={viewInvoicePrintableRef}
            heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
            children={
              <ViewInvoiceTourBilling
                billing_information={invoiceData.billing_information}
              />
            }
          />
        )
      ),
    },

    {
      label: "Details",
      key: "2",
      children: invoiceData && (
        <TabDetailsView
          invoiceDetails={invoiceData}
          children={<ViewInvocieTourDetails invoiceInformation={invoiceData} />}
          viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
        />
      ),
    },
    {
      label: "Payments",
      key: "3",
      children: (
        <TabPaymentView
          invoiceDetails={invoiceData}
          viewInvoicePaymentsRef={viewInvoicePaymentsRef}
        />
      ),
    },
    {
      label: "Activity Log",
      key: "4",
      children: (
        <InvoiceActivityLogs
          id={invoice_id}
          viewInvoiceAcivityRef={viewInvoiceAcivityRef}
        />
      ),
    },
  ];

  if (invoiceData?.is_edited) {
    items.push({
      key: "5",
      label: "Edited Invoice",
      children: (
        <>
          {invoiceData && (
            <ViewFakeInvoice
              invoiceData={invoiceData}
              viewInvoicePrintableRef={viewEditedInvoiceRef}
              heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
              children={
                <FakeFlightViewTourBilling
                  billing_information={invoiceData.billing_information}
                />
              }
            />
          )}
        </>
      ),
    });
  }
  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={invoice_category_id}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
      />

      <Tabs
        onChange={(e) => {
          switch (Number(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint("viewInvoice");
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint("costDetails");
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint("payments");
              break;
            case 4:
              setSelectedForPrint && setSelectedForPrint("activity");
              break;
            case 5:
              setSelectedForPrint && setSelectedForPrint("edited");
              break;
            default:
              break;
          }
        }}
        type="card"
        style={{ marginTop: "15px" }}
        items={items.sort((a, b) => Number(a.key) - Number(b.key))}
      ></Tabs>
    </>
  );
};

export default ViewInvoiceTour;
