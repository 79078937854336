import React, { useState } from "react";
import { useGetSingleContactGiftsQuery } from "../Endpoints/contactsEndpoints";
import { Table } from "antd";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { ContactGiftTableColumns } from "../utils/ContactGiftTableColumns";

const ViewGiftModal = ({ id }: { id: number }) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const { data, isLoading } = useGetSingleContactGiftsQuery(id, {
    skip: !id,
  });
  return (
    <div>
      {" "}
      <Table
        size="small"
        bordered
        rowKey={"ag_id"}
        dataSource={data?.data?.gifts}
        columns={ContactGiftTableColumns()}
        pagination={
          data?.data?.gifts?.length !== undefined &&
          data?.data?.gifts?.length < 20
            ? false
            : {
                ...pagination,
                total: data?.data?.gifts?.length,
                showSizeChanger: true,
                pageSizeOptions: ["20","50", "100", "200", "500"],
                // onChange: handlePaginationChange,
              }
        }
        scroll={{ x: true }}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
      />
    </div>
  );
};

export default ViewGiftModal;
