import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  ConfigProvider,
  Form,
  Space,
  Table,
  theme,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import { useLazyGetAllAccountReportQuery } from '../endpoints/accountReportEndpoints';
import { IAccountReportFormDataType } from '../types/accountReportTypes';
import { accountReportColumn } from '../utils/accountReportColumn';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  Fixed2,
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';

const AccountsReport = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  //calculate the amount
  let totalAmountAllReport: number = 0;
  let totalAmountDebitAllReport: number = 0;
  const [fetchAccountReport, { data, isLoading, isFetching }] =
    useLazyGetAllAccountReportQuery();

  if (data?.data) {
    for (let i = 0; i < data?.data.length; i++) {
      const element = data?.data[i];
      if (element.actransaction_type == 'DEBIT') {
        totalAmountDebitAllReport += Fixed2(element.actransaction_amount);
      } else {
        totalAmountAllReport += Fixed2(element.actransaction_amount);
      }
    }
  }

  const onFinish = async (values: IAccountReportFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IAccountReportFormDataType = {
      date_range: date_range,
      account_id: values.account_id,
    };

    await fetchAccountReport({
      account_id: body.account_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: body.account_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IAccountReportFormDataType = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IAccountReportFormDataType = {
      date_range: date_range,
      account_id: values.account_id,
    };

    fetchAccountReport({
      account_id: body.account_id,
      query: `${query}&${body.date_range}`,
    });

    setExcelQuery({
      Id: body.account_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const to_date = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('account_id', 'all');
    const body: IAccountReportFormDataType = {
      date_range: `from_date=${to_date}&to_date=${to_date}`,
      account_id: 'all',
    };

    fetchAccountReport({
      account_id: body.account_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.account_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Account Report`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <Table
        size='small'
        bordered
        columns={accountReportColumn(queryData)}
        dataSource={data?.data}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={count > 50 ? tableParams.pagination : false}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
        summary={(_) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography.Text strong></Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography.Text strong></Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={0}>
                  <Typography.Text strong>Total: </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <div
                    style={{
                      fontWeight: '700',
                      textAlign: 'right',
                    }}
                  >
                    <Typography.Text strong style={{ color: 'red' }}>
                      {totalAmountDebitAllReport}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <div
                    style={{
                      fontWeight: '700',
                      textAlign: 'right',
                    }}
                  >
                    <Typography.Text strong style={{ color: 'green' }}>
                      {Fixed2(totalAmountAllReport)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography.Text strong></Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </ConfigProvider>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Accounts Report' }}
      />
    </div>
  );

  return (
    <div>
      <BreadCrumb arrOfOption={['Reports', 'Accounts Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'accountsReport',
              excelName: 'accountsReport',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader
          required
          date_range
          selectAccountList
          reports_title='Accounts Report'
        />
      </Form>
      <Typography.Text>You have total: {data?.count} rows</Typography.Text>
      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
};

export default AccountsReport;
