import { Button, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import {
  FormInput,
  FormInputItem,
  NumberInputCurrencyFormat,
} from "../../../components/common/FormItem/FormItems";
import { SelectOptionsInput } from "../../../components/common/FormItem/SelectCustomFeilds";
import { useForm, useWatch } from "antd/es/form/Form";
import { useAppDispatch } from "../../../app/hooks";
import { setCommonModal } from "../../../common/slices/modalSlice";
import { IEmdData } from "../Type/InoviceReissue.interface";
import { useLazyTicketIsExistQuery } from "../../IATA_AIR_TICKET/api/endpoints/airticketInvoiceEndpoints";

const AddReIssueEmd = ({
  index,
  setEmdData,
  indexData,
}: {
  index: number;
  setEmdData: any;
  indexData: IEmdData;
}) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [handleTicketNo, setHandleTicketNo] = useState<string>("");
  // VALIDATE INVOICE NO

  const [ticketIsExist, { data: ticketIsExisting }] =
    useLazyTicketIsExistQuery();

  useEffect(() => {
    const ticketFetcing = setTimeout(async () => {
      if (handleTicketNo) {
        await ticketIsExist(handleTicketNo);
        const ticket = form.getFieldValue("airticket_ticket_no");
        if (ticket) {
          form.validateFields(["airticket_ticket_no"]);
        }
      }
    }, 800);
    return () => clearTimeout(ticketFetcing);
  }, [handleTicketNo]);
  useEffect(() => {
    if (indexData) {
      if (indexData.index === index) {
        form.setFieldsValue(indexData);
      } else {
        form.resetFields();
      }
    }
  }, [indexData, index]);
  const onFinish = (values: any) => {
    values.airticket_gross_fare = values.airticket_base_fare;

    setEmdData((prev: any) => {
      const existingIndex = prev.findIndex((item: any) => item.index === index);

      if (existingIndex !== -1) {
        const updatedData = [...prev];
        updatedData[existingIndex] = { ...values, index };
        return updatedData;
      } else {
        return [...prev, { ...values, index }];
      }
    });
    form.resetFields();
    dispatch(setCommonModal());
  };
  // const fare_difference =
  //   useWatch([formName, index, "airticket_fare_difference"], form) || 0;
  // let comm_percent =
  //   useWatch([formName, index, "airticket_commission_percent"], form) / 100 ||
  //   0;
  // const commissionAmount = Math.round(fare_difference * comm_percent);
  return (
    <div style={{ marginTop: "20px" }}>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        {" "}
        <Row gutter={[10, 0]} align={"middle"}>
          <FormInput
            name={"airticket_ticket_no"}
            label="Ticket No"
            onChange={(value: string) => {
              setHandleTicketNo(value);
            }}
            size={8}
            required
            rules={[
              {
                validator: async (_, value) => {
                  if (ticketIsExisting?.data && value) {
                    return Promise.reject();
                  } else return Promise.resolve();
                },
                message: "Ticket already exist!",
              },
            ]}
          />

          <>
            <NumberInputCurrencyFormat
              label="Base Fare"
              name={"airticket_base_fare"}
              placeholder="Base Fare"
              size={8}
              mdSize={8}
              min="0"
              maxChar={14}
              required
            />{" "}
            <NumberInputCurrencyFormat
              required
              label={"Commission % "}
              name={"airticket_commission_percent"}
              placeholder="Fare commission here"
              size={8}
              mdSize={8}
              min="0"
              max={10}
              maxChar={1}
            />{" "}
            <SelectOptionsInput
              label="Air-ticket class"
              name={"airticket_ticket_type"}
              options={[
                "Economy",
                "Premium Economy",
                "Business",
                "First Class",
              ]}
              size={8}
            />
            <FormInputItem
              name={"airticket_emd_remarks"}
              label="Remarks"
              size={8}
            />
            <SelectOptionsInput
              label="GDS :"
              name="airticket_gds_id"
              options={["Sabre", "Galileo", "Amadeus"]}
              size={6}
              allowClear={true}
            />
            <Button type="primary" htmlType="submit">
              {indexData?.airticket_ticket_no ? "Edit " : "Add "}
            </Button>
          </>
        </Row>
      </Form>
    </div>
  );
};

export default AddReIssueEmd;
