import { Table, TableColumnsType, Typography } from "antd";
import { Link } from "react-router-dom";
import { findInvoiceRoute } from "../../../../layout/components/SearchInvoices";
import { invoiceViewStyle } from "../../../Quotation/Components/ViewGenerateInvoice";
import {
  IAirTicketRefundVendorDetails,
  IAirtTicketRefundSingleDataType,
} from "../../RefundTypes/RefundTypes";
import { DateFormat } from "../../../../components/date_Format/date_format";
import { Fixed2 } from "../../../../common/utils/common.utils";
import { ColumnsType } from "antd/es/table";

export type TableType = {
  key: string;
  name: any;
  value?: any;
};

const AirTicketRefundDetails = ({
  data,
}: {
  data?: IAirtTicketRefundSingleDataType;
}) => {
  const columns: TableColumnsType<IAirtTicketRefundSingleDataType> = [
    {
      title: "Refund Date",
      dataIndex: "atrefund_date",
      render: (atrefund_date) => DateFormat(atrefund_date),
    },
    { title: "Voucher", dataIndex: "atrefund_vouchar_number" },
    {
      title: "Invoice No",
      dataIndex: "invoice_no",
      render: (_, data) => (
        <Link
          to={
            "../" +
            findInvoiceRoute(data.invoice_category_id) +
            "/" +
            "details" +
            "/" +
            data.invoice_id
          }
          state={location.pathname}
        >
          {data.invoice_no}
        </Link>
      ),
    },
    {
      title: "Cleint Name",
      dataIndex: "client_name",
      render: (_, { client_name, client_id }) => {
        return (
          <Link to={`/reports/client_ledger?client_query=${client_id}`}>
            {client_name}
          </Link>
        );
      },
    },
    { title: "Total Amount", dataIndex: "crefund_total_amount" },
    { title: "Charge", dataIndex: "crefund_charge_amount" },
    { title: "Return", dataIndex: "crefund_return_amount" },
    // {
    //   title: "Profit",
    //   dataIndex: "crefund_profit",
    //   render: (amount) => {
    //     return (
    //       <Typography.Text
    //         style={{
    //           color:
    //             Number(amount) === 0
    //               ? "#18b4e9"
    //               : Number(amount) > 0
    //               ? "green"
    //               : "red",
    //         }}
    //       >
    //         {`${Fixed2(Math.abs(Number(amount)))}.00`}
    //       </Typography.Text>
    //     );
    //   },
    // },

    {
      title: "Create Date",
      dataIndex: "atrefund_create_date",
      render: (atrefund_create_date) => DateFormat(atrefund_create_date),
    },
  ];
  const itemColumns: TableColumnsType<IAirTicketRefundVendorDetails> = [
    {
      title: "Ticket",
      dataIndex: "airticket_ticket_no",
      render: (_, data) => (
        <Link
          to={
            "../" +
            findInvoiceRoute(data.invoice_category_id) +
            "/" +
            "details" +
            "/" +
            data.invoice_id
          }
          state={location.pathname}
        >
          {data.airticket_ticket_no}
        </Link>
      ),
    },
    { title: "Routes", dataIndex: "airticket_routes" },
    { title: "Pnr", dataIndex: "airticket_pnr" },
    { title: "Vendor Name", dataIndex: "vendor_name" },
    { title: "Total", dataIndex: "vrefund_total_amount" },
    { title: "Charge", dataIndex: "vrefund_charge_amount" },
    { title: "Return", dataIndex: "vrefund_return_amount" },
  ];
  const billingInfoColumn: TableType[] = [
    {
      key: "1",
      name: "Profit",
      value: data?.crefund_profit,
    },
  ];

  console.log(data?.crefund_profit);
  return (
    <div style={invoiceViewStyle}>
      <div>
        <h2 style={{ color: "#18b4e9" }}>REFUND DETAILS</h2>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyItems: "center",
            alignItems: "center",
            backgroundColor: "#fff",
            padding: 20,
            borderRadius: 5,
          }}
        >
          <div>
            <Typography.Title level={5} style={{ marginBottom: "5px" }}>
              CLIENT INFORMATION
            </Typography.Title>
            <Table
              columns={columns}
              dataSource={data ? [data] : []}
              pagination={false}
            />
            <Typography.Title
              level={5}
              style={{ marginBottom: "5px", marginTop: 20 }}
            >
              VENDOR INFORMATION
            </Typography.Title>
            <Table
              columns={itemColumns}
              dataSource={data ? data?.refund_info : []}
              pagination={false}
            />
          </div>
        </div>

        <Table
          size="small"
          bordered
          rowKey={"atrefund_id"}
          className="invoiceBillingTable"
          dataSource={data ? [data?.crefund_profit] : []}
          columns={billingInfoColumn}
          showHeader={false}
          pagination={false}
          style={{ width: "270px" }}
        />
      </div>
    </div>
  );
};

export default AirTicketRefundDetails;
