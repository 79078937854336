import { HTTPResponse } from "../../../../common/types/commonTypes";
import { api } from "../../../../app/baseQuery";

import {
  ContactDataType,
  IContact,
  IContactFilters,
  IContactFormData,
  ISendGiftFormData,
  ISingleContactGift,
} from "../types/contactTypes";
import assyncWrapper from "../../../../common/utils/assyncWrapper";
import { message } from "antd";

export const CONTACTS = "CONTACTS";
export const contactsEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //  get all contact
    getAllContacts: build.query<HTTPResponse<IContact[]>, IContactFilters>({
      query: (params) => {
        const { current, pageSize, ...rest } = params;
        let uri = `/configuration/account-contacts/get-all?page=${current}&size=${pageSize}`;

        return { url: uri, params: rest };
      },
      providesTags: () => ["Contacts"],
    }),

    //  get all contact with pagination
    getAllContactWithPag: build.query<
      HTTPResponse<ContactDataType[]>,
      { current: number; pageSize: number }
    >({
      query: (arg) => ({
        url: `/configuration/account-contacts?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => ["Contacts"],
    }),

    //create contact
    createContact: build.mutation<void, IContactFormData>({
      query: (body) => ({
        url: `/configuration/account-contacts/create`,
        method: "POST",
        body: body,
      }),
      onQueryStarted: async (_arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success("Contact Added Successfully");
        });
      },
      invalidatesTags: () => ["Contacts"],
    }),

    //edit contact
    editContact: build.mutation<void, { body: IContactFormData; id: number }>({
      query: ({ body, id }) => ({
        url: `/configuration/account-contacts/edit-contact/${id}`,
        method: "PATCH",
        body: body,
      }),

      invalidatesTags: () => ["Contacts"],
    }),

    //delete contact
    deleteContact: build.mutation<void, IContact>({
      query: (body) => ({
        url: `/configuration/account-contacts/delete-contact/${body.account_contact_id}`,
        method: "DELETE",
      }),
      onQueryStarted: async (_arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success("Successfully Contact Deleted");
        });
      },

      invalidatesTags: () => ["Contacts"],
    }),
    //get single contact gifts
    getSingleContactGifts: build.query<
      HTTPResponse<ISingleContactGift>,
      number
    >({
      query: (id) => ({
        url: `/configuration/account-contacts/get/${id}`,
      }),
      providesTags: () => ["send-gift"],
    }),

    //send gift
    createSendGift: build.mutation<void, ISendGiftFormData>({
      query: (body) => ({
        url: `/configuration/account-contacts/create-gifts`,
        method: "POST",
        body: body,
      }),
      onQueryStarted: async (_arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success("Successfully Gift Sent ");
        });
      },

      invalidatesTags: () => ["send-gift", "Contacts"],
    }),

    //edit gift
    editGift: build.mutation<void, { body: ISendGiftFormData; id: number }>({
      query: ({ body, id }) => ({
        url: `/configuration/account-contacts/edit-gifts/${id}`,
        method: "PATCH",
        body: body,
      }),
      onQueryStarted: async (_arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success("Successfully Gift Updated ");
        });
      },

      invalidatesTags: () => ["send-gift"],
    }),

    //delete gift
    deleteGift: build.mutation<void, number>({
      query: (id) => ({
        url: `/configuration/account-contacts/delete-gifts/${id}`,
        method: "DELETE",
      }),
      onQueryStarted: async (_arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success("Successfully Gift Deleted ");
        });
      },

      invalidatesTags: () => ["send-gift", "Contacts"],
    }),
  }),
});

export const {
  useGetAllContactsQuery,
  useCreateContactMutation,
  useEditContactMutation,
  useDeleteContactMutation,
  useGetAllContactWithPagQuery,
  useGetSingleContactGiftsQuery,
  useCreateSendGiftMutation,
  useEditGiftMutation,
  useDeleteGiftMutation,
} = contactsEndpoints;
