import { Col, ConfigProvider, Row, Table, theme } from "antd";
import { IViewInvoiceDetails } from "../../../../common/types/commonInterfaces";
import { pagination } from "../../../../common/utils/common.utils";

type props = {
  invoice_info: IViewInvoiceDetails;
};
const InvoiceTourCostTotal = ({ invoice_info }: props) => {
  const {
    invoice_sub_total,
    invoice_net_total,
    invoice_service_charge,
    invoice_vat,
  } = invoice_info;

  const billingInfoData = [
    {
      key: "1",
      name: "Sub Total",
      value: (
        <>
          <Row justify={"end"}>
            <Col>{Number(invoice_sub_total)}</Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      name: "Service Charge",
      value: (
        <>
          <Row justify={"end"}>
            <Col>{Number(invoice_service_charge)}</Col>
          </Row>
        </>
      ),
    },
    {
      key: "3",
      name: "Net Total",
      value: (
        <>
          <Row justify={"end"}>
            <Col>{Number(invoice_net_total)}</Col>
          </Row>
        </>
      ),
    },
    {
      key: "4",
      name: "Payment",
      value: 0,
    },
    {
      key: "5",
      name: "Vat",
      value: invoice_vat,
    },
  ];
  const billingInfoColumn = [
    {
      dataIndex: "name",
      key: "name",
      width: "150px",
    },
    {
      dataIndex: "value",
      key: "value",
      width: "120px",
    },
  ];
  return (
    <Row justify="end" className="mt-5">
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Table
          size="small"
          bordered
          rowKey={(record) => record.key}
          className="invoiceBillingTable"
          rowClassName={"invoiceBillingTd"}
          dataSource={billingInfoData.filter((item) => item.value)}
          columns={billingInfoColumn}
          showHeader={false}
          style={{ width: "270px" }}
          pagination={false}
        />
      </ConfigProvider>
    </Row>
  );
};

export default InvoiceTourCostTotal;
