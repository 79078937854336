import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  formatAmount,
  formatAmountAbs,
} from "../../../../common/utils/common.utils";
import { FormButton } from "../../../../components/common/FormItem/FormItems";
import { SelectClientsOnly } from "../../../../components/common/FormItem/SelectCustomFeilds";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { disabledFeatureDate } from "../../../../helpers/CapitalizeFirstLetter";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import {
  useLazyGetClientDueQuery,
  useLazyGetExcelQuery,
} from "../../ledgers/endpoints/ledgerEndpoints";
import { useClientDueAdvanceQuery } from "../endpoints/total_due_advance_clientEndpoints";
import { ITotal_Due_Advance_FormData } from "../types/Total_Due_Advance_ClientTypes";
import { Total_Due_Advance_ClientUtils } from "../utils/Total_Due_Advance_ClientUtils";

type Props = {};

function Total_Due_Advance_Client({}: Props) {
  const [form] = Form.useForm();

  const toDate = dayjs().format("YYYY-MM-DD");

  const [getClientDue] = useLazyGetClientDueQuery();

  const [excelQuery, setExcelQuery] = useState<{
    // Id: string;
    query: string;
  }>({
    // Id: "all",
    query: "",
  });

  // Pagination Logic
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  // const filter = Form.useWatch("filter", form);

  const [client, setClient] = useState<string>("all");
  const [date, setDate] = useState<string>(toDate);

  useEffect(() => {
    form.setFieldValue("payment_date", dayjs());
    // form.setFieldValue("client_id", "all");
    setExcelQuery({
      // Id: "all",
      query: `?payment_date=${date}&page=${pagination.current}&size=${pagination.pageSize}`,
    });
  }, []);

  const { data, isLoading } = useClientDueAdvanceQuery({
    ...pagination,
    // client_id: client,
    // filter: filter,
    payment_date: date,
  });

  const onFinish = async (values: ITotal_Due_Advance_FormData) => {
    const payment_date = dayjs(values.payment_date).format("YYYY-MM-DD");

    // setClient(String(values.client_id) || "all");
    setDate(payment_date);

    const body: ITotal_Due_Advance_FormData = {
      // client_id: values.client_id,
      payment_date: "payment_date=" + payment_date,
    };

    setExcelQuery({
      // Id: body.client_id.toString(),
      query: `?${body.payment_date}&page=${pagination.current}&size=${pagination.pageSize}`,
    });
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Due_Advance_Report_${date}`,
    pageStyle: ``,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <FormHeaderTitle title="CLIENT DUE/ADVANCE" />

      <ConfigProvider>
        <Typography style={{ marginBottom: 15 }}>
          You have total: {data?.count} Results
        </Typography>

        <Table
          size="small"
          bordered
          rowKey={(e) => e.serial}
          // className="invoiceBillingTable"
          rowClassName={"invoiceBillingTd"}
          dataSource={data?.data}
          columns={Total_Due_Advance_ClientUtils(pagination)}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
          scroll={{ x: true }}
          pagination={
            data?.count !== undefined && data?.count < 20
              ? false
              : {
                  ...pagination,
                  total: data?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ["50", "100", "200", "500"],
                  onChange: handlePaginationChange,
                }
          }
          summary={(tableData) => {
            const totalDebit = tableData.reduce((accumulator, item) => {
              return accumulator + parseFloat(item.total_debit);
            }, 0);
            const totalCredit = tableData.reduce((accumulator, item) => {
              return accumulator + parseFloat(item.total_credit);
            }, 0);
            const totalDue = tableData.reduce((accumulator, item) => {
              const amount =
                parseFloat(item.current_balance) < 0
                  ? Math.abs(+item.current_balance)
                  : 0;
              return accumulator + amount;
            }, 0);
            const totalAdvance = tableData.reduce((accumulator, item) => {
              const amount =
                parseFloat(item.current_balance) > 0
                  ? Math.abs(+item.current_balance)
                  : 0;
              return accumulator + amount;
            }, 0);

            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={3} align="right">
                  <Typography.Text strong style={{ textAlign: "right" }}>
                    Total{" "}
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  <div
                    style={{
                      color: "red",
                      textAlign: "right",
                    }}
                  >
                    {formatAmountAbs(totalDebit)}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>
                    <div
                      style={{
                        color: "green",
                        textAlign: "right",
                      }}
                    >
                      {formatAmountAbs(totalCredit)}
                    </div>
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>
                    <div
                      style={{
                        color: "red",
                        textAlign: "right",
                      }}
                    >
                      {formatAmountAbs(totalDue)}
                    </div>
                  </Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                  <Typography.Text strong>
                    <div
                      style={{
                        color: "green",
                        textAlign: "right",
                      }}
                    >
                      {formatAmountAbs(totalAdvance)}
                    </div>
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </ConfigProvider>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "CLIENT DUE/ADVANCE" }}
      />
    </div>
  );

  return (
    <div>
      <BreadCrumb arrOfOption={["Report", "CLIENT DUE/ADVANCE"]} />

      <Row align={"middle"} justify={"space-between"}>
        <Col lg={18} md={18} sm={14} xs={24}>
          <Form layout="vertical" form={form} onFinish={onFinish}>
            <Row gutter={20}>
              {/* <SelectClientsOnly
                required
                name="client_id"
                size={8}
                mdSize={8}
                smSize={10}
                xsSize={24}
                label="Select Client"
                placeholder="Select Client"
                offDropDown
                showAll
              /> */}

              <Col span={12} xs={24} sm={10} md={6} lg={6} xxl={3}>
                <Form.Item
                  label="Select Date"
                  name="payment_date"
                  rules={[{ required: true, message: ` Date is required!` }]}
                  style={{ width: "100%" }}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY"}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <Col lg={2} md={3} sm={4}>
                <FormButton label="Search" icon textAlign="end" />
              </Col>
            </Row>
          </Form>
        </Col>
        <Col lg={6} md={6} sm={8} xs={24}>
          <Space style={{ marginBottom: "1rem" }}>
            <Button type="primary" onClick={handlePrint} loading={isLoading}>
              <PrinterOutlined />
              Print
            </Button>
            <Button
              type="primary"
              onClick={() => {
                getClientDue({
                  // client_id: excelQuery.Id,
                  query: excelQuery.query,
                  excelApiName: "client-advance-due-excel",
                  excelName: "total_due_advance_client",
                });
              }}
            >
              <FileTextOutlined />
              Excel Report
            </Button>
          </Space>
        </Col>
      </Row>

      {/* //-----------//----------// @LEDGER TABLE HERE //--------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
}

export default Total_Due_Advance_Client;
