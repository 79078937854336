import React from 'react';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { Col, Table, Typography } from 'antd';
import { useTodayPurchasePaymentsQuery } from '../endpoints/totalCollectionPayments';
import { ColumnsType } from 'antd/es/table';
import {
  ITodayPaymentReport,
  ITodayPurchaseReport,
} from '../types/totalCollectionTypes';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import dayjs from 'dayjs';
import {
  Fixed2,
  TableParams,
  pagination,
} from '../../../../common/utils/common.utils';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import { Link } from 'react-router-dom';

type Props = {
  value: 'sales&collection' | 'sales&payments';
  refSalesPurchase: React.MutableRefObject<null>;
  refSalesPayment: React.MutableRefObject<null>;
};

export default function PurchaseAndPayments({
  value,
  refSalesPayment,
  refSalesPurchase,
}: Props) {
  const { data, isLoading, isError, refetch } = useTodayPurchasePaymentsQuery();

  const [tableParams, setTableParams] = React.useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 20,
      total: data?.data?.purchase.length,
    },
  });

  const column: ColumnsType<ITodayPurchaseReport> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((tableParams?.pagination?.current || 1) - 1) *
            (tableParams?.pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Purchase Date',
      dataIndex: 'invoice_sales_date',
      render(value, record, index) {
        return (
          <>
            {record?.invoice_sales_date
              ? dayjs(record?.invoice_sales_date).format('DD-MM-YYYY')
              : ''}
          </>
        );
      },
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
    },

    {
      title: 'Purchase Amount',
      dataIndex: 'cost_price',
      key: 'cost_price',
      align: 'right',
      render(value, record, index) {
        return Number(value);
      },
    },
  ];

  const [tableParams1, setTableParams1] = React.useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 20,
      total: data?.data?.payments.length,
    },
  });

  const columnPayments: ColumnsType<ITodayPaymentReport> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((tableParams1?.pagination?.current || 1) - 1) *
            (tableParams1?.pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Payment Date',
      dataIndex: 'payment_date',
      render(value, record, index) {
        return <>{value ? dayjs(value).format('DD-MM-YYYY') : ''}</>;
      },
    },
    {
      title: 'Vouchar No',
      dataIndex: 'vouchar_no',
      key: 'vouchar_no',
    },

    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
    },
    {
      title: 'Receipt No',
      dataIndex: 'vpay_receipt_no',
      key: 'vpay_receipt_no',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      render: (curr, rec) => {
        const { billing_combine_id, billing_vendor_id } = rec;

        if (billing_vendor_id)
          return (
            <Link to={`/reports/vendor_ledger?id=${rec.billing_vendor_id}`}>
              {curr}
            </Link>
          );

        if (billing_combine_id)
          return (
            <Link to={`/reports/combined_ledger?id=${rec.billing_combine_id}`}>
              {curr}
            </Link>
          );
      },
    },

    {
      title: 'Payment Amount',
      dataIndex: 'payment_amount',
      key: 'payment_amount',
      align: 'right',
    },
  ];

  React.useEffect(() => {
    refetch();
  }, [value]);

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      size='small'
      bordered
      columns={column}
      loading={{ spinning: isLoading, indicator: loadingIndicator }}
      dataSource={data?.data?.purchase}
      scroll={{ x: true }}
      summary={(_) => {
        return (
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={4} index={1}>
              <Col offset={20}>
                <Typography.Text strong>Total :</Typography.Text>
              </Col>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              <div
                style={{
                  color: 'green',
                  fontWeight: '700',
                  textAlign: 'right',
                }}
              >
                <Typography.Text style={{ fontWeight: 'bolder' }}>
                  {Fixed2(
                    _?.reduce((a, b) => a + Number(b.cost_price || 0), 0) || 0
                  )}
                </Typography.Text>
              </div>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
      pagination={pagination(data?.data?.purchase.length)}
      onChange={(args1) => {
        setTableParams((prev) => ({ ...prev, pagination: args1 }));
      }}
    />
  );
  const jsx_com1 = (
    <Table
      size='small'
      bordered
      columns={columnPayments}
      loading={{ spinning: isLoading, indicator: loadingIndicator }}
      dataSource={data?.data?.payments}
      summary={(_) => {
        return (
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={6} index={1}>
              <Col offset={20}>
                <Typography.Text strong>Total :</Typography.Text>
              </Col>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              <div
                style={{
                  color: 'green',
                  fontWeight: '700',
                  textAlign: 'right',
                }}
              >
                <Typography.Text style={{ fontWeight: 'bolder' }}>
                  {Fixed2(
                    _?.reduce((a, b) => a + Number(b.payment_amount || 0), 0) ||
                      0
                  )}
                </Typography.Text>
              </div>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        );
      }}
      pagination={pagination(data?.data?.payments.length)}
      onChange={(args1) => {
        setTableParams1((prev) => ({ ...prev, pagination: args1 }));
      }}
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={refSalesPurchase}
        title={{ info_title: '', title: 'Daily Purchase' }}
      />
    </div>
  );
  const print_content1 = (
    <div hidden>
      <CommonViewReport
        children={jsx_com1}
        printRef={refSalesPayment}
        title={{ info_title: '', title: 'Daily Payments' }}
      />
    </div>
  );

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <div style={{ flexGrow: '1' }}>
        <FormHeaderTitle title='Purchase' />
        {jsx_com}
      </div>
      <div style={{ flexGrow: '1' }}>
        <FormHeaderTitle title='Payments' />
        {jsx_com1}
      </div>

      {/* Sales Print */}
      {print_content}

      {/* Collection Print */}
      {print_content1}
    </div>
  );
}
