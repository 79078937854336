import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Form, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Fixed2,
  TableParams,
  handleTableChange,
} from "../../../../common/utils/common.utils";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import { useLazyGetExcelQuery } from "../../ledgers/endpoints/ledgerEndpoints";
import { useLazyGetAllHeadWiseExpenseReportQuery } from "../endpoints/HeadWiseExpenseReportEndpoints";
import { IHeadWiseExpenseReportFormDataType } from "../types/headWiseExpenseReportTypes";
import { HeadWiseExpenseReportColumn } from "../utils/HeadWiseExpenseReportColumn";

const HeadWiseExpenseReport = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: "",
    query: "",
  });
  useEffect(() => {
    form.setFieldValue("date_range", [dayjs(), dayjs()]);
    form.setFieldValue("head_expense", "all");
  }, []);

  let totalAmount: number = 0;
  const [fetchHeadWiseExpenseReport, { data, isLoading }] =
    useLazyGetAllHeadWiseExpenseReportQuery();

  if (data?.data) {
    for (let i = 0; i < data?.data.length; i++) {
      totalAmount += Number(data?.data[i].expdetails_amount);
    }
  }

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dailyValue = searchParams.get("daily");

    const monthFormDate = searchParams.get("from_date");
    const monthToDate = searchParams.get("to_date");

    const yearFormDate = searchParams.get("y_from_date");
    const yearToDate = searchParams.get("y_to_date");

    if (dailyValue) {
      onFinish({
        date_range: [dailyValue, dailyValue],
        head_expense: "all",
      });
    }

    if (monthFormDate && monthToDate) {
      form.setFieldValue("date_range", [
        dayjs(monthFormDate),
        dayjs(monthToDate),
      ]);
      onFinish({
        date_range: [monthFormDate, monthToDate],
        head_expense: "all",
      });
    }

    if (yearFormDate && yearToDate) {
      form.setFieldValue("date_range", [
        dayjs(yearFormDate),
        dayjs(yearToDate),
      ]);
      onFinish({
        date_range: [yearFormDate, yearToDate],
        head_expense: "all",
      });
    }
  }, [location.search]);

  const onFinish = async (values: IHeadWiseExpenseReportFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      head_expense: values.head_expense,
    };
    await fetchHeadWiseExpenseReport({
      head_expense: body.head_expense,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: body.head_expense.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: "0" | "1";
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: "0", pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ["50", "100", "200", "500"],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IHeadWiseExpenseReportFormDataType = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      head_expense: values.head_expense,
    };

    fetchHeadWiseExpenseReport({
      head_expense: body.head_expense,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.head_expense.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Head_Wise_Expense_Report`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <ConfigProvider
      theme={
        {
          // algorithm: theme.defaultAlgorithm,
        }
      }
    >
      <Table
        columns={HeadWiseExpenseReportColumn(queryData)}
        dataSource={data?.data}
        bordered
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
        size="small"
        pagination={count > 50 ? tableParams.pagination : false}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
        summary={(_) => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                <Table.Summary.Cell index={0}>
                  <div
                    style={{
                      fontWeight: "700",
                      textAlign: "right",
                    }}
                  >
                    <Typography.Text strong>Total: </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <div
                    style={{
                      fontWeight: "700",
                      textAlign: "right",
                    }}
                  >
                    <Typography.Text strong>
                      {Fixed2(totalAmount)}
                    </Typography.Text>
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}> </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </ConfigProvider>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Total Due/Advance (Agents)" }}
      />
    </div>
  );
  return (
    <div>
      <BreadCrumb arrOfOption={["Reports", "Expense Report"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: "expense/expenseReport",
              excelName: "head_wise_expense_report",
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <ReportsHeader
          required
          date_range
          reports_select
          showSelectHead
          select_placeholder="Head Of Expense"
        />
      </Form>

      {
        <div ref={componentRef}>
          <FormHeaderTitle title="Expense Report" />

          {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
          {jsx_com}
        </div>
      }

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
};

export default HeadWiseExpenseReport;
