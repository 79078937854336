import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { Fixed2, formatAmount } from '../../../../common/utils/common.utils';
import { CollectionType } from './CollectionTypes';

export const CollectionColumn = (pagination: {
  current: number;
  pageSize: number;
}): ColumnsType<CollectionType> => {
  return [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Received Date',
      key: 'receipt_payment_date',
      render: (_, record) => {
        return `${dayjs(record.receipt_payment_date).format('DD MMM YYYY')}`;
      },
    },
    {
      title: 'Receipt No.',
      dataIndex: 'receipt_vouchar_no',
      key: 'receipt_vouchar_no',
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      key: 'account_name',

      render: (curr, data) => {
        const { account_id } = data;
        return (
          <Link
            to={`/accounts/account_statement/${account_id}`}
            state={`/accounts/account_statement/${account_id}`}
          >
            {curr}
          </Link>
        );
      },
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',

      render: (curr, record) => {
        const { client_id, combine_id } = record;

        return (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?client_query=${client_id}`
                : `/reports/combined_ledger?id=${combine_id}`
            }
          >
            <span>{curr}</span>
          </Link>
        );
      },
    },
    {
      title: 'Pay to',
      dataIndex: 'receipt_payment_to',
      key: 'receipt_payment_to',
    },
    {
      title: 'Pay Method',
      dataIndex: 'acctype_name',
      key: 'acctype_name',
    },
    {
      title: 'Bank Name',
      dataIndex: 'bank_name',
      key: 'bank_name',
    },

    {
      title: 'Received Amount',
      dataIndex: 'receipt_total_amount',
      key: 'receipt_total_amount',
      align: 'right',
      render: (curr) => (
        <span className='color-collection'>{formatAmount(curr)}</span>
      ),
    },
    {
      title: 'Received By',
      dataIndex: 'employee_full_name',
      key: 'employee_full_name',
      align: 'right',
    },
    {
      title: 'Prepared By',
      dataIndex: 'user_full_name',
      key: 'user_full_name',
      align: 'right',
    },
  ];
};
