import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useSalesReportQuery } from "../Api/Endpoints/DashboardEndpoints";
import BSPBillingInfo from "../Components/BSP/BSPBillingInfo";
import OtherVendorBillingInfo from "../Components/BSP/OtherVendorBillingInfo";
import DailyReport from "../Components/DailyReport";
import MonthlyReport from "../Components/MonthlyReport";
import NoticeBar from "../Components/NoticeBar";
import ReportCardSkeleton from "../Components/ReportCardSkeleton";
import VendorInfo from "../Components/VendorInfo";
import YearlyReport from "../Components/YearlyReport";
import "../style/dashboard.css";
import { useLottie } from "lottie-react";
import banner from "../../../assets/moon.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { iconStyle } from "../../../layout/pages/Sidebar";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const IataDashboard = () => {
  const { data: salesData, isLoading: reportCardLoading } =
    useSalesReportQuery();
  const dAlert = localStorage.getItem("dAlert");
  const [alertShow, setAlertShow] = useState<boolean>(true);

  // useEffect(() => {
  //   if (dAlert) {
  //     setAlertShow(false);
  //   }
  // }, [dAlert]);
  /*   const { data } = useGetDashboardSummaryQuery();

  const { data: expenseData, isLoading: expenseLoading } =
    useExpensesDataQuery();

  const expenses = expenseData?.data;

  const summary = data?.data;
  const accountBalInsight = summary?.currAccStatus;
  const totalClVnCm = summary?.account_status; */

  const [isDarkMode, setIsDarkMode] = useState(false);
  const dark = localStorage.getItem("theme");

  useEffect(() => {
    if (dark === "darkTheme") {
      setIsDarkMode(false);
    } else if (dark === "defaultTheme") {
      setIsDarkMode(true);
    }
  }, [dark]);

  const options = {
    animationData: banner,
    loop: true,
  };
  const { View } = useLottie(options);

  return (
    <div>
      <NoticeBar />

      {dAlert !== "false" && (
        <div
          style={{
            marginBottom: "10px",
            borderRadius: "50px",
            position: "relative",
          }}
        >
          {View}
          <div
            onClick={() => {
              localStorage.setItem("dAlert", "false"), setAlertShow(false);
            }}
            style={{
              position: "absolute",
              right: "0px",
              top: "-10px",
              color: "white",
              backgroundColor: "red",
              borderRadius: "2px",
              padding: "0px 2px",
              cursor: "pointer",
            }}
          >
            {" "}
            <FontAwesomeIcon style={iconStyle} icon={faClose} />
          </div>
        </div>
      )}

      <Row justify={"space-between"} gutter={[20, 40]}>
        {reportCardLoading ? (
          <ReportCardSkeleton />
        ) : (
          <>
            <Col xs={24} xl={8} md={12} sm={24}>
              <DailyReport data={salesData?.data} isDarkMode={isDarkMode} />
            </Col>
            <Col xs={24} xl={8} md={12} sm={24}>
              <MonthlyReport data={salesData?.data} isDarkMode={isDarkMode} />
            </Col>
            <Col xs={24} xl={8} md={12} sm={24}>
              <YearlyReport data={salesData?.data} isDarkMode={isDarkMode} />
            </Col>
          </>
        )}

        <VendorInfo isDarkMode={isDarkMode} />
        <BSPBillingInfo isDarkMode={isDarkMode} />
        <OtherVendorBillingInfo isDarkMode={isDarkMode} />

        {/*   <Col xl={8} md={12} sm={12} xs={24}>
          <Account_Details_Insight
            accountBalInsight={accountBalInsight}
            isDarkMode={isDarkMode}
          />
        </Col>

        <Col xl={8} md={12} sm={12} xs={24}>
          <Account_Insight isDarkMode={isDarkMode} />
        </Col>

        <Col xl={8} md={12} sm={12} xs={24}>
          <Client_Vendor_Agent_Insight
            totalClVnCm={totalClVnCm}
            isDarkMode={isDarkMode}
          />
        </Col>
        <Col xl={8} md={12} sm={12} xs={24}>
          <InvoiceDetails isDarkMode={isDarkMode} />
        </Col>

        <Col xl={8} md={12} sm={24} xs={24}>
          <ExpenseTotal
            expenses={expenses}
            expenseLoading={expenseLoading}
            isDarkMode={isDarkMode}
          />
        </Col>

        <BestClient isDarkMode={isDarkMode} />
        <BestEmployees isDarkMode={isDarkMode} /> */}
      </Row>
    </div>
  );
};

export default IataDashboard;
