import { Col, Divider, Form, Select } from "antd";
import { useGetAllTourGroupQuery } from "../../../Configuration/Tour/TourGroups/endpoints/tourGroupEndpoints";
import CreateTourGroup from "../../../Configuration/Tour/TourGroups/modal/CreateTourGroup";
import { useState } from "react";

type Props = {
  name?: string;
  label?: string;
  size?: number;
  required?: boolean;
  placeholder?: string;
  setGroupName?: React.Dispatch<React.SetStateAction<number | undefined>>;
  xxl?: number;
};
const SelectTourGroup = ({
  name,
  label,
  size,
  required,
  placeholder,
  setGroupName,
  xxl,
}: Props) => {
  const { data } = useGetAllTourGroupQuery();
  const selectData = data?.data;
  const [click, setClick] = useState<boolean>();
  const selectOptioinChildren: React.ReactNode[] = [];
  if (selectData) {
    for (let i = 0; i < selectData.length; i++) {
      selectOptioinChildren.push(
        <Select.Option
          key={selectData[i].group_id + " " + selectData[i].group_name}
          value={selectData[i].group_id}
        >
          {selectData[i].group_name}
        </Select.Option>
      );
    }
  }
  return (
    <Col xs={24} sm={12} md={8} lg={size ? size : 8} xxl={xxl}>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required || false,
            message: `${label} is required!`,
          },
        ]}
      >
        <Select
          allowClear
          placeholder={placeholder}
          showSearch
          style={{ padding: "0", margin: "0", border: "0" }}
          optionFilterProp="roleMobile"
          filterOption={(input, option) =>
            (option!.children as unknown as string)
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          dropdownRender={(menu) => (
            <>
              <CreateTourGroup
                btnTitle={"Create Group"}
                setClick={setClick}
                setGroupName={setGroupName}
              />

              <Divider style={{ margin: "8px 0" }} />
              {menu}
            </>
          )}
          dropdownMatchSelectWidth={150}
          open={click === true ? false : undefined}
        >
          {selectOptioinChildren}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default SelectTourGroup;
