import { PrinterOutlined } from "@ant-design/icons";
import { Button, Form, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Fixed2,
  TableParams,
  handleTableChange,
} from "../../../../common/utils/common.utils";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import { useLazyGetExcelQuery } from "../../ledgers/endpoints/ledgerEndpoints";
import { useLazyGetAllPayrollReportQuery } from "../endpoints/payrollReportEndpoints";
import { IPayrollReportFormDataType } from "../types/payrollReportTypes";
import PayrollReportColumn from "../utils/payrollReportColumns";

const PayrollReport = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: "",
    query: "",
  });

  const [fetchPayrollReport, { data, isLoading, isFetching }] =
    useLazyGetAllPayrollReportQuery();

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const from_date = searchParams.get("from_date");
    const to_date = searchParams.get("to_date");

    if (from_date && to_date) {
      form.setFieldValue("date_range", [dayjs(from_date), dayjs(to_date)]);
      form.setFieldValue("employee_id", "all");
      form.submit();
    } else {
      form.setFieldValue("date_range", [dayjs(), dayjs()]);
    }
  }, []);

  const onFinish = async (values: IPayrollReportFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      employee_id: values.employee_id,
    };
    await fetchPayrollReport({
      payroll_id: body.employee_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: body.employee_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: "0" | "1";
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: "0", pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ["50", "100", "200", "500"],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IPayrollReportFormDataType = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      employee_id: values.employee_id,
    };
    fetchPayrollReport({
      payroll_id: body.employee_id,
      query: `${query}&${body.date_range}`,
    });

    setExcelQuery({
      Id: body.employee_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const toDate = dayjs().format("YYYY-MM-DD");
  useEffect(() => {
    const values = form.getFieldsValue();
    const date_range = `from_date="${toDate}"&to_date="${toDate}"`;
    form.setFieldValue("employee_id", "all");
    const body = {
      date_range: date_range,
      employee_id: values.employee_id,
    };
    fetchPayrollReport({
      payroll_id: "all",
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: "all",
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Expense_Report`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      columns={PayrollReportColumn(queryData)}
      bordered
      size="small"
      dataSource={data?.data?.data}
      loading={{
        spinning: isLoading || isFetching,
        indicator: loadingIndicator,
      }}
      pagination={count > 50 ? tableParams.pagination : false}
      onChange={(args1, args2, args3) =>
        handleTableChange({
          args: {
            pagination: args1,
            filters: args2,
            sorter: args3,
          },
          states: {
            refetch,
            setQueryData,
            setTableParams,
            tableParams,
          },
        })
      }
      summary={(_) => {
        let TotalSalariesAmount = 0;
        let TotalPassSalariesAmount = 0;
        let TotalPresetSalariesAmount = 0;

        _.forEach(
          ({
            payroll_net_amount,
            payroll_pass_salaries,
            payroll_preset_salaries,
          }) => {
            TotalSalariesAmount += Number(payroll_net_amount || 0);
            TotalPassSalariesAmount += Number(payroll_pass_salaries || 0);
            TotalPresetSalariesAmount += Number(payroll_preset_salaries || 0);
          }
        );

        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={4} colSpan={4}>
                <Typography
                  style={{
                    textAlign: "right",
                    marginRight: "5px",
                    fontWeight: "bold",
                  }}
                >
                  Total:
                </Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <Typography
                  style={{
                    textAlign: "right",
                    marginRight: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {Fixed2(TotalSalariesAmount)}
                </Typography>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={7}>
                <Typography
                  style={{
                    textAlign: "right",
                    marginRight: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {Fixed2(TotalPassSalariesAmount)}
                </Typography>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={7}>
                <Typography
                  style={{
                    textAlign: "right",
                    marginRight: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {Fixed2(TotalPresetSalariesAmount)}
                </Typography>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={8}>
                <Typography></Typography>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Payroll Report" }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={["Reports", "Payroll Report"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        {/* <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'payrollReport',
              excelName: 'payrollReport',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button> */}
      </Space>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <ReportsHeader
          date_range
          required
          showAll
          reports_title="Payroll Report"
          showSelectEmployee
        />
      </Form>

      <>
        <FormHeaderTitle title="Payroll Report" />
        {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
        {jsx_com}
      </>

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default PayrollReport;
