import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Form, Space, Table, Typography } from "antd";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Fixed2,
  TableParams,
  formatNumber,
  handleTableChange,
} from "../../../../common/utils/common.utils";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { useLazyGetExcelQuery } from "../../ledgers/endpoints/ledgerEndpoints";
import { useLazyGetAllClientDiscountQuery } from "../endpoints/ClientDiscountEndpoints";
import { IClientDiscountFormDataType } from "../types/ClientDiscountTypes";
import ClientDiscountColumn from "../utils/ClientDiscountColumn";

const ClientDiscount = () => {
  const [form] = Form.useForm();
  const searchParams = new URLSearchParams(location.search);
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: "",
    query: "",
  });
  let totalDiscountAmount: number = 0;
  let totalSales: number = 0;
  const [fetchClientDiscount, { data, isLoading }] =
    useLazyGetAllClientDiscountQuery();
  if (data?.data) {
    for (let i = 0; i < data.data.length; i++) {
      totalDiscountAmount += Number(data.data[i].discount_total);
      totalSales += Number(data.data[i].invoice_net_total);
    }
  }

  useEffect(() => {
    const from_date = searchParams.get("from_date");
    const to_date = searchParams.get("to_date");

    if (from_date && to_date) {
      form.setFieldValue("date_range", [dayjs(from_date), dayjs(to_date)]);
      form.setFieldValue("invoice_combclient_id", "all");
      form.submit();
    } else {
      form.setFieldValue("date_range", [dayjs(), dayjs()]);
    }
  }, []);

  const onFinish = async (values: IClientDiscountFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      invoice_combclient_id: values.invoice_combclient_id,
    };
    await fetchClientDiscount({
      invoice_combclient_id: body.invoice_combclient_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: body.invoice_combclient_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: "0" | "1";
    current: number;
    pageSize: number;
  }>({ current: 1, isTrash: "0", pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ["50", "100", "200", "500"],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IClientDiscountFormDataType = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      invoice_combclient_id: values.invoice_combclient_id,
    };
    fetchClientDiscount({
      invoice_combclient_id: body.invoice_combclient_id,
      query: `${query}&${body.date_range}`,
    });

    setExcelQuery({
      Id: body.invoice_combclient_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const toDate = dayjs().format("YYYY-MM-DD");
  useEffect(() => {
    const values = form.getFieldsValue();
    const date_range = `from_date=${toDate}&to_date=${toDate}`;

    form.setFieldValue("invoice_combclient_id", "all");
    const body = {
      date_range: date_range,
      invoice_combclient_id: values.invoice_combclient_id,
    };
    fetchClientDiscount({
      invoice_combclient_id: "all",
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: "all",
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Expense_Report`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      rowKey={(e) => e.invoice_id}
      columns={ClientDiscountColumn(queryData)}
      bordered
      size="small"
      dataSource={data?.data}
      loading={{ spinning: isLoading, indicator: loadingIndicator }}
      pagination={count > 50 ? tableParams.pagination : false}
      onChange={(args1, args2, args3) =>
        handleTableChange({
          args: {
            pagination: args1,
            filters: args2,
            sorter: args3,
          },
          states: {
            refetch,
            setQueryData,
            setTableParams,
            tableParams,
          },
        })
      }
      summary={(_) => {
        return (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4} align="right">
                <Typography
                  style={{
                    textAlign: "right",
                    marginRight: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Total:
                </Typography>
              </Table.Summary.Cell>
              {/* <Table.Summary.Cell index={7}>
                <Typography className="bold color-sales align-right">
                  {formatNumber(data?.summary?.total_sub_total || 0)}
                </Typography>
              </Table.Summary.Cell> */}
              <Table.Summary.Cell index={7}>
                <Typography className="bold color-discount align-right">
                  {formatNumber(data?.summary?.total_discount || 0)}
                </Typography>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7}>
                <Typography className="bold color-sales align-right">
                  {formatNumber(data?.summary?.total_net_total || 0)}
                </Typography>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Client Discount" }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={["Reports", "Client Discount"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: "clientDiscount",
              excelName: "clientDiscount",
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <ReportsHeader
          date_range
          required
          reports_title="Client Discount"
          showSelectClients
        />
      </Form>

      <>
        <Typography.Text>You have total: {data?.count} rows</Typography.Text>

        {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
        {jsx_com}
      </>

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default ClientDiscount;
