import { Alert, Row } from "antd";
import { NamePath } from "antd/es/form/interface";
import { FormInstance, useWatch } from "rc-field-form";
import { useEffect, useState } from "react";
import FormHeaderTitle from "../../../modules/Invoice(Visa)/Components/FormHeaderTitle";
import { useGetAccountByTypeQuery } from "../../../modules/vendor/api/endpoints/vendorPaymentEndpoints";
import AccountLastBalanceInput from "../../../modules/vendor/components/AccountLastBalanceInput";
import {
  DateInput,
  FormInputItem,
  NumberInput,
  TextAreaInput,
} from "../FormItem/FormItems";
import SelectPaymentMethod from "../paymentMethod/SelectPaymentMethod";
type Props = {
  netTotal: number;
  form: FormInstance<any>;
  serviceChargeFieldName?: NamePath;
};

const InvoiceMoneyReceipt = ({
  netTotal,
  form,
  serviceChargeFieldName,
}: Props) => {
  const [payMethodCatId, setPayMethodCatId] = useState<number | undefined>(1);
  const { data: accounts } = useGetAccountByTypeQuery(Number(payMethodCatId));

  const serviceCharge = useWatch(serviceChargeFieldName || "____", form);
  const receipt_total_amount = useWatch(["receipt_total_amount"], form);
  const account_id = useWatch(["account_id"], form);
  const receipt_note = useWatch(["receipt_note"], form);
  const receipt_payment_type = useWatch(["receipt_payment_type"], form);
  const trans_no = useWatch(["trans_no"], form);
  const receipt_payment_date = useWatch(["receipt_payment_date"], form);
  const cheque_bank_name = useWatch(["cheque_bank_name"], form);
  const receipt_money_receipt_no = useWatch(["receipt_money_receipt_no"], form);

  const setIsRequired =
    receipt_total_amount ||
    receipt_total_amount === 0 ||
    account_id ||
    receipt_note ||
    receipt_payment_type ||
    trans_no ||
    cheque_bank_name ||
    receipt_money_receipt_no ||
    receipt_payment_date;

  useEffect(() => {
    if (
      Number(receipt_total_amount || 0) >
      netTotal + Number(serviceCharge || 0)
    ) {
      form.setFieldValue(
        "receipt_total_amount",
        netTotal + Number(serviceCharge || 0)
      );
    }
  }, [receipt_total_amount, serviceCharge]);

  useEffect(() => {
    form.resetFields(["account_id", "available_balance"]);
  }, [payMethodCatId]);

  return (
    <div className="border p-5 my-20">
      <FormHeaderTitle title="Money Receipt 🧾" />
      <Row gutter={[10, 0]}>
        <SelectPaymentMethod
          size={4}
          mdSize={8}
          smSize={12}
          xxl={3}
          required={setIsRequired}
          name="receipt_payment_type"
          label="Payment Method "
          onChange={(value: number) => {
            setPayMethodCatId(value);
          }}
        />
        {Number(payMethodCatId) !== 4 && (
          <AccountLastBalanceInput
            size={4}
            mdSize={8}
            smSize={12}
            xxl={3}
            required={setIsRequired}
            accountsLastBalance={accounts ? accounts.data : []}
            name="account_id"
            label="Account:"
          />
        )}
        {Number(payMethodCatId) === 3 && (
          <FormInputItem
            label="Trans No:"
            size={4}
            name="trans_no"
            required={setIsRequired}
          />
        )}
        {Number(payMethodCatId) === 4 && (
          <>
            <FormInputItem
              size={4}
              label="Cheque No"
              name="cheque_number"
              required
            />

            <DateInput
              name="cheque_withdraw_date"
              label="Withdraw Date"
              required
              size={4}
            />

            <FormInputItem
              name={"cheque_bank_name"}
              label="Bank Name"
              required
              size={4}
            />
          </>
        )}
        <NumberInput
          label="Amount:"
          size={4}
          xxl={3}
          name={"receipt_total_amount"}
          required={setIsRequired}
        />

        <NumberInput
          size={3}
          label="Discount:"
          name={"receipt_total_discount"}
          min="0"
          maxChar={12}
          minChar={0}
        />

        <DateInput
          xxl={3}
          size={4}
          required={setIsRequired}
          label="Payment Date:"
          name={"receipt_payment_date"}
        />
        <FormInputItem
          size={4}
          xxl={3}
          label="Reciept no"
          name={"receipt_money_receipt_no"}
          min="0"
          maxChar={14}
        />
        <TextAreaInput
          size={Number(payMethodCatId) === 3 ? 3 : 6}
          label="Note"
          name="receipt_note"
          autoSize={true}
        />
      </Row>
    </div>
  );
};

export default InvoiceMoneyReceipt;
