import { Card, Col, Divider, Form, message, Row, UploadFile } from "antd";
import { useEffect, useState } from "react";
import { storageBaseUrl } from "../../../../common/utils/common.utils";
import {
  FormButton,
  FormInputItem,
  MobileInput,
} from "../../../../components/common/FormItem/FormItems";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import {
  useGetProfileDetailsQuery,
  userProfileDetails,
  useUpdateProfileDetailsMutation,
  useUpdateProfileLogoMutation,
} from "../api/ProfileAPIEndpoints";
import { ImageUpload } from "../components/ImageUpload";

type Props = {};

const Profile = (props: Props) => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { data, isLoading, isFetching, isSuccess } =
    useGetProfileDetailsQuery();

  const [updateProfile, { isSuccess: update_success, isError }] =
    useUpdateProfileDetailsMutation();

  const [updateLogo, { isSuccess: logo_success, isError: logo_error }] =
    useUpdateProfileLogoMutation();

  const onFinish = (values: ISubmit) => {
    const { logo, ...others } = values;
    const body: userProfileDetails = {
      ...others,
    };
    updateProfile({ body: body });

    const formData = new FormData();
    if (logo) {
      formData.append("scan_copy_0", fileList[0].originFileObj!);
      updateLogo({ body: formData, id: data?.data?.org_id! });
    }
  };

  useEffect(() => {
    if (isSuccess && data) {
      form.setFieldsValue({ ...data.data });
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: storageBaseUrl + data.data?.org_logo,
        },
      ]);
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (update_success) {
      message.success("Update successfully done");
    } else if (isError) {
      message.error("Information update failed");
    }
  }, [update_success, isError]);

  useEffect(() => {
    if (logo_success) {
      message.success("Logo changed successfully done");
    } else if (logo_error) {
      message.error("Logo change failed");
    }
  }, [logo_success, logo_error]);

  return (
    <Card title="Profile Setting">
      {(isLoading || isFetching) && <LoadingIndicator />}
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={() => message.error("Please fill all required fields")}
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        labelAlign="right"
      >
        <Row justify={"center"} gutter={[20, 20]}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Divider> Change Logo</Divider>

            <Row
              justify={"center"}
              style={{ borderRight: "1px solid lightgray", height: "90%" }}
            >
              <ImageUpload
                name="logo"
                label=""
                setFileList={setFileList}
                fileList={fileList}
              />
            </Row>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Divider> Organization Information</Divider>
            <FormInputItem
              label="Agency Name"
              name="org_name"
              size={24}
              required
            />

            <FormInputItem
              label="Email Address"
              name="org_owner_email"
              size={24}
              readOnly
            />

            <MobileInput
              label="Mobile No"
              name="org_mobile_number"
              size={24}
              required
            />

            <FormInputItem
              label="Full Name"
              name="org_owner_full_name"
              size={24}
              required
            />

            <FormInputItem
              label="Address 1"
              name="org_address1"
              size={24}
              required
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={8}>
            <Divider> Extra Information</Divider>

            <FormInputItem label="Address 2" name="org_address2" size={24} />

            <FormInputItem label="Extra Info" name="org_extra_info" size={24} />

            <FormInputItem label="Facebook" name="org_facebook" size={24} />

            <FormInputItem label="Website" name="org_website" size={24} />

            <FormButton label="Update" />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Profile;

interface ISubmit {
  logo: string;
  org_name: string;
  org_owner_email: string;
  org_mobile_number: string;
  org_owner_full_name: string;
  org_address1: string;
  org_address2: string;
  org_extra_info: string;
  org_facebook: string;
  org_website: string;
}
