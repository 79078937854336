import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Row,
  Table,
  Typography,
} from 'antd';
import 'dayjs/locale/en';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  formatAmountAbs,
  formatNumber,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectEmployee } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { landscapePageStyle } from '../../ledgers/pages/ClientLedgers';
import { useEmployeeDueAdvanceQuery } from '../endpoints/total_due_advance_EmployeeEndpoints';
import { ITotal_Due_Advance_FormData } from '../types/Total_Due_Advance_EmployeeTypes';
import { Total_Due_Advance_EmployeeUtils } from '../utils/Total_Due_Advance_EmployeeUtils';
type Props = {};

function Total_Due_Advance_Employee({ }: Props) {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();

  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: 'all',
    query: '',
  });

  // Pagination Logic
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [employee, setEmployee] = useState<string>('all');

  useEffect(() => {
    form.setFieldValue('employee_id', 'all');
    setExcelQuery({
      Id: 'all',
      query: `&page=${pagination.current}&size=${pagination.pageSize}`,
    });
  }, []);

  const { data, isLoading } = useEmployeeDueAdvanceQuery({
    ...pagination,
    employee_id: employee,
  });

  const onFinish = (values: ITotal_Due_Advance_FormData) => {
    setEmployee(String(values.employee_id) || 'all');
    const body: ITotal_Due_Advance_FormData = {
      employee_id: values.employee_id,
    };

    setExcelQuery({
      Id: body.employee_id.toString(),
      query: `?page=${pagination.current}&size=${pagination.pageSize}`,
    });
  };

  const componentRef: any = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Due_Advance_Report_Employee`,
    pageStyle: landscapePageStyle,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <div className='page-number'>
      <FormHeaderTitle title='Total Due/Advance (employee)' />
      <ConfigProvider>
        <Typography style={{ marginBottom: 15 }}>
          You have total: {data?.count} Results
        </Typography>

        <Table
          size='small'
          bordered
          dataSource={data?.data}
          columns={Total_Due_Advance_EmployeeUtils(pagination)}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
          scroll={{ x: true }}
          pagination={
            data?.count !== undefined && data?.count < 20
              ? false
              : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['20', '50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
          }
          summary={(tableData) => {
            let totalAdvance = 0;
            let totalDue = 0;

            tableData.map(({ client_lbalance }) => {
              const balance = Number(client_lbalance);
              if (balance > 0) {
                totalAdvance += balance;
              } else if (balance < 0) {
                totalDue += balance;
              }
            });
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={4}>
                  <Typography.Title
                    level={5}
                    style={{
                      display: 'block',
                      textAlign: 'right',
                      marginRight: '10px',
                    }}
                  >
                    Total Due Advanced
                  </Typography.Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  <Typography.Title level={5} className='align-right'>
                    <div className='color-due'>{formatAmountAbs(totalDue)}</div>
                  </Typography.Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2}>
                  <Typography.Title level={5} className=' align-right'>
                    <span className='color-profit'>
                      {formatNumber(totalAdvance)}
                    </span>
                  </Typography.Title>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                  {
                    <Typography.Title
                      level={5}
                      style={{
                        color: totalAdvance + totalDue > 0 ? 'green' : 'red',
                        textAlign: 'right',
                      }}
                    >
                      {formatAmountAbs(totalAdvance + totalDue)}
                    </Typography.Title>
                  }
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </ConfigProvider>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Total Due/Advance (employee)' }}
      />
    </div>
  );

  return (
    <div>
      <BreadCrumb arrOfOption={['Report', 'Total Due/Advance (employee)']} />

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify={'space-between'} align={'middle'}>
          <Col span={12}>
            <Row gutter={10}>
              <SelectEmployee
                required
                name='employee_id'
                mdSize={16}
                smSize={16}
                xsSize={24}
                label='Select Employee'
                placeholder='Select Employee'
                offDropDown
                showAll
              />

              <FormButton label='Search' icon textAlign='left' />
            </Row>
          </Col>
          <Col span={12}>
            <Row justify={'end'} gutter={10}>
              <Col>
                <Button
                  type='primary'
                  onClick={handlePrint}
                  loading={isLoading}
                >
                  <PrinterOutlined />
                  Print
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  onClick={() => {
                    if (data?.count)
                      fetchExcel({
                        client_id: excelQuery.Id,
                        query: excelQuery.query,
                        excelApiName: 'totalDueAdvance/employee',
                        excelName: 'total_due_advance_Employee',
                      });
                  }}
                >
                  <FileTextOutlined />
                  Excel Report
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      {/* //-----------//----------// @LEDGER TABLE HERE //--------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </div>
  );
}

export default Total_Due_Advance_Employee;