import { Card, Col, Skeleton } from "antd";
import React from "react";

const VendorDetailsSkeleton = () => {
  return (
    <>
      <Col xl={8} md={12} sm={12} xs={24}>
        <Card style={{ height: 268 }}>
          <Skeleton title={false} active paragraph={{ rows: 5 }} />
        </Card>
      </Col>
      <Col xl={16} md={12} sm={12} xs={24}>
        <Card style={{ height: 268 }}>
          <Skeleton title={false} active paragraph={{ rows: 5 }} />
        </Card>
      </Col>
    </>
  );
};

export default VendorDetailsSkeleton;
