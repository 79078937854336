import { PrinterOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Table,
  Typography,
  theme,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { InvoiceFooter } from "../../../../common/Invoice/InvoiceFooter";
import { Fixed2, pagination } from "../../../../common/utils/common.utils";
import {
  a4sizeStyle,
  invoiceViewStyle,
} from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice";
import NumToWord from "../../../../components/number_to_words/NumToWord";
import { invoiceViewTitleStyle } from "../../../Money_Receipt/Components/MoneyReceiptDesign";
import ViewRefundHeader from "../../Components/ViewRefundHeader";
import { IAirtTicketRefundSingleDataType } from "../../RefundTypes/RefundTypes";
import { AirTicketRefundClientSingleViewUtils } from "../../Utils/AirTicket Utils/AirTicketRefundSingleUtils/AirTicketRefundClientSingleViewUtils";
import { AirTicketRefundVendorSingleViewUtils } from "../../Utils/AirTicket Utils/AirTicketRefundSingleUtils/AirTicketRefundVendorSingleViewUtils";
type Props = { data?: IAirtTicketRefundSingleDataType };

const AirTicketRefundCopy = ({ data }: Props) => {
  const componentRefClient = useRef(null);
  const refs: React.MutableRefObject<HTMLDivElement[]> = useRef([]);
  const [index, setIndex] = useState<number>(0);

  const [date, setDate] = useState<string>();

  let client_total_refund_price = 0;
  let client_total_refund_charge = 0;
  let vendor_total_refund_price = 0;
  let vendor_total_refund_charge = 0;

  data &&
    data.refund_info.map((item: any) => {
      client_total_refund_price =
        client_total_refund_price + Number(item.crefund_total_amount);

      client_total_refund_charge =
        client_total_refund_charge +
        Number(item.rfitem_ticket_client_refund_charge);

      vendor_total_refund_price += Number(item.vrefund_total_amount);

      vendor_total_refund_charge =
        vendor_total_refund_charge + Number(item.vrefund_charge_amount);
    });

  useEffect(() => {
    if (data) {
      setDate(dayjs(data.atrefund_date).format("DD MMM YYYY"));
    }
  }, [data]);

  const handleClientPrint = useReactToPrint({
    content: () => componentRefClient.current,
    documentTitle: `Refund${date}_$}`,
    pageStyle:
      "@page { size: a4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }",
  });
  const handleVendorPrint = useReactToPrint({
    content: () => refs.current[index],
    documentTitle: `Refund${date}_$}`,
    pageStyle:
      "@page { size: a4; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 10px !important; } }",
  });

  return (
    <div>
      {/* CLIENT COPY */}

      <div style={invoiceViewStyle}>
        <div>
          <div style={flexBetween}>
            <h2 style={{ color: "#18b4e9" }}>CLIENT COPY</h2>

            <Button type="primary" onClick={handleClientPrint}>
              <PrinterOutlined />
              Print
            </Button>
          </div>

          <ConfigProvider
            theme={{
              algorithm: theme.defaultAlgorithm,
            }}
          >
            <Card style={a4sizeStyle}>
              <div ref={componentRefClient}>
                <ViewRefundHeader refund={data} />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Title level={5} style={invoiceViewTitleStyle}>
                    Refund Voucher
                  </Typography.Title>
                  Client Copy
                </div>

                <Row justify={"space-between"} align="middle">
                  <Col
                    style={{
                      color: "#fff",
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    <Typography.Text className="changeFontBlock">
                      <b>Client Name :</b> {data?.client_name}
                    </Typography.Text>

                    <Typography.Text className="changeFontBlock">
                      <b>Mobile No :</b> {data?.client_mobile || ""}
                    </Typography.Text>
                  </Col>

                  <Col
                    style={{
                      color: "#fff",
                      fontFamily: "'Source Sans Pro', sans-serif",
                    }}
                  >
                    <Typography.Title
                      style={{ fontFamily: "'Source Sans Pro', sans-serif" }}
                      level={5}
                    >
                      Refund Information:
                    </Typography.Title>

                    <Typography.Text className="changeFontBlock">
                      <b>Voucher No :</b> {data?.atrefund_vouchar_number}
                    </Typography.Text>

                    <Typography.Text className="changeFontBlock">
                      <b>Date :</b>{" "}
                      {dayjs(data?.atrefund_date).format("DD MMM YYYY")}
                    </Typography.Text>
                  </Col>
                </Row>

                <Table
                  size="small"
                  className="invoiceBillingTable"
                  rowClassName={"invoiceBillingTd"}
                  bordered
                  columns={AirTicketRefundClientSingleViewUtils()}
                  dataSource={data ? [data] : []}
                  style={{ marginTop: "10%" }}
                  pagination={false}
                  rowKey={(record: any) => record.rfitem_ticket_no}
                  summary={() => {
                    return (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0}>
                            <Typography style={{ fontWeight: 500 }}>
                              Total Refund Amount
                            </Typography>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}></Table.Summary.Cell>
                          <Table.Summary.Cell index={2}></Table.Summary.Cell>
                          <Table.Summary.Cell index={3}></Table.Summary.Cell>
                          <Table.Summary.Cell index={4}>
                            <Typography style={{ fontWeight: 500 }}>
                              {Number(data?.crefund_return_amount) || 0}
                            </Typography>
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    );
                  }}
                />

                <div
                  style={{
                    margin: "30px 0",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography.Text strong>
                    <NumToWord number={Fixed2(data?.crefund_return_amount)} />
                  </Typography.Text>
                  <Typography.Text strong>Note : </Typography.Text>
                </div>

                <InvoiceFooter />
              </div>
            </Card>
          </ConfigProvider>
        </div>
      </div>

      {/* VENDOR COPY */}
      {data &&
        data.refund_info.map((item: any, index: number) => {
          let vrefund_amount =
            Number(item.vrefund_total_amount) -
            Number(item.vrefund_charge_amount);

          return (
            <div style={invoiceViewStyle}>
              <div>
                <div style={flexBetween}>
                  <h2 style={{ color: "#18b4e9" }}>VENDOR COPY</h2>
                  <Button
                    type="primary"
                    onClick={() => {
                      setIndex(index);
                      handleVendorPrint();
                    }}
                  >
                    <PrinterOutlined />
                    Print
                  </Button>
                </div>

                <ConfigProvider
                  key={index}
                  theme={{
                    algorithm: theme.defaultAlgorithm,
                  }}
                >
                  <Card style={a4sizeStyle} key={index}>
                    <div
                      ref={(e: HTMLDivElement) => {
                        refs.current[index] = e;
                      }}
                    >
                      <ViewRefundHeader refund={data} />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyItems: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography.Title
                          level={5}
                          style={invoiceViewTitleStyle}
                        >
                          Refund Voucher
                        </Typography.Title>
                        Vendor Copy
                      </div>

                      <Row justify={"space-between"} align="middle">
                        <Col
                          style={{
                            color: "#fff",
                            fontFamily: "'Source Sans Pro', sans-serif",
                          }}
                        >
                          <Typography.Text
                            style={{
                              display: "block",
                              fontSize: "13px",
                              fontFamily: "'Source Sans Pro', sans-serif",
                            }}
                          >
                            <b>Vendor Name :</b> {item.vendor_name}
                          </Typography.Text>
                        </Col>

                        <Col
                          style={{
                            color: "#fff",
                            fontFamily: "'Source Sans Pro', sans-serif",
                          }}
                        >
                          <Typography.Title
                            style={{
                              fontFamily: "'Source Sans Pro', sans-serif",
                            }}
                            level={5}
                          >
                            Refund Information:
                          </Typography.Title>

                          <Typography.Text
                            style={{
                              display: "block",
                              fontSize: "13px",
                              fontFamily: "'Source Sans Pro', sans-serif",
                            }}
                          >
                            <b>Voucher No :</b> {data?.atrefund_vouchar_number}
                          </Typography.Text>

                          <Typography.Text
                            style={{
                              display: "block",
                              fontSize: "13px",
                              fontFamily: "'Source Sans Pro', sans-serif",
                            }}
                          >
                            <b>Date :</b> {date}
                          </Typography.Text>
                        </Col>
                      </Row>

                      <Table
                        size="small"
                        className="invoiceBillingTable"
                        rowClassName={"invoiceBillingTd"}
                        bordered
                        columns={AirTicketRefundVendorSingleViewUtils()}
                        dataSource={data && [data.refund_info[index]]}
                        style={{ marginTop: "10%" }}
                        pagination={pagination(
                          data && [data.refund_info[index]].length
                        )}
                        rowKey={(record) => record.rfitem_ticket_no}
                        summary={(record) => {
                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={5}>
                                  <Typography
                                    style={{
                                      fontWeight: 500,
                                    }}
                                  >
                                    Total Refund Amount
                                  </Typography>
                                </Table.Summary.Cell>
                                {/* <Table.Summary.Cell
                                    index={1}
                                  ></Table.Summary.Cell>
                                  <Table.Summary.Cell
                                    index={2}
                                  ></Table.Summary.Cell>
                                  <Table.Summary.Cell
                                    index={3}
                                  ></Table.Summary.Cell> */}
                                <Table.Summary.Cell index={4}>
                                  <Typography
                                    style={{
                                      fontWeight: 500,
                                    }}
                                  >
                                    {Number(record[0].vrefund_total_amount) -
                                      Number(record[0].vrefund_charge_amount)}
                                  </Typography>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          );
                        }}
                      />

                      <div
                        style={{
                          margin: "30px 0",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography.Text strong>
                          <NumToWord number={Fixed2(vrefund_amount)} />
                        </Typography.Text>
                        <Typography.Text strong>Note : </Typography.Text>
                      </div>

                      <InvoiceFooter />
                    </div>
                  </Card>
                </ConfigProvider>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default AirTicketRefundCopy;

export const flexBetween: React.CSSProperties = {
  width: "8.27in",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "white",
  padding: 5,
  borderRadius: 10,
};
