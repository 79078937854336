import dayjs from "dayjs";
import { useLazyGetAllRefundReportVendorQuery } from "../endpoints/refundReportEndpoints";
import { IRefundReportFormDataType } from "../types/refundReportTypes";
import {
  Button,
  Card,
  Form,
  Radio,
  RadioChangeEvent,
  Space,
  Table,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import { RefundReportColumnVendor } from "../utils/RefundReportColumn";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { useLazyGetExcelQuery } from "../../ledgers/endpoints/ledgerEndpoints";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import { Fixed2 } from "../../../../common/utils/common.utils";
const RefundRefund = ({
  setValue,
  value,
}: {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  value: number;
}) => {
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState("");
  //pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const [form] = Form.useForm();
  //calculatte the total Amount
  let vtotalRefund: number = 0;
  let vtotalCharge: number = 0;
  let vtotalReturn: number = 0;
  const [fetchRefundReportVendor, { data: vendor_data, isLoading }] =
    useLazyGetAllRefundReportVendorQuery();

  if (vendor_data?.data) {
    for (let i = 0; i < vendor_data?.data.length; i++) {
      vtotalRefund += Number(vendor_data?.data[i].vrefund_amount);
      vtotalCharge += Number(vendor_data?.data[i].vcharge_amount);
      vtotalReturn += Number(vendor_data?.data[i].vrefund_return_amount);
    }
  }

  const onFinish = async (values: IRefundReportFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      currentSize: pagination.pageSize,
      currentPage: pagination.current,
    };

    await fetchRefundReportVendor({
      date_range: body.date_range,
      currentPage: body.currentPage,
      currentSize: body.currentSize,
    });
    setExcelQuery(
      `?${body.date_range}&page=${body.currentPage}&size=${body.currentSize}`
    );
  };

  useEffect(() => {
    form.setFieldValue("date_range", [dayjs(), dayjs()]);
    const date_range = `from_date=${dayjs().format(
      "YYYY-MM-DD"
    )}&to_date=${dayjs().format("YYYY-MM-DD")}`;
    const body = {
      date_range: date_range,
      currentSize: pagination.pageSize,
      currentPage: pagination.current,
    };

    setExcelQuery(
      `?${body.date_range}&page=${body.currentPage}&size=${body.currentSize}`
    );

    fetchRefundReportVendor({
      date_range: body.date_range,
      currentPage: body.currentPage,
      currentSize: body.currentSize,
    });
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Refund Report`,
  });
  //Excel sheet Data
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const optionsSummaryReport = [
    { label: "Client Report", value: 1 },
    { label: "Vendor Report", value: 2 },
  ];

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <>
        <Card>
          <Table
            size="small"
            bordered
            dataSource={vendor_data?.data}
            columns={RefundReportColumnVendor()}
            loading={{
              spinning: isLoading,
              indicator: loadingIndicator,
            }}
            pagination={
              vendor_data?.count && vendor_data?.count > 20
                ? {
                    ...pagination,
                    total: vendor_data?.count,
                    showSizeChanger: true,
                    pageSizeOptions: ["50", "100", "200", "500"],
                    onChange: handlePaginationChange,
                  }
                : false
            }
            summary={(_) => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={1} colSpan={5}>
                      <div
                        style={{
                          fontWeight: "700",
                          textAlign: "right",
                        }}
                      >
                        <Typography.Text strong>Total</Typography.Text>
                      </div>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={2}>
                      <div
                        style={{
                          fontWeight: "700",
                          textAlign: "right",
                        }}
                      >
                        <Typography.Text strong>
                          {Fixed2(vtotalRefund).toLocaleString()}
                        </Typography.Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <div
                        style={{
                          fontWeight: "700",
                          textAlign: "right",
                        }}
                      >
                        <Typography.Text strong>
                          {Fixed2(vtotalCharge).toLocaleString()}
                        </Typography.Text>
                      </div>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <div
                        style={{
                          fontWeight: "700",
                          textAlign: "right",
                        }}
                      >
                        <Typography.Text strong>
                          {Fixed2(vtotalReturn).toLocaleString()}
                        </Typography.Text>
                      </div>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Card>
      </>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Refund Report" }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={["Reports", "Refund Report"]} />

      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              client_id: "",
              query: excelQuery,
              excelApiName: `refundReportVendor`,
              excelName: "refundReportVendor",
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
        <Radio.Group
          options={optionsSummaryReport}
          onChange={onChange}
          value={value}
          optionType="button"
          buttonStyle="solid"
        />
      </Space>

      <br />

      <Form layout="vertical" form={form} onFinish={onFinish}>
        <ReportsHeader
          date_range
          required
          reports_title="Vendor Refund Report"
        />
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default RefundRefund;
