import { Button, Form, Input } from "antd";
import { useAppDispatch } from "../../../../app/hooks";
import TextArea from "antd/es/input/TextArea";
import { DateInput } from "../../../../components/common/FormItem/FormItems";
import dayjs from "dayjs";
import { useCreateSendGiftMutation } from "../Endpoints/contactsEndpoints";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import { ISendGiftFormData } from "../types/contactTypes";
import { setCommonModal } from "../../../../common/slices/modalSlice";

const SendGiftModal = ({ id }: { id: number }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [createSendGift, { isLoading }] = useCreateSendGiftMutation();

  const handleSubmit = async (values: ISendGiftFormData) => {
    const body = {
      ag_ac_id: id,
      ...values,
      ag_date: dayjs(values.ag_date).format("YYYY-MM-DD"),
    };
    await createSendGift(body).then((res: any) => {
      if (res.data.success) {
        form.resetFields();
        dispatch(setCommonModal());
      }
    });
  };

  return (
    <>
      <Form
        layout="horizontal"
        form={form}
        labelAlign="left"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmit}
        style={{ paddingTop: "20px" }}
        initialValues={{ ag_date: dayjs() }}
      >
        <DateInput label="Date" name="ag_date" required size={24} />
        <Form.Item
          label="Gift Name"
          name="ag_items"
          rules={[{ required: true, message: "Please enter gift name!" }]}
        >
          <Input placeholder="Enter gift name" />
        </Form.Item>
        <Form.Item label="Remark" name="ag_remark">
          <TextArea placeholder="Enter remark" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Send
          </Button>
        </Form.Item>
        {isLoading && <LoadingIndicator />}
      </Form>
    </>
  );
};

export default SendGiftModal;
