import { PrinterOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Row, Table } from 'antd';
import Space from 'antd/lib/space';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { SelectUsers } from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../components/common/spinner/LoadingIndicator';
import { useGetActivityLogsQuery } from '../endpoints/activityLogsEndPoints';
import ActivityClientColumn from '../utils/ActivityClientColumn';
import { rangePresets } from '../../../../common/utils/common.utils';

const ActivityLogs = () => {
  const [date, setDate] = useState([
    dayjs().format('YYYY-MM-DD'),
    dayjs().format('YYYY-MM-DD'),
  ]);
  const [userID, setUserID] = useState('all');
  const [form] = Form.useForm();

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('user_id', 'all');
  }, []);

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Client_Ledger_${date[0]}`,
    pageStyle:
      '@page { size: a4 landscape; margin: 0mm;  } @media print { body { -webkit-print-color-adjust: exact; } }',
  });

  const { data, isLoading, isFetching } = useGetActivityLogsQuery({
    ...pagination,
    from_date: date[0],
    to_date: date[1],
    id: userID,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [userID, date]);

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate && setDate(dateString);
  };

  // @HANDLE CONTENTS
  const jsx_com = (
    <Table
      size='small'
      bordered
      pagination={
        data?.count !== undefined && data?.count < 20
          ? false
          : {
              ...pagination,
              total: data?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
      }
      columns={ActivityClientColumn(pagination)}
      dataSource={data?.data}
      loading={{ spinning: isLoading, indicator: loadingIndicator }}
    />
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'User Activity Logs' }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'User audit trails']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>

      {(isFetching || isLoading) && <LoadingIndicator />}

      <Form layout='vertical' form={form}>
        <Row gutter={16}>
          <SelectUsers
            required
            name='user_id'
            size={6}
            label='Select User'
            placeholder='Select User'
            onSelect={(e) => setUserID(e)}
            showAll
          />
          <Col span={6} xs={24} sm={24} md={24} lg={5}>
            <Form.Item
              label='Date Range'
              name='date_range'
              rules={[{ required: true, message: ` Date Range is required!` }]}
            >
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                allowClear={false}
                onChange={handleDateRangeChange}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {jsx_com}

      {print_content}
    </>
  );
};

export default ActivityLogs;
