import { Button, Col, Form, FormListFieldData, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { ICostBillingInfo } from "../../../../modules/Invoice_Hajj_Pre_Reg/Types/InvoiceHajjiPre.Types";
import { useWatch } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import {
  SelectProduct,
  SelectVendors,
} from "../../FormItem/SelectCustomFeilds";
import { FormInputItem, NumberInput } from "../../FormItem/FormItems";
import BillingIsDeleted from "../../../../modules/Invoice(Visa)/Components/BillingIsDeleted";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import { Fixed2 } from "../../../../common/utils/common.utils";
import { useGetAllAgentProfileQuery } from "../../../../modules/Client/Agents_Profile/api/endpoints/agentProfileEndPoints";
import { IBilling_information } from "../../../../modules/InvoiceUmrah/Types/InvoiceUmrahTypes";

type Props = {
  field: FormListFieldData;
  form: FormInstance<any>;
  billInitialValues?: ICostBillingInfo[];
  billing_information?: IBilling_information[];
  setProductId: number | undefined;
  add: (defaultValue?: any, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  index: number;
  conditionalCostVendor?: boolean;
  disableRemoveButtonIndex?: number;
};

const BillingInfoForm = ({
  field,
  form,
  billing_information,
  setProductId,
  add,
  remove,
  index,
  conditionalCostVendor,
  disableRemoveButtonIndex,
}: Props) => {
  const [disabled, setDisabled] = useState(false);
  const { id } = useParams();
  const unitPrice = useWatch(
    ["billing_information", field.name, "billing_unit_price"],
    form
  );

  const qty = useWatch(
    ["billing_information", field.name, "billing_quantity"],
    form
  );
  const costPrice = useWatch(
    ["billing_information", field.name, "billing_cost_price"],
    form
  );
  const is_deleted: 0 | 1 = useWatch(
    ["billing_information", index, "is_deleted"],
    form
  );

  useEffect(() => {
    form.setFields([
      {
        name: ["billing_information", field.name, "billing_subtotal"],
        value: Number(unitPrice || 0) * Number(qty || 0),
      },
      {
        name: ["billing_information", field.name, "billing_profit"],
        value:
          (Number(unitPrice || 0) - Number(costPrice || 0)) * Number(qty || 0),
      },
      {
        name: ["billing_information", field.name, "billing_total_cost_price"],
        value: Number(costPrice || 0) * Number(qty || 0),
      },
    ]);
  }, [unitPrice, qty, costPrice]);

  //set vendor field name when create
  const [vendorSelectsInstanct, setInstanceVendorSelect] = useState<number>();
  useEffect(() => {
    if (vendorSelectsInstanct) {
      form.setFieldValue(
        ["billing_information", field.name, "billing_comvendor"],
        "vendor-" + vendorSelectsInstanct
      );
    }
  }, [vendorSelectsInstanct]);

  //----------- calculate agent commission-----------------------------
  const { data } = useGetAllAgentProfileQuery();
  const invoice_agent_id: number | undefined = useWatch(
    "invoice_agent_id",
    form
  );
  const getAllAgentProfile = data?.data;
  const billing_profit = useWatch(["billing_information"], form);
  const sumTotalProfit = billing_profit?.reduce(
    (acc: number, curr: any) => acc + Fixed2(curr?.billing_profit),
    0
  );
  useEffect(() => {
    if (invoice_agent_id) {
      const agentInfo = getAllAgentProfile?.find(
        (item) => item?.agent_id === invoice_agent_id
      );

      form.setFieldValue(
        ["invoice_agent_com_amount"],
        Fixed2(
          (Number(agentInfo?.agent_commission_rate || 0) / 100) *
            Number(sumTotalProfit || 0)
        )
      );
    }
  }, [invoice_agent_id, sumTotalProfit]);

  useEffect(() => {
    if (is_deleted === 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    if (disableRemoveButtonIndex && disableRemoveButtonIndex > index) {
      setDisabled(true);
    }
  }, [is_deleted, disableRemoveButtonIndex]);

  const isVendorSelected = useWatch(
    ["billing_information", field.name, "billing_comvendor"],
    form
  );

  const isCostPrice = useWatch(
    ["billing_information", field.name, "billing_cost_price"],
    form
  );

  return (
    <Row
      style={is_deleted === 1 ? { backgroundColor: "#FFC0CB" } : {}}
      align="bottom"
      gutter={[10, 0]}
    >
      <SelectProduct
        label="Product"
        name={[field.name, "billing_product_id"]}
        size={3}
        placeholder="Select Product"
        required
        disabled={disabled}
      />
      <FormInputItem
        label="Pax Name"
        name={[field.name, "pax_name"]}
        size={3}
        disabled={disabled}
      />
      <FormInputItem
        label="Description"
        name={[field.name, "billing_description"]}
        size={3}
        disabled={disabled}
      />

      <NumberInput
        label="Quantity"
        name={[field.name, "billing_quantity"]}
        size={2}
        required
        min="0"
        maxChar={3}
        disabled={disabled}
      />
      <NumberInput
        label="Unit Price"
        name={[field.name, "billing_unit_price"]}
        size={2}
        required
        min="0"
        disabled={disabled}
      />

      <NumberInput
        required={conditionalCostVendor ? isVendorSelected : true}
        label="Cost Price"
        name={[field.name, "billing_cost_price"]}
        size={2}
        min="0"
        disabled={disabled}
      />

      <NumberInput
        label="Total Sales"
        name={[field.name, "billing_subtotal"]}
        size={2}
        readOnly
        min="0"
        maxChar={14}
        disabled={disabled}
      />
      <NumberInput
        label="Total Cost"
        name={[field.name, "billing_total_cost_price"]}
        size={2}
        min="0"
        maxChar={14}
        disabled={disabled}
      />
      <NumberInput
        label="Profit"
        name={[field.name, "billing_profit"]}
        size={2}
        readOnly
        min="0"
        maxChar={14}
        disabled={disabled}
      />

      <SelectVendors
        required={conditionalCostVendor ? isCostPrice : true}
        label="Vendor"
        name={[field.name, "billing_comvendor"]}
        size={2}
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value: string) {
              if (value && getFieldValue("invoice_combclient_id") === value) {
                return Promise.reject();
              } else {
                return Promise.resolve();
              }
            },
            message: "Client and vendor can't be same!",
          }),
        ]}
        setInstanceVendorSelect={setInstanceVendorSelect}
        disabled={disabled}
      />

      {/* BILLING DELETE DETECT BY THIS is_deleted FIELD */}
      <BillingIsDeleted name={[field.name, "is_deleted"]} />

      {field.name === 0 ? (
        <Col lg={1}>
          <Form.Item label={false}>
            <Button type="primary" onClick={() => add()}>
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Col>
      ) : (
        <Col lg={1}>
          <Form.Item label={false}>
            <Button
              type="primary"
              disabled={
                disableRemoveButtonIndex
                  ? disableRemoveButtonIndex > index
                  : false
              }
              danger
              onClick={() => {
                const prev = form.getFieldValue([
                  "billing_information",
                  field.name,
                  "is_deleted",
                ]);
                form.setFieldValue(
                  ["billing_information", field.name, "is_deleted"],
                  Number(!prev)
                );

                if (id) {
                  if (
                    billing_information?.length &&
                    index >= billing_information.length
                  )
                    remove(field.name);
                } else {
                  remove(field.name);
                }
              }}
            >
              <MinusCircleOutlined />
            </Button>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
};

export default BillingInfoForm;
