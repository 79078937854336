import { Card, Image, Typography } from "antd";
import moment from "moment";
import { useAppSelector } from "../../../app/hooks";
import { ReceiptHeader } from "../../../common/Invoice/InvoiceHeader";
import { NumToWord_raw } from "../../../components/number_to_words/NumToWord";
import { IMoneyReceiptSingleDataType } from "../Types/MoneyReceiptTypes";
import Style from "../style/Receipt.module.css";
import { Fixed2 } from "../../../common/utils/common.utils";

type Props = {
  moneyReceipt: IMoneyReceiptSingleDataType;
  type: string;
};

const MoneyRec_card = ({ moneyReceipt, type }: Props) => {
  const {
    acctype_name,
    mobile_number,
    client_name,
    receipt_money_receipt_no,
    receipt_note,
    receipt_payment_date,
    receipt_vouchar_no,
    receipt_total_amount,
    receipt_total_discount,
    bank_name,
    account_name,
    cheque_number,
    client_last_balance,
    receipt_payment_to,
    invoice_no,
    agent_name,
    receipt_walking_customer_name,
    authority,
  } = moneyReceipt;

  const balance =
    Number(client_last_balance) < 0
      ? `Due ${Fixed2(Math.abs(Number(client_last_balance)))}`
      : `Advance ${Fixed2(client_last_balance)}`;

  const { Title } = Typography;

  const currencyName = useAppSelector(
    (state) => state.user?.organization_info?.org_currency
  );

  const appConfig = useAppSelector((state) => state.configSlice);

  const qrCodeValue = `
Name:${client_name}${
    receipt_walking_customer_name && "/" + receipt_walking_customer_name
  }
Mobile:${mobile_number}
Amount:${receipt_total_amount}
  `;

  return (
    <Card className={Style.card}>
      {/* header */}

      <div style={{ marginTop: "-15px" }}>
        <ReceiptHeader qrCodeValue={qrCodeValue} />
      </div>

      <Title style={{ textAlign: "center" }} level={5}>
        MONEY RECEIPT
      </Title>

      <p style={{ textAlign: "center", marginTop: "-10px" }}>{type}</p>

      {appConfig.tac_signtr === 1 && (
        <div
          style={{
            position: "absolute",
            bottom: 60,
            right: 60,
            pointerEvents: "none",
            userSelect: "none",
          }}
        >
          <Image preview={false} src={authority?.sig_signature} width={90} />
        </div>
      )}

      <div
        style={type === "" ? { marginTop: "45px" } : {}}
        className={Style.info1}
      >
        {receipt_vouchar_no && (
          <div className={Style.disFlex}>
            <p style={{ marginRight: 5 }}>Receipt No:</p>
            <span className={Style.spanText}>{receipt_vouchar_no}</span>
          </div>
        )}

        {receipt_money_receipt_no && (
          <div className={Style.disFlex}>
            <p style={{ marginRight: 5 }}>Receipt No:</p>
            <span className={Style.spanText}>{receipt_money_receipt_no}</span>
          </div>
        )}

        <div className={Style.disFlex}>
          <p style={{ marginRight: 5 }}>Date: </p>
          <span className={Style.spanText2}>
            {moment(receipt_payment_date).format("DD-MMM-YYYY")}
          </span>
        </div>

        {agent_name && (
          <div className={Style.disFlex}>
            <p style={{ marginRight: 5 }}>Agent Name:</p>
            <span className={Style.spanText2}>{agent_name}</span>
          </div>
        )}
      </div>

      <div style={{ gap: 10 }} className={Style.section1}>
        <div className={Style.disFlex} style={{ flexGrow: 2 }}>
          <p>Received with thanks from:</p>
          <span className={Style.spanText} style={{ flexGrow: 1 }}>
            <span style={{ fontWeight: "bold" }} className={Style.ms_1}>
              {client_name}
              {receipt_walking_customer_name &&
                "/" + receipt_walking_customer_name}
            </span>
          </span>
        </div>

        {mobile_number?.length && mobile_number !== "N/A" ? (
          <div className={Style.disFlex} style={{ flexGrow: 1 }}>
            <p>Contact No:</p>
            <span className={Style.spanText} style={{ flexGrow: 1 }}>
              <span className={Style.ms_1}>{mobile_number}</span>
            </span>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className={Style.f_mt2}>
        <p>Amount of TK (In Word):</p>
        <span className={Style.spanText} style={{ flexGrow: 1 }}>
          <span className={Style.ms_1}>
            {receipt_total_amount && (
              <NumToWord_raw number={Number(receipt_total_amount || 0)} />
            )}
          </span>
        </span>
      </div>

      <div style={{ gap: 10 }} className={Style.section1}>
        <div className={Style.disFlex} style={{ flexGrow: 1 }}>
          <p>Payment For:</p>
          <span className={Style.spanText} style={{ flexGrow: 1 }}>
            <span className={Style.ms_1}>
              {receipt_payment_to?.charAt(0) +
                receipt_payment_to?.slice(1).toLowerCase()}{" "}
              {invoice_no ? <span>({invoice_no})</span> : ""}
            </span>
          </span>
        </div>

        <div className={Style.disFlex} style={{ flexGrow: 1 }}>
          <p>Balance:</p>
          <span className={Style.spanText} style={{ flexGrow: 1 }}>
            <span
              style={{
                color: Number(client_last_balance) >= 0 ? "green" : "red",
              }}
              className={Style.ms_1}
            >
              {Number(client_last_balance) != 0 ? balance : 0}{" "}
              {currencyName?.toUpperCase()}
            </span>
          </span>
        </div>
      </div>

      {acctype_name == "Cash" && (
        <div style={{ gap: 10 }} className={Style.section1}>
          <div className={Style.disFlex} style={{ flexGrow: 1 }}>
            <p>Paid Via:</p>
            <span className={Style.spanText} style={{ flexGrow: 1 }}>
              <span className={Style.ms_1}>{acctype_name}</span>
            </span>
          </div>

          <div className={Style.disFlex} style={{ flexGrow: 1 }}>
            <p>Account Name:</p>
            <span className={Style.spanText} style={{ flexGrow: 1 }}>
              <span className={Style.ms_1}>{account_name}</span>
            </span>
          </div>
        </div>
      )}

      {acctype_name == "Bank" && (
        <div style={{ gap: 10 }} className={Style.section1}>
          <div className={Style.disFlex} style={{ flexGrow: 1 }}>
            <p>Paid Via:</p>
            <span className={Style.spanText} style={{ flexGrow: 1 }}>
              <span className={Style.ms_1}>{acctype_name}</span>
            </span>
          </div>

          <div className={Style.disFlex} style={{ flexGrow: 1 }}>
            <p>Account Name:</p>
            <span className={Style.spanText} style={{ flexGrow: 1 }}>
              <span className={Style.ms_1}>{account_name}</span>
            </span>
          </div>
        </div>
      )}

      {acctype_name == "Cheque" && (
        <div
          style={{ display: "flex", justifyContent: "space-between", gap: 10 }}
        >
          <div style={{ flexGrow: 1 }} className={Style.disFlex}>
            <p>Paid Via:</p>
            <span style={{ flexGrow: 1 }} className={Style.spanText}>
              <span className={Style.ms_1}>{acctype_name}</span>
            </span>
          </div>

          <div style={{ flexGrow: 1 }} className={Style.disFlex}>
            <p>Chq No:</p>
            <span style={{ flexGrow: 1 }} className={Style.spanText}>
              <span className={Style.ms_1}>{cheque_number}</span>
            </span>
          </div>

          <div style={{ flexGrow: 1 }} className={Style.disFlex}>
            <p>Bank Name:</p>
            <span style={{ flexGrow: 1 }} className={Style.spanText}>
              <span className={Style.ms_1}>{bank_name}</span>
            </span>
          </div>
        </div>
      )}

      {acctype_name == "Mobile banking" && (
        <div style={{ gap: 10 }} className={Style.section1}>
          <div className={Style.disFlex} style={{ flexGrow: 1 }}>
            <p>Paid Via:</p>
            <span className={Style.spanText} style={{ flexGrow: 1 }}>
              <span className={Style.ms_1}>{acctype_name}</span>
            </span>
          </div>

          <div className={Style.disFlex} style={{ flexGrow: 1 }}>
            <p>Account Name:</p>
            <span className={Style.spanText} style={{ flexGrow: 1 }}>
              <span className={Style.ms_1}>{account_name}</span>
            </span>
          </div>
        </div>
      )}

      <div className={Style.f_mt2} style={{ flexGrow: 1 }}>
        <p>For the purpose of:</p>
        <span className={Style.spanText} style={{ flexGrow: 1 }}>
          <span className={Style.ms_1}>{receipt_note}</span>
        </span>
      </div>

      <div
        style={{
          display: "flex",
          gap: 10,
        }}
      >
        <div>
          <p className={type === "" ? Style.footerAmount : Style.footerAmount}>
            Amount of: {Fixed2(receipt_total_amount || 0).toLocaleString()}{" "}
            {currencyName?.toUpperCase()}
          </p>
        </div>
        {Fixed2(receipt_total_discount) ? (
          <div>
            <p
              className={type === "" ? Style.footerAmount : Style.footerAmount}
            >
              Discount: {Fixed2(receipt_total_discount)}{" "}
              {currencyName?.toUpperCase()}
            </p>
          </div>
        ) : (
          ""
        )}
      </div>

      <div style={{ position: "relative", padding: 10 }}>
        <div className={Style.foo_3}>
          <p className={Style.foo_4}>Customer Signature</p>
          <p style={{ marginRight: 20 }} className={Style.foo_4}>
            Authority Signature
          </p>
        </div>
      </div>
    </Card>
  );
};

export default MoneyRec_card;
