import {
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  message,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../../app/hooks';
import { selectUser } from '../../../../../auth/states/userSlice';
import useGetSearchColumnProps from '../../../../../common/hooks/SearchData';
import { setModal } from '../../../../../common/slices/commonSlice';
import { perProps } from '../../../../../common/types/commonTypes';
import BreadCrumb from '../../../../../components/common/breadCrumb/BreadCrumb';
import CommonTableHeader from '../../../../../components/common/CommonTableHeader';
import PlacesInfo from '../component/PlacesInfo';
import {
  useCreateTourPlaceMutation,
  useDeleteTourPlaceMutation,
  useGetAllPlacesWithPaginationQuery,
} from '../endpoints/PlaceTourEndPoints';
import PlacesTourEditModal from '../modal/PlacesTourEditModal';
import {
  IPlaceCreateDataType,
  IPlaceTourGetDataType,
} from '../types/PlaceTourTypes';
import { FormatDate } from '../../../../../common/utils/common.utils';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../../components/common/spinner/LoadingIndicator';

const Places = ({ permission }: perProps) => {
  const [form] = Form.useForm();
  const [editInfo, setEditInfo] = useState<IPlaceTourGetDataType | null>(null);
  const dispatch = useAppDispatch();
  const searchColumnProps = useGetSearchColumnProps<IPlaceTourGetDataType>();
  const modalOpen = (e: IPlaceTourGetDataType) => {
    setEditInfo(e);
    dispatch(setModal(true));
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  //get all places
  const {
    isLoading,
    data: tourPlace,
    refetch,
  } = useGetAllPlacesWithPaginationQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: IPlaceTourGetDataType[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, count } };
    },
  });

  //create New cities
  const [addTourPlace, { isError, isSuccess, isLoading: createLoading }] =
    useCreateTourPlaceMutation();
  const user = useSelector(selectUser);
  useEffect(() => {
    if (isSuccess) {
      message.success('Tour Place Added Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleSubmit = async (values: IPlaceCreateDataType) => {
    const body = [...values.place_info];
    const newPlace = body?.map((item) => {
      return {
        ...item,
        place_created_by: user?.user_id as number,
        place_status: 1,
      };
    });
    await addTourPlace(newPlace);
    form.resetFields();
  };

  //delete cities
  const [
    deleteTourPlace,
    {
      isError: isDeleteError,
      isSuccess: isDeleteSucc,
      isLoading: deleteLoading,
    },
  ] = useDeleteTourPlaceMutation();
  useEffect(() => {
    if (isDeleteSucc) {
      message.success('Tour Place Deleted Successfully');
    } else if (isDeleteError) {
      message.error('Something went wrong');
    }
  }, [isDeleteSucc, isDeleteError]);
  const handleDelete = async (values: IPlaceTourGetDataType) => {
    await deleteTourPlace({ ...values, place_created_by: user?.user_id });
  };

  const columns: ColumnsType<IPlaceTourGetDataType> = [
    {
      title: 'SL',
      dataIndex: 'key',
      key: 'sl',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Place Name',
      dataIndex: 'place_name',
      key: 'place_name',
      ...searchColumnProps('place_name'),
    },
    {
      title: 'City',
      dataIndex: 'city_name',
      key: 'city_name',
      ...searchColumnProps('city_name'),
    },
    {
      title: 'Country',
      dataIndex: 'country_name',
      key: 'country_name',
      ...searchColumnProps('country_name'),
    },
    {
      title: 'Create date',
      dataIndex: 'place_create_date',
      key: 'place_create_date',
      render: (_, { place_create_date }) => {
        return <> {FormatDate(place_create_date)} </>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'place_status',
      key: 'place_status',
      align: 'center',
      render: (_, { place_status }) => (
        <Tag color={place_status === 0 ? 'red' : 'green'} key={place_status}>
          {place_status === 0 ? 'Inactive' : 'Active'}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      render: (_, record) => (
        <Space size='small'>
          {permission?.['update:any'] && record.agency_id && (
            <Button
              size='small'
              type='primary'
              onClick={() => modalOpen(record)}
            >
              Edit
            </Button>
          )}

          {permission?.['delete:any'] && record.agency_id && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record)}
            >
              <Button
                size='small'
                type='primary'
                danger
                loading={deleteLoading}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <BreadCrumb
        arrOfOption={['Configuration', 'Places']}
        refetch={refetch}
        reloaderSize='small'
      />
      {permission?.['create:any'] && (
        <Form layout='vertical' form={form} onFinish={handleSubmit}>
          <PlacesInfo />
          <Button
            type='primary'
            htmlType='submit'
            style={{ marginBottom: '1rem' }}
            loading={createLoading}
          >
            Create Place
          </Button>
        </Form>
      )}

      <Row justify={'space-between'}>
        <Col lg={12}>
          <CommonTableHeader
            title={'Place'}
            modalWidth={500}
            element={
              editInfo ? (
                <PlacesTourEditModal info={editInfo} setNull={setEditInfo} />
              ) : (
                <></>
              )
            }
          />
        </Col>
      </Row>
      <Table
        size='small'
        bordered
        pagination={
          tourPlace?.count !== undefined && tourPlace?.count < 20
            ? false
            : {
              ...pagination,
              total: tourPlace?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
        dataSource={tourPlace.dataToReturn}
        columns={columns}
        scroll={{ x: true }}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
      />
      {deleteLoading || isLoading || (createLoading && <LoadingIndicator />)}
    </>
  );
};

export default Places;
