import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { formatAmount } from "../../../../common/utils/common.utils";
import { findInvoiceRoute } from "../../../../layout/components/SearchInvoices";
import { IVendorPurchasePayment } from "../types/VendorWisePurchase_PaymentTypes";

export const VendorWisePurchaseUtils = ({
  pagination,
}: {
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IVendorPurchasePayment> => {
  return [
    {
      title: "SL.",
      dataIndex: "key",
      key: "key",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: "Sales Date",
      key: "sales_date",
      render: (_, record) => {
        return `${dayjs(record.sales_date).format("DD MMM YYYY")}`;
      },
    },
    {
      title: "Invoice No.",
      key: "invoice_no",
      dataIndex: "invoice_no",
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;

        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                "/" +
                "details" +
                "/" +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },

    {
      title: "Ticket No",
      key: "airticket_ticket_no",
      dataIndex: "airticket_ticket_no",
    },

    {
      title: "Vendor Name",
      key: "vendor_name",
      dataIndex: "vendor_name",
      render: (curr, rec) => {
        return (
          <Link to={`/reports/vendor_ledger?id=${rec.vendor_id}`}>{curr}</Link>
        );
      },
    },

    {
      title: "Purchase Amount",
      key: "cost_price",
      dataIndex: "cost_price",
      align: "right",
      render: (cost_price) => {
        return (
          <span className="color-purchase"> {formatAmount(cost_price)} </span>
        );
      },
    },

    // {
    //   title: 'Vendor Payment',
    //   key: 'payment_amount',
    //   dataIndex: 'payment_amount',
    //   align: 'right',
    //   render: (payment_amount) => {
    //     return <> {formatAmount(payment_amount)} </>;
    //   },
    // },

    // {
    //   title: "Due Amount",
    //   key: "due_amount",
    //   dataIndex: "due_amount",
    //   align: "right",
    //   render: (due_amount) => {
    //     return <> {formatAmount(due_amount)} </>;
    //   },
    // },
  ];
};
