import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { formatAmountAbs } from "../../../../common/utils/common.utils";
import { dateFormate } from "../../../Dashboard/Utils/formateDate";
import { ITotal_Due_Advance_ClientDataType } from "../types/Total_Due_Advance_ClientTypes";

const { Text } = Typography;
export const Total_Due_Advance_ClientUtils = (pagination: {
  current: number;
  pageSize: number;
}): ColumnsType<ITotal_Due_Advance_ClientDataType> => {
  return [
    {
      title: "SL.",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      render: (value, record, index) => (
        <Link to={`/clients/details/client-${record.ctrxn_cl_id}`}>
          {record.client_name}
        </Link>
      ),
    },

    {
      title: "Mobile No.",
      dataIndex: "client_mobile",
      key: "client_mobile",
      render: (_, record) =>
        record.client_mobile ? record.client_mobile : null,
    },
    {
      title: "Debit",
      dataIndex: "total_debit",
      key: "total_debit",
      align: "right",
      render: (curr) => (
        <span className="color-loss">{formatAmountAbs(curr)}</span>
      ),
    },
    {
      title: "Credit",
      dataIndex: "total_credit",
      key: "total_credit",
      align: "right",
      render: (curr) => (
        <span className="color-profit">{formatAmountAbs(curr)}</span>
      ),
    },

    {
      title: "Due",
      dataIndex: "current_balance",
      key: "current_balance",
      align: "right",
      render: (current_balance) => {
        return (
          <Typography.Text
            style={{
              color: "red",
            }}
          >
            {Number(current_balance) < 0 &&
              formatAmountAbs(Number(current_balance))}
          </Typography.Text>
        );
      },
    },
    {
      title: "Advance",
      dataIndex: "current_balance",
      key: "current_balance",
      align: "right",
      render: (current_balance) => {
        return (
          <Typography.Text
            style={{
              color: "green",
            }}
          >
            {Number(current_balance) > 0 &&
              formatAmountAbs(Number(current_balance))}
          </Typography.Text>
        );
      },
    },
  ];
};
