import { Card, Col, DatePicker, Row, Segmented, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Fixed2,
  rangePresets,
  separateClient,
} from '../../../common/utils/common.utils';
import BreadCrumb from '../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../components/common/spinner/LoadingIndicator';
import { findInvoiceRoute } from '../../../layout/components/SearchInvoices';
import { useOtherBillingSummaryViewQuery } from '../Api/Endpoints/DashboardEndpoints';
import {
  ticket_issue,
  ticket_re_issue,
  ticket_refund,
} from '../interfaces/dashboard.interfaces';
const { Text } = Typography;
const OthersBillingSummary = () => {
  const [date, setDate] = useState<{
    from_date: Dayjs | null;
    to_date: Dayjs | null;
  }>({
    from_date: null,
    to_date: null,
  });
  const searchParams = new URLSearchParams(location.search);
  const billing_type = searchParams.get('billing_type');

  const [week, setWeek] = useState('Default');

  const { data, isLoading, isFetching } = useOtherBillingSummaryViewQuery({
    week: week,
    billingType: billing_type!,
    from_date: dayjs(date.from_date).format('YYYY-MM-DD'),
    to_date: dayjs(date.to_date).format('YYYY-MM-DD'),
  });

  // This useEffect is working based on Params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    setWeek('Default');
  }, [location.search]);

  const ticket_issue: ColumnsType<ticket_issue> = [
    {
      title: 'SL',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (_, data, index) => index + 1,
    },
    {
      title: 'Sales Date',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (_, data) =>
        data?.airticket_sales_date &&
        dayjs(data.airticket_sales_date).format('DD-MMM-YYYY'),
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data, index) => (
        <Link
          to={`../${
            findInvoiceRoute(data.invoice_category_id) +
            '/' +
            'details' +
            '/' +
            data.invoice_id
          }`}
        >
          {data.invoice_no}
        </Link>
      ),
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (rec, data) => {
        const { client_id, combined_id } = separateClient(data.comb_client);

        return (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?client_query=${client_id}`
                : `/reports/combined_ledger?id=${combined_id}`
            }
          >
            {rec}
          </Link>
        );
      },
    },
    {
      title: 'Ticket No.',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: 'Airline',
      dataIndex: 'airline_name',
      key: 'airline_name',
    },
    {
      title: 'Gross Fare',
      dataIndex: 'airticket_gross_fare',
      key: 'airticket_gross_fare',
      render: (airticket_gross_fare) =>
        Fixed2(airticket_gross_fare).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Base Fare',
      dataIndex: 'airticket_base_fare',
      key: 'airticket_base_fare',
      render: (airticket_base_fare) =>
        Fixed2(airticket_base_fare).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render: (airticket_purchase_price) => (
        <span style={{ color: 'red' }}>
          {Fixed2(airticket_purchase_price).toLocaleString()}
        </span>
      ),
      align: 'right',
    },
  ];
  const ticket_reissue: ColumnsType<ticket_re_issue> = [
    {
      title: 'SL',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (_, data, index) => index + 1,
    },
    {
      title: 'Sales Date',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (_, data) =>
        data?.airticket_sales_date &&
        dayjs(data.airticket_sales_date).format('DD-MMM-YYYY'),
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data, index) => (
        <Link
          to={`../${
            findInvoiceRoute(data.invoice_category_id) +
            '/' +
            'details' +
            '/' +
            data.invoice_id
          }`}
        >
          {data.invoice_no}
        </Link>
      ),
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (rec, data) => {
        const { client_id, combined_id } = separateClient(data.comb_client);

        return (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?client_query=${client_id}`
                : `/reports/combined_ledger?id=${combined_id}`
            }
          >
            {rec}
          </Link>
        );
      },
    },

    {
      title: 'Ticket No.',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },

    {
      title: 'Fare Difference',
      dataIndex: 'airticket_fare_difference',
      key: 'airticket_fare_difference',
      render: (airticket_fare_difference) =>
        Fixed2(airticket_fare_difference).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Penalties',
      dataIndex: 'airticket_penalties',
      key: 'airticket_penalties',
      render: (airticket_penalties) =>
        Fixed2(airticket_penalties).toLocaleString(),
      align: 'right',
    },

    {
      title: 'Gross Fare',
      dataIndex: 'airticket_gross_fare',
      key: 'airticket_gross_fare',
      render: (airticket_gross_fare) =>
        Fixed2(airticket_gross_fare).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Base Fare',
      dataIndex: 'airticket_base_fare',
      key: 'airticket_base_fare',
      render: (airticket_base_fare) =>
        Fixed2(airticket_base_fare).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Purchase Price',
      dataIndex: 'airticket_purchase_price',
      key: 'airticket_purchase_price',
      render: (airticket_purchase_price) => (
        <span style={{ color: 'red' }}>
          {Fixed2(airticket_purchase_price).toLocaleString()}
        </span>
      ),
      align: 'right',
    },
  ];
  const ticket_refund: ColumnsType<ticket_refund> = [
    {
      title: 'SL',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (_, data, index) => index + 1,
    },
    {
      title: 'Refund Date',
      dataIndex: 'airticket_tax',
      key: 'airticket_tax',
      render: (_, data) =>
        data?.vrefund_date && dayjs(data.vrefund_date).format('DD-MMM-YYYY'),
    },
    {
      title: 'Voucher No.',
      dataIndex: 'atrefund_vouchar_number',
      key: 'atrefund_vouchar_number',
      render: (_, data, index) => {
        if (data.refund_type === 'PARTIAL') {
          return (
            <Link to={`/refund/partial/view/${data?.vrefund_refund_id}`}>
              {data.atrefund_vouchar_number}
            </Link>
          );
        } else if (data.refund_type === 'AIRTICKET') {
          return (
            <Link to={`/refund/airticket/details/${data?.vrefund_refund_id}`}>
              {data.atrefund_vouchar_number}
            </Link>
          );
        }
      },
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (rec, data) => {
        const { client_id, combined_id } = separateClient(data.comb_client);

        return (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?client_query=${client_id}`
                : `/reports/combined_ledger?id=${combined_id}`
            }
          >
            {rec}
          </Link>
        );
      },
    },
    {
      title: 'Ticket No.',
      dataIndex: 'airticket_ticket_no',
      key: 'airticket_ticket_no',
    },
    {
      title: 'PNR',
      dataIndex: 'airticket_pnr',
      key: 'airticket_pnr',
    },
    {
      title: 'Payment Method',
      dataIndex: 'vrefund_payment_type',
      key: 'vrefund_payment_type',
    },
    {
      title: 'Total Amount',
      dataIndex: 'vrefund_total_amount',
      key: 'vrefund_total_amount',
      render: (vrefund_total_amount) =>
        Fixed2(vrefund_total_amount).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Charge Amount',
      dataIndex: 'vrefund_charge_amount',
      key: 'vrefund_charge_amount',
      render: (vrefund_charge_amount) =>
        Fixed2(vrefund_charge_amount).toLocaleString(),
      align: 'right',
    },
    {
      title: 'Return Amount',
      dataIndex: 'vrefund_return_amount',
      key: 'vrefund_return_amount',
      render: (vrefund_return_amount) => (
        <span style={{ color: 'red' }}>
          {Fixed2(vrefund_return_amount).toLocaleString()}
        </span>
      ),
      align: 'right',
    },
  ];

  // TICKET ISSUE
  let total_gross_fare = 0;
  let total_base_fare = 0;
  let total_purchase_price = 0;

  // TICKET RE-ISSUE
  let total_fare_difference = 0;
  let total_penalties = 0;
  let total_re_issue_gross_fare = 0;
  let total_re_issue_base_fare = 0;
  let total_re_issue_purchase_price = 0;

  // TICKET REFUND
  let total_charge_amount = 0;
  let total_return_amount = 0;
  let total_amount = 0;

  useEffect(() => {
    // TICKET ISSUE
    total_gross_fare = 0;
    total_base_fare = 0;
    total_purchase_price = 0;

    // TICKET RE-ISSUE
    total_fare_difference = 0;
    total_penalties = 0;
    total_re_issue_gross_fare = 0;
    total_re_issue_base_fare = 0;
    total_re_issue_purchase_price = 0;

    // TICKET REFUND
    total_charge_amount = 0;
    total_return_amount = 0;
    total_amount = 0;
  }, []);

  const curr_month = dayjs().format('MMMM').toLocaleUpperCase();
  const curr_year = dayjs().format('YYYY');
  const lastDayOfMonth = dayjs().endOf('month').format('DD');

  const prev_month = dayjs().subtract(1, 'months').format('MMMM');
  const prev_year = dayjs().subtract(1, 'months').format('YYYY');

  const latsDayOfPreviousMonth = dayjs()
    .subtract(1, 'month')
    .endOf('month')
    .format('DD');

  const previous = `1/${prev_month}/${prev_year} - 15/${prev_month}/${prev_year}`;
  const previous_next = `16/${prev_month}/${prev_year} - ${latsDayOfPreviousMonth}/${prev_month}/${prev_year}`;

  const week_1 = `01/${curr_month}/${curr_year} - 08/${curr_month}/${curr_year}`;
  const week_2 = `09/${curr_month}/${curr_year} - 15/${curr_month}/${curr_year}`;
  const week_3 = `16/${curr_month}/${curr_year} - 24/${curr_month}/${curr_year}`;
  const week_4 = `25/${curr_month}/${curr_year} - ${lastDayOfMonth}/${curr_month}/${curr_year}`;

  const total_ticket_issue_purchase = data?.data?.ticket_issue?.reduce(
    (acc, curr) => Fixed2(curr.airticket_purchase_price) + acc,
    0
  );

  const total_ticket_re_issue__purchase = data?.data?.ticket_re_issue?.reduce(
    (acc, curr) => Fixed2(curr.airticket_purchase_price) + acc,
    0
  );

  const total_ticket_refund_return_amount = data?.data?.ticket_refund?.reduce(
    (acc, curr) => Fixed2(curr.vrefund_return_amount) + acc,
    0
  );

  return (
    <>
      <BreadCrumb arrOfOption={['BSP Summary']} />
      <div style={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }}>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Segmented<string>
              options={[
                {
                  value: 'Default',
                  title: `Default Options`,
                  label: `Default (${billing_type})`,
                },
                {
                  value: 'previous',
                  title: previous,
                  label: `${dayjs()
                    .subtract(1, 'month')
                    .format('MMMM')
                    .toLocaleUpperCase()} (1-15)`,
                },
                {
                  value: 'previous_next',
                  title: previous_next,
                  label: `${dayjs()
                    .subtract(1, 'month')
                    .format('MMMM')
                    .toLocaleUpperCase()} (16-${latsDayOfPreviousMonth})`,
                },

                {
                  value: 'first',
                  title: week_1,
                  label: `${curr_month} (01-08)`,
                },
                {
                  value: 'second',
                  title: week_2,
                  label: `${curr_month} (09-15)`,
                },
                {
                  value: 'third',
                  title: week_3,
                  label: `${curr_month} (16-23)`,
                },
                {
                  value: 'fourth',
                  title: week_4,
                  label: `${curr_month} (24-${lastDayOfMonth})`,
                },
              ]}
              value={week}
              onChange={(value) => {
                setWeek(value);
              }}
            />
          </Col>
          <Col>
            <DatePicker.RangePicker
              presets={rangePresets}
              onChange={(e) =>
                setDate((prev) => ({
                  ...prev,
                  from_date: e?.length ? e[0] : null,
                  to_date: e?.length ? e[1] : null,
                }))
              }
            />
          </Col>
        </Row>
        <Card
          size='small'
          title={
            <div
              style={{
                display: 'flex ',
                justifyContent: 'space-between',
              }}
            >
              <span>TICKET ISSUE</span>{' '}
              <span>
                ISSUE DATE RANGE :{' '}
                <Text type='secondary'>
                  {' '}
                  {`${
                    data?.data?.sales_from_date &&
                    dayjs(data?.data?.sales_from_date).format('DD-MM-YYYY')
                  } `}{' '}
                </Text>{' '}
                TO
                <Text type='secondary'>
                  {` ${
                    data?.data?.sales_to_date &&
                    dayjs(data?.data?.sales_to_date).format('DD-MM-YYYY')
                  }`}
                </Text>
              </span>
              <span></span>
            </div>
          }
        >
          <Table
            size='small'
            loading={{
              spinning: isLoading || isFetching,
              indicator: loadingIndicator,
            }}
            bordered
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={data?.data?.ticket_issue}
            columns={ticket_issue}
            pagination={false}
            summary={(data) => {
              data?.map((item) => {
                total_gross_fare += Fixed2(item.airticket_gross_fare);
                total_base_fare += Fixed2(item.airticket_base_fare);
                total_purchase_price += Fixed2(item.airticket_purchase_price);
              });

              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={7}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>Total: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={0}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>
                        {total_gross_fare.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>
                        {total_base_fare.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <div className='report_total_footer'>
                      <Typography.Text strong style={{ color: '#EE4266' }}>
                        {total_purchase_price.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Card>
        <Card size='small' title='TICKET RE-ISSUE'>
          <Table
            loading={{
              spinning: isLoading || isFetching,
              indicator: loadingIndicator,
            }}
            size='small'
            bordered
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={data?.data?.ticket_re_issue}
            columns={ticket_reissue}
            pagination={false}
            summary={(data) => {
              data.map((item) => {
                total_fare_difference += Fixed2(item.airticket_fare_difference);
                total_penalties += Fixed2(item.airticket_penalties);
                total_re_issue_gross_fare += Fixed2(item.airticket_gross_fare);

                total_re_issue_base_fare += Fixed2(
                  item.total_re_issue_base_fare
                );

                total_re_issue_purchase_price += Fixed2(
                  item.airticket_purchase_price
                );
              });

              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={6}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>Total: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={0}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>
                        {total_fare_difference.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>
                        {total_penalties.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>
                        {total_re_issue_gross_fare.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>
                        {total_re_issue_base_fare.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={7}>
                    <div className='report_total_footer'>
                      <Typography.Text strong style={{ color: '#EE4266' }}>
                        {total_re_issue_purchase_price.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Card>
        <Card size='small' title='TICKET REFUND'>
          <Table
            size='small'
            bordered
            loading={{
              spinning: isLoading || isFetching,
              indicator: loadingIndicator,
            }}
            className='invoiceBillingTable'
            rowClassName={'invoiceBillingTd'}
            dataSource={data?.data?.ticket_refund}
            columns={ticket_refund}
            pagination={false}
            summary={(data) => {
              data?.forEach((item) => {
                total_charge_amount += Fixed2(item.vrefund_charge_amount);
                total_return_amount += Fixed2(item.vrefund_return_amount);
                total_amount += Fixed2(item.vrefund_total_amount);
              });

              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={7}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>Total: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={0}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>
                        {total_amount.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={7}>
                    <div className='report_total_footer'>
                      <Typography.Text strong>
                        {total_charge_amount.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <div className='report_total_footer'>
                      <Typography.Text strong style={{ color: '#EE4266' }}>
                        {total_return_amount.toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </Card>

        <Typography.Title
          level={4}
          style={{
            textAlign: 'end',
          }}
        >
          COMBINED TOTAL : {'  '}
          <span>
            {(
              (total_ticket_issue_purchase ?? 0) +
              (total_ticket_re_issue__purchase ?? 0) -
              (total_ticket_refund_return_amount ?? 0)
            ).toLocaleString()}
          </span>{' '}
          /-
        </Typography.Title>
      </div>
    </>
  );
};

export default OthersBillingSummary;
