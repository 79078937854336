import { Button, Popconfirm, Space, Typography, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { IPermission, ISetState } from "../../../../common/types/commonTypes";
import { IContact, IGift } from "../types/contactTypes";
import {
  useDeleteContactMutation,
  useDeleteGiftMutation,
} from "../Endpoints/contactsEndpoints";
import { useAppDispatch } from "../../../../app/hooks";
import { setModal } from "../../../../common/slices/commonSlice";
import CommonTableHeader from "../../../../components/common/CommonTableHeader";
import { setCommonModal } from "../../../../common/slices/modalSlice";
import SendGiftModal from "../modals/SendGiftModal";
import ViewGiftModal from "../modals/ViewGiftModal";
import dayjs from "dayjs";
import EditGiftModal from "../modals/EditGiftModal";

export const ContactGiftTableColumns = (): ColumnsType<IGift> => {
  const dispatch = useAppDispatch();

  const [deleteGift, { isLoading }] = useDeleteGiftMutation();

  const handleDelete = async (id: number) => {
    await deleteGift(id);
  };

  const viewGiftModal = (record: IGift) => {
    dispatch(
      setCommonModal({
        title: "Edit gift",
        content: <EditGiftModal data={record} />,
        show: true,
        // width: 500,
      })
    );
  };

  return [
    {
      title: "SL.",
      render: (_, _data, index) => index + 1,
    },

    {
      title: "Date",
      dataIndex: "ag_date",
      key: "ag_date",
      render: (ag_date) => dayjs(ag_date).format("DD-MM-YYYY"),
    },
    {
      title: "Gift Name",
      dataIndex: "ag_items",
      key: "ag_items",
    },
    {
      title: "Remark",
      dataIndex: "ag_remark",
      key: "ag_remark",
    },

    {
      title: "Actions",
      render: (record) => (
        <Space size="small">
          <Button
            size="small"
            type="primary"
            onClick={() => {
              viewGiftModal(record);
            }}
          >
            Edit
          </Button>

          <Button danger size="small" type="primary" loading={isLoading}>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record?.ag_id)}
            >
              Delete
            </Popconfirm>
          </Button>
        </Space>
      ),
    },
  ];
};
