import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, Modal, Row, Typography, theme } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { setModal } from "../../common/slices/commonSlice";
import { globalTheme } from "../../common/slices/themeSlice";
import { IProps } from "../utils/componentTypes";

const { Title } = Typography;

const CommonTableHeader = ({
  title,
  modalTitle,
  button_text,
  element,
  setNull,
  onAnotherpage,
  onClickFn,
  modalWidth,
  permission,
  width,
  align,
  trashbutton,
}: IProps) => {
  const [size, _setSize] = useState<SizeType>("middle");
  const modalSlice = useSelector((state: RootState) => state.commonSlice);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      dispatch(setModal(false));
    };
  }, []);

  const showModal = () => {
    dispatch(setModal(true));
  };

  const handleOk = () => {
    dispatch(setModal(false));

    // setNull && setNull(null);
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(setModal(false));

    if (setNull) {
      // @ts-ignore
      setNull(null);
    }
  };
  const themeGlobal = useAppSelector(globalTheme);

  return (
    <Row justify={align ? "center" : "start"}>
      <CommonHeaderStyle
        style={{
          padding: "5px 15px",
          margin: "5px 0 10px 0",
          borderRadius: 4,
          // boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset',
          width: width ? 900 : 1400,
        }}
      >
        <Row align="middle" justify="space-between">
          {title && <Title level={4}>{title}</Title>}

          <div>
            {trashbutton ? trashbutton : ""}
            {button_text && permission && (
              <Button
                className="btn"
                type="primary"
                value="small"
                icon={<PlusOutlined />}
                size={size}
                onClick={() => (onClickFn ? onClickFn() : showModal())}
              >
                {button_text}
              </Button>
            )}
          </div>
        </Row>

        <Modal
          title={modalTitle || title}
          open={modalSlice.isModal}
          okText={"Submit"}
          cancelText={"Close"}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          width={modalWidth}
          forceRender
        >
          {element}
        </Modal>
      </CommonHeaderStyle>
    </Row>
  );
};

export default CommonTableHeader;

const CommonHeaderStyle = styled.div``;
