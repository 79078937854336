import { useState } from 'react';
import { Button, Row, Table } from 'antd';
import { useAppDispatch } from '../../../../app/hooks';
import { setModal } from '../../../../common/slices/commonSlice';
import ClientEditModal from '../modals/ClientEditModal';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { columns } from '../utils/utils';
import { ClientCategoryDataType } from '../types/clientCategoryTypes';
import ClientCategoryAddModal from '../modals/ClientCategoryAddModal';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { useGetClientCategoryWithPaginationQuery } from '../endpoints/clientCategoryEndpoints';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { crud, perProps } from '../../../../common/types/commonTypes';
import { DeleteOutlined } from '@ant-design/icons';

const ClientCategory = ({ permission }: perProps) => {
  const [editInfo, setEditInfo] = useState<ClientCategoryDataType | null>(null);
  const dispatch = useAppDispatch();
  const showModal = () => {
    dispatch(setModal(true));
  };
  const [isTrash, setIsTrash] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    isLoading,
    isFetching,
    data: clientCategory,
    refetch,
  } = useGetClientCategoryWithPaginationQuery(
    { ...pagination, is_deleted: Number(isTrash) },
    {
      selectFromResult: (cache) => {
        const data: any = cache.data?.data;
        const total = cache?.data?.count;
        const dataToReturn: ClientCategoryDataType[] = [];
        const clientCategoryList: string[] = [];

        if (data) {
          for (let i = 0; i < data?.length; i++) {
            const element = data[i];
            if (element.category_prefix) {
              clientCategoryList.push(element.category_prefix);
            }

            dataToReturn.push({ ...element, key: i + 1 });
          }
        }

        return { ...cache, data: { dataToReturn, clientCategoryList, total } };
      },
    }
  );

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Configuration', 'Client Categories']}
        refetch={refetch}
        reloaderSize='small'
      />

      <CommonTableHeader
        width
        align
        title='Client Category'
        button_text='Add Client Category'
        modalTitle={editInfo ? 'Edit Client Category' : 'Add Client Category'}
        setNull={setEditInfo}
        element={
          editInfo ? (
            <ClientEditModal
              info={editInfo}
              clientCategory={clientCategory.clientCategoryList}
              setNull={setEditInfo}
            />
          ) : (
            <ClientCategoryAddModal
              clientCategory={clientCategory.clientCategoryList}
            />
          )
        }
        permission={permission?.[crud.create]}
      />

      <Row justify={'center'}>
        <Table
          style={{ width: 900 }}
          size='small'
          bordered
          pagination={
            clientCategory?.total !== undefined && clientCategory?.total < 20
              ? false
              : {
                ...pagination,
                total: clientCategory?.total,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
          }
          columns={columns({
            setEditInfo,
            showModal,
            permission,
            pagination,
            isTrash,
          })}
          dataSource={clientCategory.dataToReturn}
          loading={{
            spinning: isLoading || isFetching,
            indicator: loadingIndicator,
          }}
          scroll={{ x: true }}
        />
      </Row>
    </div>
  );
};

export default ClientCategory;
