import { Layout, Typography, theme } from "antd";
import { Footer } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../../auth/states/userSlice";
import "../style/layoutStyle.css";
import HeaderContainer from "./HeaderContainer";
import Sidebar from "./Sidebar";

const { Content } = Layout;

const LayoutContainer: React.FC = () => {
  const [width, setWidth] = useState(245);
  const [collapsed, setCollapsed] = useState(
    window.innerWidth < 1200 ? true : false
  );
  const [isHide, setIsHide] = useState(window.innerWidth < 768 ? true : false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const user = useAppSelector(selectUser);

  const isTab = () => {
    return window.innerWidth < 1024;
  };
  const isMobile = () => {
    return window.innerWidth < 768;
  };

  useEffect(() => {
    const handleResize = () => {
      if (isTab()) {
        setCollapsed(true);

        // @HANDLE IS MOBILE
        if (isMobile()) {
          setIsHide(true);
        } else {
          setIsHide(false);
        }
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Layout
      hasSider
      style={{ minHeight: "100vh" }}
      // className='layoutDesign custom-scrollbar'
    >
      <Sidebar
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        setWidth={setWidth}
        width={width}
      />

      {!collapsed ? (
        <div className="innerDiv" style={{ width: width }}></div>
      ) : (
        <div className="innerCollapsedDiv"></div>
      )}

      <Layout
        className="site-layout layout"
        style={{
          marginLeft: collapsed ? (isHide ? 0 : 76.667) : 0,
        }}
      >
        {user?.user_id ? (
          <HeaderContainer collapsed={collapsed} setCollapsed={setCollapsed} />
        ) : (
          <></>
        )}
        <Content
          style={{
            padding: 20,
            minHeight: "90vh",
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
        <Footer
          // className='sticky-footer'
          style={{
            textAlign: "center",
            color: "#265073",
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          <Typography.Text style={{ fontSize: 16 }}>
            {" "}
            Support Details:
          </Typography.Text>{" "}
          <Typography.Text style={{ fontSize: 16 }}>
            01958398325, 01958398344, (10:00AM to 06:00PM) | Email:
            sup.m360ict@gmail.com
          </Typography.Text>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutContainer;
