import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Fixed2, separateClient } from "../../../../common/utils/common.utils";
import { findInvoiceRoute } from "../../../../layout/components/SearchInvoices";
import { IAgentsReportLedgerTypes } from "../types/agentLedgersTypes";
type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
};
export const AgentReportsDataUtils = ({
  pagination,
}: Props): ColumnsType<IAgentsReportLedgerTypes> => {
  return [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: "Date",
      key: "agtrxn_created_at",
      align: "center",
      render: (_, record) => {
        return `${dayjs(record.agtrxn_created_at).format("DD MMM YYYY")}`;
      },
    },

    {
      title: "Voucher No.",
      key: "agtrxn_voucher",
      dataIndex: "agtrxn_voucher",
      align: "center",
      render: (_, data) => (
        <>
          {data?.agtrxn_invoice_id ? (
            <Link
              to={
                "../" +
                findInvoiceRoute(data.invoice_category_id) +
                "/" +
                "details" +
                "/" +
                data.agtrxn_invoice_id
              }
              state={location.pathname}
            >
              {data.agtrxn_voucher}
            </Link>
          ) : (
            <> {data.agtrxn_voucher}</>
          )}
        </>
      ),
    },

    {
      title: "Client Name",
      key: "client_name",
      dataIndex: "client_name",
      render: (value, record) => {
        const { client_id, combined_id } = separateClient(record?.comb_client);
        return (
          <Link
            to={
              client_id
                ? `/clients/details/${record?.comb_client}`
                : `/combineClients/details/${combined_id}`
            }
          >
            {record.client_name}
          </Link>
        );
      },
    },

    {
      title: "Particular",
      dataIndex: "trxntype_name",
      key: "trxntype_name",
    },
    {
      title: "Tr. Type",
      dataIndex: "agtrxn_type",
      key: "agtrxn_type",
      align: "center",
    },

    {
      title: "Debit",
      render: (_, record) => {
        return (
          record.agtrxn_type === "DEBIT" && (
            <Typography.Text>
              <span style={{ color: "red" }}>
                {Number(record.agtrxn_amount)}
              </span>
            </Typography.Text>
          )
        );
      },
      key: "agtrxn_amount",
      align: "right",
    },

    {
      title: "Credit",
      render: (_, record) => {
        return (
          record.agtrxn_type === "CREDIT" && (
            <Typography.Text>
              <span style={{ color: "green" }}>
                {Number(record.agtrxn_amount)}
              </span>
            </Typography.Text>
          )
        );
      },
      key: "agtrxn_amount",
      align: "right",
    },

    {
      title: "Last Balance",
      dataIndex: "agtrxn_lbalance",
      key: "agtrxn_lbalance",
      align: "right",
      render: (amount) => {
        return (
          <Typography.Text
            style={{
              color:
                Number(amount) === 0
                  ? "#18b4e9"
                  : Number(amount) > 0
                  ? "green"
                  : "red",
            }}
          >
            {Number(amount) === 0 ? "" : Number(amount) > 0 ? "" : "-"}

            {Fixed2(Math.abs(Number(amount)))}
          </Typography.Text>
        );
      },
    },
    {
      title: "Created By",
      key: "user_full_name",
      dataIndex: "user_full_name",
    },
  ];
};
