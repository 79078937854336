import { Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import {
  formatAmount,
  formatAmountAbs,
} from "../../../../common/utils/common.utils";
import { BSPBillCheck_Summary } from "../type/BSPType";

type Props = {
  bspData: BSPBillCheck_Summary | undefined;
  isLoading: boolean;
};

const BSP_Summary = ({ bspData, isLoading }: Props) => {
  const columns = (): ColumnsType<{
    key: string;
    name: string;
    iata_info: string;
    db_info: string;
    difference: number;
  }> => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (curr) => <span className="bold tt-uppercase">{curr}</span>,
      },
      {
        title: "IATA",
        dataIndex: "iata_info",
        key: "iata_info",
        align: "right",
        render: (curr) => (
          <span
            style={{
              color: "#125B9A",
              fontFamily: "monospace",
              fontWeight: "bold",
              textAlign: "right",
              fontSize: 16,
            }}
          >
            {curr}
          </span>
        ),
      },
      {
        title: "TRABILL",
        dataIndex: "db_info",
        key: "db_info",
        align: "right",
        render: (curr) => (
          <span
            style={{
              color: "#326b7d",
              fontFamily: "monospace",
              fontWeight: "bold",
              textAlign: "right",
              fontSize: 16,
            }}
          >
            {curr}
          </span>
        ),
      },
      {
        title: "Difference",
        dataIndex: "difference",
        key: "difference",
        align: "right",
        render: (curr) => {
          return curr ? (
            <span
              className="color-loss"
              style={{
                color: "red",
                fontFamily: "monospace",
                fontWeight: "bold",
                textAlign: "right",
                fontSize: 16,
              }}
            >
              {formatAmountAbs(curr)}
            </span>
          ) : (
            <Typography.Text>✅</Typography.Text>
          );
        },
      },
    ];
  };

  const {
    from_date = null,
    to_date = null,
    iata_issues = 0,
    iata_refunds = 0,
    iata_grand_total = 0,
    db_issue = 0,
    db_refund = 0,
    db_grand_total = 0,
    iata_amd = 0,
    db_adm = 0,
  } = bspData || {};

  const issue_difference = iata_issues - db_issue;
  const refund_difference = iata_refunds - db_refund;
  const amd_difference = iata_amd - db_adm;
  const grand_total_difference = iata_grand_total - db_grand_total;

  const dataSource: {
    key: string;
    name: string;
    iata_info: string;
    db_info: string;
    difference: number;
  }[] = [
    {
      key: "1",
      name: "Date Range",
      iata_info: `${dayjs(from_date)?.format("DD/MMM/YY")} to ${dayjs(
        to_date
      ).format("DD/MMM/YY")}`,
      db_info: `${dayjs(from_date)?.format("DD/MMM/YY")} to ${dayjs(
        to_date
      )?.format("DD/MMM/YY")}`,
      difference: 0,
    },

    {
      key: "2",
      name: "Issues",
      iata_info: formatAmount(iata_issues),
      db_info: formatAmount(db_issue),
      difference: issue_difference,
    },
    {
      key: "3",
      name: "Refunds",
      iata_info: formatAmount(iata_refunds),
      db_info: formatAmount(db_refund),
      difference: refund_difference,
    },
    {
      key: "4",
      name: "ADM",
      iata_info: formatAmount(iata_amd),
      db_info: formatAmount(db_adm),
      difference: amd_difference,
    },
    {
      key: "5",
      name: "Grand Total",
      iata_info: formatAmount(iata_grand_total),
      db_info: formatAmount(db_grand_total),
      difference: grand_total_difference,
    },
  ];

  return (
    <Table
      dataSource={bspData ? dataSource : []}
      columns={columns()}
      pagination={false}
      bordered
      size="small"
    />
  );
};

export default BSP_Summary;
