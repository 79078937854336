import { BellFilled, MoneyCollectOutlined } from "@ant-design/icons";
import { Badge, Button, Menu, MenuProps, Popover, Tooltip } from "antd";
import { Link } from "react-router-dom";
import {
  useGetAllCollectionChequeNotificationsQuery,
  useGetAllDueInvoiceNotificationsQuery,
  useGetAllExpiresPassportNotificationsQuery,
  useGetAllPendingPaymentChequeNotificationsQuery,
  useGetAllVisaDelivaryNotificationsQuery,
} from "./Api/NotificationEndpoints";

// =======================
import {
  ContainerOutlined,
  DesktopOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
type MenuItem = Required<MenuProps>["items"][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const Notification = () => {
  const { data: collecCheNotification } =
    useGetAllCollectionChequeNotificationsQuery({ cheque_status: "PENDING" });
  const { data: pendingPaymentChequeNotifi } =
    useGetAllPendingPaymentChequeNotificationsQuery({
      cheque_status: "PENDING",
    });
  const { data: dueNotification } = useGetAllDueInvoiceNotificationsQuery();
  const { data: expireNotification } =
    useGetAllExpiresPassportNotificationsQuery();
  const { data: visadelivaryNotification } =
    useGetAllVisaDelivaryNotificationsQuery();

  //==----------------
  const totalNotificatiionCount =
    Number(collecCheNotification?.data?.count) +
    Number(pendingPaymentChequeNotifi?.data?.count) +
    Number(dueNotification?.count) +
    Number(visadelivaryNotification?.data?.visa_delivery?.count) +
    Number(visadelivaryNotification?.data?.visa_pending?.count) +
    Number(expireNotification?.data?.count);

  const items: MenuItem[] = [
    //pendingPaymentCheque and Collection cheque
    collecCheNotification?.data?.count ||
    pendingPaymentChequeNotifi?.data?.count
      ? getItem(
          `Pending Cheques (${
            (pendingPaymentChequeNotifi?.data?.count || 0) +
            (collecCheNotification?.data?.count || 0)
          }) `,
          "one",
          <MoneyCollectOutlined />,
          [
            collecCheNotification?.data?.count
              ? getItem(
                  <Link to="/cheques">
                    Collection Cheque -{" "}
                    {collecCheNotification?.data?.count || 0}
                  </Link>,
                  "1"
                )
              : null,
            pendingPaymentChequeNotifi?.data?.count
              ? getItem(
                  <Link to="/cheques">
                    Payment Cheque -{" "}
                    {pendingPaymentChequeNotifi?.data?.count || 0}
                  </Link>,
                  "2"
                )
              : null,
          ]
        )
      : null,

    // invoice visa pending and delivery date
    visadelivaryNotification?.data?.visa_delivery?.count ||
    visadelivaryNotification?.data?.visa_pending?.count
      ? getItem(
          `Invoice visa (${
            visadelivaryNotification?.data?.visa_delivery?.count +
            visadelivaryNotification?.data?.visa_pending?.count
          }) `,
          "two",
          <ContainerOutlined />,
          [
            visadelivaryNotification?.data?.visa_delivery?.count
              ? getItem(
                  <Link to="/visa-delivery">
                    Today Visa Delivery -{" "}
                    {visadelivaryNotification?.data?.visa_delivery?.count}
                  </Link>,
                  "3"
                )
              : null,
            visadelivaryNotification?.data?.visa_pending?.count
              ? getItem(
                  <Link to="/visa-pending">
                    Visa Pending -{" "}
                    {visadelivaryNotification?.data?.visa_pending?.count}
                  </Link>,
                  "4"
                )
              : null,
          ]
        )
      : null,

    // passport expire
    expireNotification?.data?.count
      ? getItem(
          <Link to="/expire-passport">
            Passport Expiring - ({expireNotification?.data?.count})
          </Link>,
          "5",
          <PieChartOutlined />
        )
      : null,

    // invoice due
    dueNotification?.count
      ? getItem(
          <Link to="/due-invoice">
            Due Invoices - ({dueNotification?.count})
          </Link>,
          "6",
          <DesktopOutlined />
        )
      : null,
  ];

  const content = (
    <Menu mode="inline" items={items} style={{ color: "#6c757d" }} />
  );

  return (
    <Popover
      content={content}
      title={
        <div>
          <span style={{ color: "#6c757d" }}>Notification</span>
          <span
            style={{
              background: "#4f8dca",
              color: "#fff",
              padding: "0 0.6rem",
              borderRadius: "10rem",
              marginLeft: 10,
            }}
          >
            {totalNotificatiionCount || 0} New
          </span>

          <p
            style={{
              height: "0",
              margin: "0.5rem 0",
              overflow: "hidden",
              borderTop: "1px solid #e9ecef",
            }}
          />
        </div>
      }
      overlayStyle={{ textAlign: "center" }}
      trigger="click"
    >
      <Badge
        size="default"
        count={
          (typeof totalNotificatiionCount === "number" &&
            totalNotificatiionCount) ||
          0
        }
        style={{ background: "red", color: "white" }}
      >
        <Tooltip title="Notifaction">
          <Button
            type="primary"
            // style={{
            //   padding: 0,
            //   borderRadius: "50%",
            //   width: 32,
            //   height: 32,
            // }}
            icon={<BellFilled />}
          />
        </Tooltip>
      </Badge>
    </Popover>
  );
};

export default Notification;
