import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IPermission } from '../../../../common/types/commonTypes';
import {
  Fixed2,
  formatAmount,
  rangePresets,
} from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import {
  ITaxRefundList,
  useDeleteTaxRefundMutation,
  useGetAllTaxRefundQuery,
} from '../../Api/Endpoints/AirTicketTaxRefundEndpoints';

type Props = { permission?: IPermission };

const AirTicketTaxRefundList = ({ permission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const navigate = useNavigate();

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const { data, isLoading, isFetching, refetch } = useGetAllTaxRefundQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search,
  });

  const [deleteTaxRefund, { isLoading: taxRefundLoading, isSuccess, isError }] =
    useDeleteTaxRefundMutation();

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [date, search]);

  // ================== notification =========================
  useEffect(() => {
    if (isSuccess) {
      message.success('Tax Refund Delete Successfully Done');
    }
    if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);

  const columns: ColumnsType<ITaxRefundList> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: 'Refund Date',
      key: 'refund_date',
      dataIndex: 'refund_date',
      render: (curr) => <>{dayjs(curr).format('DD-MM-YYYY')}</>,
    },

    {
      title: 'Voucuher No',
      key: 'refund_voucher',
      dataIndex: 'refund_voucher',
      render: (curr, data) => {
        return <Link to={`/refund/tax-view/${data.refund_id}`}>{curr}</Link>;
      },
    },

    {
      title: 'Invoice No',
      key: 'invoice_no',
      dataIndex: 'invoice_no',

      render: (value, record) => (
        <Link
          to={
            '../' +
            findInvoiceRoute(record.invoice_category_id) +
            '/' +
            'details' +
            '/' +
            record.invoice_id
          }
          state={location.pathname}
        >
          {value}
        </Link>
      ),
    },

    {
      title: 'Client Name',
      key: 'client_name',
      dataIndex: 'client_name',

      render: (value, record) => {
        const { client_id, combine_id } = record;

        return record?.combine_id || record?.client_id ? (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?client_query=${client_id}`
                : `/reports/combined_ledger?id=${combine_id}`
            }
          >
            <span>{record.client_name}</span>
          </Link>
        ) : (
          value
        );
      },
    },

    {
      title: 'Refund Type',
      dataIndex: 'client_refund_type',
      key: 'client_refund_type',
    },

    {
      title: 'Vendor Tax Refund',
      dataIndex: 'vendor_total_tax_refund',
      key: 'vendor_total_tax_refund',
      render: (curr) => formatAmount(curr),
      align: 'right',
    },

    {
      title: 'Client Tax Refund',
      dataIndex: 'client_total_tax_refund',
      key: 'client_total_tax_refund',
      render: (curr) => formatAmount(curr),
      align: 'right',
    },

    {
      title: 'Refund Profit',
      dataIndex: 'refund_profit',
      key: 'refund_profit',
      render: (curr) => formatAmount(curr),
      align: 'right',
    },
    {
      title: 'Created By',
      dataIndex: 'user_full_name',
      key: 'user_full_name',
      align: 'end',
    },

    {
      title: 'Action',
      key: 'operation',

      render: (_, data, index) => {
        const { refund_id } = data;

        return (
          <Space size='small'>
            {permission?.['read:any'] && (
              <Button
                size='small'
                type='primary'
                onClick={() =>
                  navigate(`/refund/tax-view/${data.refund_id}`, {
                    state: location.pathname,
                  })
                }
              >
                View
              </Button>
            )}

            {permission?.['update:any'] && (
              <Button
                size='small'
                type='primary'
                // onClick={() => handleView(data)}
                onClick={() =>
                  navigate(`/refund/edit-tax-details/${data.refund_id}`, {
                    state: location.pathname,
                  })
                }
              >
                Edit
              </Button>
            )}
            <Popconfirm
              onConfirm={() => deleteTaxRefund(String(refund_id))}
              okButtonProps={{ loading: taxRefundLoading }}
              title='Sure to delete?'
            >
              <Button size='small' danger type='primary'>
                Delete
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Refund', 'Tax Refund List']}
        refetch={() => refetch()}
        reloaderSize='small'
      />

      <Row
        justify={'space-between'}
        gutter={[5, { xs: 8, sm: 16, md: 24, lg: 24 }]}
      >
        {permission?.['create:any'] && (
          <Col>
            <Link to='/refund/tax' state={location.pathname}>
              <Button style={{ marginBottom: '0.5rem' }} type='primary'>
                <PlusOutlined />
                Add Tax Refund
              </Button>
            </Link>
          </Col>
        )}

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        style={{ marginTop: 10 }}
        size='small'
        bordered
        rowKey={(e) => e?.refund_id}
        columns={columns}
        dataSource={data?.data}
        scroll={{ x: true }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </div>
  );
};

export default AirTicketTaxRefundList;
