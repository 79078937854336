import { Col, Input, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../../app/hooks';
import { setModal } from '../../../../../common/slices/commonSlice';
import { perProps } from '../../../../../common/types/commonTypes';
import CommonTableHeader from '../../../../../components/common/CommonTableHeader';
import BreadCrumb from '../../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../../components/common/spinner/LoadingIndicator';
import CreateTourGroup from '../modal/CreateTourGroup';
import TourGroupEditModal from '../modal/TourGroupEditModal';
import { ITourGroupDataType } from '../types/TourTypes';
import { TourGroupColumn } from '../utils/TourGroupColumn';
import { useGetAllTourGroupWithPaginationQuery } from '../endpoints/tourGroupEndpoints';

const TourGroup = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<ITourGroupDataType | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [search, setSearch] = useState<string>('');

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [click, setClick] = useState<boolean>();
  const showModal = () => {
    dispatch(setModal(true));
  };

  const {
    isLoading,
    data: tourGroup,
    refetch,
  } = useGetAllTourGroupWithPaginationQuery(
    { ...pagination, searchTerm },
    {
      selectFromResult: (cache) => {
        const data = cache.data?.data;
        const count = cache.data?.count;
        const dataToReturn: ITourGroupDataType[] = [];
        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            dataToReturn.push({
              ...element,
              key: i + 1,
            });
          }
        }
        return { ...cache, data: { dataToReturn, count } };
      },
    }
  );
  useEffect(() => {
    const debounce = setTimeout(() => {
      setSearchTerm(search);
    }, 400);

    return () => {
      clearTimeout(debounce);
    };
  }, [search]);

  return (
    <>
      <BreadCrumb
        arrOfOption={['Configuration', 'Tour Group']}
        refetch={refetch}
        reloaderSize='small'
      />
      {permission?.['create:any'] && (
        <CreateTourGroup modalTitle='Add Tour Info' setClick={setClick} />
      )}

      <Row justify={'space-between'}>
        <Col lg={12}>
          <CommonTableHeader
            title='Tour Group'
            modalTitle={editInfo ? 'Edit Tour Info' : 'Add Tour Info'}
            permission={permission?.['create:any']}
            element={
              editInfo ? (
                <TourGroupEditModal info={editInfo} setNull={setEditInfo} />
              ) : (
                <></>
              )
            }
          />
        </Col>
        <Col lg={12}>
          <Row justify={'space-between'}>
            <Col></Col>
            <Col>
              {' '}
              <Input
                placeholder='Search Here'
                allowClear
                onChange={(e) => setSearch(e.target.value)}
                style={{ width: 300, marginTop: '10px' }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size='small'
        bordered
        pagination={
          tourGroup?.count !== undefined && tourGroup?.count < 20
            ? false
            : {
              ...pagination,
              total: tourGroup?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
        dataSource={tourGroup.dataToReturn}
        columns={TourGroupColumn({
          setEditInfo,
          showModal,
          permission,
          pagination,
        })}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
      />
    </>
  );
};

export default TourGroup;
