import { api } from "../../../../../app/baseQuery";
import { HTTPResponse } from "../../../../../common/types/commonTypes";

export const VoidApiEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all loan
    getDetailsForVoid: build.query<HTTPResponse<voidItemsList>, string>({
      query: (id) => ({
        url: `/invoice-air-ticket/invoice-details-for-void/${id}`,
      }),
      //   providesTags: [
      //     { type: 'Loan', id: LOAN },
      //     { type: 'Payment', id: PAYMENT },
      //     { type: 'Receive', id: RECEIVE },
      //   ],
    }),

    postVoid: build.mutation<
      HTTPResponse<void>,
      { body: IPostVoid; id: string | number }
    >({
      query: ({ id, body }) => ({
        url: `/invoice-air-ticket/void/${id}`,
        body: body,
        method: "PUT",
      }),
      invalidatesTags: () => [
        { type: "Void", id: "LIST" },
        "invoices",
        "view-invoice",
      ],
    }),
  }),
});

export const { useLazyGetDetailsForVoidQuery, usePostVoidMutation } =
  VoidApiEndpoints;

export interface voidItemsList {
  invoice_id: number;
  invoice_no: string;
  comb_client: string;
  client_name: string;
  net_total: string;
  cate_id: number;
  invoice_discount: number;
  vendors: voidItemsVendor[] | IVisaVendor[];
}

export interface voidItemsVendor {
  vendor_name: string;
  comb_vendor: string;
  cost_price: string;
  airticket_discount_total: string;
  airticket_ticket_no: string;
  airticket_id: number;
  sales: string;
}

export interface IPostVoid {
  invoice_void_date: string;
  net_total: string | number;
  client_charge: number;
  void_amount: number;
  void_discount: number;
  invoice_no: string;
  cate_id: number;
  comb_client: string;
  invoice_vendors?: any;
}

export interface IVisaVendor {
  billing_id: number;
  billing_product_id: number;
  billing_quantity: number;
  billing_remaining_quantity: number;
  billing_unit_price: number;
  billing_subtotal: number;
  billing_cost_price: string;
  billing_profit: string;
  billing_vendor_id: number;
}
