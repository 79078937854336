import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import BreadCrumb from "../../breadCrumb/BreadCrumb";

import dayjs from "dayjs";
import { useHotkeys } from "react-hotkeys-hook";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { IViewInvoiceDetails } from "../../../../common/types/commonInterfaces";
import RefundModal from "../../../../modules/Invoices/common/RefundModal";
import TabInvoiceADM from "./TabInvoiceADM";
import TabInvoiceVoid, { handleCancel, handleOk } from "./void/TabInvoiceVoid";
import { setCommonModal } from "../../../../common/slices/modalSlice";
import EmdModal from "../emd/EmdModal";

type Props = {
  invoice_category_id?: number;
  invoice_id: number;
  selectedPrintableRef: React.RefObject<HTMLDivElement>;
  links?: { addLink: string; breadcrumbTitle: string };
  invoiceData: IViewInvoiceDetails | undefined;
  setClickPrint?: React.Dispatch<React.SetStateAction<boolean>>;
  isClickPrint?: boolean;
};

export interface IADM {
  inv_cat?: number;
  inv_id?: number;
  open: boolean;
}

export const portraitPageStyle = `
    @page {
      size: A4 portrait;

    }
    @media print {
      body {
          color: black !important;
      }
    }
  `;

const ViewInvoiceHeader = ({
  invoice_category_id,
  selectedPrintableRef,
  links,
  invoice_id,
  invoiceData,
  setClickPrint,
  isClickPrint,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVoid, setIsVoid] = useState(false);
  const [adm, setAdm] = useState<IADM>({
    inv_cat: undefined,
    inv_id: undefined,
    open: false,
  });

  // BREADCRUMB, LINKS
  let addLink = "";
  let breadcrumbTitle = "";
  switch (invoice_category_id) {
    case 1:
      breadcrumbTitle = "Invoice Airticket";
      addLink = "/airticket";
      break;
    case 2:
      breadcrumbTitle = "Invoice Airticket Non Commission";
      addLink = "/noncomairticket";
      break;
    case 3:
      breadcrumbTitle = "Invoice Airticket Reissue";
      addLink = "/reissueairticket";
      break;
    case 4:
      breadcrumbTitle = "Invoice Tour Package";
      addLink = "/tour-invoice/all";
      break;
    case 5:
      breadcrumbTitle = "Invoice Other";
      addLink = "/invoiceother";
      break;
    case 10:
      breadcrumbTitle = "Invoice Visa";
      addLink = "/visa";
      break;
    case 26:
      breadcrumbTitle = "Invoice Ummrah";
      addLink = "/invoiceumrah";
      break;
    case 30:
      breadcrumbTitle = "Invoice Hajj Pre Registration";
      addLink = "/hajjpreregistration";
      break;
    case 31:
      breadcrumbTitle = "Invoice Hajj";
      addLink = "/invoicehajj";
      break;
    default:
  }

  // REFUND FROM VIEW INVOICE : REISSUE/UMMRAH/HAJJ
  const isRefundAble = [3, 26, 31].includes(invoice_category_id || 0);
  const isVoidAble = [1, 2, 3, 5].includes(invoice_category_id || 0);

  if (links) {
    breadcrumbTitle = links.breadcrumbTitle;
    addLink = links.addLink;
  }

  const org_name = useAppSelector(
    (state) => state.user?.organization_info.org_name
  );

  const handlePrint = useReactToPrint({
    onBeforePrint: () => setClickPrint && setClickPrint(true),
    onAfterPrint: () => setClickPrint && setClickPrint(false),
    content: () => selectedPrintableRef.current,
    pageStyle: portraitPageStyle,
    removeAfterPrint: true,
    documentTitle: `${org_name}-${invoiceData?.invoice_no}-${dayjs().unix()}`,
  });

  useHotkeys("ctrl + p", (e) => {
    e.preventDefault();
    handlePrint();
  });

  const voidInvoice = () => {
    setIsModalOpen(true);
  };

  const IsRefund = invoiceData?.invoice_is_refund;
  const IsReissue = invoiceData?.reissued?.length;

  useEffect(() => {
    if (invoiceData?.invoice_date) {
      if (IsReissue || IsRefund) {
        setIsVoid(false);
      } else {
        setIsVoid(true);
      }
    }
  }, [IsReissue, IsRefund]);

  const showModal = () => {
    dispatch(
      setCommonModal({
        title: "Emd Ticket",
        content: <EmdModal invoice_id={invoice_id} invoiceData={invoiceData} />,
        show: true,
        width: 900,
      })
    );
  };
  return (
    <>
      <BreadCrumb arrOfOption={[breadcrumbTitle, "View"]} />
      <Row wrap gutter={[5, 0]}>
        <Col className="commonBox">
          <Link to={addLink}>
            <Button icon={<ArrowLeftOutlined />} type="primary">
              Return to Invoice List
            </Button>
          </Link>
        </Col>
        <Col className="commonBox">
          <Button
            loading={isClickPrint}
            onClick={handlePrint}
            icon={<PrinterOutlined />}
            type="primary"
          >
            Print
          </Button>
        </Col>

        {isVoidAble ||
        (isVoid &&
          !invoiceData?.refunds?.client_refund?.length &&
          !invoiceData?.refunds?.vendor_refund?.length) ? (
          <Col className="commonBox">
            <Button
              onClick={voidInvoice}
              icon={<CloseCircleOutlined />}
              type="primary"
              danger
            >
              Void
            </Button>
          </Col>
        ) : (
          ""
        )}

        {/* <Col className="commonBox">
          <Button
            onClick={showModal}
           
            type="primary"
          >
            Emd
          </Button>
        </Col> */}

        {isRefundAble && (
          <RefundModal
            title={breadcrumbTitle}
            invoiceId={invoice_id}
            invoiceNo={invoiceData?.invoice_no as string}
            invoice_category_id={invoice_category_id}
          />
        )}
      </Row>

      <Modal
        title="Void Ticket"
        open={isModalOpen}
        onOk={() => handleOk(setIsModalOpen)}
        onCancel={() => handleCancel(setIsModalOpen)}
        footer={false}
        width={900}
      >
        <TabInvoiceVoid
          invoice_category_id={invoice_category_id}
          invoice_id={invoice_id}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>

      {adm.inv_id && <TabInvoiceADM setAdm={setAdm} adm={adm} />}
    </>
  );
};

export default ViewInvoiceHeader;
