import { Route } from "react-router";
import PermissionRoute from "../../../auth/components/PermissionRoute";
import { moduleType } from "../../../auth/states/userSlice";
import { crud, rolePermissionType } from "../../../common/types/commonTypes";
import { getListPermission } from "../../../common/utils/common.utils";
import EditInvoiceAirTicket from "../pages/EditInvoiceAirTicket";
import InvoiceAirTicketByPNR from "../pages/InvoiceAirTicketByPNR";
import NewInvoiceAirTicket from "../pages/NewInvoiceAirTicket";
import ListOfInvoiceAirticket from "../pages/ViewInvoiceInfo/ListOfInvoiceAirticket";
import ViewInvoicesDetails from "../pages/ViewInvoiceInfo/ViewInvoiceAirticke";

// DASHBOARD
export const iataAirTicketRoutes = (
  permission: rolePermissionType | undefined,
  hasPermission: (module: moduleType) => boolean | undefined
) => {
  const addMRPermission = permission?.money_receipt?.["create:any"];

  return (
    <>
      <Route
        path="/airticket"
        element={
          <PermissionRoute
            permission={
              hasPermission("invoice_airticket") &&
              getListPermission(permission?.invoice_airticket)
            }
            element={
              <ListOfInvoiceAirticket
                permission={permission?.invoice_airticket}
                addMRPermission={addMRPermission}
              />
            }
          />
        }
      />

      <Route
        path="/airticket/details/:id"
        element={
          <PermissionRoute
            permission={
              hasPermission("invoice_airticket") &&
              permission?.invoice_airticket?.[crud.read]
            }
            element={<ViewInvoicesDetails />}
          />
        }
      />

      <Route
        path="/airticket/add"
        element={
          <PermissionRoute
            permission={
              hasPermission("invoice_airticket") &&
              permission?.invoice_airticket?.[crud.create]
            }
            element={<NewInvoiceAirTicket />}
          />
        }
      />
      <Route
        path="/airticket/pnr/add"
        element={
          <PermissionRoute
            permission={
              hasPermission("invoice_airticket") &&
              permission?.invoice_airticket?.[crud.create]
            }
            element={<InvoiceAirTicketByPNR />}
          />
        }
      />
      <Route
        path="/airticket/edit/:airticketInvoiceId"
        element={
          <PermissionRoute
            permission={
              hasPermission("invoice_airticket") &&
              permission?.invoice_airticket?.["update:any"]
            }
            element={<EditInvoiceAirTicket />}
          />
        }
      />
    </>
  );
};
