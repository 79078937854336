import type { RadioChangeEvent } from "antd";
import { IGetAppConfig } from "../type/appConfig_Type";
import {
  usePostEditAppConfigMutation,
  usePostEditAppConfigSignatureMutation,
} from "../endpoint/appConfigEndpoint";
import { Col, Radio, Row, Space, Typography } from "antd";
import FileUpload from "../../../../common/utils/FileUpload";
type Props = {
  configDetails: IGetAppConfig | undefined;
  setConfigDetails: React.Dispatch<
    React.SetStateAction<IGetAppConfig | undefined>
  >;
};
const IataLogo = ({ configDetails, setConfigDetails }: Props) => {
  const [postEdit] = usePostEditAppConfigMutation();
  const [postEditSignature] = usePostEditAppConfigSignatureMutation();

  const defaultValue = configDetails?.iata_wtr_mark;

  const onChange = (e: RadioChangeEvent) => {
    const body: IGetAppConfig = {
      ...configDetails,
      iata_wtr_mark: e.target.value,
    };

    postEdit(body);
  };

  const handleImageUpload = (img: any) => {
    const formData = new FormData();
    if (img) formData.append("iata_wtr_mark_url", img[0]?.originFileObj);

    postEditSignature(formData);
  };
  return (
    <div
      style={{
        maxWidth: 900,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Row justify={"space-between"}>
        <Col>
          <Typography.Title level={4}>Iata Logo :</Typography.Title>
          <Typography.Text type="secondary">
            You can customize the watermark on your iata logo.
          </Typography.Text>
        </Col>
        <Col>
          <Radio.Group onChange={onChange} value={defaultValue}>
            <Space direction="vertical">
              <Radio value={0}>No Watermark</Radio>
              <Radio value={1}>Watermark (Default Logo)</Radio>
            </Space>
            <div style={{ marginTop: 10 }}>
              <FileUpload
                noBorder
                size={24}
                msg="Iata Logo"
                onChange={(img) => handleImageUpload(img)}
                imagURL={configDetails?.iata_wtr_mark_url}
              />
            </div>
          </Radio.Group>
        </Col>
      </Row>
    </div>
  );
};

export default IataLogo;
