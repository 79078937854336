import { ArrowLeftOutlined, DollarOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { IAllInvoices } from '../../../../common/types/commonInterfaces';
import { IPermission } from '../../../../common/types/commonTypes';
import {
  filterNullColumns,
  formatAmount,
  rangePresets,
  separateClient,
} from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import Reload from '../../../../components/common/reload/Reload';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import InvoiceOtherAddMoneyModal from '../../../Invoice(Other)/Modals/InvoiceOtherAddMoneyModal';
import {
  useDeleteReissueMutation,
  useGetAllInvoicesReissueQuery,
} from '../../api/endpoints/reIssueAirTicketInvoiceEndpoints';

type Props = {
  permission: IPermission | undefined;
  addMRPermission: ['*'] | undefined;
};

const ListOfInvoiceReissue = ({ permission, addMRPermission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);
  const [openModal, setOpenModal] = useState(false);
  const [invoiceInfo, setInvoiceInfo] = useState<IAllInvoices>();
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const {
    data,
    isFetching,
    isLoading: isLoadingAll,
    refetch,
  } = useGetAllInvoicesReissueQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });

  const [
    deleteInvoice,
    { isError, isSuccess, isLoading: isLoadingDelete, error },
  ] = useDeleteReissueMutation();

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  // LINKS
  const createLink = '/reissueairticket/newclient';
  const viewLink = '/reissueairticket/details';
  const editLink = '/reissueairticket/edit';
  const editListExisting = '/reissueairticket/edit/existing';

  // @ONClICK
  const handleOnDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteInvoice({
      id,
      user_id: user?.user_id,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Invoice Deleted Successfully!');
    }
    if (isError) {
      setColumnIndex(null);
      message.error('Thare was an error');
    }
  }, [isSuccess, isError]);

  const ModalOperation = (data: IAllInvoices) => {
    setInvoiceInfo(data);
    setOpenModal(!openModal);
  };

  const columns: ColumnsType<IAllInvoices> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      align: 'center',
    },
    {
      title: 'Invoice Type',
      dataIndex: 'invoice_reissue_client_type',
      key: 'invoice_reissue_client_type',
      width: 80,
      render: (item) => {
        let color = 'red';
        if (item === 'IUR') {
          color = 'cyan';
        } else if (item === 'NEW') {
          color = 'blue';
        }
        return <Tag color={color}>{item}</Tag>;
      },
    },
    {
      title: 'Sales Date',
      key: 'invoice_date',
      align: 'center',
      render: (_, data) => (
        <>{dayjs(data.invoice_date).format('DD MMM YYYY')} </>
      ),
      sorter: (a, b) =>
        dayjs(a.invoice_date).unix() - dayjs(b.invoice_date).unix(),
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => {
        const { client_id, combined_id } = separateClient(record?.comb_client);

        return (
          <Link
            to={
              client_id
                ? `/clients/details/${record?.comb_client}`
                : `/combineClients/details/${combined_id}`
            }
          >
            <span>
              {record.client_name}
              {record.mobile ? ' ' + record.mobile : ''}
            </span>
          </Link>
        );
      },
      width: 150,
    },
    {
      title: 'Sales Price',
      dataIndex: 'net_total',
      key: 'net_toal',
      width: 120,
      align: 'right',
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),

      render: (curr) => (
        <span className='color-sales'>{formatAmount(curr)}</span>
      ),
    },
    {
      title: 'Rec Amount',
      dataIndex: 'invclientpayment_amount',
      key: 'invclientpayment_amount',
      align: 'right',
      width: 120,
      render: (curr) => (
        <span className='color-collection'>{formatAmount(curr)}</span>
      ),
    },
    {
      title: 'Due Amount',
      align: 'right',
      width: 120,
      render(_, record) {
        const dueAmount =
          Number(record?.net_total) - Number(record?.invclientpayment_amount);

        return dueAmount > 0 ? (
          <span style={{ color: 'red' }}>
            {formatAmount(
              Number(record?.net_total) -
                Number(record?.invclientpayment_amount)
            )}
          </span>
        ) : (
          <Tag color='cyan' icon={<DollarOutlined />}>
            PAID
          </Tag>
        );
      },
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),
    },
    {
      title: 'MR. No.',
      dataIndex: 'money_receipt_num',
      key: 'money_receipt_num',
      width: 120,
      render: (_, data) => (
        <span>
          {data?.money_receipt_num?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },

    {
      title: 'Sales by',
      dataIndex: 'sales_by',
      key: 'sales_by',
      width: 150,
    },

    {
      title: 'Action',
      key: 'operation',

      render: (_, data, index) => (
        <Space size='small'>
          {permission?.['read:any'] && (
            <Link
              to={viewLink + '/' + data.invoice_id}
              state={location?.pathname}
            >
              <Button size='small' type='primary'>
                View
              </Button>
            </Link>
          )}

          {!data.invoice_is_reissued && permission?.['update:any'] ? (
            <Link
              to={
                data.invoice_reissue_client_type === 'NEW'
                  ? editLink + '/' + data?.invoice_id
                  : editListExisting + '/' + data?.invoice_id
              }
              state={location?.pathname}
            >
              <Button size='small' type='primary'>
                Edit
              </Button>
            </Link>
          ) : (
            ''
          )}
          {!data.invoice_is_reissued &&
            permission?.['delete:any'] &&
            !Number(data?.invclientpayment_amount || 0) &&
            data.billing_status !== 'Pending' && (
              <Button
                size='small'
                type='primary'
                danger
                loading={columnIndex === index && true}
              >
                <Popconfirm
                  onConfirm={() => handleOnDelete(data?.invoice_id, index)}
                  title='Sure to delete?'
                >
                  Delete
                </Popconfirm>
              </Button>
            )}

          {addMRPermission && !data?.invoice_is_refund && (
            <Button
              disabled={
                Number(data?.net_total) <= Number(data?.invclientpayment_amount)
              }
              onClick={(e) => ModalOperation(data)}
              size='small'
              type='primary'
            >
              Add Money
            </Button>
          )}
        </Space>
      ),
    },
  ];

  // Handle Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  const reissueList = filterNullColumns(data?.data, columns);

  return (
    <>
      {/* BREADCRUMB */}
      <BreadCrumb arrOfOption={['Invoice', 'Air Ticket', 'Reissue']} />

      {/* HEADER */}
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: '25px' }}
        justify={'space-between'}
      >
        {invoiceInfo?.invoice_id && (
          <InvoiceOtherAddMoneyModal
            invoice_id={invoiceInfo?.invoice_id}
            InvocieModalTitle={'Invoice Air Ticket'}
            disabled={false}
            setOpenModal={setOpenModal}
            openModal={openModal}
          />
        )}

        <Col span={12}>
          <Row gutter={10}>
            {permission?.['create:any'] && (
              <Col xs={24} sm={12} md={8} lg={8}>
                <Link to={createLink} state={`/reissueairticket/existing`}>
                  <Button
                    type='primary'
                    icon={<ArrowLeftOutlined />}
                    style={{ width: '100%' }}
                  >
                    Create
                  </Button>
                </Link>
              </Col>
            )}

            <Col xs={24} sm={12} md={8} lg={8} style={{ display: 'flex' }}>
              <Col>
                <Reload
                  refetch={() => {
                    refetch();
                  }}
                />
              </Col>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='Search Invoices...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* TABLE */}
      <Table
        columns={reissueList.col}
        rowClassName={(record) => {
          const { invoice_is_reissued: reissue, invoice_is_refund: refund } =
            record;
          let className = '';

          if (reissue) return (className = 'reissued-row-style');
          if (refund) return (className = 'refund-row-style');

          return className;
        }}
        bordered
        rowKey={(e) => e.invoice_id}
        loading={{
          spinning: isLoadingAll || isFetching || isLoadingDelete,
          indicator: loadingIndicator,
        }}
        dataSource={data?.data}
        scroll={{ x: true }}
        size='small'
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </>
  );
};

export default ListOfInvoiceReissue;
