import { message } from "antd";
import { api } from "../../../../app/baseQuery";
import { HTTPResponse } from "../../../../common/types/commonTypes";
import {
  IGetAllSignature,
  IGetAppConfig,
  IUpdateAppConfig,
} from "../type/appConfig_Type";

const tags = "APPCONFIG";
const signature = "signature";

export const appConfigEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getAppConfig: build.query<HTTPResponse<IGetAppConfig>, void>({
      query: (arg) => ({
        url: `configuration/office/app-config`,
      }),
      providesTags: () => [tags],
    }),

    postEditAppConfig: build.mutation<HTTPResponse<string>, IGetAppConfig>({
      query: (body) => ({
        url: `configuration/office/app-config`,
        method: "PATCH",
        body: body,
      }),

      transformErrorResponse: (response) => {
        if (response.status === 400 && response.data) {
          const { message: err } = response.data as {
            message: string;
            success: boolean;
          };
          message.error(`${err}`);
        } else {
          message.error("Something went wrong");
        }
      },
      invalidatesTags: () => [tags],
    }),

    postEditAppConfigSignature: build.mutation<HTTPResponse<string>, FormData>({
      query: (body) => ({
        url: `configuration/office/app-config/signature`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: () => [signature],
    }),

    postEmployeeSignature: build.mutation<HTTPResponse<string>, FormData>({
      query: (body) => ({
        url: `configuration/office/signature`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: () => [signature],
    }),
    getAllEmployeeSignature: build.query<
      HTTPResponse<IGetAllSignature[]>,
      { pageSize: number; current: number; search?: string }
    >({
      query: (arg) => ({
        url: `configuration/office/signature?page=${arg.current}&size=${arg.pageSize}`,
      }),
      providesTags: () => [signature],
    }),
    getEmployeeSignatureStatus: build.mutation<
      HTTPResponse<IGetAllSignature[]>,
      { id: number; status: "ACTIVE" | "INACTIVE" }
    >({
      query: ({ id, status }) => {
        return {
          url: `configuration/office/signature/${id}`,
          body: { status: status },
          method: "PUT",
        };
      },
      invalidatesTags: () => [signature],
    }),

    updateEmployeeSignature: build.mutation<
      HTTPResponse<void>,
      { id: number; body: FormData }
    >({
      query: ({ body, id }) => ({
        url: `configuration/office/signature/${id}`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: () => [signature],
    }),
  }),
});

export const {
  usePostEditAppConfigMutation,
  useGetAppConfigQuery,
  useLazyGetAppConfigQuery,
  usePostEditAppConfigSignatureMutation,
  usePostEmployeeSignatureMutation,
  useGetAllEmployeeSignatureQuery,
  useGetEmployeeSignatureStatusMutation,
  useUpdateEmployeeSignatureMutation,
} = appConfigEndpoints;
