import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import { useEffect, useRef, useState } from 'react';

import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { useWatch } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
  rangePresets,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import {
  SelectEmployee,
  SelectProduct,
} from '../../../../components/common/FormItem/SelectCustomFeilds';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useLazyGetSalesManExcelsReportQuery,
  useLazyGetsalesReportItemAndSalesmanQuery,
} from '../endpoints/salesReportItemAndSalesmanEndpoints';
import {
  ISalesItemAndSalesManFormdataType,
  ISalesItemType,
} from '../types/salesReportItemAndSalesmanTypes';
import SalesReportItemAndSalesManColumn from '../utils/SalesReportItemAndSalesManColumn';
const SalesReportItemAndSalesMan = () => {
  const [paramData, setParamData] = useState<{
    date_range: string;
    id: number | string;
  }>();
  const [form] = Form.useForm();
  const employee_id = useWatch('employee_id', form);
  const product_id = useWatch('product_id', form);
  const [fetchExcel] = useLazyGetSalesManExcelsReportQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    sales_man_id: string;
    query: string;
  }>({
    Id: '',
    sales_man_id: '',
    query: '',
  });

  const [fetchSalesReportItem, { data, isLoading, isFetching }] =
    useLazyGetsalesReportItemAndSalesmanQuery();

  let totalAmount: number = data?.data?.total.total_sales || 0;

  const onFinish = async (values: ISalesItemAndSalesManFormdataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: ISalesItemType = {
      date_range: date_range,
      item_id: values.product_id,
      sales_man_id: values.employee_id,
    };
    await fetchSalesReportItem({
      item_id: body.item_id,
      sales_man_id: body.sales_man_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: body.item_id.toString(),
      sales_man_id: body.sales_man_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setParamData({
      date_range: date_range,
      id: body.sales_man_id.toString(),
    });
  };

  const to_date = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    const date_range = `from_date=${to_date}&to_date=${to_date}`;
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('product_id', 'all');
    form.setFieldValue('employee_id', 'all');
    fetchSalesReportItem({
      item_id: 'all',
      sales_man_id: 'all',
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: 'all',
      sales_man_id: 'all',
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);
  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  const LastPage = Math.ceil(count / (tableParams?.pagination?.pageSize || 0));

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: ISalesItemAndSalesManFormdataType = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      item_id: values.product_id,
      sales_man_id: values.employee_id,
    };
    fetchSalesReportItem({
      item_id: body.item_id,
      sales_man_id: values.sales_man_id,
      query: `${query}&${body.date_range}`,
    });

    setExcelQuery({
      Id: body.item_id.toString(),
      sales_man_id: body.sales_man_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Item & Sales Man-Wise Sales Report`,
  });

  const employeeColInfo = [
    {
      title: '',
      dataIndex: 'property',
      key: 'property',
      width: '150px',
    },
    {
      title: '',
      dataIndex: 'value',
      key: 'value',
      width: '150px',
    },
  ];

  const employeeInfoData = [
    {
      key: '1',
      property: 'Name',
      value: data?.data?.employee?.employee_full_name,
    },
    {
      key: '2',
      property: 'Mobile',
      value: data?.data?.employee?.employee_mobile,
    },
    {
      key: '3',
      property: 'Department',
      value: data?.data?.employee?.department_name,
    },
    {
      key: '4',
      property: 'Designation',
      value: data?.data?.employee?.designation_name,
    },
  ];

  // @HANDLE CONTENTS
  const jsx_com = data?.data?.report.length ? (
    <>
      <FormHeaderTitle title={'Item & Sales Man-Wise Sales Report'} />

      <Table
        size='small'
        bordered
        columns={SalesReportItemAndSalesManColumn(queryData, paramData)}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        dataSource={data?.data?.report}
        pagination={count > 50 ? tableParams.pagination : false}
        onChange={(args1, args2, args3) =>
          handleTableChange({
            args: {
              pagination: args1,
              filters: args2,
              sorter: args3,
            },
            states: {
              refetch,
              setQueryData,
              setTableParams,
              tableParams,
            },
          })
        }
        summary={(_) => {
          if (tableParams.pagination?.current === LastPage)
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={6}>
                    <div
                      style={{
                        color: 'green',
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>Total: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align='left'>
                    <Typography
                      style={{
                        textAlign: 'right',
                        marginRight: '5px',
                        fontWeight: 'bold',
                      }}
                    >
                      {Number(totalAmount).toFixed(2)}
                    </Typography>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
        }}
      />
    </>
  ) : (
    <></>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Item & Sales Man-Wise Sales Report' }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb
        arrOfOption={['Reports', 'Item & Sales Man-Wise Sales Report']}
      />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              employee_id: excelQuery.sales_man_id,
              excelApiName: 'sales/salesManItem',
              excelName: 'sales_report_item_and_salesman',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>

      <Form layout='vertical' form={form} onFinish={onFinish}>
        <div className=' '>
          <Row
            gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
            align='middle'
            justify={'space-between'}
          >
            <div style={{ display: 'flex' }}>
              <SelectEmployee
                name='employee_id'
                size={8}
                label='Select sales by'
                placeholder='Select sales by'
                showAll
                offDropDown
              />
              <SelectProduct
                size={8}
                name='product_id'
                placeholder='select Item'
                label='Select Product'
                onChange={(value) => {}}
                showAll
              />
              <Col span={6} xs={24} sm={24} md={24} lg={10}>
                <Form.Item label='Date Range' name='date_range' required>
                  <DatePicker.RangePicker
                    presets={rangePresets}
                    format={'DD-MM-YYYY'}
                    allowClear={false}
                  />
                </Form.Item>
              </Col>
              <FormButton
                label='Search'
                icon
                textAlign='left'
                disabled={employee_id || product_id ? false : true}
                loading={isLoading}
              />
            </div>
            {data?.data?.employee && (
              <Col className='p-5 '>
                <FormHeaderTitle title='Employee Details' />
                <Table
                  rowKey={(e) => e.key}
                  bordered
                  className='invoiceBillingTable'
                  rowClassName={'invoiceBillingTd'}
                  columns={employeeColInfo}
                  dataSource={employeeInfoData}
                  showHeader={false}
                  size='small'
                  pagination={false}
                  locale={{ emptyText: ' ' }}
                  loading={{
                    spinning: isLoading,
                    indicator: loadingIndicator,
                  }}
                />
              </Col>
            )}
          </Row>
        </div>
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default SalesReportItemAndSalesMan;
