import { message } from "antd";
import { api } from "../../../../../app/baseQuery";
import { HTTPResponse } from "../../../../../common/types/commonTypes";
import assyncWrapper from "../../../../../common/utils/assyncWrapper";

export const emdEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    //get all loan
    getDetailsForEmd: build.query<HTTPResponse<emdItemsList[]>, number>({
      query: (id) => ({
        url: `/invoice-air-ticket/emd/${id}`,
      }),
      //   providesTags: [
      //     { type: 'Loan', id: LOAN },
      //     { type: 'Payment', id: PAYMENT },
      //     { type: 'Receive', id: RECEIVE },
      //   ],
    }),

    postEmd: build.mutation<HTTPResponse<void>, IPostVoid[]>({
      query: (body) => ({
        url: `/invoice-air-ticket/emd`,
        body: body,
        method: "POST",
      }),
      onQueryStarted: async (_arg, { queryFulfilled }) => {
        assyncWrapper(async () => {
          await queryFulfilled;
          message.success("Emd created successfully");
        });
      },
      invalidatesTags: () => [
        { type: "Void", id: "LIST" },
        "invoices",
        "view-invoice",
      ],
    }),
  }),
});

export const { useGetDetailsForEmdQuery, usePostEmdMutation } = emdEndpoints;

export interface emdItemsList {
  emd_id: number;
  emd_ticket_no: string;
  emd_client: string;
  emd_ctrxn_id: number;
  emd_invoice_id: number;
  emd_airticket_id: number;
  emd_category_id: number;
  emd_penalties: string;
  emd_ait: string;
  emd_commission: string;
  emd_created_by: number;
  emd_date: string;
  user_full_name: string;
}

export interface voidItemsVendor {
  vendor_name: string;
  comb_vendor: string;
  cost_price: string;
  airticket_discount_total: string;
  airticket_ticket_no: string;
  airticket_id: number;
  sales: string;
}

export interface IPostVoid {
  emd_ticket_no: string;
  emd_invoice_id: number;
  emd_airticket_id: number;
  emd_category_id: number;
  emd_penalties: string;
  emd_ait: string;
  emd_commission: string;
  emd_client: string;
  invoice_no: string;
  emd_date: string;
  airticket_ticket_no?: string;
}
