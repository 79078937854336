import {
  ArrowLeftOutlined,
  FileTextOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Row, Space, Tabs } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import useQueryParam from "../../../../common/hooks/useQueryParams";
import { SelectBSPFile } from "../../../../components/common/FormItem/SelectCustomFeilds";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import { capitalizeFirstLetter } from "../../../../helpers/CapitalizeFirstLetter";
import { useGetBspBillingCrossCheckQuery } from "../api/bspBillApisEndpoints";
import BSP_Refund from "../components/BSP_Refund";
import BSP_Summary from "../components/BSP_Summary";
import BSP_Ticket from "../components/BSP_Ticket";

type Props = {};

export type report_type = "SUMMARY" | "TICKET" | "REFUND";

const BSPBill_Compare = (props: Props) => {
  const { id } = useParams();
  const [form] = useForm();
  const navigate = useNavigate();

  const file_id = useWatch("file_id", form);
  const [reportType, setReportType] = useQueryParam<string, report_type>(
    "report_type",
    "SUMMARY"
  );

  const [fileID, setFileID] = useQueryParam("fileID", file_id);

  useEffect(() => {
    if (fileID !== "undefined") {
      form.setFieldsValue({ file_id: Number(fileID) });
    } else if (id) {
      form.setFieldsValue({ file_id: Number(id) });
    }
  }, [id, fileID]);

  useEffect(() => {
    if (file_id) {
      setFileID(file_id);
    }
  }, [file_id]);

  const { data, isLoading, isFetching } = useGetBspBillingCrossCheckQuery(
    {
      id: file_id!,
      type: reportType,
    },
    { skip: !file_id }
  );

  const bspData = data?.data;

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `BSP Report`,
    // pageStyle: landscapePageStyle,
  });

  const jsx = (
    <>
      {reportType === "SUMMARY" ? (
        <BSP_Summary
          bspData={bspData?.summary}
          isLoading={isLoading || isFetching}
        />
      ) : null}
      {reportType === "TICKET" ? (
        <BSP_Ticket
          bspData={bspData?.tickets}
          isLoading={isLoading || isFetching}
        />
      ) : null}
      {reportType === "REFUND" ? (
        <BSP_Refund
          bspData={bspData?.refunds}
          isLoading={isLoading || isFetching}
        />
      ) : null}
    </>
  );

  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx}
        printRef={componentRef}
        title={{
          info_title: `BSP Bill ${capitalizeFirstLetter(reportType)}`,
          title: `BSP Bill ${capitalizeFirstLetter(reportType)}`,
        }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={["Report", `BSP Bill SUMMARY`]} />

      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <Space style={{ marginBottom: "1rem" }}>
            <Button
              type="primary"
              onClick={() => navigate("/reports/bsp-bill-list")}
            >
              <ArrowLeftOutlined />
              Back to List/Upload
            </Button>
            <Button type="primary" onClick={handlePrint}>
              <PrinterOutlined />
              Print
            </Button>
            <Button type="primary" onClick={() => {}}>
              <FileTextOutlined />
              Excel Report
            </Button>
          </Space>
        </Col>
        <Col span={6}>
          <Form form={form} layout="vertical">
            <SelectBSPFile
              size={24}
              label="Select BSP File :"
              name={"file_id"}
            />
          </Form>
        </Col>
      </Row>
      {(isLoading || isFetching) && <LoadingIndicator />}
      <Tabs
        type="card"
        onChange={(e: any) => setReportType(e)}
        defaultActiveKey={reportType}
        items={[
          {
            label: "SUMMARY",
            key: "SUMMARY",
            animated: true,
            children: (
              <BSP_Summary
                bspData={bspData?.summary}
                isLoading={isLoading || isFetching}
              />
            ),
          },
          {
            label: "TICKET",
            key: "TICKET",
            animated: true,
            children: (
              <BSP_Ticket
                bspData={bspData?.tickets}
                isLoading={isLoading || isFetching}
              />
            ),
          },
          {
            label: "REFUND",
            key: "REFUND",
            animated: true,
            children: (
              <BSP_Refund
                bspData={bspData?.refunds}
                isLoading={isLoading || isFetching}
              />
            ),
          },
        ]}
      />

      {print_content}
    </>
  );
};

export default BSPBill_Compare;

export interface IAllInvoices {
  key: string;
  slNo: string;
  ticket: string;
  airline: string;
  issueDate: string;
  remarks: string;
  netPayable: number;
}
