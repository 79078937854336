import { Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Fixed2 } from '../../../../common/utils/common.utils';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import FormHeaderTitle from '../../../Invoice(Visa)/Components/FormHeaderTitle';
import {
  useGetSalesIDQuery,
  useLazyGetExcelsClinetWiseCollandSalesQuery,
} from '../endpoints/clientWiseCollectionAndSalesEndpoints';
import { ClientWiseSalesUtils } from '../utils/ClientWiseCollectionAndSalesUtils';

type Props = {
  date: any;
  client: string;
};

const OnlySalesReport = ({ date, client }: Props) => {
  const [fetchExcel] = useLazyGetExcelsClinetWiseCollandSalesQuery();

  // Handle Pagination For Sales
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  // API Call
  const { isError, isFetching, isLoading, data } = useGetSalesIDQuery({
    client_id: client,
    from: dayjs(date[0]).format('YYYY-MM-DD'),
    to: dayjs(date[1]).format('YYYY-MM-DD'),
    page: pagination.current,
    size: pagination.pageSize,
  });

  const SalesLastPage = Math.ceil(
    Number(data?.data?.sales_count || 0) / (pagination?.pageSize || 0)
  );

  // Count Total
  let salesTotal: number = Number(data?.data?.sales?.sales_price || 0);

  return (
    <>
      <FormHeaderTitle title='Sales' />
      <Table
        bordered
        dataSource={data?.data?.sales?.sales_data}
        columns={ClientWiseSalesUtils(pagination)}
        size='small'
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        pagination={
          data?.data?.sales_count !== undefined && data?.data?.sales_count < 20
            ? false
            : {
                ...pagination,
                total: data?.data?.sales_count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        summary={(_) => {
          if (SalesLastPage === pagination.current)
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={8}>
                    <div
                      style={{
                        color: 'green',
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>Total: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align='right'>
                    <Typography
                      style={{
                        textAlign: 'right',
                        marginRight: '10px',
                        fontWeight: 'bold',
                      }}
                    >
                      {Fixed2(salesTotal)}
                    </Typography>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
        }}
      />
    </>
  );
};

export default OnlySalesReport;
