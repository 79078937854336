import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Form, Space, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetExcelQuery } from '../../ledgers/endpoints/ledgerEndpoints';
import { useLazyGetAllVisaWiseProfitlossQuery } from '../endpoints/profitLossEndpoints';
import { IVisaWiseFormData } from '../types/visaWiseProfitLossTypes';
import { VisaWiseProfitLossColumn } from '../utils/VisaWiseProfitLossColumn';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';

const Visa_Wise_Profit_loss = () => {
  const [form] = Form.useForm();
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
    query: string;
  }>({
    Id: '',
    query: '',
  });
  let saleSum: number = 0;
  let costSum: number = 0;
  let profit_Loss_Sum: number = 0;
  const [fetchVisaProfitLoss, { data, isLoading }] =
    useLazyGetAllVisaWiseProfitlossQuery();

  if (data?.data) {
    for (let i = 0; i < data?.data.length; i++) {
      const element = data?.data[i];
      saleSum += Number(element.costitem_sale_price);
      costSum +=
        Number(element.costitem_cost_price) * Number(element.billing_quantity);
      profit_Loss_Sum += Number(
        Number(element.costitem_sale_price) -
        Number(element.costitem_cost_price) * Number(element.billing_quantity)
      );
    }
  }
  const onFinish = async (values: IVisaWiseFormData) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;

    const body: IVisaWiseFormData = {
      date_range: date_range,
      visa_id: values.visa_id,
    };
    await fetchVisaProfitLoss({
      visa_id: body.visa_id,
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });

    setExcelQuery({
      Id: body.visa_id.toString(),
      query: `?${body.date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  };
  const toDayDate = dayjs().format('YYYY-MM-DD');
  useEffect(() => {
    const date_range = `from_date=${toDayDate}&to_date=${toDayDate}`;
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
    form.setFieldValue('visa_id', 'all');
    fetchVisaProfitLoss({
      visa_id: 'all',
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: 'all',
      query: `?${date_range}&page=${queryData.current}&size=${queryData.pageSize}`,
    });
  }, []);

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 0, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IVisaWiseFormData = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body: IVisaWiseFormData = {
      date_range: date_range,
      visa_id: values.visa_id,
    };

    fetchVisaProfitLoss({
      visa_id: body.visa_id,
      query: `${query}&${body.date_range}`,
    });
    setExcelQuery({
      Id: body.visa_id.toString(),
      query: `${query}&${body.date_range}`,
    });
  };
  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Visa Wise Profit Loss`,
  });

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <ConfigProvider
        theme={
          {
            // algorithm: theme.defaultAlgorithm,
          }
        }
      >
        <Table
          size='small'
          bordered
          pagination={count > 50 ? tableParams.pagination : false}
          columns={VisaWiseProfitLossColumn(queryData)}
          dataSource={data?.data}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
          onChange={(args1, args2, args3) =>
            handleTableChange({
              args: {
                pagination: args1,
                filters: args2,
                sorter: args3,
              },
              states: {
                refetch,
                setQueryData,
                setTableParams,
                tableParams,
              },
            })
          }
          summary={(_) => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={5}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div
                      style={{
                        color: 'green',
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>Total Sale: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        color: 'green',
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>{saleSum}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>

                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={5}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div
                      style={{
                        color: 'green',
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>Total Cost: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        color: 'green',
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>{costSum}</Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
                <Table.Summary.Row>
                  <Table.Summary.Cell
                    index={0}
                    colSpan={5}
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div
                      style={{
                        color: 'green',
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>
                        Total Profit/Loss:
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        color: 'green',
                        fontWeight: '700',
                        textAlign: 'right',
                      }}
                    >
                      <Typography.Text strong>
                        {profit_Loss_Sum}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </ConfigProvider>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Visa Wise Profit/Loss Ledger' }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb
        arrOfOption={['Reports', 'ProfitLoss', 'Visa Wise Profit/Loss Ledger']}
      />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
              query: excelQuery.query,
              excelApiName: 'profitLoss/visaWiseProfitLoss',
              excelName: 'visa_wise_profit',
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader
          selectVisa
          required
          date_range
          reports_title='Visa Wise Profit/Loss Ledger'
        />
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default Visa_Wise_Profit_loss;
