import { Button, Form, Input, Select } from "antd";
import { useAppDispatch } from "../../../../app/hooks";
import { IContact, IContactFormData } from "../types/contactTypes";
import { useWatch } from "antd/es/form/Form";
import { useEffect } from "react";
import {
  SelectAirline,
  SelectClient,
  SelectVendors,
} from "../../../../components/common/FormItem/SelectCustomFeilds";
import { FormInputItem } from "../../../../components/common/FormItem/FormItems";
import TextArea from "antd/es/input/TextArea";
import { useEditContactMutation } from "../Endpoints/contactsEndpoints";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import { setCommonModal } from "../../../../common/slices/modalSlice";
const EditContactModal = ({ info }: { info: IContact }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    if (info) {
      form.setFieldsValue({
        ...info,

        // ac_account_id: {
        //   label: info.joined_name,
        //   value:
        //     info?.joined_type === "vendor"
        //       ? "vendor-" + info.account_contact_id
        //       : info.account_contact_id,
        // },
      });
    }
  }, [info]);

  const [editContact, { isSuccess, isLoading }] = useEditContactMutation();
  const handleSubmit = async (values: IContactFormData) => {
    const accountID = values.ac_account_id?.value
      ? typeof values.ac_account_id?.value === "string"
        ? values.ac_account_id.value.split("-")[1]
        : values.ac_account_id.value
      : typeof values.ac_account_id === "string"
      ? values.ac_account_id.split("-")[1]
      : values.ac_account_id;

    const body = {
      ...values,
      ac_account_id: Number(accountID),
    };

    await editContact({ body, id: info?.account_contact_id });
  };
  const accountType = useWatch("ac_account_type", form);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setCommonModal());
    }
  }, [isSuccess]);

  return (
    <>
      <Form
        layout="horizontal"
        form={form}
        labelAlign="left"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Account Type"
          name="ac_account_type"
          rules={[
            {
              required: true,
              message: "Please select account type",
            },
          ]}
        >
          <Select
            placeholder="Select account type"
            options={[
              { value: "client", label: "Client" },
              { value: "vendor", label: "Vendor" },
              { value: "airline", label: "Airline" },
              { value: "visa", label: "Visa" },
            ]}
          />
        </Form.Item>
        {accountType === "client" && (
          <SelectClient
            label={"Select Client"}
            placeholder="Select client"
            name="ac_account_id"
            required
            size={24}
          />
        )}

        {accountType === "vendor" && (
          <SelectVendors
            required={true}
            name="ac_account_id"
            size={24}
            showAll
            label="Select Vendor"
            placeholder="Select Vendor"
          />
        )}
        {accountType === "airline" && (
          <SelectAirline
            label={"Select Airline"}
            placeholder="Select Airline"
            name="ac_account_id"
            required
            size={24}
          />
        )}

        <Form.Item
          label="Name"
          name="ac_contact_name"
          rules={[
            { required: true, message: "Please enter company name!" },
            { max: 75, message: "Maximum 65 characters" },
          ]}
        >
          <Input placeholder="Enter contact person name" />
        </Form.Item>
        <Form.Item
          label="Designation"
          name="ac_contact_designation"
          // rules={[
          //   { required: true, message: "Please enter contact person name!" },
          //   { max: 75, message: "Maximum 65 characters" },
          // ]}
        >
          <Input placeholder="Enter contact person designation" />
        </Form.Item>
        <FormInputItem
          size={24}
          name={"ac_contact_email"}
          label={"Email"}
          inputType="email"
          rules={[
            {
              type: "email",
              message: "Please enter a valid email address",
            },
          ]}
        />
        <Form.Item
          label="Phone"
          name="ac_contact_phone"
          rules={[{ max: 20, message: "Maximum 20 characters" }]}
        >
          <Input placeholder="Enter phone number" />
        </Form.Item>

        <Form.Item label="Address" name="ac_contact_address">
          <TextArea placeholder="Enter address" />
        </Form.Item>
        <Form.Item label="Remark" name="ac_remark">
          <TextArea placeholder="Enter remark" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
          {/* loading={isLoading} */}
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Update
          </Button>
        </Form.Item>
        {isLoading && <LoadingIndicator />}
      </Form>
    </>
  );
};

export default EditContactModal;
