import { Button, Popconfirm, Space, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUser } from "../../../../auth/states/userSlice";
import {
  IPermission,
  ISetState,
  crud,
} from "../../../../common/types/commonTypes";
import { useDeleteReceiveMutation } from "../../Api/Endpoints/ReceiveEndpoints";
import {
  ILoanDeleteRestoreBody,
  ViewLoanReceiveDataType,
} from "../../LoanTypes/LoanTypes";
import { useEffect, useState } from "react";

export const ViewReceiveList = ({
  setEditInfo,
  showModal,
  permission,
  pagination,
}: {
  setEditInfo: ISetState<ViewLoanReceiveDataType | null>;
  showModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<ViewLoanReceiveDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const setEditAccount = (value: ViewLoanReceiveDataType) => {
    setEditInfo(value);
    showModal();
  };
  const user = useSelector(selectUser);

  const [deleteReceive, { isError, isLoading, isSuccess }] =
    useDeleteReceiveMutation();
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);
  const handleDelete = async (
    values: ViewLoanReceiveDataType,
    index: number
  ) => {
    const body: ILoanDeleteRestoreBody = {
      deleted_by: user?.user_id as number,
    };
    setColumnIndex(index);
    await deleteReceive({ body: body, id: values.received_id });
  };

  return [
    {
      title: "SL.",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: "Date",
      key: "payment_date",
      render: (_, record) => {
        return `${dayjs(record.received_date).format("DD MMM YYYY")}`;
      },
    },
    {
      title: "Voucher No",
      dataIndex: "received_vouchar_no",
      key: "received_vouchar_no",
    },
    {
      title: "Loan Authority",
      dataIndex: "authority_name",
      key: "authority_name",
    },
    {
      title: "Loan Name",
      dataIndex: "loan_name",
      key: "loan_name",
    },

    {
      title: "Method",
      dataIndex: "loan_pay_type",
      key: "loan_pay_type",
    },
    {
      title: "Details",
      dataIndex: "pay_details",
      key: "pay_details",

      render: (_, record) => {
        let color = record.pay_details === "DEPOSIT" ? "green" : "red";
        function status(record: any) {
          if (
            record.pay_details == "DEPOSIT" ||
            record.pay_details == "PENDING" ||
            record.pay_details == "BOUNCE"
          ) {
            return <span style={{ color }}> {record.pay_details} </span>;
          } else {
            return `${record.account_name}`;
          }
        }

        return status(record);
      },
    },

    {
      title: "Amount",
      dataIndex: "received_amount",
      key: "received_amount",
      render: (_, record) => Number(record.received_amount),
    },

    {
      title: "Action",
      key: "operation",

      render: (_, record, index) => {
        return (
          <Space size="small">
            <Link
              to={`/loan/receive/view/${record.received_id}`}
              state={location.pathname}
            >
              <Button size="small" type="primary">
                <Typography style={{ color: "#ffffff" }}>View</Typography>
              </Button>
            </Link>
            {permission?.[crud.update] && record.pay_details !== "DEPOSIT" && (
              <Button
                size="small"
                type="primary"
                onClick={() => setEditAccount(record)}
              >
                Edit
              </Button>
            )}

            {permission?.[crud.delete] && (
              <Button
                danger
                size="small"
                type="primary"
                loading={columnIndex === index && true}
              >
                <Popconfirm
                  onConfirm={() => handleDelete(record, index)}
                  title="Sure to delete?"
                >
                  Delete
                </Popconfirm>
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
};
