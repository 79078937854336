import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { Fixed2 } from "../../../../common/utils/common.utils";
import { ITotal_Due_AdvanceCombinedDataType } from "../types/Total_Due_Advanced_CombinedTypes";

const { Text } = Typography;

export const Total_Due_Advance_CombinedUtils = (queryData: {
  isTrash: "0" | "1";
  current: number;
  pageSize: number;
}): ColumnsType<ITotal_Due_AdvanceCombinedDataType> => {
  return [
    {
      title: "SL.",
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: "Name",
      dataIndex: "combine_name",
      key: "combine_name",
      render: (value, record, index) => (
        <Link to={`/combineClients/details/${record.combine_id}`}>
          {record.combine_name}
        </Link>
      ),
    },
    {
      title: "Mobile",
      dataIndex: "combine_mobile",
      key: "combine_mobile",
    },
    {
      title: "Email",
      dataIndex: "combine_email",
      key: "combine_email",
    },
    {
      title: "Present Due",
      dataIndex: "lbalance_amount",
      key: "lbalance_amount",
      align: "right",
      render: (_, record) => {
        return (
          <Text type="danger">
            {Number(record.comtrxn_lbalance) < 0
              ? Fixed2(Math.abs(Number(record.comtrxn_lbalance)))
              : "00"}
          </Text>
        );
      },
    },
    {
      title: "Present Advance",
      dataIndex: "lbalance_amount",
      key: "lbalance_amount",
      align: "right",
      render: (_, record) => {
        return (
          <Text type="success">
            {Number(record.comtrxn_lbalance) > 0
              ? Number(record.comtrxn_lbalance)
              : "00"}
          </Text>
        );
      },
    },
    {
      title: "Last Balance",
      dataIndex: "combine_lbalance",
      key: "combine_lbalance",
      align: "center",
      render: (amount) => {
        return (
          <p
            style={{
              color:
                Number(amount) === 0
                  ? "#18b4e9"
                  : Number(amount) > 0
                  ? "green"
                  : "red",
            }}
          >
            {Fixed2(Math.abs(Number(amount)))}
          </p>
        );
      },
    },
  ];
};
