import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import useGetSearchColumnProps from "../../../../common/hooks/SearchData";
import { Fixed2, formatAmount } from "../../../../common/utils/common.utils";
import { findInvoiceRoute } from "../../../../layout/components/SearchInvoices";
import {
  IRefund,
  IRefundReportClientDataTypes,
  IRefundReportData,
  IRefundReportVendorDataTypes,
} from "../types/refundReportTypes";
//RefundReportColumn
export const RefundReportColumnClient =
  (): ColumnsType<IRefundReportClientDataTypes> => {
    const searchColumnProps =
      useGetSearchColumnProps<IRefundReportClientDataTypes>();
    return [
      {
        title: "SL.",
        dataIndex: "key",
        key: "key",
        render: (_, data, index) => index + 1,
      },
      {
        title: "Refund Date",
        dataIndex: "refund_created_date",
        key: "refund_created_date",
        render: (_, record) => {
          return `${dayjs(record.atrefund_date).format("DD MMM YYYY")}`;
        },
      },

      // {
      //   title: "Invoice No",
      //   dataIndex: "invoice_no",
      //   key: "invoice_no",
      //   render: (_, data) => {
      //     const { invoice_no, invoice_category_id, invoice_id } = data;
      //     return (
      //       <>
      //         <Link
      //           to={`../${
      //             findInvoiceRoute(invoice_category_id) +
      //             "/" +
      //             "details" +
      //             "/" +
      //             invoice_id
      //           }`}
      //         >
      //           {invoice_no}
      //         </Link>
      //       </>
      //     );
      //   },
      // },
      {
        title: "Vouchar No",
        dataIndex: "vouchar_no",
        key: "vouchar_no",
      },

      {
        title: "Client Name",
        dataIndex: "client_name",
        key: "client_name",
        render: (_, record) => (
          <Link
            to={
              record.atrefund_client_id
                ? `/reports/client_ledger?client_query=${record.atrefund_client_id}`
                : `/reports/combined_ledger?id=${record.atrefund_combined_id}`
            }
          >
            {record.client_name}
          </Link>
        ),
      },

      {
        title: "Charge Amount",
        dataIndex: "client_refund_charge",
        key: "client_refund_charge",
        render: (_, record) =>
          Fixed2(record.client_refund_charge).toLocaleString(),
        align: "right",
      },
      {
        title: "Return Amount",
        dataIndex: "refund_total_amount",
        key: "refund_total_amount",
        render: (_, record) =>
          Fixed2(record.refund_total_amount).toLocaleString(),
        align: "right",
      },

      {
        title: "Net Profit/Loss",
        dataIndex: "refund_profit",
        key: "refund_profit",
        render: (_, record) => Fixed2(record.refund_profit).toLocaleString(),
        align: "right",
      },
    ];
  };
export const RefundReportColumnVendor =
  (): ColumnsType<IRefundReportVendorDataTypes> => {
    const searchColumnProps =
      useGetSearchColumnProps<IRefundReportVendorDataTypes>();
    return [
      {
        title: "SL.",
        dataIndex: "key",
        key: "key",
        render: (_, data, index) => index + 1,
      },
      {
        title: "Date",
        dataIndex: "refund_created_date",
        key: "refund_created_date",
        render: (_, record) => {
          return `${dayjs(record.refund_created_date).format("DD MMM YYYY")}`;
        },
      },

      {
        title: "Invoice No",
        dataIndex: "invoice_no",
        key: "invoice_no",
      },
      {
        title: "Vouchar No",
        dataIndex: "vouchar_no",
        key: "vouchar_no",
      },
      {
        title: "Vendor Name",
        dataIndex: "vendor_name",
        key: "vendor_name",
      },
      {
        title: "Refund Amount",
        dataIndex: "vrefund_amount",
        key: "vrefund_amount",
        render: (_, record) => Fixed2(record.vrefund_amount).toLocaleString(),

        align: "right",
      },
      {
        title: "Charge Amount",
        dataIndex: "vcharge_amount",
        key: "vcharge_amount",
        render: (_, record) => Fixed2(record.vcharge_amount).toLocaleString(),

        align: "right",
      },
      {
        title: "Return Amount",
        dataIndex: "vrefund_return_amount",
        key: "vrefund_return_amount",
        render: (_, record) =>
          Fixed2(record.vrefund_return_amount).toLocaleString(),
        align: "right",
      },
    ];
  };
export const RefundReportColumn = (): ColumnsType<IRefund> => {
  return [
    {
      title: "SL.",
      dataIndex: "key",
      key: "key",
      render: (_, data, index) => index + 1,
    },
    {
      title: "Date",
      dataIndex: "refund_created_date",
      key: "refund_created_date",
      render: (_, record) => {
        return `${dayjs(record.atrefund_date).format("DD MMM YYYY")}`;
      },
    },

    {
      title: "Vouchar No",
      dataIndex: "atrefund_vouchar_number",
      key: "atrefund_vouchar_number",
    },
    // {
    //   title: "Client Total",
    //   dataIndex: "client_total",
    //   key: "client_total",
    //   render: (curr) => formatAmount(curr),
    //   align: "right",
    // },
    {
      title: "Client Charge",
      dataIndex: "cleint_charge",
      key: "cleint_charge",
      render: (curr) => <span>{formatAmount(curr)}</span>,
      align: "right",
    },
    {
      title: "Client Refund",
      dataIndex: "client_refund",
      key: "client_refund",
      render: (curr) => (
        <span className="color-loss">{formatAmount(curr)}</span>
      ),
      align: "right",
    },

    // {
    //   title: "Vendor Total",
    //   dataIndex: "vendor_total",
    //   key: "vendor_total",
    //   render: (curr) => formatAmount(curr),
    //   align: "right",
    // },
    {
      title: "Vendor Charge",
      dataIndex: "vendor_charge",
      key: "vendor_charge",
      render: (curr) => <span>{formatAmount(curr)}</span>,
      align: "right",
    },
    {
      title: "Vendor Refund",
      dataIndex: "vendor_refund",
      key: "vendor_refund",
      render: (curr) => (
        <span className="color-profit">{formatAmount(curr)}</span>
      ),
      align: "right",
    },
  ];
};
