import { api } from "../../../../app/baseQuery";
import { IPaginationSD } from "../../../../common/types/commonInterfaces";
import { HTTPResponse } from "../../../../common/types/commonTypes";
import {
  IInvoiceAirTaxRefundPost,
  IInvoiceAirticketTaxRefundInfo,
} from "../../../IATA_AIR_TICKET/types/invoiceAirTicketTypes";
import { ITaxRefundDetails } from "../../Pages/AirTicketTax/AirTicketTax_View";
import {
  IAirTicketTextGetForEdit,
  ISubmitAirTicketSubmit,
} from "../../Utils/TaxRefund/AirticketTaxType";

export const AirTicketTaxRefundEndpoints = api.injectEndpoints({
  endpoints: (build) => ({
    getAllTaxRefund: build.query<HTTPResponse<ITaxRefundList[]>, IPaginationSD>(
      {
        query: (arg) => ({
          url: `/refund/tax-refunds?page=${arg.current}&size=${arg.pageSize}&from_date=${arg.from}&to_date=${arg.to}&search=${arg.search}`,
        }),
        providesTags: [{ type: "TaxRefund" }],
      }
    ),

    getTaxRefundView: build.query<HTTPResponse<ITaxRefundDetails>, string>({
      query: (id) => ({
        url: `/refund/tax-refunds/${id}`,
      }),
      providesTags: [{ type: "TaxRefund" }],
    }),

    deleteTaxRefund: build.mutation<HTTPResponse<ITaxRefundDetails>, string>({
      query: (id) => ({
        url: `/refund/tax-refunds/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "TaxRefund" }, "BSPSummary"],
    }),

    getForEditTaxRefund: build.query<
      HTTPResponse<IAirTicketTextGetForEdit>,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `invoice-air-ticket/tax-refund-view/${id}`,
      }),
      providesTags: ["invoices"],
    }),

    updateEditedTaxRefund: build.mutation<
      HTTPResponse<void>,
      { id: string; body: ISubmitAirTicketSubmit }
    >({
      query: ({ body, id }) => ({
        url: `invoice-air-ticket/tax-refund/${id}`,
        body: body,
        method: "PATCH",
      }),
      invalidatesTags: ["invoices", { type: "TaxRefund" }],
    }),

    postInvoiceAirTicketTaxRefundInfo: build.mutation<
      HTTPResponse<void>,
      IInvoiceAirTaxRefundPost
    >({
      query: (body) => ({
        url: `invoice-air-ticket/tax-refund`,
        body,
        method: "POST",
      }),
      invalidatesTags: ["AirticketInvoice", "invoices", { type: "TaxRefund" }],
    }),

    getInvoiceAirTicketTaxRefundInfo: build.query<
      HTTPResponse<IInvoiceAirticketTaxRefundInfo[]>,
      number
    >({
      query: (id) => ({ url: `invoice-air-ticket/tax-refund/${id}` }),
      providesTags: ["AirticketInvoice", "invoices"],
    }),
  }),
});

export const {
  useGetAllTaxRefundQuery,
  useGetTaxRefundViewQuery,
  useDeleteTaxRefundMutation,
  useGetForEditTaxRefundQuery,
  useUpdateEditedTaxRefundMutation,
  usePostInvoiceAirTicketTaxRefundInfoMutation,
  useGetInvoiceAirTicketTaxRefundInfoQuery,
} = AirTicketTaxRefundEndpoints;

export interface ITaxRefundList {
  refund_id: number;
  refund_voucher: string;
  client_name: string;
  refund_date: string;
  client_refund_type: string;
  client_total_tax_refund: string;
  vendor_total_tax_refund: string;
  created_at: string;
  refund_profit: string;
  invoice_category_id: number;
  invoice_no: string;
  invoice_id: number;
  client_id: number;
  combine_id: number;
  user_full_name: string;
}
