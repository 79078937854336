import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Row, Segmented, Typography } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Fixed2,
  formatAmount,
  rangePresets,
} from "../../../../common/utils/common.utils";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import { useBspBillingQuery } from "../../Api/Endpoints/DashboardEndpoints";
import { TicketStatus } from "../../interfaces/dashboard.interfaces";
import "../../style/weeklyReport.css";
import DashboardTitle from "../DashboardTitle";
import ConsolidateRemittance from "./ConsolidateRemittance";

const BSPBillingInfo = ({ isDarkMode }: any) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("Upcoming");
  const [date, setDate] = useState<{
    from_date: Dayjs | null;
    to_date: Dayjs | null;
  }>({
    from_date: null,
    to_date: null,
  });

  const { data, isLoading, isFetching } = useBspBillingQuery({
    billing_type: value,
    from_date: dayjs(date.from_date).format("YYYY-MM-DD"),
    to_date: dayjs(date.to_date).format("YYYY-MM-DD"),
  });

  if (!data?.data) {
    return (
      <Col xs={24} lg={24} sm={24}>
        <div className="dashboard-box-shape">
          <DashboardTitle title="BSP UPCOMING BILLING" />

          <div className="bspBilling" style={{ padding: "0px 20px 10PX" }}>
            <Typography.Title level={4} style={{ textAlign: "center" }}>
              No data found
            </Typography.Title>
          </div>
        </div>
      </Col>
    );
  }

  const bspData = data?.data;

  const iata_vendor_id = bspData?.iata_vendor;
  const consolidateRemittance = data?.data?.consolidateRemittance;

  // ticket_issue
  const ticket_issue = data?.data?.ticket_issue;
  const totalCommission_issue =
    Fixed2(bspData?.ticket_issue?.iata_commission) +
    Fixed2(bspData?.ticket_issue?.taxes_commission);

  const netCommission_issue =
    totalCommission_issue - Fixed2(bspData?.ticket_issue?.ait);

  // ticket_re_issue
  const ticket_re_issue = data?.data?.ticket_re_issue;
  const totalCommission_re_issue =
    Fixed2(bspData?.ticket_re_issue?.iata_commission) +
    Fixed2(bspData?.ticket_re_issue?.taxes_commission);

  const netCommission_re_issue =
    totalCommission_re_issue - Fixed2(bspData?.ticket_re_issue?.ait);

  const total_purchase_amount =
    Fixed2(bspData?.ticket_issue.purchase_amount) +
    Fixed2(bspData?.ticket_re_issue.purchase_amount);

  const combine_total =
    total_purchase_amount - Fixed2(bspData?.ticket_refund.refund_amount);

  const formattedStartDate = dayjs(bspData?.sales_from_date)
    .format("DD-MMM-YYYY")
    .toUpperCase();
  const formattedEndDate = dayjs(bspData?.sales_to_date)
    .format("DD-MMM-YYYY")
    .toUpperCase();

  const due_amount = combine_total - Fixed2(data.data.iata_payment);

  return (
    <Col xs={24} lg={24} sm={24}>
      <div className="dashboard-box-shape">
        <DashboardTitle
          title="BSP UPCOMING BILLING"
          link={`/bsp-summary?sales_start=${formattedStartDate}&sales_end=${formattedEndDate}&billing_type=${value}`}
          state={location.pathname}
          extra={
            <Button
              icon={<CloudUploadOutlined />}
              onClick={() =>
                navigate("/reports/bsp-bill-list", {
                  state: "vendors/payment/add",
                })
              }
            />
          }
        />

        <div className="bspBilling" style={{ padding: "0px 20px 10PX" }}>
          {(isLoading || isFetching) && <LoadingIndicator />}
          <Row justify={"space-between"} align={"top"}>
            <Col>
              <Typography.Title
                level={5}
                style={{
                  color: "#2a6375",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                SALES PERIOD : {formattedStartDate} to {formattedEndDate}
              </Typography.Title>
            </Col>
            <Col>
              <Typography.Title
                level={5}
                style={{
                  color: "#2a6375",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                BILLING PERIOD :{" "}
                {dayjs(data?.data?.billing_from_date)
                  .format("DD-MMM-YYYY")
                  .toUpperCase()}{" "}
                to{" "}
                {dayjs(data?.data?.billing_to_date)
                  .format("DD-MMM-YYYY")
                  .toUpperCase()}
              </Typography.Title>
            </Col>
          </Row>
          <Row justify={"space-between"}>
            <DatePicker.RangePicker
              presets={rangePresets}
              format={"DD-MMM-YYYY"}
              onChange={(e) =>
                setDate((prev) => ({
                  ...prev,
                  from_date: e?.length ? e[0] : null,
                  to_date: e?.length ? e[1] : null,
                }))
              }
            />

            <Segmented<string>
              options={["Previous", "Upcoming"]}
              value={value}
              onChange={(value) => {
                setValue(value);
              }}
            />
          </Row>
          <SingleDataRow
            isDarkMode={isDarkMode}
            data={ticket_issue}
            reissues={ticket_re_issue}
            totalCommission={totalCommission_issue}
            netCommission={netCommission_issue}
            reissueCommission={totalCommission_re_issue}
            reissueNetCommission={netCommission_re_issue}
            totalIssueReissue={total_purchase_amount}
            refundTotal={Number(bspData?.ticket_refund.refund_amount)}
            combinedTotal={combine_total}
            total_sales={data.data.total_sales}
            due_amount={due_amount}
          />

          {consolidateRemittance ? (
            <ConsolidateRemittance
              data={consolidateRemittance}
              iata_vendor_id={iata_vendor_id}
            />
          ) : null}
        </div>
      </div>
    </Col>
  );
};

export default BSPBillingInfo;

type Props = {
  isDarkMode: any;
  data: TicketStatus | undefined;
  reissues: TicketStatus | undefined;
  totalCommission: number;
  netCommission: number;
  reissueCommission: number;
  reissueNetCommission: number;
  totalIssueReissue: number;
  refundTotal: number;
  combinedTotal: number;
  // iata_payment: string;
  total_sales: string;
  due_amount: number;
};

const SingleDataRow = ({
  isDarkMode,
  data,
  reissues,
  totalCommission,
  netCommission,
  reissueCommission,
  reissueNetCommission,
  totalIssueReissue,
  refundTotal,
  combinedTotal,
  total_sales,
}: Props) => {
  return (
    <div style={{ marginTop: 30, textAlign: "center" }}>
      <Row style={{ borderBottom: "1px solid lightgray" }}>
        <Col xl={2} md={24}>
          <Typography.Title
            level={5}
            style={{
              color: "#538392",
              fontFamily: "sans-serif",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            ISSUES
          </Typography.Title>
        </Col>
        <Col xxl={22} xl={22} lg={22} md={24}>
          <div className="billing_details">
            <div className={`box ${isDarkMode ? "dark-box" : "white-box"}`}>
              <p>Gross Fare</p>
              <p>{Fixed2(data?.gross_fare).toLocaleString()}</p>
            </div>
            <div className={`box ${isDarkMode ? "dark-box" : "white-box"}`}>
              <p>Tax Amount</p>
              <p>{Fixed2(data?.tax).toLocaleString()}</p>
            </div>
            <div
              className={`box ${
                isDarkMode ? "dark-box" : "white-box"
              } truncate`}
            >
              <p>Gross Commission</p>
              <p>{Fixed2(data?.iata_commission).toLocaleString()}</p>
            </div>
            <div className={`box ${isDarkMode ? "dark-box" : "white-box"}`}>
              <p>Taxes Commission</p>
              <p>{Fixed2(data?.taxes_commission || 0).toLocaleString()}</p>
            </div>
            <div className={`box ${isDarkMode ? "dark-box" : "white-box"}`}>
              <p>Total Commission</p>
              <p>{Fixed2(totalCommission).toLocaleString()}</p>
            </div>
            <div className={`box ${isDarkMode ? "dark-box" : "white-box"}`}>
              <p>AIT</p>
              <p>{Fixed2(data?.ait).toLocaleString()}</p>
            </div>
            <div
              style={{ color: "#2db7f5" }}
              className={`box ${
                isDarkMode ? "dark-box" : "white-box"
              } truncate`}
            >
              <p>Net Commission</p>
              <p>{Fixed2(netCommission).toLocaleString()}</p>
            </div>
            <div
              style={{ color: "#EE4266" }}
              className={`box ${
                isDarkMode ? "dark-box" : "white-box"
              } truncate`}
            >
              <p>Payable Amount</p>
              <p>{Fixed2(data?.purchase_amount).toLocaleString()}</p>
            </div>
          </div>
        </Col>

        <Col xl={2} md={24} style={{ marginTop: 20 }}>
          <Typography.Title
            level={5}
            style={{
              color: "#538392",
              fontFamily: "sans-serif",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            REISSUES
          </Typography.Title>
        </Col>
        <Col xxl={22} xl={22} lg={22} md={24} style={{ marginTop: 20 }}>
          <div className="billing_details">
            <div className={`${isDarkMode ? "dark-box" : "white-box"}`}>
              <p>{Fixed2(reissues?.gross_fare).toLocaleString()}</p>
            </div>
            <div className={`${isDarkMode ? "dark-box" : "white-box"}`}>
              <p>{Fixed2(reissues?.tax).toLocaleString()}</p>
            </div>
            <div
              className={`${isDarkMode ? "dark-box" : "white-box"} truncate`}
            >
              <p>{Fixed2(reissues?.iata_commission).toLocaleString()}</p>
            </div>
            <div className={`${isDarkMode ? "dark-box" : "white-box"}`}>
              <p>{Fixed2(reissues?.taxes_commission || 0).toLocaleString()}</p>
            </div>
            <div className={`${isDarkMode ? "dark-box" : "white-box"}`}>
              <p>{Fixed2(reissueCommission).toLocaleString()}</p>
            </div>
            <div className={`${isDarkMode ? "dark-box" : "white-box"}`}>
              <p>{Fixed2(reissues?.ait).toLocaleString()}</p>
            </div>
            <div
              style={{ color: "#2db7f5" }}
              className={`${isDarkMode ? "dark-box" : "white-box"} truncate`}
            >
              <p>{Fixed2(reissueNetCommission).toLocaleString()}</p>
            </div>
            <div
              style={{ color: "#EE4266" }}
              className={`${isDarkMode ? "dark-box" : "white-box"} truncate`}
            >
              <p>{Fixed2(reissues?.purchase_amount).toLocaleString()}</p>
            </div>
          </div>
        </Col>
      </Row>

      {/* <Divider /> */}

      <Row justify={"space-between"}>
        <Col>
          <Typography.Title
            level={5}
            style={{
              color: "#326b7d",
              fontFamily: "monospace",
              fontWeight: "bold",
              textAlign: "right",
            }}
          >
            TOTAL SALES: {formatAmount(total_sales)}
          </Typography.Title>
        </Col>

        <Col style={{ marginRight: 25 }}>
          <Typography.Title
            level={5}
            style={{
              color: "#326b7d",
              fontFamily: "monospace",
              fontWeight: "bold",
              textAlign: "right",
            }}
          >
            ISSUES & REISSUES TOTAL: {formatAmount(totalIssueReissue)}
          </Typography.Title>

          <Typography.Title
            level={5}
            style={{
              color: "#326b7d",
              fontFamily: "monospace",
              fontWeight: "bold",
              textAlign: "right",
            }}
          >
            REFUNDS: {formatAmount(refundTotal)}
          </Typography.Title>

          <Typography.Title
            level={5}
            style={{
              color: "#326b7d",
              fontFamily: "monospace",
              fontWeight: "bold",
              textAlign: "right",
              fontSize: 17,
            }}
          >
            COMBINED TOTAL: {formatAmount(combinedTotal)}
          </Typography.Title>
        </Col>
      </Row>
    </div>
  );
};
