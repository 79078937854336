import dayjs from 'dayjs';

export const formatDate = (dateRange: any) => {
  const [startDate, endDate] = dateRange.split(' To ');
  const formatPart = (dateString: any) => {
    return dayjs(dateString).format('DD-MMM-YYYY').toUpperCase();
  };
  return `${formatPart(startDate)} To ${formatPart(endDate)}`;
};

export function checkNullDate(
  date: string | undefined
): dayjs.Dayjs | undefined {
  return date ? dayjs(date) : undefined;
}

export function dateFormate(date: string | undefined) {
  return date ? dayjs(date).format('DD-MMM-YYYY') : undefined;
}
