import { ArrowLeftOutlined, PrinterOutlined } from "@ant-design/icons";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ConfigProvider, Flex, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useReactToPrint } from "react-to-print";
import { useAppSelector } from "../../../app/hooks";
import { selectUser } from "../../../auth/states/userSlice";
import { Fixed2, formatAmount } from "../../../common/utils/common.utils";
import BreadCrumb from "../../../components/common/breadCrumb/BreadCrumb";
import CommonViewReport from "../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import { iconStyle } from "../../../layout/pages/Sidebar";
import { landscapePageStyle } from "../../Reports/ledgers/pages/ClientLedgers";
import { useBspUpcomingPaymentQuery } from "../Api/Endpoints/DashboardEndpoints";
import ConsolidateRemittanceModal from "../Components/BSP/ConsolidateRemittanceModal";
import { IBSPUpcomingPaymentConsolidate } from "../interfaces/dashboard.interfaces";
import Reload from "../../../components/common/reload/Reload";
import { loadingIndicator } from "../../../components/common/spinner/LoadingIndicator";

const { Text } = Typography;
const BSPUpcomingPayment = () => {
  const componentRef = useRef(null);
  const navigate = useNavigate();
  const [bspPeriod, setBspPeriod] = useState("");

  const user = useAppSelector(selectUser);

  const currency = useAppSelector(
    (state) => state.user?.organization_info.org_currency
  );
  const {
    data,
    isLoading,
    refetch: refetchBsp,
    isFetching,
  } = useBspUpcomingPaymentQuery();
  console.log(data);

  // const updatedConsolidated: any[] = [];

  // data?.data?.reduce((prev, curr, index) => {
  //   if (index > 0) {
  //     updatedConsolidated.push({
  //       ...curr,
  //       bsp_paid: Number(curr?.bsp_paid) + Number(prev?.bsp_extra_paid),
  //     });
  //   }

  //   return curr;
  // }, {} as IBSPUpcomingPaymentConsolidate);

  const column = (
    isPrint: boolean = false
  ): ColumnsType<IBSPUpcomingPaymentConsolidate> => {
    return [
      {
        title: "Period",
        dataIndex: "bsp_period",
        key: "bsp_period",
        width: 100,
        align: "center",
        render: (curr) => <Text strong>{curr}</Text>,
      },
      {
        title: "Remittance Date",
        key: "remittance_date",
        dataIndex: "remittance_date",
        render: (curr) => <>{dayjs(curr).format("DD MMM YYYY")} </>,
        width: 135,
        align: "center",
      },
      {
        title: "Amount to be paid",
        dataIndex: "amount_to_be_paid",
        key: "amount_to_be_paid",
        width: 150,
        render: (amount_to_be_paid) => {
          // const to_be_paid =
          //   Fixed2(rec.total_issue_amount) - Fixed2(rec.total_refund_amount);
          return (
            currency?.toUpperCase() + " " + formatAmount(amount_to_be_paid)
          );
        },
      },
      {
        title: "Paid",
        dataIndex: "bsp_paid",
        key: "bsp_paid",
        width: 120,
        render: (curr, rec, index) => {
          if (index === 0) {
            return (
              currency?.toUpperCase() + " " + formatAmount(curr ? -curr : curr)
            );
          }
          return (
            currency?.toUpperCase() +
            " " +
            formatAmount(curr ? -curr + Number(rec?.bsp_extra_paid || 0) : curr)
          );
        },
      },
      {
        title: "Balance",
        width: 120,
        render: (curr, rec, index) => {
          const balance =
            Fixed2(rec.total_issue_amount) -
            Fixed2(rec.total_refund_amount) -
            Fixed2(rec.bsp_paid);

          if (index === 0) {
            return currency?.toUpperCase() + " " + formatAmount(balance);
          }
          return (
            currency?.toUpperCase() +
            " " +
            formatAmount(balance + Number(rec?.bsp_extra_paid || 0))
          );
        },
      },
      {
        title: "Status",
        width: 120,
        align: "center",
        render: (curr, rec) => {
          const balance =
            Fixed2(rec.total_issue_amount) -
            Fixed2(rec.total_refund_amount) -
            Fixed2(rec.bsp_paid) +
            Number(rec?.bsp_extra_paid || 0);
          return balance <= 0 ? (
            <Text
              strong
              style={{
                background: "#b8e9b2",
                color: "black",
                padding: "4px 15px",
              }}
            >
              COMPLETE
            </Text>
          ) : (
            <Text
              strong
              style={{
                background: "#f6c636",
                color: "black",
                padding: "4px 15px",
              }}
            >
              PENDING
            </Text>
          );
        },
      },
      {
        title: "Action",
        width: 120,
        align: "center",
        hidden: isPrint,
        render: (curr, rec) => {
          return (
            <FontAwesomeIcon
              onClick={() => setBspPeriod(rec.bsp_period)}
              style={{
                ...iconStyle,
                border: "1px solid lightgrey",
                padding: "5px",
                borderRadius: "100%",
                fontSize: "16px",
                cursor: "pointer",
              }}
              icon={faPaperclip}
            />
          );
        },
      },
    ];
  };

  const jsx = (isPrint: boolean = false) => (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBorderRadius: 0,
            rowHoverBg: "#343a401e",
            headerBg: "",
          },
        },
      }}
    >
      <Table
        rowClassName={(record) => {
          console.log(record);
          const {
            total_issue_amount,
            total_refund_amount,
            bsp_paid,
            bsp_extra_paid,
          } = record;

          const balance =
            Fixed2(total_issue_amount) -
            Fixed2(total_refund_amount) -
            Fixed2(bsp_paid) +
            Number(bsp_extra_paid || 0);
          const paid = bsp_paid + Number(bsp_extra_paid || 0);
          let className = "";

          if (balance <= 0) return (className = "void_inv-row-style");
          if (balance > 0 && parseFloat(paid) > 0)
            return (className = "refund-row-style");

          return className;
        }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        dataSource={data?.data}
        columns={column(isPrint)}
        pagination={false}
        size="small"
        prefixCls="custom_bsp_table"
      />
    </ConfigProvider>
  );

  const print_content = (isPrint: boolean = false) => {
    return (
      <div hidden>
        <CommonViewReport
          children={jsx(isPrint)}
          printRef={componentRef}
          title={{
            info_title: "Upcoming BSP Payment List",
            title: "Upcoming BSP Payment List",
          }}
        />
      </div>
    );
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Upcoming_BSP_Payment_List",
    pageStyle: landscapePageStyle,
  });

  return (
    <>
      <BreadCrumb
        arrOfOption={[`${user?.organization_info.org_name} - Consolidated`]}
      />

      <ConsolidateRemittanceModal
        bsp_period={bspPeriod}
        setBspPeriod={setBspPeriod}
        bspPeriod={bspPeriod}
      />

      <Flex gap={10}>
        <Button
          onClick={() => navigate("../")}
          type="primary"
          icon={<ArrowLeftOutlined />}
        >
          Dashboard
        </Button>

        <Button onClick={handlePrint} type="primary" icon={<PrinterOutlined />}>
          Print
        </Button>
        <Reload
          refetch={() => {
            refetchBsp();
          }}
        />
      </Flex>

      <Typography.Title
        level={5}
        style={{
          fontFamily: "inherit",
          fontWeight: "bold",
          fontSize: 18,
          margin: "20px 0",
          textTransform: "uppercase",
        }}
      >{`${user?.organization_info.org_name} - Consolidated`}</Typography.Title>

      {jsx(false)}

      {print_content(true)}
    </>
  );
};

export default BSPUpcomingPayment;
