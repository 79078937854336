import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { IPermission } from "../../common/types/commonTypes";

const PermissionRoute = ({
  element,
  permission,
}: {
  element: React.ReactElement;
  permission?: string[] | boolean | IPermission;
}) => {
  const user = useAppSelector((state: RootState) => state.user);
  const location = useLocation();

  if (user) {
    return permission ? element : <Navigate to="/" />;
  } else {
    return <Navigate to="/login" state={location.pathname} />;
  }
};
export default PermissionRoute;
