import {
  Badge,
  Button,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
  message,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { IPermission } from "../../../../common/types/commonTypes";
import { IContact, IContactFilters } from "../types/contactTypes";
import { useDeleteContactMutation } from "../Endpoints/contactsEndpoints";
import { useAppDispatch } from "../../../../app/hooks";
import { setCommonModal } from "../../../../common/slices/modalSlice";
import SendGiftModal from "../modals/SendGiftModal";
import ViewGiftModal from "../modals/ViewGiftModal";
import { dateFormate } from "../../../Dashboard/Utils/formateDate";
import EditContactModal from "../modals/EditContactModal";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faGift, faTrash } from "@fortawesome/free-solid-svg-icons";

import sendGift from "../../../../assets/give-gift.svg";
dayjs.extend(isBetween);
type Props = {
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
  filterData: IContactFilters;
  isPrint?: boolean;
};

export const contactColumns = ({
  pagination,
  permission,
  filterData,
  isPrint,
}: Props): ColumnsType<IContact> => {
  const dispatch = useAppDispatch();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const [deleteContact, { isError, isSuccess, isLoading }] =
    useDeleteContactMutation();

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
      message.error("Something went wrong");
    }
  }, [isSuccess, isError]);

  const handleDelete = async (values: IContact, index: number) => {
    setColumnIndex(index);
    await deleteContact({ ...values });
  };

  const giftModal = (id: number) => {
    dispatch(
      setCommonModal({
        title: "Send gift",
        content: <SendGiftModal id={id} />,
        show: true,
        width: 500,
      })
    );
  };
  const viewGiftModal = (id: number) => {
    dispatch(
      setCommonModal({
        title: "All gift",
        content: <ViewGiftModal id={id} />,
        show: true,
      })
    );
  };

  const editContact = (record: IContact) => {
    dispatch(
      setCommonModal({
        title: "Edit Contact",
        content: <EditContactModal info={record} />,
        show: true,
        width: 500,
      })
    );
  };

  return [
    {
      title: "SL.",
      dataIndex: "key",
      key: "serial",
      render: (_, _data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: "Type",
      dataIndex: "ac_account_type",
      key: "ac_account_type",
    },
    {
      title: "Company(Airline Name)",
      dataIndex: "joined_name",
      key: "joined_name",
      width: 230,
    },
    {
      title: "Contact person",
      dataIndex: "ac_contact_name",
      key: "ac_contact_name",
      width: 230,
    },
    {
      title: "Designation",
      dataIndex: "ac_contact_designation",
      key: "ac_contact_designation",
      width: 100,
    },

    {
      title: "Mobile",
      dataIndex: "ac_contact_phone",
      key: "ac_contact_phone",
    },
    // {
    //   title: "Email",
    //   dataIndex: "ac_contact_email",
    //   key: "ac_contact_email",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "ac_contact_address",
    //   key: "ac_contact_address",
    // },

    // {
    //   title: "Remark",
    //   dataIndex: "ac_remark",
    //   key: "ac_remark",
    // },
    {
      title: "Sent Gift",
      align: "center",

      render: (record: IContact) => {
        const giftDate = record?.gift_dates[0]
          ? dayjs(record?.gift_dates[0])
          : null;
        if (!giftDate) {
          return (
            <Typography
              style={{
                color: "red",
              }}
            >
              NO
            </Typography>
          );
        }

        const isSent = giftDate.isBetween(
          dayjs(filterData?.start_date),
          dayjs(filterData?.end_date)
        );

        return (
          <Typography
            style={{
              color: isSent ? "green" : "red",
            }}
          >
            {isSent ? "YES" : "NO"}
          </Typography>
        );
      },
    },
    {
      title: "Gift Sent Date",
      dataIndex: "last_gift_date",
      key: "last_gift_date",
      render: (last_gift_date) => {
        return last_gift_date
          ? `${dayjs(last_gift_date).format("DD MMM YYYY")}`
          : "";
      },
      width: 200,
    },
    {
      title: "Last Gift Name",
      width: 200,
      dataIndex: "last_gift_item",
      key: "last_gift_item",
    },

    {
      title: "Gift Dates",
      width: 230,

      render: (record: IContact) => (
        <p>
          {record?.gift_dates?.slice(0, 4).map((date, index) => (
            <span key={index}>
              {dateFormate(date)}
              {record?.gift_dates.length > 1 &&
              index < record?.gift_dates.slice(0, 4).length - 1
                ? ", "
                : ""}
            </span>
          ))}
          {record?.gift_dates.length > 4 && <span>...</span>}{" "}
        </p>
      ),
    },
    {
      title: "Actions",
      key: "operation",
      align: "center",
      hidden: isPrint,
      render: (_, record, index) => (
        <Space size="small">
          <Tooltip title="View Gifts">
            <Button
              size="middle"
              type="primary"
              onClick={() => {
                viewGiftModal(record?.account_contact_id);
              }}
              icon={<FontAwesomeIcon icon={faGift} />}
            />
          </Tooltip>{" "}
          <Tooltip title=" Send Gift">
            <Button
              size="middle"
              type="primary"
              onClick={() => {
                giftModal(record?.account_contact_id);
              }}
              icon={<img src={sendGift} height={20} width={20} />}
            />
          </Tooltip>
          {permission?.["update:any"] && (
            <Tooltip title="Edit contact">
              <Button
                size="middle"
                type="primary"
                onClick={() => {
                  editContact(record);
                }}
                icon={<FontAwesomeIcon icon={faEdit} />}
              />
            </Tooltip>
          )}
          {/* {permission?.["update:any"] && ( */}
          {/* )} */}
          {permission?.["delete:any"] && (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record, index)}
            >
              <Tooltip title="Delete">
                {" "}
                <Button
                  danger
                  size="middle"
                  type="primary"
                  loading={columnIndex === index && true}
                  icon={<FontAwesomeIcon icon={faTrash} />}
                />
              </Tooltip>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
