import { ArrowLeftOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Space, Tabs, theme } from "antd";
import dayjs from "dayjs";
import React, { MutableRefObject } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { useAppSelector } from "../../../../app/hooks";
import { invoiceViewStyle } from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import MoneyReceiptDesign from "../../Components/MoneyReceiptDesign";
import { useGetSingleMoneyReceiptQuery } from "../../api/endpoints/moneyReceiptEndpoints";
import MoneyReceiptDetails from "./MoneyReceiptDetails";

import MoneyReceiptSingleCopy from "../../Components/MoneyReceiptSingleCopy";

const portraitPageStyle = `
  @page {
    size: A4 portrait;
  }
  @media print {
    body {
      margin: 0;

    }
  }
`;

type Props = {};
const Money_Receipt_View = (props: Props) => {
  const { id } = useParams();

  const viewReceiptPrintableRef: MutableRefObject<HTMLDivElement | null> =
    React.useRef<HTMLDivElement>(null);
  const viewSingleMoneyRef: MutableRefObject<HTMLDivElement | null> =
    React.useRef<HTMLDivElement>(null);
  const viewReceiptDetailsRef: MutableRefObject<HTMLDivElement | null> =
    React.useRef<HTMLDivElement>(null);

  const { isLoading, data: { data: moneyReceipt } = {} } =
    useGetSingleMoneyReceiptQuery(Number(id));

  const [selectedForPrint, setSelectedForPrint] = React.useState<
    "viewReceipt" | "viewDetails" | "viewSingle"
  >("viewReceipt");

  let selectedPrintableRef: MutableRefObject<HTMLDivElement | null> =
    viewReceiptPrintableRef;

  switch (selectedForPrint) {
    case "viewReceipt":
      selectedPrintableRef = viewReceiptPrintableRef;
      break;
    case "viewDetails":
      selectedPrintableRef = viewReceiptDetailsRef;
      break;
    case "viewSingle":
      selectedPrintableRef = viewSingleMoneyRef;
      break;
    default:
      selectedPrintableRef = viewReceiptPrintableRef;
      break;
  }

  const org_name = useAppSelector(
    (state) => state.user?.organization_info.org_name
  );

  const handleCashierPrint = useReactToPrint({
    content: () => selectedPrintableRef.current,
    documentTitle: `${org_name}-${
      moneyReceipt?.receipt_vouchar_no
    }-${dayjs().unix()}`,
    removeAfterPrint: true,
    pageStyle: portraitPageStyle,
  });

  useHotkeys("ctrl + p", (e) => {
    e.preventDefault();
    handleCashierPrint();
  });

  return (
    <div>
      <BreadCrumb arrOfOption={["Money Receipt", "View Money Receipt"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Link to="/moneyreceipt">
          <Button type="primary">
            <ArrowLeftOutlined />
            Return to Money Receipt List
          </Button>
        </Link>
        <Button type="primary" onClick={handleCashierPrint}>
          <PrinterOutlined />
          Print
        </Button>
      </Space>

      {isLoading ? <LoadingIndicator /> : ""}
      <Tabs
        onChange={(e) => {
          switch (Number(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint("viewReceipt");
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint("viewDetails");
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint("viewSingle");
              break;

            default:
              break;
          }
        }}
        type="card"
        items={[
          {
            key: "1",
            label: "Invoice",
            children: (
              <ConfigProvider
                theme={{
                  algorithm: theme.defaultAlgorithm,
                }}
              >
                <div style={invoiceViewStyle}>
                  {moneyReceipt && (
                    <MoneyReceiptDesign
                      moneyReceipt={moneyReceipt}
                      cashiercomponentRef={viewReceiptPrintableRef}
                    />
                  )}
                </div>
              </ConfigProvider>
            ),
          },
          {
            key: "2",
            label: "Details",
            children: (
              <ConfigProvider
                theme={{
                  algorithm: theme.defaultAlgorithm,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "rgba(211, 211, 211, 0.2)",
                    minHeight: "100vh",
                  }}
                >
                  <MoneyReceiptDetails
                    id={id}
                    cashiercomponentRef={viewReceiptDetailsRef}
                  />
                </div>
              </ConfigProvider>
            ),
          },

          {
            key: "3",
            label: "Single Copy",
            children: (
              <ConfigProvider
                theme={{
                  algorithm: theme.defaultAlgorithm,
                }}
              >
                <div style={invoiceViewStyle}>
                  {moneyReceipt && (
                    <MoneyReceiptSingleCopy
                      moneyReceipt={moneyReceipt}
                      cashiercomponentRef={viewSingleMoneyRef}
                    />
                  )}
                </div>
              </ConfigProvider>
            ),
          },
        ]}
      ></Tabs>
    </div>
  );
};

export default Money_Receipt_View;
