import { Button, Form, Row } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../app/hooks";
import { selectUser } from "../../../../auth/states/userSlice";
import { selectCommon, setModal } from "../../../../common/slices/commonSlice";
import { ISetState } from "../../../../common/types/commonTypes";
import { formatAmountAbs } from "../../../../common/utils/common.utils";
import { FormInputItem, TextAreaInput } from '../../../../components/common/FormItem/FormItems';
import { useSendSMSMutation } from "../../../SMS System/Api/Endpoints/SMSEndpoints";
import { ISMSFormData } from "../../../SMS System/SMSTypes/SMSTypes";
import { ClientDataType } from "../types/clientInterfaces";

interface IProps {
  info: ClientDataType;
  setNull: ISetState<ClientDataType | null>;
}
const ClientSmsModal = ({ info, setNull }: IProps) => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const modalSlice = useSelector(selectCommon);
  const [form] = Form.useForm();
  const {
    client_category_id,
    client_id,
    client_name,
    client_last_balance,
    mobile,
  } = info;
  const message = `Dear ${client_name},You have a due of ${formatAmountAbs(
    client_last_balance
  )}.${user?.organization_info.org_name}-${user?.organization_info.org_mobile}`;

  useEffect(() => {
    form.setFieldsValue({
      client_name: client_name,
      client_last_balance: -(
        client_last_balance != undefined && client_last_balance
      ),
      client_mobile: mobile,
      message
    });
  }, [info]);

  const [sendSMS, { isError }] = useSendSMSMutation();
  const handleOnSubmit = async (values: ISMSFormData) => {
    const body: ISMSFormData = {
      client_mobile: mobile,
      client_id: `client-${client_id}`,
      text_type: "TEXT",
      client_category_id: client_category_id as number,
      message: values.message,
      date: dayjs().format("YYYY-MM-DD"),
      created_by: user?.user_id as number,
    };

    await sendSMS([body]);
    setNull(null);
    dispatch(setModal(false));
  };

  return (
    <div>
      <Form layout="vertical" onFinish={handleOnSubmit} form={form}>
        <Row gutter={24}>
          <FormInputItem size={24} name="client_name" label="Name:" readOnly required />
          <FormInputItem size={8} name="client_last_balance" label="Due Amount:" readOnly required />
          <FormInputItem size={16} name="client_mobile" label="Mobile:" readOnly required />
          <TextAreaInput size={24} name={"message"} label='Message' readOnly />
        </Row>
        <Row justify={"end"}>
          <Button type="primary" htmlType="submit">
            {modalSlice.isModal}Send SMS
          </Button>
        </Row>

      </Form>
    </div>
  );
};

export default ClientSmsModal;
