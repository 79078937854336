import { Button, Popconfirm, Space, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectUser } from "../../../../auth/states/userSlice";
import {
  IPermission,
  ISetState,
  crud,
} from "../../../../common/types/commonTypes";
import { useDeleteLoanMutation } from "../../Api/Endpoints/LoanEndpoints";
import {
  ILoanDeleteRestoreBody,
  LoanDataType,
} from "../../LoanTypes/LoanTypes";

export const TakingLoanList = ({
  setEditTakingLoanInfo,
  showEditTakingLoanModal,
  permission,
  pagination,
}: {
  setEditTakingLoanInfo: ISetState<LoanDataType | null>;
  showEditTakingLoanModal: () => void;
  permission?: IPermission;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<LoanDataType> => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);

  const user = useSelector(selectUser);

  const setEditAccount = (value: LoanDataType) => {
    setEditTakingLoanInfo(value);
    showEditTakingLoanModal();
  };

  const [deleteLoan, { isError, isLoading, isSuccess }] =
    useDeleteLoanMutation();

  const handleDelete = async (values: LoanDataType, index: number) => {
    const body: ILoanDeleteRestoreBody = {
      deleted_by: user?.user_id as number,
    };
    setColumnIndex(index);
    await deleteLoan({ body: body, id: values.loan_id });
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);
  return [
    {
      title: "SL.",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: "Date",
      key: "loan_date",
      render: (_, record) => {
        return `${dayjs(record.loan_date).format("DD MMM YYYY")}`;
      },
    },
    {
      title: "Voucher No",
      dataIndex: "loan_vouchar_no",
      key: "loan_vouchar_no",
    },
    {
      title: "Authority",
      dataIndex: "authority_name",
      key: "authority_name",
    },
    {
      title: "Loan Name",
      dataIndex: "loan_name",
      key: "loan_name",
    },
    {
      title: "Loan Type",
      dataIndex: "loan_type",
      key: "loan_type",
    },

    // {
    //   title: 'Method',
    //   dataIndex: 'loan_pay_type',
    //   key: 'loan_pay_type',
    // },
    {
      title: "Account Name",
      dataIndex: "pay_details",
      key: "pay_details",

      render: (_, record) => {
        let color = record.pay_details === "DEPOSIT" ? "green" : "red";
        function status(record: any) {
          if (
            record.pay_details == "DEPOSIT" ||
            record.pay_details == "PENDING" ||
            record.pay_details == "BOUNCE"
          ) {
            return <span style={{ color }}> {record.pay_details} </span>;
          } else {
            return `${record.account_name}`;
          }
        }

        return status(record);
      },
    },

    // {
    //   title: 'Installment',
    //   key: 'loan_installment',
    //   render(_, record) {
    //     return (
    //       <>
    //         {record.loan_installment == 'YES' ? (
    //           `${
    //             record.loan_installment_per_day
    //               ? record.loan_installment_per_day + ` Per Day x`
    //               : record.loan_installment_per_month + ` Per Month x`
    //           } ${record.loan_installment_duration}`
    //         ) : (
    //           <></>
    //         )}
    //       </>
    //     );
    //   },
    // },

    {
      title: "Amount",
      dataIndex: "loan_amount",
      key: "loan_amount",
      render: (loan_amount) => Number(loan_amount),
    },
    {
      title: "Interest(%)",
      dataIndex: "loan_interest_percent",
      key: "loan_interest_percent",
      render: (_, record) => Number(record.loan_interest_percent),
    },

    {
      title: "Payable/Receivable",
      dataIndex: "loan_payable_amount",
      key: "loan_payable_amount",
      render: (_, record) => (
        <span style={{ color: record.loan_payable_amount ? "red " : "green" }}>
          {Number(record.loan_payable_amount || record.loan_receivable_amount)}
        </span>
      ),
    },

    {
      title: "Payment/Received",
      dataIndex: "loan_payable_amount",
      key: "loan_payable_amount",
      render: (_, record) => (
        <span>
          {record.loan_payable_amount ? "Pay " : "Rec "}
          {Number(record.loan_amount) - Number(record.loan_due_amount)}
          /-
        </span>
      ),
    },

    {
      title: "Due",
      dataIndex: "loan_due_amount",
      key: "loan_due_amount",
      render: (_, record) => Number(record.loan_due_amount),
    },

    {
      title: "Action",
      key: "operation",
      render: (_, record, index) => {
        return (
          <Space size="small">
            <Link to={`/loan/view/${record.loan_id}`} state={location.pathname}>
              <Button size="small" type="primary">
                <Typography style={{ color: "#ffffff" }}>View</Typography>
              </Button>
            </Link>

            {permission?.[crud.update] && record?.pay_details !== "DEPOSIT" && (
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setEditAccount(record);
                }}
              >
                Edit
              </Button>
            )}

            {permission?.[crud.delete] && (
              <Button
                danger
                size="small"
                type="primary"
                loading={columnIndex === index && true}
              >
                <Popconfirm
                  onConfirm={() => handleDelete(record, index)}
                  title="Sure to delete?"
                >
                  Delete
                </Popconfirm>
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
};
