import { Modal, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import {
  formatAmount,
  formatAmountAbs,
} from "../../../../common/utils/common.utils";
import { useBspBillSummaryQuery } from "../api/bspBillApisEndpoints";

type Props = {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  setEditInfo: React.Dispatch<React.SetStateAction<string>>;
  editInfo: string;
};

const BSPBill_Summary_modal = ({
  isModalOpen,
  setIsModalOpen,
  editInfo,
  setEditInfo,
}: Props) => {
  const { data, isLoading, isFetching } = useBspBillSummaryQuery(
    { ref: editInfo },
    { skip: editInfo ? false : true }
  );

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setEditInfo("");
    setIsModalOpen(false);
  };

  const totals = data?.data?.reduce(
    (acc, item) => {
      acc.total_iata_issues += item.iata_issues;
      acc.total_iata_refunds += item.iata_refunds;
      acc.total_iata_grand_total += item.iata_grand_total;
      acc.total_db_issue += item.db_issue;
      acc.total_db_refund += item.db_refund;
      acc.total_db_grand_total += item.db_grand_total;
      acc.total_difference_amount += item.difference_amount;
      acc.total_iata_adm_amount += item.iata_amd;
      acc.total_db_adm_amount += item.db_adm;
      return acc;
    },
    {
      total_iata_issues: 0,
      total_iata_refunds: 0,
      total_iata_grand_total: 0,
      total_db_issue: 0,
      total_db_refund: 0,
      total_db_grand_total: 0,
      total_difference_amount: 0,
      total_iata_adm_amount: 0,
      total_db_adm_amount: 0,
    }
  );

  const columns = (): ColumnsType<{
    key: string;
    name: string;
    iata_info: string;
    db_info: string;
    difference: number;
  }> => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (curr) => <span className="bold tt-uppercase">{curr}</span>,
      },
      {
        title: "IATA Info",
        dataIndex: "iata_info",
        key: "iata_info",
        align: "right",
        render: (curr) => <span style={{ color: "#FF9800" }}>{curr}</span>,
      },
      {
        title: "Trabill Info",
        dataIndex: "db_info",
        key: "db_info",
        align: "right",
        render: (curr) => <span style={{ color: "#219C90" }}>{curr}</span>,
      },

      {
        title: "Difference",
        dataIndex: "difference",
        key: "difference",
        align: "right",
        render: (curr) => {
          return curr ? (
            <span className="color-loss">{formatAmountAbs(curr)}</span>
          ) : (
            <Typography.Text>✅</Typography.Text>
          );
        },
      },
    ];
  };

  const {
    total_iata_issues = 0,
    total_db_issue = 0,
    total_iata_refunds = 0,
    total_db_refund = 0,
    total_iata_grand_total = 0,
    total_db_grand_total = 0,
    total_iata_adm_amount = 0,
    total_db_adm_amount = 0,
  } = totals || {};

  const issue_difference = total_iata_issues - total_db_issue;
  const refund_difference = total_iata_refunds - total_db_refund;
  const grand_total_difference = total_iata_grand_total - total_db_grand_total;
  const grand_amd_difference = total_iata_adm_amount - total_db_adm_amount;

  const dataSource: {
    key: string;
    name: string;
    iata_info: string;
    db_info: string;
    difference: number;
  }[] = [
    {
      key: "2",
      name: "Issues",
      iata_info: formatAmount(total_iata_issues),
      db_info: formatAmount(total_db_issue),
      difference: issue_difference,
    },
    {
      key: "3",
      name: "Refunds",
      iata_info: formatAmount(total_iata_refunds),
      db_info: formatAmount(total_db_refund),
      difference: refund_difference,
    },
    {
      key: "4",
      name: "ADM",
      iata_info: formatAmount(total_iata_adm_amount),
      db_info: formatAmount(total_db_adm_amount),
      difference: grand_amd_difference,
    },
    {
      key: "5",
      name: "Grand Total",
      iata_info: formatAmount(total_iata_grand_total),
      db_info: formatAmount(total_db_grand_total),
      difference: grand_total_difference,
    },
  ];

  return (
    <Modal
      title={`Summary Details -> ${editInfo}`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      width={700}
    >
      <Table
        dataSource={data?.data ? dataSource : []}
        columns={columns()}
        pagination={false}
        bordered
        size="small"
      />
    </Modal>
  );
};

export default BSPBill_Summary_modal;
