import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { ITransactionHistory } from "../../AccountsTypes/AccountsTypes";
import { Fixed2 } from "../../../../common/utils/common.utils";

type Props = {
  pagination: {
    current: number;
    pageSize: number;
  };
};

export const ListOfTransactionHistoryColumn = ({
  pagination,
}: Props): ColumnsType<ITransactionHistory> => {
  return [
    {
      title: "SL.",
      dataIndex: "key",
      key: "key",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: "Date",
      key: "acctrxn_created_at",
      render: (_, record) => {
        return `${dayjs(record.acctrxn_created_at).format("DD MMM YYYY")}`;
      },
    },
    {
      title: "Vouchar No.",
      dataIndex: "acctrxn_voucher",
      key: "acctrxn_voucher",
      align: "center",
    },

    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
    },
    {
      title: "Particulars",
      dataIndex: "trxntype_name",
      key: "trxntype_name",
    },

    {
      title: "Tr.Type",
      dataIndex: "actransaction_type",
      key: "actransaction_type",
      align: "center",
      render: (_, record) => {
        return (
          <Typography
            style={{
              color: record?.acctrxn_type == "DEBIT" ? "red" : "green",
            }}
          >
            {record?.acctrxn_type}
          </Typography>
        );
      },
    },

    {
      title: "Debit",
      dataIndex: "actransaction_type",
      key: "actransaction_type",
      align: "center",
      render: (_, record) => {
        if (record.acctrxn_type == "DEBIT") {
          return (
            <Typography style={{ color: "red" }}>
              {Number(record.acctrxn_amount) || 0}
            </Typography>
          );
        }
      },
    },
    {
      title: "Credit",
      dataIndex: "actransaction_type",
      key: "actransaction_type",
      align: "center",
      render: (_, record) => {
        if (record.acctrxn_type == "CREDIT") {
          return (
            <Typography style={{ color: "green" }}>
              {Number(record.acctrxn_amount) || 0}
            </Typography>
          );
        }
      },
    },
    {
      title: "Total Last Balance",
      dataIndex: "acctrxn_lbalance",
      key: "acctrxn_lbalance",
      align: "center",

      render: (amount) => {
        return (
          <p
            style={{
              color:
                Number(amount) === 0
                  ? "#18b4e9"
                  : Number(amount) > 0
                  ? "green"
                  : "red",
            }}
          >
            {Fixed2(Math.abs(Number(amount)))}
          </p>
        );
      },
    },
    {
      title: "Note",
      dataIndex: "acctrxn_note",
      key: "acctrxn_note",
      width: 250,
    },
  ];
};
