import React, { useEffect, useState } from "react";
import { useLazyGetAllRefundReportQuery } from "../endpoints/refundReportEndpoints";
import dayjs from "dayjs";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { Form, Table, Typography } from "antd";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import { IRefundReportFormDataType } from "../types/refundReportTypes";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { Fixed2, formatAmount } from "../../../../common/utils/common.utils";
import { RefundReportColumn } from "../utils/RefundReportColumn";

const OverAllRefoundReport = () => {
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const from_date = searchParams.get("from_date");
    const to_date = searchParams.get("to_date");

    if (from_date && to_date) {
      form.setFieldValue("date_range", [
        dayjs(from_date).startOf("month"),
        dayjs(to_date).endOf("month"),
      ]);
      form.submit();
    } else {
      form.setFieldValue("date_range", [dayjs(), dayjs()]);
    }
  }, []);
  const [fetchRefundReport, { data, isLoading, isFetching }] =
    useLazyGetAllRefundReportQuery();

  console.log(data);
  useEffect(() => {
    const date_range = `from_date=${dayjs()
      .startOf("month")
      .format("YYYY-MM-DD")}&to_date=${dayjs()
      .endOf("month")
      .format("YYYY-MM-DD")}`;
    const body = {
      date_range: date_range,
      currentSize: pagination.current,
      currentPage: pagination.pageSize,
    };
    fetchRefundReport({
      date_range: body.date_range,
      currentPage: body.currentPage,
      currentSize: body.currentSize,
    });
  }, []);

  const onFinish = async (values: IRefundReportFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      currentSize: pagination.current,
      currentPage: pagination.pageSize,
    };
    await fetchRefundReport({
      date_range: body.date_range,
      currentPage: body.currentPage,
      currentSize: body.currentSize,
    });
    // setExcelQuery({
    //   query: `?${body.date_range}&page=${body.currentSize}&size=${body.currentPage}`,
    // });
  };

  const jsx_com = (
    <div>
      <>
        <Table
          size="small"
          rowKey={(e) => e.atrefund_id}
          bordered
          dataSource={data?.data?.data}
          columns={RefundReportColumn()}
          loading={{
            spinning: isLoading || isFetching,
            indicator: loadingIndicator,
          }}
          pagination={
            data?.count && data?.count > 20
              ? {
                  ...pagination,
                  total: data?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ["50", "100", "200", "500"],
                  onChange: handlePaginationChange,
                }
              : false
          }
          summary={() => {
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={1} colSpan={3}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>Total: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>
                        {formatAmount(data?.data?.summary?.total_client)}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell> */}
                  <Table.Summary.Cell index={4}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>
                        {formatAmount(data?.data?.summary?.total_client_charge)}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong className="color-loss">
                        {formatAmount(data?.data?.summary?.total_client_refund)}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>
                        {formatAmount(data?.data?.summary?.total_vendor)}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell> */}
                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>
                        {formatAmount(data?.data?.summary?.total_vendor_charge)}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong className="color-profit">
                        {formatAmount(data?.data?.summary?.total_vendor_refund)}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </>
    </div>
  );
  return (
    <>
      {" "}
      <BreadCrumb arrOfOption={["Reports", "Refund Report"]} />
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <ReportsHeader date_range required reports_title="Refund Report" />
      </Form>
      {jsx_com}
    </>
  );
};

export default OverAllRefoundReport;
