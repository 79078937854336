import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Modal, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { crud, perProps } from '../../../../common/types/commonTypes';
import CommonTableHeader from '../../../../components/common/CommonTableHeader';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllVendorBillAdjustmentQuery } from '../../Api/Endpoints/vendorBillAdjustmentEndpoints';
import Vendor_Bill_Add_Modal from '../../modals/Vendor_Bill_Adjustment/Vendor_Bill_Add_Modal';
import { ListOfVendorBillAdjustementColumn } from '../../utils/Vendor_Bill_Adjustment/ListOfVendorBillAdjustementColumn';
import { rangePresets } from '../../../../common/utils/common.utils';

const Vendor_Bill_Adjustment = ({ permission }: perProps) => {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const { data, isLoading, isFetching } = useGetAllVendorBillAdjustmentQuery({
    ...pagination,
    from: date[0] || '',
    to: date[1] || '',
    search: search,
  });
  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [date, search]);

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Handle Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <Row justify={'space-between'} style={{ marginBottom: '1rem' }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 8 }}>
          {permission?.[crud.create] && (
            <Col>
              <Button type='primary' onClick={showModal}>
                <PlusOutlined />
                Add Vendor Bill Adjustment
              </Button>
            </Col>
          )}
        </Row>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Here...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <CommonTableHeader
        title=''
        element={
          <Modal
            footer={false}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Vendor_Bill_Add_Modal setIsModalOpen={setIsModalOpen} />
          </Modal>
        }
      />

      <Table
        size='small'
        rowKey={(e) => e.vbilladjust_id}
        bordered
        columns={ListOfVendorBillAdjustementColumn({ permission, pagination })}
        dataSource={data?.data}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default Vendor_Bill_Adjustment;
