import { ColumnsType } from "antd/lib/table";
import { IAirtTicketRefundSingleDataType } from "../../../RefundTypes/RefundTypes";

export const AirTicketRefundClientSingleViewUtils =
  (): ColumnsType<IAirtTicketRefundSingleDataType> => {
    return [
      {
        title: "Ticket No",
        dataIndex: "airticket_ticket_no",
        key: "airticket_ticket_no",
        render: (airticket_ticket_no) => {
          return <div>{airticket_ticket_no || ""}</div>;
        },
        align: "center",
      },

      {
        title: "PNR",
        dataIndex: "airticket_pnr",
        key: "airticket_pnr",
        render: (airticket_pnr) => {
          return <div>{airticket_pnr || ""}</div>;
        },
        align: "center",
      },

      {
        title: "PAX",
        key: "passport_name",
        dataIndex: "passport_name",
        render: (passport_name) => {
          return <div>{passport_name || ""}</div>;
        },
        align: "center",
      },
      {
        title: "Refund Charge",
        key: "crefund_charge_amount",
        dataIndex: "crefund_charge_amount",
        render: (crefund_charge_amount) => {
          return <div>{Number(crefund_charge_amount) || 0}</div>;
        },
        align: "center",
      },
      {
        title: "Refund Amount",
        key: "crefund_total_amount",
        dataIndex: "crefund_total_amount",
        render: (crefund_total_amount) => {
          return <div>{Number(crefund_total_amount) || 0}</div>;
        },
        align: "center",
      },
    ];
  };
