import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../app/hooks";
import { Button, Form, Input } from "antd";
import { IGift, ISendGiftFormData } from "../types/contactTypes";
import { useEditGiftMutation } from "../Endpoints/contactsEndpoints";
import dayjs from "dayjs";
import { setCommonModal } from "../../../../common/slices/modalSlice";
import { DateInput } from "../../../../components/common/FormItem/FormItems";
import TextArea from "antd/es/input/TextArea";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";

const EditGiftModal = ({ data }: { data: IGift }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [editGift, { isLoading }] = useEditGiftMutation();
  console.log(data);
  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data, ag_date: dayjs(data.ag_date) });
    }
  }, [data]);

  const handleSubmit = async (values: ISendGiftFormData) => {
    const body = {
      ag_ac_id: data?.ag_ac_id,
      ...values,
      ag_date: dayjs(values.ag_date).format("YYYY-MM-DD"),
    };
    await editGift({ body, id: data?.ag_id }).then((res: any) => {
      if (res.data.success) {
        dispatch(setCommonModal());
      }
    });
  };

  return (
    <>
      <Form
        layout="horizontal"
        form={form}
        labelAlign="left"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 16 }}
        onFinish={handleSubmit}
        style={{ paddingTop: "20px" }}
      >
        <DateInput label="Date" name="ag_date" size={24} />
        <Form.Item label="Gift Name" name="ag_items">
          <Input placeholder="Enter gift name" />
        </Form.Item>
        <Form.Item label="Remark" name="ag_remark">
          <TextArea placeholder="Enter remark" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 9, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Update
          </Button>
        </Form.Item>
        {isLoading && <LoadingIndicator />}
      </Form>
    </>
  );
};

export default EditGiftModal;
