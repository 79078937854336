import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Fixed2,
  FormatDate,
  separateClient,
} from '../../../common/utils/common.utils';
import { findInvoiceRoute } from '../../../layout/components/SearchInvoices';
import InvoiceOtherAddMoneyModal from '../../../modules/Invoice(Other)/Modals/InvoiceOtherAddMoneyModal';
import BreadCrumb from '../../common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../common/spinner/LoadingIndicator';
import { useGetAllDueInvoiceNotificationsQuery } from '../Api/NotificationEndpoints';
import { IDueInvoice } from '../Interfaces/Notification.interface';

const DueInvoice = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const {
    data: dueNotification,
    isLoading,
    isFetching,
  } = useGetAllDueInvoiceNotificationsQuery({ ...pagination });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const column: ColumnsType<IDueInvoice> = [
    {
      title: 'SL.',
      render: (_, data, index) => <> {index + 1} </>,
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => (
        <Link
          to={
            '../' +
            findInvoiceRoute(data.invoice_category_id) +
            '/' +
            'details' +
            '/' +
            data.invoice_id
          }
          state={location.pathname}
        >
          {data.invoice_no}
        </Link>
      ),
    },

    {
      title: 'Invoice Date',
      key: 'passport_date_of_birth',
      render: (_, data) => <> {FormatDate(data.invoice_create_date)} </>,
    },
    {
      title: 'Due Date',
      key: 'invoice_due_date',
      render: (_, data) => <> {FormatDate(data?.invoice_due_date)} </>,
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => {
        const { client_id, combined_id } = separateClient(record?.comb_client);

        return (
          <Link
            to={
              client_id
                ? `/reports/client_ledger?client_query=${client_id}`
                : `/reports/combined_ledger?id=${combined_id}`
            }
          >
            <span>
              {record.client_name}
              {record.client_mobile ? ' ' + record.client_mobile : ''}
            </span>
          </Link>
        );
      },
      width: 150,
    },
    {
      title: 'Net Total',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      render(invoice_net_total) {
        return invoice_net_total ? Fixed2(invoice_net_total) : '';
      },
    },
    {
      title: 'Received Amount',
      dataIndex: 'total_payment',
      key: 'total_payment',
      render(total_payment) {
        return total_payment ? Fixed2(total_payment) : '';
      },
    },
    {
      title: 'Due Amount',
      dataIndex: 'due',
      key: 'due',
      render(value, record, index) {
        return (
          Fixed2(record?.invoice_net_total) - Fixed2(record?.total_payment)
        );
      },
    },
    {
      title: 'Action',
      key: 'client_mobile',
      render: (_, data) => {
        return (
          <InvoiceOtherAddMoneyModal
            invoice_id={data.invoice_id}
            InvocieModalTitle={'Payment invoice due'}
            disabled={false}
          />
        );
      },
    },
  ];

  return (
    <div>
      <BreadCrumb arrOfOption={['Due Invoice']} />
      <Table
        size='small'
        bordered
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        dataSource={dueNotification?.data}
        columns={column}
        pagination={
          dueNotification?.count !== undefined && dueNotification?.count < 20
            ? false
            : {
                ...pagination,
                total: dueNotification?.count,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '200', '500'],
                onChange: handlePaginationChange,
              }
        }
      />
    </div>
  );
};

export default DueInvoice;
