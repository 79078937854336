import { PlusOutlined } from '@ant-design/icons';
import {
  Col,
  Form,
  Modal,
  Upload,
  UploadFile,
  UploadProps,
  message,
} from 'antd';
import { RcFile } from 'antd/es/upload';
import React, { useState } from 'react';

type Props = {
  label: string;
  name: string;
  required?: boolean;
  type?: string;
  setFileList?: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
  offPrefixCls?: boolean;
  fileList?: UploadFile<any>[];
  buttonText?: string;
  style?: React.CSSProperties | undefined;
};

export const ImageUpload = ({
  label,
  name,
  required,
  fileList,
  setFileList,
  offPrefixCls,
  buttonText,
  style,
}: Props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const getBase64 = (file: any): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList && setFileList(newFileList);
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{buttonText || 'Upload Logo'}</div>
    </button>
  );

  const handleCancel = () => setPreviewOpen(false);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <Col>
      <Form.Item
        label={label}
        name={name}
        rules={[
          { required: required || false, message: `Please input this field` },
        ]}
        valuePropName={name}
      >
        <Upload
          style={style}
          prefixCls={offPrefixCls ? '' : 'ant-custom-upload'}
          action={(file) =>
            new Promise((resolve, reject) => {
              resolve('');
            })
          }
          beforeUpload={beforeUpload}
          accept={'image/jpeg,image/jpeg,image/png,image'}
          maxCount={1}
          listType='picture-card'
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList && fileList.length >= 1 ? null : uploadButton}
        </Upload>
      </Form.Item>

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Col>
  );
};
