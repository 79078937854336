import { Col, Image, Row, Typography } from "antd";
import moment from "moment";
import { IAuthorizedBy, IPreparedBy } from "../types/commonInterfaces";

type Props = {
  authorized_by?: IAuthorizedBy;
  prepared_by?: IPreparedBy;
};

const printTime = moment().format("ll LTS");

const { Text } = Typography;

export const InvoiceFooter = ({ authorized_by, prepared_by }: Props) => {
  const signature_text: React.CSSProperties = {
    fontSize: 12,
    fontFamily: "'Source Sans Pro', sans-serif",
    lineHeight: "1.3em",
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "calc(100% - 25px)",
        padding: "20px 10px",
        bottom: 5,
      }}
    >
      <Row justify={"space-between"} align={"middle"}>
        <Col>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // textAlign: 'center',
              borderTop: "1px dotted gray",
            }}
          >
            <Image
              style={{
                position: "absolute",
                top: -20,
                left: 5,
                userSelect: "none",
                pointerEvents: "none",
              }}
              src={authorized_by?.sig_signature}
              width={70}
            />
            <Text style={{ ...signature_text, fontSize: 12 }} strong>
              Authorized By
            </Text>
            <Text style={{ ...signature_text, fontSize: 11 }}>
              {authorized_by?.sig_name_title}
            </Text>
            <Text style={{ ...signature_text, fontSize: 10 }}>
              {authorized_by?.sig_position}
            </Text>
          </div>
        </Col>

        <Col>
          <Text
            style={{
              marginTop: "10px",
              fontSize: "10px",
              fontFamily: "monospace",
              position: "relative",
            }}
          >
            {printTime}
          </Text>
        </Col>

        <Col>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              borderTop: "1px dotted gray",
              padding: "0px 10px",
            }}
          >
            {prepared_by?.sig_signature && (
              <Image
                style={{
                  position: "absolute",
                  top: -20,
                  left: 5,
                  userSelect: "none",
                  pointerEvents: "none",
                }}
                src={prepared_by?.sig_signature}
                width={70}
              />
            )}

            <Text style={{ ...signature_text, fontSize: 12 }} strong>
              Prepared By
            </Text>
            <Text style={{ ...signature_text, fontSize: 11 }}>
              {prepared_by?.sig_name_title}
            </Text>
            <Text style={{ ...signature_text, fontSize: 10 }}>
              {prepared_by?.sig_position}
            </Text>

            {prepared_by?.sig_phone_no && (
              <Text style={signature_text}>
                Phone No: {prepared_by?.sig_phone_no || "N/A"}
              </Text>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
