import { Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";
import { Fixed2, formatAmountAbs } from "../../../../common/utils/common.utils";
import { dateFormate } from "../../../Dashboard/Utils/formateDate";
import { ITotal_Due_Advance_VendorDataType } from "../types/Total_Due_Advance_VendorTypes";

const { Text } = Typography;
export const Total_Due_Advance_VendorUtils = (queryData: {
  isTrash: "0" | "1";
  current: number;
  pageSize: number;
}): ColumnsType<ITotal_Due_Advance_VendorDataType> => {
  return [
    {
      title: "SL.",
      dataIndex: "serial",
      key: "serial",
      // render: (_, data, index) => (
      //   <>
      //     {((queryData.current || 1) - 1) * (queryData.pageSize || 20) +
      //       1 +
      //       index}
      //   </>
      // ),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (curr, rec) => {
        return (
          <Link to={`/reports/vendor_ledger?id=${rec.vtrxn_v_id}`}>{curr}</Link>
        );
      },
    },
    {
      title: "Mobile No.",
      dataIndex: "vendor_mobile",
      key: "vendor_mobile",
    },
    {
      title: "Debit",
      dataIndex: "total_debit",
      key: "total_debit",
      align: "right",
      render: (curr) => (
        <span className="color-loss">{formatAmountAbs(curr)}</span>
      ),
    },
    {
      title: "Credit",
      dataIndex: "total_credit",
      key: "total_credit",
      align: "right",
      render: (curr) => (
        <span className="color-profit">{formatAmountAbs(curr)}</span>
      ),
    },
    {
      title: "Due",
      dataIndex: "current_balance",
      key: "current_balance",
      align: "right",
      render: (current_balance) => {
        return (
          <Typography.Text
            style={{
              color: "red",
            }}
          >
            {Number(current_balance) < 0 &&
              formatAmountAbs(Number(current_balance))}
          </Typography.Text>
        );
      },
    },
    {
      title: "Advance",
      dataIndex: "current_balance",
      key: "current_balance",
      align: "right",
      render: (current_balance) => {
        return (
          <Typography.Text
            style={{
              color: "green",
            }}
          >
            {Number(current_balance) > 0 &&
              formatAmountAbs(Number(current_balance))}
          </Typography.Text>
        );
      },
    },
  ];
};
