import { Form, Table } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useLazyGetAllVoidQuery } from '../endpoints/void_and_delete_endpoint';
import { void_utils } from '../utils/void_utils';

type Props = {};

const Void_Invoices = (props: Props) => {
  const [form] = Form.useForm();
  const searchParams = new URLSearchParams(location.search);
  const [from, setFrom] = useState(dayjs().format('YYYY-MM-DD'));
  const [to, setTo] = useState(dayjs().format('YYYY-MM-DD'));

  useEffect(() => {
    const from_date = searchParams.get('from_date');
    const to_date = searchParams.get('to_date');

    if (from_date && to_date) {
      form.setFieldValue('date_range', [dayjs(from_date), dayjs(to_date)]);
      form.submit();
    } else {
      form.setFieldValue('date_range', [dayjs(), dayjs()]);
    }
  }, []);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const [getData, { isLoading, isFetching, data }] = useLazyGetAllVoidQuery();

  useEffect(() => {
    getData({
      current: pagination.current,
      pageSize: pagination.pageSize,
      from_date: from,
      to_date: to,
    });
  }, [pagination]);

  useEffect(() => {
    getData({
      current: pagination.current,
      pageSize: pagination.pageSize,
      from_date: dayjs().format('YYYY-MM-DD'),
      to_date: dayjs().format('YYYY-MM-DD'),
    });
  }, []);

  const dataLength: any = data?.data?.length;

  const onFinish = (values: any) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    setFrom(setFrom_date);
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    setTo(setTo_date);

    getData({
      current: pagination.current,
      pageSize: pagination.pageSize,
      from_date: dayjs(values.date_range[0]).format('YYYY-MM-DD'),
      to_date: dayjs(values.date_range[1]).format('YYYY-MM-DD'),
    });
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={['Void and Delete Invoices']}
        reloaderSize='small'
      />
      <>
        <Form layout='vertical' form={form} onFinish={onFinish}>
          <ReportsHeader
            select_placeholder='All'
            date_range
            required
            reports_select
          />
        </Form>
        {dataLength !== 0 && (
          <Table
            size='small'
            bordered
            pagination={
              data?.count !== undefined && data?.count < 20
                ? false
                : {
                    ...pagination,
                    total: data?.count,
                    showSizeChanger: true,
                    pageSizeOptions: ['50', '100', '200', '500'],
                    onChange: handlePaginationChange,
                  }
            }
            columns={void_utils({ pagination })}
            dataSource={data?.data}
            loading={{
              spinning: isLoading || isFetching,
              indicator: <LoadingIndicator />,
            }}
          />
        )}
      </>
    </div>
  );
};

export default Void_Invoices;
