import { InfoCircleFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  message,
  Modal,
  Row,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Cell, Pie, PieChart } from "recharts";
import { Fixed2 } from "../../../common/utils/common.utils";
import {
  DateInput,
  FormInputItem,
  NumberInput,
} from "../../../components/common/FormItem/FormItems";
import {
  useIataLimitQuery,
  useUpdateBalanceMutation,
} from "../Api/Endpoints/DashboardEndpoints";
import { IUpdateBalance } from "../interfaces/dashboard.interfaces";
import "../style/dashboardVendor.css";
import DashboardTitle from "./DashboardTitle";

const VendorCharts = ({ isDarkMode }: any) => {
  const { data: iataLimit, isSuccess } = useIataLimitQuery();
  const [updateBal, { isLoading, isSuccess: up_succ, isError: up_err }] =
    useUpdateBalanceMutation();
  const [form] = useForm();

  const iataDetails = iataLimit?.data;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (iataDetails) {
      form.setFieldsValue({
        vendor_start_date: dayjs(iataDetails.vendor_start_date),
        vendor_end_date: dayjs(iataDetails.vendor_end_date),
        vendor_bank_guarantee: Fixed2(iataDetails.limit_amount),
        vendor_id: iataDetails.vendor_id,
      });
    }
  }, [iataDetails, isSuccess, isModalOpen]);

  const uses_amount =
    Fixed2(iataLimit?.data?.uses_amount) > 0
      ? 0
      : Math.abs(Fixed2(iataLimit?.data?.uses_amount));
  const limit_amount = Fixed2(iataLimit?.data?.limit_amount);

  // const value = Math.ceil((uses_amount / limit_amount) * 100);
  const value =
    limit_amount !== 0 ? Math.ceil((uses_amount / limit_amount) * 100) : 0;

  const RADIAN = Math.PI / 180;
  const data = [
    { name: "A", value: 50, color: "#5db302" },
    { name: "B", value: 30, color: "#fca103" },
    { name: "C", value: 20, color: "#ed0202" },
  ];
  const cx = 110;
  const cy = 150;
  const iR = 50;
  const oR = 100;

  const needle = (
    value: any,
    data: any,
    cx: any,
    cy: any,
    iR: any,
    oR: any,
    color: any
  ) => {
    let total = 0;
    data.forEach((v: any) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle
        key="needle-circle"
        cx={x0}
        cy={y0}
        r={r}
        fill={color}
        stroke="none"
      />,
      <path
        key="needle-path"
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="none"
        fill={color}
      />,
    ];
  };

  const getGraphPayment = (index: number) => {
    if (index === 0) {
      return 50;
    } else if (index === 1) {
      return 75;
    } else {
      return 100;
    }
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${getGraphPayment(index)}%`}
      </text>
    );
  };

  const handleFinished = (e: IUpdateBalance) => {
    updateBal({
      ...e,
      vendor_end_date: dayjs(e.vendor_end_date).format("YYYY-MM-DD"),
      vendor_start_date: dayjs(e.vendor_start_date).format("YYYY-MM-DD"),
    });
  };

  useEffect(() => {
    if (up_succ) {
      message.success("Balance updated successfully");
      handleOk();
    } else if (up_err) {
      message.error("Error on updating balance");
    }
  }, [up_err, up_succ]);
  return (
    <>
      <div
        className="dashboard-box-shape"
        style={{ height: 320, overflowY: "hidden" }}
      >
        <DashboardTitle title="REMITTANCE HOLDING CAPACITY" />

        <Row align={"middle"} justify={"space-around"}>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: -50,
            }}
          >
            <PieChart width={235} height={160}>
              <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                stroke="none"
                label={renderCustomizedLabel}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              {needle(
                value > 100 ? 100 : value,
                data,
                cx,
                cy,
                iR,
                oR,
                "#04011a"
              )}
            </PieChart>

            <Typography.Title
              level={5}
              style={{
                fontWeight: "bold",
                letterSpacing: 1,
              }}
            >
              Percentage usage : {Fixed2(value)}%
            </Typography.Title>
            <Typography.Text style={{ fontSize: 15 }}>
              Last Updated {dayjs().format("ddd, DD MMM YYYY")}
            </Typography.Text>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginRight: 25 }}>
              <Typography.Text
                style={{ display: "block", marginBottom: 15 }}
                className="border-bottom"
              >
                {" "}
                RHC Amount{" "}
                <InfoCircleFilled style={{ color: "#2da8fd", fontSize: 14 }} />
              </Typography.Text>
              <Typography.Text
                style={{ display: "block", marginBottom: 15 }}
                className="border-bottom"
              >
                Current usage{" "}
                <InfoCircleFilled style={{ color: "#2da8fd", fontSize: 14 }} />
              </Typography.Text>
              <Typography.Text
                style={{ display: "block", marginBottom: 15 }}
                className="border-bottom"
              >
                Current Financial Security
              </Typography.Text>
              <Typography.Text
                style={{ display: "block", marginBottom: 15 }}
                className="border-bottom"
              >
                Remittance frequency
              </Typography.Text>
            </div>

            <div>
              <Typography.Text
                style={{ display: "block", marginBottom: 15 }}
                className="border-bottom"
              >
                {limit_amount.toLocaleString()}
              </Typography.Text>
              <Typography.Text
                style={{ display: "block", marginBottom: 15 }}
                className="border-bottom"
              >
                {uses_amount.toLocaleString()}
              </Typography.Text>
              <Typography.Text
                style={{ display: "block", marginBottom: 15 }}
                className="border-bottom"
              >
                {limit_amount.toLocaleString()}
              </Typography.Text>
              <Typography.Text
                style={{ display: "block", marginBottom: 15 }}
                className="border-bottom"
              >
                Fortnightly
              </Typography.Text>
            </div>
          </Col>
        </Row>

        <Row justify={"center"}>
          <Col>
            <Button
              onClick={showModal}
              style={{
                background: "#3d38f7",
                padding: "18px 15px",
                color: "#fff",
                border: "none",
                borderRadius: 0,
              }}
            >
              Balance Update
            </Button>
          </Col>
        </Row>

        <Row justify={"center"} style={{ margin: "10px 0" }}>
          <Link
            to={`/bsp-upcoming-payment`}
            style={{
              fontWeight: "normal",
              textAlign: "center",
              fontSize: 16,
              color: "#3d38f7",
            }}
          >
            IATA E-Statement
          </Link>
        </Row>
      </div>
      <Modal
        title="Update Balance"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={false}
      >
        <Divider style={{ padding: "0px 0px ", margin: "10px 0px 20px 0px" }} />
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18, offset: 1 }}
          onFinish={handleFinished}
        >
          <NumberInput
            name={"vendor_bank_guarantee"}
            label="Bank Guarantee"
            size={24}
            placeholder="Vendor Bank Guarantee"
            required
          />
          <FormInputItem name={"vendor_id"} hidden required />
          <DateInput
            name={"vendor_start_date"}
            label="Start Date"
            size={24}
            placeholder="Vendor Start Date"
            required
            onChange={(e: any) => {
              form.setFieldsValue({ vendor_end_date: dayjs(e).add(1, "year") });
            }}
          />
          <DateInput
            name={"vendor_end_date"}
            label="End Date"
            size={24}
            placeholder="Vendor End Date"
            required
            disabled
          />
          <Row
            justify={"end"}
            style={{
              marginRight: 10,
              paddingBottom: "0px !important",
              height: 20,
            }}
          >
            <Form.Item>
              <Button loading={isLoading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default VendorCharts;
