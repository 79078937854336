import { Button, Popconfirm, Space, Table, Tag, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState, useEffect } from 'react';
import { useAppDispatch } from '../../../../../app/hooks';
import useGetSearchColumnProps from '../../../../../common/hooks/SearchData';
import { setModal } from '../../../../../common/slices/commonSlice';
import { perProps } from '../../../../../common/types/commonTypes';
import { FormatDate } from '../../../../../common/utils/common.utils';
import CommonTableHeader from '../../../../../components/common/CommonTableHeader';
import BreadCrumb from '../../../../../components/common/breadCrumb/BreadCrumb';
import {
  useDeleteTourCitiesMutation,
  useGetAllCitiesWithPaginationQuery,
} from '../endpoints/CitiesTourEndPoints';
import CitiesTourEditModal from '../modal/CitiesTourEditModal';
import CreateTourCitiesModal from '../modal/CreateTourCitiesModal';
import { ICitiesGetDataType } from '../types/citiesType';
import LoadingIndicator, {
  loadingIndicator,
} from '../../../../../components/common/spinner/LoadingIndicator';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../auth/states/userSlice';

const Cities = ({ permission }: perProps) => {
  const [editInfo, setEditInfo] = useState<ICitiesGetDataType | null>(null);
  const user = useSelector(selectUser);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const dispatch = useAppDispatch();
  const searchColumnProps = useGetSearchColumnProps<ICitiesGetDataType>();

  const modalOpen = (e: ICitiesGetDataType) => {
    setEditInfo(e);
    dispatch(setModal(true));
  };

  //get all cities
  const {
    isLoading,
    data: tourCities,
    refetch,
  } = useGetAllCitiesWithPaginationQuery(pagination, {
    selectFromResult: (cache) => {
      const data = cache.data?.data;
      const count = cache.data?.count;
      const dataToReturn: ICitiesGetDataType[] = [];
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          dataToReturn.push({
            ...element,
            key: i + 1,
          });
        }
      }
      return { ...cache, data: { dataToReturn, count } };
    },
  });

  //delete cities
  const [deleteTourCities, { isError, isSuccess, isLoading: deleteLoading }] =
    useDeleteTourCitiesMutation();
  useEffect(() => {
    if (isSuccess) {
      message.success('Cities Deleted Successfully');
    } else if (isError) {
      message.error('Something went wrong');
    }
  }, [isSuccess, isError]);
  const handleDelete = async (values: ICitiesGetDataType) => {
    await deleteTourCities({ ...values, city_updated_by: user?.user_id });
  };

  const columns: ColumnsType<ICitiesGetDataType> = [
    {
      title: 'SL',
      dataIndex: 'key',
      key: 'sl',
      align: 'center',
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'City',
      dataIndex: 'city_name',
      key: 'city_name',
      ...searchColumnProps('city_name'),
    },
    {
      title: 'Country',
      dataIndex: 'country_name',
      key: 'country_name',
      ...searchColumnProps('city_name'),
    },
    {
      title: 'Create Date',
      dataIndex: 'city_create_date',
      key: 'city_create_date',
      align: 'center',
      render: (_, data) => {
        return <> {FormatDate(data.city_create_date)} </>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'address',
      key: 'address',
      render: (_, { city_status }) => (
        <Tag color={city_status === 0 ? 'red' : 'green'} key={city_status}>
          {city_status === 0 ? 'Inactive' : 'Active'}
        </Tag>
      ),
      align: 'center',
    },
    {
      title: 'action',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      render: (_, record) => (
        <Space size='small'>
          {permission?.['update:any'] && record.agency_id && (
            <Button
              size='small'
              type='primary'
              onClick={() => modalOpen(record)}
            >
              Edit
            </Button>
          )}

          {permission?.['delete:any'] && record.agency_id && (
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => handleDelete(record)}
            >
              <Button
                size='small'
                type='primary'
                danger
                loading={deleteLoading}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <BreadCrumb
        arrOfOption={['Configuration', 'Cities']}
        refetch={refetch}
        reloaderSize='small'
      />

      {/* create citiesModal */}
      {permission?.['create:any'] && <CreateTourCitiesModal />}
      <CommonTableHeader
        modalTitle={'Edit Cities'}
        title={'Cities'}
        element={editInfo ? <CitiesTourEditModal info={editInfo} /> : <></>}
      />

      <Table
        size='small'
        bordered
        pagination={
          tourCities?.count !== undefined && tourCities?.count < 20
            ? false
            : {
              ...pagination,
              total: tourCities?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
        dataSource={tourCities.dataToReturn}
        columns={columns}
        scroll={{ x: true }}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
      />
      {isLoading || (deleteLoading && <LoadingIndicator />)}
    </>
  );
};

export default Cities;
