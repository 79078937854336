import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Descriptions, Input, Row, Table } from "antd";
import DescriptionsItem from "antd/es/descriptions/Item";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IPermission, crud } from "../../../../common/types/commonTypes";
import { removeSpaceBeforeWord } from "../../../../components/common/Invoice/InvoiceListHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { useGetAllOthersRefundClientQuery } from "../../Api/Endpoints/OthersRefundClientEndpoints";
import { OthersRefundClientViewUtils } from "../../Utils/OthersClientUtils/OthersRefundClientViewUtils";
import { rangePresets } from "../../../../common/utils/common.utils";

type Props = { permission?: IPermission };

const ClientRefundHistory = ({ permission }: Props) => {
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState("");

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const { data, isLoading, isFetching, refetch } =
    useGetAllOthersRefundClientQuery({
      ...pagination,
      from: date[0] || "",
      to: date[1] || "",
      search: search,
    });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [search, date]);

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  // Handle Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <div>
      <BreadCrumb
        arrOfOption={["Refund", "Others Refund"]}
        refetch={() => refetch()}
        reloaderSize="small"
      />
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: "25px" }}
        justify={"space-between"}
      >
        {permission?.[crud.create] && (
          <Link to="/refund/others">
            <Button type="primary">
              <PlusOutlined />
              Add Others Refund
            </Button>
          </Link>
        )}

        <Col span={12}>
          <Row justify={"end"} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={"YYYY-MM-DD"}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder="Search Invoices..."
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size="small"
        bordered
        columns={OthersRefundClientViewUtils({ permission, pagination })}
        dataSource={data?.data}
        pagination={
          data?.count !== undefined && data?.count < 20
            ? false
            : {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ["20","50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
        }
        scroll={{ x: true }}
        expandable={{
          rowExpandable: (record) => true,
          expandedRowRender: (record, index) => {
            return (
              <Descriptions size="small" bordered column={3}>
                {record.vendor_info.map((item) => {
                  return (
                    <React.Fragment key={index}>
                      <DescriptionsItem label="Vendor Name :">
                        {item.vrefund_vendor_name}
                      </DescriptionsItem>
                      <DescriptionsItem label="Refund Amount">
                        {Number(item.vrefund_amount)}
                      </DescriptionsItem>
                      <DescriptionsItem label="Type">
                        {item.vrefund_payment_type}
                      </DescriptionsItem>
                    </React.Fragment>
                  );
                })}
              </Descriptions>
            );
          },
        }}
        loading={{
          spinning: isLoading || isFetching,
          indicator: loadingIndicator,
        }}
        rowKey={(_, index) => index || 0}
      />
    </div>
  );
};

export default ClientRefundHistory;
