import { Tabs } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router";
import InvoiceNotFound from "../../../../auth/pages/InvoiceNotFound";
import InvoiceActivityLogs from "../../../../components/common/Invoice/ViewInvoiceInfo/ActivityLogs";
import CommonViewInvoice from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewInvoice";
import TabDetailsView from "../../../../components/common/Invoice/ViewInvoiceInfo/TabDetailsView";
import TabPaymentView from "../../../../components/common/Invoice/ViewInvoiceInfo/TabPaymentView";
import ViewInvoiceHeader from "../../../../components/common/Invoice/ViewInvoiceInfo/ViewInvoiceHeader";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import { useGetViewInvoiceNonComQuery } from "../../api/endpoints/airTicketNonCommissionEndpoints";
import ViewAirticketChildren from "../../components/ViewAirticketChildren";
import AirTicketFlightAndPaxView from "../../../IATA_AIR_TICKET/Components/AirTicketFlightAndPaxView";
import ViewFakeInvoice from "../../../../components/common/Invoice/ViewInvoiceInfo/edited_invoice/view/ViewFakeInvoice";
import { FakeFlightAndPaxView } from "../../../../components/common/Invoice/ViewInvoiceInfo/edited_invoice/view/Billing/FakeFlightAndPaxView";
import { Tab } from "../../../Invoice_Re_Issue/pages/ViewInvoiceInfo/ViewInvoiceReissue";

const ViewInvoiceNonCom = () => {
  const [showFlightDetails, setShowFlightDetails] = useState<boolean>(true);
  const [showPaxDetails, setShowPaxDetails] = useState<boolean>(true);
  const invoice_id = Number(useParams().id);

  // GET INVOICE AIRTICKET DETAILS
  const {
    data: invoices,
    isFetching,
    isLoading,
    isError,
  } = useGetViewInvoiceNonComQuery(invoice_id);

  const invoiceData = invoices?.data;

  const invoice_category_id = invoiceData?.invoice_category_id;

  const viewInvoicePrintableRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceCostDetailsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoicePaymentsRef = React.useRef<HTMLDivElement>(null);
  const viewInvoiceAcivityRef = React.useRef<HTMLDivElement>(null);
  const viewEditedInvoiceRef = React.useRef<HTMLDivElement>(null);

  const header_ref = React.useRef<HTMLDivElement>(null);
  const signiture_ref = React.useRef<HTMLDivElement>(null);
  const subtotal_ref = React.useRef<HTMLDivElement>(null);

  const [selectedForPrint, setSelectedForPrint] = useState<
    "viewInvoice" | "costDetails" | "payments" | "activity" | "edited"
  >("viewInvoice");

  let selectedPrintableRef = viewInvoicePrintableRef;

  switch (selectedForPrint) {
    case "viewInvoice":
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
    case "costDetails":
      selectedPrintableRef = viewInvoiceCostDetailsRef;
      break;
    case "payments":
      selectedPrintableRef = viewInvoicePaymentsRef;
      break;
    case "activity":
      selectedPrintableRef = viewInvoiceAcivityRef;
      break;
    case "edited":
      selectedPrintableRef = viewEditedInvoiceRef;
      break;
    default:
      selectedPrintableRef = viewInvoicePrintableRef;
      break;
  }

  if (isError) return <InvoiceNotFound />;

  const items: Tab[] = [
    {
      label: "Invoice",
      key: "1",
      children: invoiceData && (
        <CommonViewInvoice
          showFlightDetails={showFlightDetails}
          showPaxDetails={showPaxDetails}
          setShowPaxDetails={setShowPaxDetails}
          setShowFlightDetails={setShowFlightDetails}
          showPaxAndFlightButton
          invoiceData={invoiceData}
          viewInvoicePrintableRef={viewInvoicePrintableRef}
          heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
          children={
            <AirTicketFlightAndPaxView
              showFlightDetails={showFlightDetails}
              showPaxDetails={showPaxDetails}
              flights={invoiceData.flights}
              paxDetails={invoiceData.pax_details}
              airticketDetails={invoiceData.airticket_information}
              heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
            />
          }
        />
      ),
    },
    {
      label: "Details",
      key: "2",
      children: (
        <TabDetailsView
          invoiceDetails={invoiceData}
          children={<ViewAirticketChildren invoiceDetails={invoiceData} />}
          viewInvoiceCostDetailsRef={viewInvoiceCostDetailsRef}
        />
      ),
    },
    {
      label: "Payments",
      key: "3",
      children: (
        <TabPaymentView
          invoiceDetails={invoiceData}
          viewInvoicePaymentsRef={viewInvoicePaymentsRef}
        />
      ),
    },
    {
      label: "Activity Log",
      key: "4",
      children: (
        <InvoiceActivityLogs
          id={invoice_id}
          viewInvoiceAcivityRef={viewInvoiceAcivityRef}
        />
      ),
    },
  ];

  if (invoiceData?.is_edited) {
    items.push({
      key: "5",
      label: "Edited Invoice",
      children: (
        <>
          {invoiceData && (
            <ViewFakeInvoice
              invoiceData={invoiceData}
              viewInvoicePrintableRef={viewEditedInvoiceRef}
              heightRefs={{ header_ref, signiture_ref, subtotal_ref }}
              children={
                <FakeFlightAndPaxView
                  flights={invoiceData.flights}
                  paxDetails={invoiceData.pax_details}
                  airticketDetails={invoiceData.airticket_information}
                  heightRefs={{
                    header_ref,
                    signiture_ref,
                    subtotal_ref,
                  }}
                />
              }
            />
          )}
        </>
      ),
    });
  }

  return (
    <>
      <ViewInvoiceHeader
        invoice_category_id={invoice_category_id}
        invoice_id={invoice_id}
        selectedPrintableRef={selectedPrintableRef}
        invoiceData={invoiceData}
      />

      {isLoading && <LoadingIndicator />}

      <Tabs
        onChange={(e) => {
          switch (Number(e)) {
            case 1:
              setSelectedForPrint && setSelectedForPrint("viewInvoice");
              break;
            case 2:
              setSelectedForPrint && setSelectedForPrint("costDetails");
              break;
            case 3:
              setSelectedForPrint && setSelectedForPrint("payments");
              break;
            case 4:
              setSelectedForPrint && setSelectedForPrint("activity");
              break;
            case 5:
              setSelectedForPrint && setSelectedForPrint("edited");
              break;
            default:
              break;
          }
        }}
        type="card"
        style={{ marginTop: "15px" }}
        items={items.sort((a, b) => Number(a.key) - Number(b.key))}
      ></Tabs>
    </>
  );
};

export default ViewInvoiceNonCom;
