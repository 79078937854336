import { ColumnsType } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { formatNumber } from "../../../../common/utils/common.utils";
import { findInvoiceRoute } from "../../../../layout/components/SearchInvoices";
import { IClientDiscountDataType } from "../types/ClientDiscountTypes";

const ClientDiscountColumn = (queryData: {
  isTrash: "0" | "1";
  current: number;
  pageSize: number;
}): ColumnsType<IClientDiscountDataType> => {
  return [
    {
      title: "SL.",
      render: (_, data, index) =>
        ((queryData.current || 1) - 1) * (queryData.pageSize || 20) + 1 + index,
    },
    {
      title: "Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (_, record) => {
        return `${dayjs(record.payment_date).format("YYYY-MM-DD")}`;
      },
    },

    {
      title: "Invoice No.",
      dataIndex: "invoice_no",
      key: "invoice_no",
      render: (_, data) => {
        const { invoice_no, invoice_category_id, invoice_id } = data;
        return (
          <>
            <Link
              to={`../${
                findInvoiceRoute(invoice_category_id) +
                "/" +
                "details" +
                "/" +
                invoice_id
              }`}
            >
              {invoice_no}
            </Link>
          </>
        );
      },
    },

    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      render: (_, record) => (
        <Link
          to={
            record.invoice_client_id
              ? `/clients/details/client-${record.invoice_client_id}`
              : `/combineClients/details/${record.invoice_combined_id}`
          }
        >
          {record.client_name}
        </Link>
      ),
    },

    // {
    //   title: "Sub Total",
    //   dataIndex: "invoice_sub_total",
    //   key: "invoice_sub_total",
    //   align: "right",
    //   render: (curr) => (
    //     <span className="color-sales">{formatNumber(curr)}</span>
    //   ),
    // },

    {
      title: "Discount",
      dataIndex: "discount_total",
      key: "discount_total",
      align: "right",
      render: (curr) => (
        <span className="color-discount">{formatNumber(curr)}</span>
      ),
    },
    {
      title: "Net Total",
      dataIndex: "invoice_net_total",
      key: "invoice_net_total",
      align: "right",
      render: (curr) => (
        <span className="color-sales">{formatNumber(curr)}</span>
      ),
    },
  ];
};

export default ClientDiscountColumn;
