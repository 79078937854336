import { Button, Popconfirm, Space, Tag, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { selectUser } from "../../../../auth/states/userSlice";
import { IPermission } from "../../../../common/types/commonTypes";
import {
  Fixed2,
  formatAmount,
  formatNumber,
} from "../../../../common/utils/common.utils";
import { useDeleteAirticketRefundMutation } from "../../Api/Endpoints/AirTicketRefundEndpoints";
import {
  IAirTicketRefundDataType,
  IRefundDeleteRestoreBody,
} from "../../RefundTypes/RefundTypes";
import DollarOutlined from "@ant-design/icons";

export const AirTicketViewUtils = ({
  permission,
  pagination,
  checkButtonState,
}: {
  permission?: IPermission;
  checkButtonState?: boolean;
  pagination: {
    current: number;
    pageSize: number;
  };
}): ColumnsType<IAirTicketRefundDataType> => {
  const navigate = useNavigate();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useSelector(selectUser);

  const [deleteAirticketRefund, { isLoading, isSuccess, isError }] =
    useDeleteAirticketRefundMutation();

  const handleRefundDelete = async (
    values: IAirTicketRefundDataType,
    index: number
  ) => {
    const body: IRefundDeleteRestoreBody = {
      refund_id: values.atrefund_id,
      deleted_by: user?.user_id as number,
    };
    setColumnIndex(index);
    await deleteAirticketRefund(body);
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);

  return [
    {
      title: "SL.",
      align: "center",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },

    {
      title: "Refund Date",
      dataIndex: "atrefund_date",
      key: "atrefund_date",
      align: "center",
      render: (_, data) => (
        <>{dayjs(data.atrefund_date).format("DD MMM YYYY")} </>
      ),
    },
    {
      title: "Voucher No",
      dataIndex: "atrefund_vouchar_number",
      key: "atrefund_vouchar_number",
    },
    {
      title: "Client",
      dataIndex: "client_name",
      key: "client_name",
      render: (value, record) => {
        const { atrefund_client_id, atrefund_combined_id } = record;

        return record?.atrefund_combined_id || record?.atrefund_client_id ? (
          <Link
            to={
              atrefund_client_id
                ? `/reports/client_ledger?client_query=${atrefund_client_id}`
                : `/reports/combined_ledger?id=${atrefund_combined_id}`
            }
          >
            <span>{record.client_name}</span>
          </Link>
        ) : (
          value
        );
      },
    },
    {
      title: "Return from Vendor",
      dataIndex: "atrefund_vendor_return",
      key: "atrefund_vendor_return",
      align: "end",
      render: (curr) => formatNumber(curr),
    },
    {
      title: "Return to Client",
      dataIndex: "atrefund_cl_return",
      key: "atrefund_cl_return",
      align: "end",
      render: (curr) => formatNumber(curr),
    },
    {
      title: "Refund Profit",
      dataIndex: "atrefund_profit",
      key: "atrefund_profit",
      align: "end",
      render: (amount) => {
        return (
          <Typography.Text
            style={{
              color:
                Number(amount) === 0
                  ? "#18b4e9"
                  : Number(amount) > 0
                  ? "green"
                  : "red",
            }}
          >
            {`${Fixed2(Math.abs(Number(amount)))}.00`}
          </Typography.Text>
        );
      },
      // render: (curr) => formatNumber(curr),
    },
    {
      title: "Created By",
      dataIndex: "user_full_name",
      key: "user_full_name",
      align: "end",
    },
    {
      title: "Action",
      key: "operation",
      align: "left",
      render: (_, record, index) => (
        <Space size="small">
          {permission?.["read:any"] && !checkButtonState && (
            <Link
              to={`/refund/airticket/details/${record.atrefund_id}`}
              state={location.pathname}
            >
              <Button size="small" type="primary">
                <Typography style={{ color: "#ffffff" }}>View</Typography>
              </Button>
            </Link>
          )}

          {permission?.["update:any"] && record.is_adjust_rf ? (
            <Button
              size="small"
              type="primary"
              onClick={() =>
                navigate(
                  `/refund/edit-airticket-adjust-refund/${record.atrefund_id}`,
                  { state: "/refund/airticket-new" }
                )
              }
            >
              Edit
            </Button>
          ) : undefined}

          {permission?.["delete:any"] && !checkButtonState && (
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleRefundDelete(record, index)}
            >
              <Button
                size="small"
                type="primary"
                danger
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];
};
