import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "../../../../app/hooks";
import { selectUser } from "../../../../auth/states/userSlice";
import { IPermission } from "../../../../common/types/commonTypes";
import { GetPayMethod } from "../../../../common/utils/CommonUtils";
import { Fixed2, rangePresets } from "../../../../common/utils/common.utils";
import { removeSpaceBeforeWord } from "../../../../components/common/Invoice/InvoiceListHeader";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import { IMoneyReceiptViewGetAllType } from "../../Types/MoneyReceiptTypes";
import {
  useDeleteMoneyReturnMutation,
  useGetAllMoneyReceiptsQuery,
} from "../../api/endpoints/moneyReceiptEndpoints";

type Props = { permission?: IPermission };

interface DataType {
  key: React.Key;
  receipt_has_deleted: number;
  trxntype_name: string;
  receipt_vouchar_no: string;
  receipt_payment_date: string;
  acctype_name: string;
  client_name: string;
  receipt_total_amount: number;
  receipt_id: number;
  account_name?: string;
  receipt_money_receipt_no?: string | null;
  cheque_or_bank_name?: string;
  mobile?: string;
}

interface AllData {
  data: DataType[];
  total_received: string;
}

const ListofMoneyReceipt = ({ permission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useAppSelector(selectUser);
  const [date, setDate] = useState([]);
  const [search, setSearch] = useState("");

  // Handle Pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const { data, isLoading, isFetching, refetch } = useGetAllMoneyReceiptsQuery({
    ...pagination,
    from: date[0] || "",
    to: date[1] || "",
    search: search,
  });

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      current: 1,
      pageSize: 20,
    }));
  }, [date, search]);

  const [deleteMoneyReceipt, { isLoading: deleteLoading, isSuccess, isError }] =
    useDeleteMoneyReturnMutation();

  const handleDelete = async (
    values: IMoneyReceiptViewGetAllType,
    index: number
  ) => {
    setColumnIndex(index);
    await deleteMoneyReceipt({
      id: values.receipt_id,
      userId: user?.user_id as number,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
    } else if (isError) {
      setColumnIndex(null);
    }
  }, [isSuccess, isError]);
  const columns: ColumnsType<IMoneyReceiptViewGetAllType> = [
    {
      title: "SL.",
      render: (_, data, index) => (
        <>
          {((pagination.current || 1) - 1) * (pagination.pageSize || 50) +
            1 +
            index}
        </>
      ),
    },

    {
      title: "Payment Date",
      dataIndex: "receipt_payment_date",
      key: "receipt_payment_date",
      align: "left",
      render: (curr) => dayjs(curr).format("DD-MM-YYYY"),
    },

    {
      title: "Receipt No",
      dataIndex: "receipt_vouchar_no",
      key: "receipt_vouchar_no",
      align: "left",
    },
    {
      title: "Manual Receipt No",
      dataIndex: "receipt_money_receipt_no",
      key: "receipt_money_receipt_no",
      align: "left",
    },

    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      render: (value, record) => {
        return (
          <Link
            to={
              record.client_id
                ? `/reports/client_ledger?client_query=${record.client_id}`
                : `/reports/combined_ledger?id=${record.combined_id}`
            }
          >
            <span>{record.client_name}</span>
          </Link>
        );
      },
      align: "left",
    },

    {
      title: "Account Name",
      dataIndex: "cheque_or_bank_name",
      key: "cheque_or_bank_name",
      align: "left",
      render: (_, record) => {
        let color = record.cheque_or_bank_name === "DEPOSIT" ? "green" : "red";

        return record.cheque_or_bank_name == "DEPOSIT" ||
          record.cheque_or_bank_name == "PENDING" ||
          record.cheque_or_bank_name == "BOUNCE" ? (
          <Tag color={color}>{record.cheque_or_bank_name}</Tag>
        ) : (
          <Link to={`/accounts/account_statement/${record.account_id}`}>
            {record.account_name}
          </Link>
        );
      },
    },

    {
      title: "Payment Method",
      key: "acctype_name",
      dataIndex: "acctype_name",
      render: (field) => <GetPayMethod field={field} />,
    },

    {
      title: "Received Amount",
      dataIndex: "receipt_total_amount",
      key: "receipt_total_amount",
      align: "center",
      render: (curr) => Fixed2(curr).toLocaleString(),
    },

    {
      title: "Discount Amount",
      dataIndex: "receipt_total_discount",
      key: "receipt_total_discount",
      align: "center",
      render: (curr) => Fixed2(curr).toLocaleString(),
    },

    {
      title: "Received By",
      key: "employee_full_name",
      dataIndex: "employee_full_name",
    },

    {
      title: "Prepared By",
      key: "user_full_name",
      dataIndex: "user_full_name",
    },

    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (_, data, index) => (
        <Space size="small">
          {permission?.["read:any"] && (
            <Link
              to={`/moneyreceipt/view/${data.receipt_id}`}
              state={location.pathname}
            >
              <Button size="small" type="primary">
                <Typography style={{ color: "#ffffff" }}>View</Typography>
              </Button>
            </Link>
          )}

          {permission?.["update:any"] &&
            data.cheque_or_bank_name !== "DEPOSIT" && (
              <Link
                to={`/moneyreceipt/edit/${data.receipt_id}`}
                state={location.pathname}
              >
                <Button size="small" type="primary">
                  Edit
                </Button>
              </Link>
            )}
          {permission?.["delete:any"] && (
            <Popconfirm
              placement="topLeft"
              title="Sure to delete?"
              onConfirm={() => handleDelete(data, index)}
            >
              <Button
                size="small"
                type="primary"
                danger
                loading={columnIndex === index && true}
              >
                Delete
              </Button>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  // Handle Search Operation
  const handleDateRangeChange = (date: any, dateString: any) => {
    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setSearch(removeSpaceBeforeWord(e.target.value));
  };

  return (
    <ViewInvoiceStyle>
      {(isLoading || isFetching) && <LoadingIndicator />}
      <BreadCrumb
        arrOfOption={["Money Receipts"]}
        refetch={() => refetch()}
        reloaderSize="small"
      />

      <Row
        justify={"space-between"}
        style={{ margin: "25px 0" }}
        gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}
      >
        <Space>
          {permission?.["create:any"] && (
            <Link to="/moneyreceipt/add" state={location.pathname}>
              <Button type="primary">
                <PlusOutlined />
                Add Money Receipt
              </Button>
            </Link>
          )}
        </Space>

        <Col span={12}>
          <Row justify={"end"} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={"YYYY-MM-DD"}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder="🔍 Search Here..."
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        size="small"
        bordered
        rowKey={(e) => e.receipt_vouchar_no}
        columns={columns}
        dataSource={data?.data?.data}
        pagination={
          data?.count !== undefined && data?.count > 50
            ? {
                ...pagination,
                total: data?.count,
                showSizeChanger: true,
                pageSizeOptions: ["50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
            : false
        }
        summary={(_) => {
          return (
            <Table.Summary.Row style={{ background: "#031c291f", height: 40 }}>
              <Table.Summary.Cell index={1} colSpan={1} />
              <Table.Summary.Cell index={0} colSpan={6}>
                <Typography.Text strong>
                  <div className="report_total_footer">
                    <Typography.Text strong>TOTAL RECEIVED </Typography.Text>
                  </div>
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={1}>
                <div>
                  <Typography.Text strong>
                    {Fixed2(data?.data?.total_received).toLocaleString()}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={1} colSpan={3} />
            </Table.Summary.Row>
          );
        }}
      />
    </ViewInvoiceStyle>
  );
};

export default ListofMoneyReceipt;

const ViewInvoiceStyle = styled.div`
  .transfer-in-list {
    width: 100%;
  }

  .transfer-in-list .transfer-in-list-td > td {
    padding: 12px !important;
    border-bottom: 1px solid lightgray;
    font-size: 13px !important;
  }

  .transfer-in-list .ant-table-thead .ant-table-cell {
    padding: 10px !important;
    background-color: #e2e2e2;
    font-size: 13px !important;
    border-bottom: 1px solid lightgray;
  }
`;
