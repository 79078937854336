import { DollarOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import {
  Fixed2,
  formatAmount,
  formatAmountAbs,
} from '../../../../common/utils/common.utils';
import { findInvoiceRoute } from '../../../../layout/components/SearchInvoices';
import { IDailySalesReport } from '../types/DailySalesReportTypes';

interface Props {
  pagination: {
    current: number;
    pageSize: number;
  };
  paramData:
  | {
    date_range: string;
    id: number | string;
  }
  | undefined;
}

export const DailySalesReportColumn = ({
  pagination,
  paramData,
}: Props): ColumnsType<IDailySalesReport> => {
  return [
    {
      title: 'SL.',
      dataIndex: 'key',
      key: 'key',
      align: 'center',

      render: (_, data, index) => (
        <>
          {((pagination?.current || 1) - 1) * (pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'sales_date',
      key: 'sales_date',
      render(value, record, index) {
        return dayjs(record.sales_date).format('DD-MM-YYYY');
      },
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_, data) => (
        <Link
          to={
            '../' +
            findInvoiceRoute(data.invoice_category_id) +
            '/' +
            'details' +
            '/' +
            data.invoice_id
          }
          state={location.pathname}
        >
          {data.invoice_no}
        </Link>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (_, record) => (
        <Link
          to={
            record.invoice_client_id
              ? `/clients/details/client-${record.invoice_client_id}`
              : `/combineClients/details/${record.invoice_combined_id}`
          }
          state={record.invoice_client_id ? `/clients` : `/combineds`}
        >
          {record.client_name}
        </Link>
      ),
    },

    {
      title: 'Sales By',
      dataIndex: 'employee_name',
      key: 'employee_name',
      render: (_, record) => (
        <Link
          to={`/reports/sales_man_collection_report?${paramData?.date_range}&employee_id=${record.employee_id}`}
          state={`/reports/sales_man_collection_report`}
        >
          {' '}
          {record.employee_name}
        </Link>
      ),
    },

    {
      title: 'Item',
      dataIndex: 'product_name',
      key: 'product_name',
    },

    {
      title: 'Sales Amount',
      dataIndex: 'invoice_sub_total',
      key: 'invoice_sub_total',
      render: (curr) => <span className='color-sales'>{formatAmount(curr)}</span>,
      align: 'right',
    },

    {
      title: 'Collect Amount',
      dataIndex: 'client_pay_amount',
      key: 'client_pay_amount',
      render: (curr) => (
        <span className='color-collection'>{formatAmount(curr)}</span>
      ),
      align: 'right',
    },

    {
      title: 'Discount',
      dataIndex: 'invoice_discount',
      key: 'invoice_discount',
      render: (curr) => (
        <span className='color-discount'>{formatAmount(curr)}</span>
      ),
      align: 'right',
    },
    {
      title: 'Net Total',
      dataIndex: 'invoice_net_total',
      key: 'invoice_net_total',
      render: (curr) => (
        <span >{formatAmount(curr)}</span>
      ),
      align: 'right',
    },

    {
      title: 'Purchase Amount',
      dataIndex: 'cost_price',
      key: 'cost_price',
      render: (curr) => (
        <span className='color-purchase'>{formatAmount(curr)}</span>
      ),
      align: 'right',
    },
    {
      title: 'Due Amount',
      dataIndex: 'due_amount',
      key: 'due_amount',
      render: (curr) =>
        curr > 0 ? (
          <span className='color-due'>{formatAmount(curr)}</span>
        ) : (
          <Tag color='cyan' icon={<DollarOutlined />}>
            PAID
          </Tag>
        ),
      align: 'right',
    },

    {
      title: 'Profit/Loss',
      dataIndex: 'profit_amount',
      key: 'profit_amount',
      render: (_, record) => {
        const profit =
          Fixed2(record.invoice_net_total) - Fixed2(record.cost_price);

        return (
          <span className={profit > 0 ? 'color-profit' : 'color-loss'}>
            {formatAmountAbs(profit)}
          </span>
        );
      },
      align: 'right',
    },
  ];
};
