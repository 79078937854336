import {
  Button,
  Col,
  DatePicker,
  Row,
  Segmented,
  Table,
  Typography,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

import {
  Fixed2,
  formatNumber,
  rangePresets,
} from "../../../../common/utils/common.utils";
import LoadingIndicator, {
  loadingIndicator,
} from "../../../../components/common/spinner/LoadingIndicator";
import { useOtherBspBillingQuery } from "../../Api/Endpoints/DashboardEndpoints";
import { IOtherBspData } from "../../interfaces/dashboard.interfaces";
import "../../style/weeklyReport.css";
import DashboardTitle from "../DashboardTitle";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/es/table";

const OtherVendorBillingInfo = ({ isDarkMode }: any) => {
  const [value, setValue] = useState("Upcoming");
  const [date, setDate] = useState<{
    from_date: Dayjs | null;
    to_date: Dayjs | null;
  }>({
    from_date: null,
    to_date: null,
  });

  const { data, isLoading, isFetching } = useOtherBspBillingQuery({
    billing_type: value,
    from_date: dayjs(date.from_date).format("YYYY-MM-DD"),
    to_date: dayjs(date.to_date).format("YYYY-MM-DD"),
  });
  const other_bsp = data?.data?.others_bsp_data;
  const bspData = data?.data;

  const formattedStartDate = dayjs(bspData?.sales_from_date)
    .format("DD-MMM-YYYY")
    .toUpperCase();
  const formattedEndDate = dayjs(bspData?.sales_to_date)
    .format("DD-MMM-YYYY")
    .toUpperCase();

  const columns: ColumnsType<IOtherBspData> = [
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (curr, rec) => {
        return (
          <Link
            to={`/reports/vendor_ledger?id=${rec.vendor_id}&start_date=${dayjs(
              bspData?.sales_from_date
            ).format("YYYY-MM-DD")}&end_date=${dayjs(
              bspData?.sales_to_date
            ).format("YYYY-MM-DD")}`}
          >
            {curr}
          </Link>
        );
      },
    },
    {
      title: "Total Cost",
      dataIndex: "total_cost_price",
      key: "total_cost_price",
      align: "right",
      render: (curr) => formatNumber(curr),
    },
    {
      title: "Total Payment",
      dataIndex: "total_payment",
      key: "total_payment",
      align: "right",
      render: (curr) => formatNumber(curr),
    },
    {
      title: "Balance Status",
      dataIndex: "vtrxn_lbalance",
      key: "vtrxn_lbalance",
      align: "right",
      render: (vtrxn_lbalance) => (
        <Typography.Text
          className={Number(vtrxn_lbalance) < 0 ? "color-due" : "color-profit"}
        >
          {" "}
          {Math.abs(Fixed2(vtrxn_lbalance)).toLocaleString()}
          {/* {formatNumber(vtrxn_lbalance)} */}
        </Typography.Text>
      ),
    },
    {
      title: "Details",
      align: "right",
      render: (record: IOtherBspData) => (
        <Link
          to={`/reports/vendor_wise_purchase_and_payment?id=${
            record.vendor_id
          }&from_date=${dayjs(bspData?.sales_from_date).format(
            "YYYY-MM-DD"
          )}&to_date=${dayjs(bspData?.sales_to_date).format("YYYY-MM-DD")}`}
        >
          <Button size="small" type="primary">
            View
          </Button>
        </Link>
      ),
    },
  ];
  return (
    <Col lg={24} sm={24}>
      <div className="dashboard-box-shape">
        <DashboardTitle
          title="OTHER'S BILLING"
          // link={`/other-billing-summary?sales_start=${formattedStartDate}&sales_end=${formattedEndDate}&billing_type=${value}`}
          // link={`/other-billing-summary?billing_type=${value}`}
          state={location.pathname}
        />

        <div className="bspBilling" style={{ padding: "0px 20px 10PX" }}>
          {(isLoading || isFetching) && <LoadingIndicator />}
          {(isLoading || isFetching) && <LoadingIndicator />}
          <Row justify={"space-between"} align={"top"}>
            <Col>
              <Typography.Title
                level={5}
                style={{
                  color: "#2a6375",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                SALES PERIOD : {formattedStartDate} to {formattedEndDate}
              </Typography.Title>
            </Col>
            <Col>
              <Typography.Title
                level={5}
                style={{
                  color: "#2a6375",
                  fontFamily: "monospace",
                  fontWeight: "bold",
                }}
              >
                BILLING PERIOD :{" "}
                {dayjs(data?.data?.billing_from_date)
                  .format("DD-MMM-YYYY")
                  .toUpperCase()}{" "}
                to{" "}
                {dayjs(data?.data?.billing_to_date)
                  .format("DD-MMM-YYYY")
                  .toUpperCase()}
              </Typography.Title>
            </Col>
          </Row>
          <Row justify={"space-between"}>
            <DatePicker.RangePicker
              presets={rangePresets}
              format={"DD-MMM-YYYY"}
              onChange={(e) =>
                setDate((prev) => ({
                  ...prev,
                  from_date: e?.length ? e[0] : null,
                  to_date: e?.length ? e[1] : null,
                }))
              }
            />

            <Segmented<string>
              options={["Previous", "Upcoming"]}
              value={value}
              onChange={(value) => {
                setValue(value);
              }}
            />
          </Row>
          <div style={{ marginTop: 20 }}>
            {" "}
            <Table
              size="small"
              rowKey={(e) => e.vendor_id}
              bordered
              columns={columns}
              dataSource={other_bsp?.length ? other_bsp : []}
              scroll={{ x: true }}
              loading={{ spinning: isLoading, indicator: loadingIndicator }}
              pagination={false}
            />
          </div>
        </div>
      </div>
    </Col>
  );
};

export default OtherVendorBillingInfo;

// type Props = {
//   isDarkMode: any;
//   data: TicketStatus | undefined;
//   reissues: TicketStatus | undefined;
//   totalCommission: number;
//   netCommission: number;
//   reissueCommission: number;
//   reissueNetCommission: number;
//   totalIssueReissue: string;
//   refundTotal: string;
//   combinedTotal: string;
//   total_sales: number;
// };

// const SingleDataRow = ({
//   isDarkMode,
//   data,
//   reissues,
//   totalCommission,
//   netCommission,
//   reissueCommission,
//   reissueNetCommission,
//   totalIssueReissue,
//   refundTotal,
//   combinedTotal,
//   total_sales,
// }: Props) => {
//   return (
//     <div style={{ marginTop: 30, textAlign: "center" }}>
//       <Row style={{ borderBottom: "1px solid lightgray" }}>
//         <Col xl={2} md={24}>
//           <Typography.Title
//             level={5}
//             style={{
//               color: "#538392",
//               fontFamily: "sans-serif",
//               fontWeight: "bold",
//               textAlign: "left",
//             }}
//           >
//             ISSUES
//           </Typography.Title>
//         </Col>
//         <Col xxl={22} xl={22} lg={22} md={24}>
//           <div className="billing_details">
//             <div className={`box ${isDarkMode ? "dark-box" : "white-box"}`}>
//               <p>Gross Fare</p>
//               <p>{Fixed2(data?.gross_fare).toLocaleString()}</p>
//             </div>
//             <div className={`box ${isDarkMode ? "dark-box" : "white-box"}`}>
//               <p>Tax Amount</p>
//               <p>{Fixed2(data?.tax).toLocaleString()}</p>
//             </div>
//             <div
//               className={`box ${
//                 isDarkMode ? "dark-box" : "white-box"
//               } truncate`}
//             >
//               <p>Gross Commission</p>
//               <p>{Fixed2(data?.iata_commission).toLocaleString()}</p>
//             </div>
//             <div className={`box ${isDarkMode ? "dark-box" : "white-box"}`}>
//               <p>Taxes Commission</p>
//               <p>{Fixed2(data?.taxes_commission || 0).toLocaleString()}</p>
//             </div>
//             <div className={`box ${isDarkMode ? "dark-box" : "white-box"}`}>
//               <p>Total Commission</p>
//               <p>{Fixed2(totalCommission).toLocaleString()}</p>
//             </div>
//             <div className={`box ${isDarkMode ? "dark-box" : "white-box"}`}>
//               <p>AIT</p>
//               <p>{Fixed2(data?.ait).toLocaleString()}</p>
//             </div>
//             <div
//               style={{ color: "#2db7f5" }}
//               className={`box ${
//                 isDarkMode ? "dark-box" : "white-box"
//               } truncate`}
//             >
//               <p>Overall Profit</p>
//               <p>{Fixed2(data?.overall_profit).toLocaleString()}</p>
//             </div>
//             <div
//               style={{ color: "#EE4266" }}
//               className={`box ${
//                 isDarkMode ? "dark-box" : "white-box"
//               } truncate`}
//             >
//               <p>Payable Amount</p>
//               <p>{Fixed2(data?.purchase_amount).toLocaleString()}</p>
//             </div>
//           </div>
//         </Col>

//         <Col xl={2} md={24} style={{ marginTop: 20 }}>
//           <Typography.Title
//             level={5}
//             style={{
//               color: "#538392",
//               fontFamily: "sans-serif",
//               fontWeight: "bold",
//               textAlign: "left",
//             }}
//           >
//             REISSUES
//           </Typography.Title>
//         </Col>
//         <Col xxl={22} xl={22} lg={22} md={24} style={{ marginTop: 20 }}>
//           <div className="billing_details">
//             <div className={`${isDarkMode ? "dark-box" : "white-box"}`}>
//               <p>{Fixed2(reissues?.gross_fare).toLocaleString()}</p>
//             </div>
//             <div className={`${isDarkMode ? "dark-box" : "white-box"}`}>
//               <p>{Fixed2(reissues?.tax).toLocaleString()}</p>
//             </div>
//             <div
//               className={`${isDarkMode ? "dark-box" : "white-box"} truncate`}
//             >
//               <p>{Fixed2(reissues?.iata_commission).toLocaleString()}</p>
//             </div>
//             <div className={`${isDarkMode ? "dark-box" : "white-box"}`}>
//               <p>{Fixed2(reissues?.taxes_commission || 0).toLocaleString()}</p>
//             </div>
//             <div className={`${isDarkMode ? "dark-box" : "white-box"}`}>
//               <p>{Fixed2(reissueCommission).toLocaleString()}</p>
//             </div>
//             <div className={`${isDarkMode ? "dark-box" : "white-box"}`}>
//               <p>{Fixed2(reissues?.ait).toLocaleString()}</p>
//             </div>
//             <div
//               style={{ color: "#2db7f5" }}
//               className={`${isDarkMode ? "dark-box" : "white-box"} truncate`}
//             >
//               <p>{Fixed2(reissueNetCommission).toLocaleString()}</p>
//             </div>
//             <div
//               style={{ color: "#EE4266" }}
//               className={`${isDarkMode ? "dark-box" : "white-box"} truncate`}
//             >
//               <p>{Fixed2(reissues?.purchase_amount).toLocaleString()}</p>
//             </div>
//           </div>
//         </Col>
//       </Row>

//       {/* <Divider /> */}

//       <Row justify={"center"}>
//         <Col sm={24} xl={5}>
//           <Row style={{ marginTop: 10 }}>
//             <Col lg={12}>
//               <Typography.Title
//                 level={5}
//                 style={{
//                   color: "#326b7d",
//                   fontFamily: "monospace",
//                   fontWeight: "bold",
//                   textAlign: "right",
//                 }}
//               >
//                 TOTAL SALES:
//               </Typography.Title>
//             </Col>
//             <Col lg={12}>
//               <Typography.Title
//                 level={5}
//                 style={{
//                   color: "#EE4266",
//                   fontFamily: "monospace",
//                   fontWeight: "bold",
//                   marginLeft: 20,
//                 }}
//               >
//                 {formatAmount(total_sales)}
//               </Typography.Title>
//             </Col>
//           </Row>
//         </Col>

//         <Col sm={24} xl={19}>
//           <Row justify={"end"} gutter={24}>
//             <Col lg={5}>
//               <Typography.Title
//                 level={5}
//                 style={{
//                   color: "#326b7d",
//                   fontFamily: "monospace",
//                   fontWeight: "bold",
//                   textAlign: "right",
//                 }}
//               >
//                 ISSUES & REISSUES TOTAL:
//               </Typography.Title>
//             </Col>
//             <Col lg={3}>
//               <Typography.Title
//                 level={5}
//                 style={{
//                   color: "#EE4266",
//                   fontFamily: "monospace",
//                   fontWeight: "bold",
//                   marginLeft: 20,
//                   textAlign: "left",
//                 }}
//               >
//                 {totalIssueReissue}
//               </Typography.Title>
//             </Col>
//           </Row>

//           <Row justify={"end"} gutter={24}>
//             <Col lg={4}>
//               <Typography.Title
//                 level={5}
//                 style={{
//                   color: "#326b7d",
//                   fontFamily: "monospace",
//                   fontWeight: "bold",
//                   textAlign: "right",
//                 }}
//               >
//                 REFUNDS:
//               </Typography.Title>
//             </Col>
//             <Col lg={3}>
//               <Typography.Title
//                 level={5}
//                 style={{
//                   color: "#EE4266",
//                   fontFamily: "monospace",
//                   fontWeight: "bold",
//                   marginLeft: 20,
//                   textAlign: "left",
//                 }}
//               >
//                 {refundTotal}
//               </Typography.Title>
//             </Col>
//           </Row>

//           <Row justify={"end"} gutter={24}>
//             <Col lg={4}>
//               <Typography.Title
//                 level={5}
//                 style={{
//                   color: "#326b7d",
//                   fontFamily: "monospace",
//                   fontWeight: "bold",
//                   textAlign: "right",
//                 }}
//               >
//                 COMBINED TOTAL:
//               </Typography.Title>
//             </Col>
//             <Col lg={3}>
//               <Typography.Title
//                 level={5}
//                 style={{
//                   color: "#EE4266",
//                   fontFamily: "monospace",
//                   fontWeight: "bold",
//                   marginLeft: 20,
//                   textAlign: "left",
//                 }}
//               >
//                 {combinedTotal}
//               </Typography.Title>
//             </Col>
//           </Row>
//         </Col>
//       </Row>
//     </div>
//   );
// };
