import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Space, Tabs, theme } from "antd";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import LoadingIndicator from "../../../../components/common/spinner/LoadingIndicator";
import { useGetSingleAirticketRefundQuery } from "../../Api/Endpoints/AirTicketRefundEndpoints";
import AirTicketRefundCopy from "./AirTicketRefundCopy";
import AirTicketRefundDetails from "./AirTicketRefundDetails";

const AirticketRefundView = () => {
  const { id } = useParams();

  const { data: singleAirticketRefund, isLoading } =
    useGetSingleAirticketRefundQuery(Number(id));
  const airTicketInvoiceData = singleAirticketRefund?.data;

  let client_total_refund_price = 0;
  let client_total_refund_charge = 0;
  let vendor_total_refund_price = 0;
  let vendor_total_refund_charge = 0;

  singleAirticketRefund?.data &&
    singleAirticketRefund?.data.refund_info.map((item: any) => {
      client_total_refund_price =
        client_total_refund_price + Number(item.crefund_total_amount);

      client_total_refund_charge =
        client_total_refund_charge +
        Number(item.rfitem_ticket_client_refund_charge);

      vendor_total_refund_price += Number(item.vrefund_total_amount);

      vendor_total_refund_charge =
        vendor_total_refund_charge + Number(item.vrefund_charge_amount);
    });

  return (
    <div>
      <BreadCrumb arrOfOption={["Money Receipt", "View Money Receipt"]} />
      <Space style={{ marginBottom: "1rem" }}>
        <Link to="/moneyreceipt">
          <Button type="primary">
            <ArrowLeftOutlined />
            Return to Money Receipt List
          </Button>
        </Link>
      </Space>

      {isLoading ? <LoadingIndicator /> : ""}
      <Tabs
        type="card"
        items={[
          {
            key: "1",
            label: "Details",
            children: (
              <ConfigProvider
                theme={{
                  algorithm: theme.defaultAlgorithm,
                }}
              >
                <AirTicketRefundDetails data={airTicketInvoiceData} />
              </ConfigProvider>
            ),
          },
          {
            key: "2",
            label: "Print Copy",
            children: (
              <ConfigProvider
                theme={{
                  algorithm: theme.defaultAlgorithm,
                }}
              >
                <AirTicketRefundCopy data={airTicketInvoiceData} />
              </ConfigProvider>
            ),
          },
        ]}
      ></Tabs>
    </div>
  );
};

export default AirticketRefundView;
