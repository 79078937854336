import { ArrowLeftOutlined, DollarOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Input,
  message,
  Popconfirm,
  Row,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectUser } from '../../../../auth/states/userSlice';
import { IAllInvoices } from '../../../../common/types/commonInterfaces';
import { IPermission } from '../../../../common/types/commonTypes';
import {
  formatAmount,
  rangePresets,
  separateClient,
  TableParams,
} from '../../../../common/utils/common.utils';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { removeSpaceBeforeWord } from '../../../../components/common/Invoice/InvoiceListHeader';
import Reload from '../../../../components/common/reload/Reload';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useVisaStatusUpdateMutation } from '../../../../components/notificatioin/Api/NotificationEndpoints';
import InvoiceOtherAddMoneyModal from '../../../Invoice(Other)/Modals/InvoiceOtherAddMoneyModal';
import {
  useDeleteInvoiceVisaMutation,
  useGetAllInvoiceVisaQuery,
} from '../../Api/Endpoints/invoiceVisaEndpoints';
import VisaApproveModal from '../../Modals/VisaApproveModal';
type Props = {
  permission?: IPermission;
  addMRPermission: ['*'] | undefined;
};

const InvoiceVisaLists = ({ permission, addMRPermission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const [dataSource, setDataSource] = useState<IAllInvoices[]>();
  const [query, setQuery] = useState<string>('');
  const user = useSelector(selectUser);

  const [fetchVisaStatus] = useVisaStatusUpdateMutation();

  // LINKS
  const createLink = '/visa/add';
  const viewLink = '/visa/details';
  const editLink = '/visa/edit';

  const [date, setDate] = useState([]);
  const [search, setSearch] = useState('');

  // GET ALL DATA ,TRASH, DELETE AND RESTORE
  const {
    data,
    isFetching,
    isLoading,
    refetch: getAllInvoices,
  } = useGetAllInvoiceVisaQuery(
    query +
      `&from_date=${date[0] || ''}&to_date=${date[1] || ''}&search=${search}`
  );

  const [
    deleteInvoice,
    { isError, isSuccess, isLoading: deleteLoading, error },
  ] = useDeleteInvoiceVisaMutation();

  // DELETE HANDLER
  const handleOnDelete = (id: number, index: number) => {
    setColumnIndex(index);
    deleteInvoice({
      id,
      user_id: user?.user_id as number,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Invoice Deleted Successfully!');
    }
    if (isError) {
      setColumnIndex(null);
      message.error('Thare was an error');
    }
  }, [isSuccess, isError]);

  const columns: ColumnsType<IAllInvoices> = [
    {
      title: 'SL.',
      render: (_, data, index) => (
        <>
          {((tableParams?.pagination?.current || 1) - 1) *
            (tableParams?.pagination?.pageSize || 20) +
            1 +
            index}
        </>
      ),
      fixed: 'left',
      width: 55,
    },
    {
      title: 'Invoice',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      width: 100,
      align: 'center',
    },
    {
      title: 'Sales Date',
      key: 'invoice_date',
      align: 'center',
      render: (_, data) => (
        <>{moment(data.invoice_date).format('DD MMM YYYY')} </>
      ),
      sorter: (a, b) =>
        moment(a.invoice_date).unix() - moment(b.invoice_date).unix(),
      width: 120,
    },

    {
      title: 'Client Name',
      dataIndex: 'client_name',
      key: 'client_name',
      render: (value, record) => {
        const { client_id, combined_id } = separateClient(record?.comb_client);

        return (
          <Link
            to={
              client_id
                ? `/clients/details/${record?.comb_client}`
                : `/combineClients/details/${combined_id}`
            }
          >
            <span>
              {record.client_name}
              {record.mobile ? ' ' + record.mobile : ''}
            </span>
          </Link>
        );
      },
      width: 150,
    },

    {
      title: 'PP No / Name',
      dataIndex: 'pp_or_name',
      key: 'pp_or_name',
      width: 130,
    },
    {
      title: 'Sales Price',
      dataIndex: 'net_total',
      key: 'net_toal',
      width: 120,
      align: 'right',
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),

      render: (curr) => (
        <span className='color-sales'>{formatAmount(curr)}</span>
      ),
    },
    {
      title: 'Rec Amount',
      dataIndex: 'invclientpayment_amount',
      key: 'invclientpayment_amount',
      align: 'right',
      width: 120,
      render: (curr) => (
        <span className='color-collection'>{formatAmount(curr)}</span>
      ),
    },
    {
      title: 'Due Amount',
      align: 'right',
      width: 120,
      render(_, record) {
        const dueAmount =
          Number(record?.net_total) - Number(record?.invclientpayment_amount);

        return dueAmount > 0 ? (
          <span style={{ color: 'red' }}>
            {formatAmount(
              Number(record?.net_total) -
                Number(record?.invclientpayment_amount)
            )}
          </span>
        ) : (
          <Tag color='cyan' icon={<DollarOutlined />}>
            PAID
          </Tag>
        );
      },
      sorter: (a, b) => Number(a.net_total) - Number(b.net_total),
    },
    {
      title: 'MR. No.',
      dataIndex: 'money_receipt_num',
      key: 'money_receipt_num',
      width: 120,
      render: (_, data) => (
        <span>
          {data?.money_receipt_num?.split(',').map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: 'Sales By',
      dataIndex: 'sales_by',
      key: 'sales_by',
      width: 120,
    },
    {
      title: 'Status',
      dataIndex: 'billing_status',
      key: 'billing_status',
      align: 'center',
      width: '100px',
      render: (_, data) => <VisaApproveModal id={data.invoice_id} />,
    },
    {
      title: 'Action',
      key: 'operation',
      width: 180,
      fixed: 'right',
      render: (_, data, index) => (
        <Space size='small'>
          {permission?.['read:any'] && (
            <Link
              to={viewLink + '/' + data.invoice_id}
              state={location.pathname}
            >
              <Button size='small' type='primary'>
                <Typography style={{ color: '#ffffff' }}>View</Typography>
              </Button>
            </Link>
          )}
          {permission?.['update:any'] &&
            !Number(data?.invclientpayment_amount || 0) && (
              <Link
                to={editLink + '/' + data.invoice_id}
                state={location.pathname}
              >
                <Button size='small' type='primary'>
                  Edit
                </Button>
              </Link>
            )}

          {permission?.['delete:any'] &&
            // data.billing_status !== 'Pending' &&
            data.billing_status !== 'Approved' && (
              <Button
                size='small'
                type='primary'
                danger
                loading={columnIndex === index && true}
              >
                <Popconfirm
                  onConfirm={() => handleOnDelete(data.invoice_id, index)}
                  title='Sure to delete?'
                >
                  Delete
                </Popconfirm>
              </Button>
            )}

          {/* add money receipt */}
          {addMRPermission && data.approve_amount > 0 && (
            <InvoiceOtherAddMoneyModal
              InvocieModalTitle={'Invoice Visa'}
              invoice_id={data.invoice_id}
              disabled={
                Number(data.approve_amount) ===
                Number(data.invclientpayment_amount)
              }
            />
          )}
        </Space>
      ),
    },
  ];

  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
    setDataSource(data?.data);
  }, [isFetching, date]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    getAllInvoices();
  };

  // Perform Search Operation

  const handleDateRangeChange = (date: any, dateString: any) => {
    setQuery(`page=1&size=50`);

    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: count,
        current: 1,
      },
    }));

    setDate(dateString);
  };

  const handleSearch = (e: any) => {
    setQuery(`page=1&size=50`);

    setTableParams((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        total: count,
        current: 1,
      },
    }));

    setSearch(removeSpaceBeforeWord(e.target.value));
  };
  return (
    <>
      {/* BREADCRUMB */}
      <BreadCrumb arrOfOption={['Invoice', 'Visa']} />

      {/* HEADER */}
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 10 }}
        style={{ marginBottom: '25px' }}
        justify={'space-between'}
      >
        <Col span={12}>
          <Row gutter={10}>
            {permission?.['create:any'] && (
              <Col xs={24} sm={12} md={8} lg={8}>
                <Link to={createLink}>
                  <Button
                    type='primary'
                    icon={<ArrowLeftOutlined />}
                    style={{ width: '100%' }}
                  >
                    Create
                  </Button>
                </Link>
              </Col>
            )}

            <Col xs={24} sm={12} md={8} lg={8} style={{ display: 'flex' }}>
              <Col>
                <Reload
                  refetch={() => {
                    refetch({});
                  }}
                />
              </Col>
            </Col>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify={'end'} gutter={10}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <DatePicker.RangePicker
                presets={rangePresets}
                format={'YYYY-MM-DD'}
                onChange={handleDateRangeChange}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Input
                placeholder='🔍 Search Invoices...'
                onBlur={handleSearch}
                onPressEnter={handleSearch}
                allowClear
              ></Input>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* TABLE */}
      <Table
        size='small'
        bordered
        rowKey={(e) => e.invoice_id}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: true }}
        pagination={count > 50 ? tableParams.pagination : false}
        onChange={(args1, args2, args3) => {
          setTableParams((prev) => ({ ...prev, pagination: args1 }));
          setQuery &&
            args1.current &&
            args1?.pageSize &&
            setQuery(`page=${args1?.current}&size=${args1?.pageSize}`);
        }}
      />
      {(isLoading || isFetching) && <LoadingIndicator />}
    </>
  );
};

export default InvoiceVisaLists;
