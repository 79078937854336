import { Form, Table } from 'antd';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import ReportsHeader from '../../../../components/common/ReportsHeader';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { IUserLoginFromDataType } from '../types/userLoginTypes';
import { useLazyGetUserLoginHistoryQuery } from '../endpoints/userLoginEndpoints';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import { UserLoginColumn } from '../utils/UserLoginColumn';

const UserLoginHistory = () => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldValue('date_range', [dayjs(), dayjs()]);
  }, []);
  const [userLoginHistory, { data: userLogin, isLoading }] =
    useLazyGetUserLoginHistoryQuery();

  const onFinish = async (values: IUserLoginFromDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;

    const body: IUserLoginFromDataType = {
      user_id: values.user_id,
      date_range: date_range,
    };
    userLoginHistory({
      user_id: body.user_id,
      date_range: body.date_range,
      currentPage: pagination.current,
      currentSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    const setFrom_date = dayjs(values.date_range[0]).format('YYYY-MM-DD');
    const setTo_date = dayjs(values.date_range[1]).format('YYYY-MM-DD');
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    if (values.user_id)
      userLoginHistory({
        user_id: values.user_id,
        date_range: date_range,
        currentPage: pagination.current,
        currentSize: pagination.pageSize,
      });
  }, [pagination.pageSize, pagination.current]);
  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'User Login History']} />
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <ReportsHeader
          date_range
          required
          showSelectUser
          reports_title='User Login History'
        />
      </Form>

      <Table
        size='small'
        bordered
        columns={UserLoginColumn()}
        dataSource={userLogin?.data}
        scroll={{ x: true }}
        pagination={{
          ...pagination,
          total: userLogin?.count,
          showSizeChanger: true,
          pageSizeOptions: ['50', '100', '200', '500'],
          onChange: handlePaginationChange,
        }}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
      />
    </>
  );
};

export default UserLoginHistory;
