import { FileTextOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Row,
  Space,
  Table,
  theme,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  TableParams,
  handleTableChange,
} from '../../../../common/utils/common.utils';
import { FormButton } from '../../../../components/common/FormItem/FormItems';
import { SelectPassport } from '../../../../components/common/FormItem/SelectCustomFeilds';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useLazyGetAllPassportWiseReportQuery,
  useLazyGetExcelsPassportWiseQuery,
} from '../endpoints/passportWiseReportEndpoints';
import { IPassportWiseReportFormDataType } from '../types/passportWiseReportTypes';
import passportWiseReportColumn from '../utils/passportWiseReportColumn';
import CommonViewReport from '../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport';
import { useLocation } from 'react-router';

const PassportWiseReport = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [form] = Form.useForm();

  const [fetchExcel] = useLazyGetExcelsPassportWiseQuery();
  const [excelQuery, setExcelQuery] = useState<{
    Id: string;
  }>({
    Id: '',
  });
  const [fetchPassportWiseReport, { data, isLoading }] =
    useLazyGetAllPassportWiseReportQuery();

  const onFinish = async (values: IPassportWiseReportFormDataType) => {
    await fetchPassportWiseReport({
      haji_info_passport_id: values.haji_info_passport_id,
      query: `?page=${queryData.current}&size=${queryData.pageSize}`,
    });
    setExcelQuery({
      Id: values.haji_info_passport_id.toString(),
    });
  };

  //pagination--start---
  const [count, setCount] = useState<number>(0);
  useEffect(() => {
    setCount(data?.count || 0);
  }, [data]);

  // @Handle pagination
  const [queryData, setQueryData] = useState<{
    isTrash: '0' | '1';
    current: number;
    pageSize: number;
  }>({ current: 1, isTrash: '0', pageSize: 50 });

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      defaultPageSize: 50,
      showSizeChanger: true,
      pageSizeOptions: ['50', '100', '200', '500'],
      pageSize: 20,
      total: count,
    },
  });

  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, total: count },
    }));
  }, [count]);

  // @refetch
  const refetch = ({ query }: { query?: string }) => {
    const values: IPassportWiseReportFormDataType = form.getFieldsValue();
    fetchPassportWiseReport({
      haji_info_passport_id: values.haji_info_passport_id,
      query: `${query}`,
    });
  };
  //pagination--end---
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Passport_Wise_Report`,
  });

  useEffect(() => {
    if (searchParams && location.search) {
      const id = searchParams.get('passport_id');
      form.setFieldValue('haji_info_passport_id', Number(id));
      form.submit();
    } else {
      form.setFieldValue('date_range', [dayjs(), dayjs()]);
      form.setFieldValue('haji_info_passport_id', 'all');
      const query = `?page=${queryData.current}&size=${queryData.pageSize}`;
      fetchPassportWiseReport({
        haji_info_passport_id: 'all',
        query: query,
      });
    }
  }, []);

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
        }}
      >
        <Table
          columns={passportWiseReportColumn()}
          size='small'
          bordered
          pagination={count > 50 ? tableParams.pagination : false}
          dataSource={data?.data}
          scroll={{ x: true }}
          loading={{ spinning: isLoading, indicator: loadingIndicator }}
          onChange={(args1, args2, args3) =>
            handleTableChange({
              args: {
                pagination: args1,
                filters: args2,
                sorter: args3,
              },
              states: {
                refetch,
                setQueryData,
                setTableParams,
                tableParams,
              },
            })
          }
        />
      </ConfigProvider>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: '', title: 'Passport Wise Report' }}
      />
    </div>
  );

  return (
    <>
      <BreadCrumb arrOfOption={['Reports', 'Passport Wise Report']} />
      <Space style={{ marginBottom: '1rem' }}>
        <Button type='primary' onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type='primary'
          onClick={() => {
            fetchExcel({
              client_id: excelQuery.Id,
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
      </Space>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Row justify={'space-between'}>
          <Col>
            <Row gutter={16}>
              <SelectPassport
                name='haji_info_passport_id'
                label='Passport No'
                size={16}
                offDropDown
                showAll
              />
              <Col lg={2}>
                <FormButton label='Search' icon textAlign='left' />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default PassportWiseReport;
