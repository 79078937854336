import { Row, Table } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useAppDispatch } from '../../../../app/hooks';
import { selectUser } from '../../../../auth/states/userSlice';
import { setModal } from '../../../../common/slices/commonSlice';
import { perProps } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import LoadingIndicator from '../../../../components/common/spinner/LoadingIndicator';
import { useGetAllAirportWithPaginationQuery } from '../endpoints/airportEndpoints';
import { AirportDataType } from '../types/AirportTypes';
import { airportColumns } from '../utils/utils';

const Airport = ({ permission }: perProps) => {
  const dispatch = useAppDispatch();
  const [editInfo, setEditInfo] = useState<AirportDataType | null>(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const user = useSelector(selectUser);
  const showModal = () => {
    dispatch(setModal(true));
  };
  const {
    isLoading,
    isFetching,
    data: airport,
    refetch,
  } = useGetAllAirportWithPaginationQuery(
    { ...pagination },
    {
      selectFromResult: (cache) => {
        const data: any = cache.data?.data;
        const dataToReturn: AirportDataType[] = [];
        const airportNameList: string[] = [];
        const total = cache.data?.count;

        if (data) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            airportNameList.push(element.airline_airport);
            dataToReturn.push({
              ...element,
              key: i + 1,
              airline_created_by: user?.user_id as number,
            });
          }
        }
        return { ...cache, data: { dataToReturn, airportNameList, total } };
      },
    }
  );

  return (
    <>
      <PageStyle>
        <BreadCrumb
          arrOfOption={['Configuration', 'Airport']}
          refetch={refetch}
          reloaderSize='small'
        />
        {/* <CommonTableHeader
          width
          align
          setNull={setEditInfo}
          title='Airport'
          modalTitle={editInfo ? 'Edit Airport Modal' : 'Add Airport Modal'}
          button_text='Airport'
          element={
            editInfo ? (
              <EditAirportModal info={editInfo} setNull={setEditInfo} />
            ) : (
              <AddAirportModal />
            )
          }
          permission={permission?.['create:any']}
        /> */}

        <Row justify={'center'}>
          <Table
            size='small'
            style={{ width: 900 }}
            bordered
            pagination={{
              ...pagination,
              total: airport.total,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }}
            columns={airportColumns({
              setEditInfo,
              showModal,
              permission,
              pagination,
            })}
            dataSource={airport.dataToReturn}
            scroll={{ x: true }}
            loading={{
              spinning: isLoading || isFetching,
              indicator: <LoadingIndicator />,
            }}
          />
        </Row>
      </PageStyle>
    </>
  );
};

export default Airport;

const PageStyle = styled.div``;
