import { Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { HTTPResponse } from "../../../../common/types/commonTypes";
import {
  formatAmount,
  formatAmountAbs,
} from "../../../../common/utils/common.utils";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import {
  IClientLedger,
  IClientLedgerDataType,
} from "../types/clientLedgerTypes";

type Props = {
  clientLedger: HTTPResponse<IClientLedgerDataType> | undefined;
  columns: ColumnsType<IClientLedger>;
  isForPrint?: boolean;
  pagination: {
    current: number;
    pageSize: number;
  };
  handlePaginationChange: (current: number, pageSize: number) => void;
  loading: boolean;
};

const { Text } = Typography;

const ClientLedgerTable = ({
  clientLedger,
  columns,
  isForPrint,
  pagination,
  handlePaginationChange,
  loading,
}: Props) => {
  if (!clientLedger?.data?.ledgers) return <></>;

  const total_debit = clientLedger?.data.summary?.total_debit || 0;
  const total_credit = clientLedger?.data.summary?.total_credit || 0;
  const client_lbalance = clientLedger?.data?.client?.client_lbalance || 0;

  return (
    <div>
      <Text className="mb-5">
        You have total: {clientLedger?.count} results
      </Text>
      <Table
        rowClassName={(record) => {
          const { ctrxn_lbalance, type } = record;
          let className = "normal-table-row";
          const isBF = type === "BF";

          if (ctrxn_lbalance == 0) {
            className += " void_inv-row-style";
          }

          if (isBF) {
            className += " bf-row";
          }

          return className;
        }}
        style={{ width: "100%" }}
        scroll={{ x: 1200 }}
        tableLayout="fixed"
        size="small"
        bordered
        rowKey={(e) => e.ctrxn_id}
        className="normal-table"
        pagination={
          clientLedger?.count !== undefined && clientLedger?.count < 20
            ? false
            : {
                ...pagination,
                total: clientLedger?.count,
                showSizeChanger: true,
                pageSizeOptions: ["20", "50", "100", "200", "500"],
                onChange: handlePaginationChange,
              }
        }
        dataSource={clientLedger?.data?.ledgers}
        columns={columns}
        loading={{
          spinning: loading,
          indicator: loadingIndicator,
        }}
        summary={(rec) => {
          return (
            <Table.Summary>
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={0} colSpan={4}>
                <Text strong style={{ textAlign: "center", fontSize: 14 }}>
                  TOTAL
                </Text>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={1} colSpan={1}>
                <div style={{ textAlign: "right" }}>
                  <Typography.Text style={{ color: "red" }} className="nowrap">
                    {formatAmount(total_debit)}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={2} colSpan={1}>
                <div style={{ textAlign: "right" }}>
                  <Typography.Text
                    style={{ color: "green" }}
                    className="nowrap"
                  >
                    {formatAmount(total_credit)}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>

              <Table.Summary.Cell index={56} colSpan={1}>
                <div style={{ textAlign: "right" }}>
                  <Typography.Text
                    className="nowrap"
                    style={{
                      color: Number(client_lbalance) < 0 ? "red" : "green",
                    }}
                  >
                    {isForPrint
                      ? formatAmount(client_lbalance)
                      : formatAmountAbs(client_lbalance)}
                  </Typography.Text>
                </div>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} colSpan={2} />
            </Table.Summary>
          );
        }}
      />

      <Row justify={"end"} className="mt-10">
        <Typography.Title level={5}>
          LAST BALANCE :{" "}
          <span
            className={
              Number(client_lbalance) < 0 ? "color-due" : "color-profit"
            }
          >
            {" "}
            {formatAmountAbs(client_lbalance)}
          </span>{" "}
          BDT{" "}
        </Typography.Title>
      </Row>
    </div>
  );
};

export default ClientLedgerTable;
