import { Button, Space, Table, Typography, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Popconfirm from 'antd/lib/popconfirm';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAppSelector } from '../../../../app/hooks';
import { IPermission } from '../../../../common/types/commonTypes';
import BreadCrumb from '../../../../components/common/breadCrumb/BreadCrumb';
import { loadingIndicator } from '../../../../components/common/spinner/LoadingIndicator';
import {
  useDeleteClientToClientMutation,
  useGetAllHajjiManagementQuery,
} from '../../API/Endpoints/HajjiManageMentEndspont';
import ClienttoClientViewModal from '../../Components/ClientToClientCom/ClienttoClientViewModal';
import {
  IClToClViewModal,
  IHajjiManagementAll,
} from '../../Types/HajjiManagement.interface';

type Props = {
  permission?: IPermission;
};

const AllClientToClient = ({ permission }: Props) => {
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });

  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };

  const {
    data: tableData,
    isLoading,
    refetch,
  } = useGetAllHajjiManagementQuery({ ...pagination });

  const [
    deleteClToCl,
    { isError, isSuccess, isLoading: deleteLoading, error },
  ] = useDeleteClientToClientMutation();

  // ============== view modal
  const [modal1Open, setModal1Open] = useState(false);
  const [modalData, setModalData] = useState<IClToClViewModal>();

  const handleView = (data: IHajjiManagementAll) => {
    const modalData = {
      trackingNo: data.ctransfer_tracking_no,
      transferFrom: data?.transfer_from_name,
      transferTo: data?.transfer_to_name,
      date: data?.transfer_date,
    };
    setModalData(modalData);
    setModal1Open(true);
  };

  // =========== HANDLE DELETE ==============
  const handleDeleteClientToClient = (id: number, index: number) => {
    setColumnIndex(index);

    deleteClToCl({ id: id, user_id: user?.user_id });
  };

  useEffect(() => {
    if (isSuccess) {
      setColumnIndex(null);
      message.success('Delete client to client success!');
    }
    if (isError) {
      setColumnIndex(null);
      message.error('Delete Failed!');
    }
  }, [isSuccess, isError]);

  // ============ TAble Column
  const columns: ColumnsType<IHajjiManagementAll> = [
    {
      title: 'SL',
      key: 'ctransfer_id',
      render: (text, data, index) => <>{index + 1}</>,
    },
    {
      title: 'Job Name',
      dataIndex: 'ctransfer_job_name',
      key: 'ctransfer_job_name',
    },
    {
      title: 'Tracking No',
      dataIndex: 'ctransfer_tracking_no',
      key: 'ctransfer_tracking_no',
    },
    {
      title: 'Transfer Date',
      dataIndex: 'transfer_date',
      key: 'transfer_date',
    },
    {
      title: 'Note',
      dataIndex: 'ctransfer_note',
      key: 'ctransfer_note',
    },

    {
      title: 'Action',

      render: (_, data, index) => (
        <Space>
          {permission?.['read:any'] && (
            <Button
              onClick={() => handleView(data)}
              size='small'
              type='primary'
              style={{ backgroundColor: '#00a65a', border: 'none' }}
            >
              <Typography style={{ color: '#ffffff' }}>View</Typography>
            </Button>
          )}

          {permission?.['update:any'] && (
            <Button
              type='primary'
              size='small'
              style={{ backgroundColor: '#00c0ef' }}
              onClick={(e) =>
                navigate(
                  `/haji/transfer/client_to_client_edit/${data.ctransfer_id}`,
                  { state: location.pathname }
                )
              }
            >
              Edit
            </Button>
          )}

          {permission?.['delete:any'] && (
            <Button
              size='small'
              type='primary'
              style={{ background: '#e90800', border: 'none' }}
              loading={columnIndex === index && true}
            >
              <Popconfirm
                onConfirm={() =>
                  handleDeleteClientToClient(data.ctransfer_id, index)
                }
                title='Sure to delete?'
              >
                Delete
              </Popconfirm>
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <StyleComponent>
      <BreadCrumb
        arrOfOption={['List of Transfer', 'Moallem to Moallem']}
        refetch={refetch}
        reloaderSize='small'
      />
      <ClienttoClientViewModal
        modal1Open={modal1Open}
        setModal1Open={setModal1Open}
        modalData={modalData}
      />

      <Table
        size='small'
        bordered
        rowKey={(e) => e.ctransfer_id}
        columns={columns}
        dataSource={tableData?.data}
        loading={{ spinning: isLoading, indicator: loadingIndicator }}
        pagination={
          tableData?.count !== undefined && tableData?.count < 20
            ? false
            : {
              ...pagination,
              total: tableData?.count,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '200', '500'],
              onChange: handlePaginationChange,
            }
        }
      />
    </StyleComponent>
  );
};

export default AllClientToClient;

const StyleComponent = styled.div`
  .transfer-in-list {
    border: 1px solid lightgray;
    width: 100%;
  }

  .transfer-in-list .transfer-in-list-td > td {
    padding: 10px !important;
    border-bottom: 1px solid lightgray;
    font-size: 13px !important;
  }

  .transfer-in-list .ant-table-thead .ant-table-cell {
    padding: 7px !important;
    font-size: 13px !important;
    border-bottom: 1px solid lightgray;
  }
`;
