import dayjs from "dayjs";
import { useLazyGetAllRefundReportClientQuery } from "../endpoints/refundReportEndpoints";
import {
  IRefundReportClientDataTypes,
  IRefundReportFormDataType,
} from "../types/refundReportTypes";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Form,
  Radio,
  RadioChangeEvent,
  Space,
  Table,
  Typography,
} from "antd";
import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import BreadCrumb from "../../../../components/common/breadCrumb/BreadCrumb";
import { FileTextOutlined, PrinterOutlined } from "@ant-design/icons";
import ReportsHeader from "../../../../components/common/ReportsHeader";
import { RefundReportColumnClient } from "../utils/RefundReportColumn";
import { loadingIndicator } from "../../../../components/common/spinner/LoadingIndicator";
import { useLazyGetExcelQuery } from "../../ledgers/endpoints/ledgerEndpoints";
import CommonViewReport from "../../../../components/common/Invoice/ViewInvoiceInfo/CommonViewReport";
import { Fixed2 } from "../../../../common/utils/common.utils";
const ClientRefund = ({
  setValue,
  value,
}: {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  value: number;
}) => {
  const [fetchExcel] = useLazyGetExcelQuery();
  const [excelQuery, setExcelQuery] = useState<{
    query: string;
  }>({
    query: "",
  });

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const from_date = searchParams.get("from_date");
    const to_date = searchParams.get("to_date");

    if (from_date && to_date) {
      form.setFieldValue("date_range", [dayjs(from_date), dayjs(to_date)]);
      form.submit();
    } else {
      form.setFieldValue("date_range", [dayjs(), dayjs()]);
    }
  }, []);

  //pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
  });
  const handlePaginationChange = (current: number, pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current,
      pageSize,
    }));
  };
  const [form] = Form.useForm();

  let ctotalRefund: number = 0;
  let customerTotalAmount: number = 0;
  let ctotalReturn: number = 0;
  let totalNetProfitLoss: number = 0;
  const [
    fetchRefundReportClient,
    { data: client_data, isLoading, isFetching },
  ] = useLazyGetAllRefundReportClientQuery();

  if (client_data?.data) {
    for (let i = 0; i < client_data?.data.length; i++) {
      customerTotalAmount += Number(client_data?.data[i].client_refund_charge);

      totalNetProfitLoss +=
        Fixed2(client_data?.data[i].client_refund_charge) -
        Fixed2(client_data?.data[i].refund_total_amount);
    }
  }

  const onFinish = async (values: IRefundReportFormDataType) => {
    const setFrom_date = dayjs(values.date_range[0]).format("YYYY-MM-DD");
    const setTo_date = dayjs(values.date_range[1]).format("YYYY-MM-DD");
    const date_range = `from_date=${setFrom_date}&to_date=${setTo_date}`;
    const body = {
      date_range: date_range,
      currentSize: pagination.current,
      currentPage: pagination.pageSize,
    };
    await fetchRefundReportClient({
      date_range: body.date_range,
      currentPage: body.currentPage,
      currentSize: body.currentSize,
    });
    setExcelQuery({
      query: `?${body.date_range}&page=${body.currentSize}&size=${body.currentPage}`,
    });
  };
  useEffect(() => {
    const date_range = `from_date=${dayjs().format(
      "YYYY-MM-DD"
    )}&to_date=${dayjs().format("YYYY-MM-DD")}`;
    const body = {
      date_range: date_range,
      currentSize: pagination.current,
      currentPage: pagination.pageSize,
    };
    fetchRefundReportClient({
      date_range: body.date_range,
      currentPage: body.currentPage,
      currentSize: body.currentSize,
    });
  }, []);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Refund Report`,
  });
  //Excel sheet Data
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  const optionsSummaryReport = [
    { label: "Client Report", value: 1 },
    { label: "Vendor Report", value: 2 },
  ];

  // @HANDLE CONTENTS
  const jsx_com = (
    <div>
      <>
        <Table
          size="small"
          rowKey={(e) => e.refund_id}
          bordered
          dataSource={client_data?.data}
          columns={RefundReportColumnClient()}
          loading={{
            spinning: isLoading || isFetching,
            indicator: loadingIndicator,
          }}
          pagination={
            client_data?.count && client_data?.count > 20
              ? {
                  ...pagination,
                  total: client_data?.count,
                  showSizeChanger: true,
                  pageSizeOptions: ["50", "100", "200", "500"],
                  onChange: handlePaginationChange,
                }
              : false
          }
          summary={(data) => {
            const totalCharge = data.reduce((accumulator, item) => {
              return Number(accumulator) + Number(item?.client_refund_charge);
            }, 0);
            const totalRefund = data.reduce((accumulator, item) => {
              return Number(accumulator) + Number(item?.refund_total_amount);
            }, 0);
            const totalProfitLoss = data.reduce((accumulator, item) => {
              return Number(accumulator) + Number(item?.refund_profit);
            }, 0);
            console.log(totalCharge);
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={1} colSpan={4}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>Total: </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={4}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>
                        {Fixed2(totalCharge).toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>
                        {Fixed2(totalRefund).toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={2}>
                    <div
                      style={{
                        fontWeight: "700",
                        textAlign: "right",
                      }}
                    >
                      <Typography.Text strong>
                        {Fixed2(totalProfitLoss).toLocaleString()}
                      </Typography.Text>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />
      </>
    </div>
  );

  // @HANDLE PRINT WITH HEADER FOOTER
  const print_content = (
    <div hidden>
      <CommonViewReport
        children={jsx_com}
        printRef={componentRef}
        title={{ info_title: "", title: "Refund Report" }}
      />
    </div>
  );
  return (
    <>
      <BreadCrumb arrOfOption={["Reports", "Client Refund Report"]} />

      <Space style={{ marginBottom: "1rem" }}>
        <Button type="primary" onClick={handlePrint}>
          <PrinterOutlined />
          Print
        </Button>
        <Button
          type="primary"
          onClick={() => {
            fetchExcel({
              client_id: "",
              query: excelQuery.query,
              excelApiName: "refundReportClient",
              excelName: "refundReportClient",
            });
          }}
        >
          <FileTextOutlined />
          Excel Report
        </Button>
        <Radio.Group
          options={optionsSummaryReport}
          onChange={onChange}
          value={value}
          optionType="button"
          buttonStyle="solid"
        />
      </Space>

      <br />

      <Form layout="vertical" form={form} onFinish={onFinish}>
        <ReportsHeader
          date_range
          required
          reports_title="Client Refund Report"
        />
      </Form>

      {/* //----------------//------------// @LEDGER TABLE HERE //----------------//------------// */}
      {jsx_com}

      {/*-----//---------//--------- @PRINTABLE CONTENT -----------//-----------//-----*/}
      {print_content}
    </>
  );
};

export default ClientRefund;
