import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import userReducer from "../auth/states/userSlice";
import configReducer from "../auth/states/configSlice";
import commonSlice from "../common/slices/commonSlice";
import accountModalSlice from "../modules/Accounts/AccountsState/accountSlice";
import loanModalSlice from "../modules/Loan_Management/Loan_State/loanSlice";
import chequeModalSlice from "../modules/Cheque_Management/states/chequeSlice";
import { api } from "./baseQuery";
import themeSlice from "../common/slices/themeSlice";
import colorSlice from "../common/slices/ColorSlice";
import modalSlice from "../common/slices/modalSlice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    user: userReducer,
    themeSlice: themeSlice,
    commonSlice: commonSlice,
    accountModalSlice: accountModalSlice,
    loanModalSlice: loanModalSlice,
    chequeModalSlice: chequeModalSlice,
    configSlice: configReducer,
    color: colorSlice,
    modalSlice: modalSlice,
  },

  middleware: (defaulMiddleware) =>
    defaulMiddleware({ serializableCheck: false }).concat(api.middleware),
  // preloadedState: { user: user() },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
