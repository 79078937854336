import dayjs from "dayjs";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import {
  Fixed2,
  formatAmountAbs,
  formatNumber,
} from "../../../common/utils/common.utils";
import { IWeeklyMonthlyDailyMonthly } from "../interfaces/dashboard.interfaces";
import DashboardTitle from "./DashboardTitle";

type Props = {
  data: IWeeklyMonthlyDailyMonthly | undefined;
  isDarkMode: any;
};

const MonthlyReport = ({ data, isDarkMode }: Props) => {
  const from_date = dayjs().startOf("month").format("YYYY-MM-DD");
  const to_date = dayjs().format("YYYY-MM-DD");

  const monthly_summary = data?.monthlySalesInfo;

  const profit_loss = Math.round(
    Fixed2(monthly_summary?.sales) +
      Fixed2(monthly_summary?.service_charge) -
      Fixed2(monthly_summary?.discount) -
      Fixed2(monthly_summary?.purchase)
  );

  const reportData = [
    {
      label: "Sales",
      name: "Sales Amount",
      value: formatNumber(monthly_summary?.sales),
      url: `/reports/daily_sales_report?from_date=${from_date}&to_date=${to_date}`,
    },
    {
      label: "Collection",
      name: "Collection Amount",
      value: formatNumber(monthly_summary?.collection),
      url: `/reports/collection-report?from_date=${from_date}&to_date=${to_date}`,
    },

    {
      label: "Discount",
      name: "Discount Amount",
      value: formatNumber(monthly_summary?.discount),
      url: `/reports/head_wise_expense_report?from_date=${from_date}&to_date=${to_date}`,
    },
    {
      label: "Purchased",
      name: "Purchased Amount",
      value: formatNumber(monthly_summary?.purchase),
      url: `/reports/vendor_wise_purchase_and_payment?from_date=${from_date}&to_date=${to_date}`,
    },

    {
      label: "Payment",
      name: "Payment Amount",
      value: formatNumber(monthly_summary?.payment),
      url: `/vendors/payment?y_from_date=${from_date}&y_to_date=${to_date}`,
    },

    {
      label: "Profit",
      name: profit_loss < 0 ? "Loss" : "Profit",
      value: formatAmountAbs(profit_loss),
      url: `/reports/over_all_profit_loss?from_date=${from_date}&to_date=${to_date}`,
    },
  ];

  return (
    <div className="dashboard-box-shape">
      <DashboardTitle
        title="MONTHLY REPORT"
        linkTitle="View Overall"
        link={`/reports/over_all_profit_loss?from_date=${from_date}&to_date=${to_date}`}
      />

      <div style={{ padding: "0px 20px 10px" }}>
        {reportData?.map((item: any, index: number) => (
          <div
            className={`${isDarkMode ? "report_box-dark" : ""} report_box${
              index === reportData?.length - 1 ? " last_box" : ""
            }`}
            key={index}
          >
            <Link
              to={item.url}
              className="hover_effect"
              style={getColor(index)}
            >
              {item.name}
            </Link>
            <p>{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthlyReport;

export const getColor = (index: number): CSSProperties => {
  let color = "";
  // FROM SIR
  if (index === 0) {
    color = "#18b4e9";
  } else if (index === 1) {
    color = "#90d60f";
  } else if (index === 2) {
    color = "#da3fd2";
  } else if (index === 3) {
    color = "#fd7e14";
  } else if (index === 4) {
    color = "#179BAE";
  }

  return { color, fontWeight: "bold", cursor: "pointer", padding: "10px 0" };
};
export const getDashboardBg = (index: number): CSSProperties => {
  let color = "";
  if (index === 0) {
    color = "#EEB76B";
  } else if (index === 1) {
    color = "#E2703A";
  } else if (index === 2) {
    color = "#9C3D54";
  } else if (index === 3) {
    color = "#310B0B";
  } else if (index === 4) {
    color = "#03506F";
  } else if (index === 5) {
    color = "#BBBBBB";
  }

  return { background: color, padding: "5px 15px" };
};
