import { FormInstance } from "antd/lib/form";
import InvoiceTourAccommodationForm from "../Components/Accommodation/InvoiceTourAccommodationForm";
import InvoiceTourFoodForm from "../Components/Foods/InvoiceTourFoodForm";
import GuideAndTicketForm from "../Components/GuideAndTicket/GuideAndTicketForm";
import InvoiceTourOtherTransportForm from "../Components/OtherTransport/InvoiceTourOtherTransportForm";
import InvoiceTourTransportForm from "../Components/Transport/InvoiceTourTransportForm";
import FormHeaderTitle from "../../Invoice(Visa)/Components/FormHeaderTitle";
import { IInvoiceTourEditData } from "../Types/InvoiceTourTypes";

type Props = {
  form: FormInstance<any>;
  invoiceTourDetails?: IInvoiceTourEditData | undefined;
};

const AddCostingTour = ({ form, invoiceTourDetails }: Props) => {
  return (
    <>
      <div className="p-5 border mt-5 mb-10">
        <FormHeaderTitle title="Transport Information 🚌" />
        <InvoiceTourTransportForm
          form={form}
          tourTransport={invoiceTourDetails?.tourTransports}
        />
      </div>
      <div className="p-5 border mt-5  mb-10">
        <FormHeaderTitle title="Food Information 🥫" />
        <InvoiceTourFoodForm
          form={form}
          tourFood={invoiceTourDetails?.tourFoods}
        />
      </div>
      <div className="p-5 border mt-5  mb-10">
        <FormHeaderTitle title="Accommodation Information 🛌" />
        <InvoiceTourAccommodationForm
          form={form}
          tourAccom={invoiceTourDetails?.tourAccms}
        />
      </div>
      <div className="p-5 border mt-5  mb-10">
        <FormHeaderTitle title="Tour Other-transport Information 🚠" />
        <InvoiceTourOtherTransportForm
          form={form}
          tourOther={invoiceTourDetails?.tourOtherTrans}
        />
      </div>

      <GuideAndTicketForm form={form} />
    </>
  );
};

export default AddCostingTour;
