export const getChequeLedgerRefLink = (ref_type?: string, ref_id?: number) => {
  if (!ref_type) {
    return null;
  }

  let link = null;

  switch (true) {
    case ref_type.includes("MR"):
      link = "/moneyreceipt/view/" + ref_id;
      break;

    case ref_type.includes("ADR"):
      link = "/moneyreceipt/advance/view/" + ref_id;
      break;

    case ref_type.includes("PR"):
      link = "/noncomairticket/details/" + ref_id;
      break;

    case ref_type.includes("EXP"):
      link = "/expense/view/" + ref_id;
      break;

    case ref_type.includes("LN"):
      link = "/loan/view/" + ref_id;
      break;

    case ref_type.includes("LNR"):
      link = "/loan/receive/view/" + ref_id;
      break;

    case ref_type.includes("LNP"):
      link = "/loan/payment/view/" + ref_id;
      break;

    default:
      break;
  }

  return link;
};
