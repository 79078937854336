import { ColumnsType } from "antd/es/table";
import Table from "antd/lib/table";
import { IViewBillingInfo } from "../../../../common/types/commonInterfaces";
import { Fixed2 } from "../../../../common/utils/common.utils";
import FormHeaderTitle from "../../../Invoice(Visa)/Components/FormHeaderTitle";
import { dateFormate } from "../../../Dashboard/Utils/formateDate";

type Props = { billing_information: IViewBillingInfo[] };
const ViewInvoiceTourBilling = ({ billing_information }: Props) => {
  const billingColumn: ColumnsType<IViewBillingInfo> = [
    {
      title: "SL.",
      render: (_, data, index) => <> {index + 1} </>,
    },

    {
      title: "Billing Date",
      dataIndex: "product_name",
      key: "product_name",
      align: "center",
    },
    {
      title: "Country Name",
      dataIndex: "country_name",
      key: "country_name",
      align: "center",
    },
    {
      title: "Pax Name",
      dataIndex: "billing_pax_name",
      key: "billing_pax_name",
      align: "center",
    },
    {
      title: "Total Pax",
      dataIndex: "billing_total_pax",
      key: "billing_total_pax",
      align: "center",
    },
    {
      title: "Room",
      dataIndex: "billing_numof_room",
      key: "billing_numof_room",
      align: "center",
    },
    {
      title: "Total Sales",
      dataIndex: "billing_total_sales",
      key: "billing_total_sales",
      align: "center",
      render: (_, data) => Fixed2(data?.billing_total_sales),
    },
  ];
  return (
    <div>
      <>
        <FormHeaderTitle title="BILLING INFORMATION" />
        <Table
          size="small"
          rowKey={(e) => e.billing_create_date}
          className="invoiceBillingTable"
          rowClassName={"invoiceBillingTd"}
          dataSource={billing_information}
          columns={billingColumn}
          bordered
          pagination={false}
        />
      </>
    </div>
  );
};

export default ViewInvoiceTourBilling;
