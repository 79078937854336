import { Route } from "react-router";
import PermissionRoute from "../../auth/components/PermissionRoute";
import { crud, rolePermissionType } from "../../common/types/commonTypes";

import { moduleType } from "../../auth/states/userSlice";
import { getListPermission } from "../../common/utils/common.utils";
import AirTicketTaxRefundList from "../../modules/Refund/Pages/AirTicketTax/AirTicketTaxRefund_List";
import AirTicketTax_View from "../../modules/Refund/Pages/AirTicketTax/AirTicketTax_View";
import CreateAirTicketTaxRefund from "../../modules/Refund/Pages/AirTicketTax/CreateAirTicketTaxRefund";
import EditAirTicketRefundDetails from "../../modules/Refund/Pages/AirTicketTax/EditAirTicketRefundDetails";
import TaxRefund_Details from "../../modules/Refund/Pages/AirTicketTax/components/TaxRefund_Details";
import AirticketRefund from "../../modules/Refund/Pages/Airticket/AirticketRefund";
import AirticketRefundHistory from "../../modules/Refund/Pages/Airticket/AirticketRefundHistory";
import AirticketRefundLogs from "../../modules/Refund/Pages/Airticket/AirticketRefundLogs";
import AirticketRefundView from "../../modules/Refund/Pages/Airticket/AirticketRefundView";
import EditAdjustRefund from "../../modules/Refund/Pages/Airticket/EditAdjustRefund";
import AirTicketRefund from "../../modules/Refund/Pages/Airticket/NewAirTicketRefund";
import ClientRefundHistory from "../../modules/Refund/Pages/Client/ClientRefundHistory";
import OtherRefundClient from "../../modules/Refund/Pages/Client/OtherRefundClient";
import OthersRefundClientLogs from "../../modules/Refund/Pages/Client/OthersRefundClientLogs";
import OthersRefundClientView from "../../modules/Refund/Pages/Client/OthersRefundClientView";
import TourPackageRefund from "../../modules/Refund/Pages/TourPackage/TourPackageRefund";
import TourRefundHistory from "../../modules/Refund/Pages/TourPackage/TourRefundHistory";
import AddPartialRefunds from "../../modules/Refund/Pages/partial/AddPartialRefunds";
import PartialRefundHistory from "../../modules/Refund/Pages/partial/PartialRefundHistory";
import ViewPartialRefund from "../../modules/Refund/Pages/partial/ViewPartialRefund";

{
  /* ==========================REFUND ===================*/
}

export const RefundRoutes = (
  permission: rolePermissionType | undefined,
  hasPermission: (module: moduleType) => boolean | undefined
) => {
  return (
    <>
      <Route
        path="/refund/airticket-new"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_airticket?.["create:any"]
            }
            element={<AirTicketRefund />}
          />
        }
      />
      <Route
        path="/refund/edit-airticket-adjust-refund/:id"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_airticket?.["create:any"]
            }
            element={<EditAdjustRefund />}
          />
        }
      />
      <Route
        path="/refund/airticket"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_airticket?.["create:any"]
            }
            element={
              <AirticketRefund permission={permission?.refund_airticket} />
            }
          />
        }
      />
      <Route
        path="/refund/history/airticket"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              getListPermission(permission?.refund_airticket)
            }
            element={
              <AirticketRefundHistory
                permission={permission?.refund_airticket}
              />
            }
          />
        }
      />
      <Route
        path="/refund/airticket/details/:id"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_airticket?.[crud.read]
            }
            element={<AirticketRefundView />}
          />
        }
      />
      <Route
        path="/refund/airticket/logs/:id"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_airticket?.[crud.read]
            }
            element={<AirticketRefundLogs />}
          />
        }
      />
      {/* ======== other refund ===================== */}
      <Route
        path="/refund/others"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_other_invoice?.[crud.create]
            }
            element={
              <OtherRefundClient
                permission={permission?.refund_other_invoice}
              />
            }
          />
        }
      />
      <Route
        path="/refund/history/others"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              getListPermission(permission?.refund_other_invoice)
            }
            element={
              <ClientRefundHistory
                permission={permission?.refund_other_invoice}
              />
            }
          />
        }
      />
      {/* ============ tour package refund ===================== */}
      <Route
        path="/refund/tour-package"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_tour_package?.[crud.create]
            }
            element={
              <TourPackageRefund permission={permission?.refund_tour_package} />
            }
          />
        }
      />
      <Route
        path="/refund/history/tour-package"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_tour_package?.[crud.read]
            }
            element={
              <TourRefundHistory permission={permission?.refund_tour_package} />
            }
          />
        }
      />
      <Route
        path="/refund/client/details/:id"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={<OthersRefundClientView />}
          />
        }
      />
      <Route
        path="/refund/tour/details/:id"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={<OthersRefundClientView />}
          />
        }
      />
      <Route
        path="/refund/client/logs/:id"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={<OthersRefundClientLogs />}
          />
        }
      />
      <Route
        path="/refund/partial"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={<AddPartialRefunds />}
          />
        }
      />
      <Route
        path="refund/history/partial"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={
              <PartialRefundHistory permission={permission?.refund_module} />
            }
          />
        }
      />
      <Route
        path="/refund/partial/view/:id"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={
              <ViewPartialRefund permission={permission?.refund_module} />
            }
          />
        }
      />{" "}
      <Route
        path="/refund/tax"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={<CreateAirTicketTaxRefund />}
          />
        }
      />
      <Route
        path="/refund/history/tax"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={
              <AirTicketTaxRefundList permission={permission?.refund_module} />
            }
          />
        }
      />
      <Route
        path="/refund/tax-details"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={<TaxRefund_Details />}
          />
        }
      />
      <Route
        path="/refund/edit-tax-details/:id"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={<EditAirTicketRefundDetails />}
          />
        }
      />
      <Route
        path="/refund/tax-view/:id"
        element={
          <PermissionRoute
            permission={
              hasPermission("refund_module") &&
              permission?.refund_module?.[crud.read]
            }
            element={<AirTicketTax_View />}
          />
        }
      />
    </>
  );
};
